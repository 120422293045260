//components
import Dashboard from "../views/Dashboard";
import ClientManagement from "../views/ClientManagement";
import Financials from "../views/Financials";
import InvoiceDetailsPage from "../views/Financials/InvoiceDetailsPage";
import ClientDetails from "../views/ClientManagement/ClientDetails";
import Report from "../views/Report";
import ReportDetails from "../views/Report/ReportDetails";
import Settings from "../views/Settings";
import Payouts from "../views/Financials/Payouts";
import ExpenseDetailsPage from "../views/Financials/ExpenseDetailsPage";
import Subscription from "../views/Subscription";
import SetUpBussiness from "../views/Bussiness";
import Messages from "../views/Messages";
import Schedule from "../views/Schedule";
import NewPost from "../views/Schedule/NewPost";
import ContentIdeas from "../views/ContentIdeas";

/*
Dashboard
ClientManagement
Financials
InvoiceDetailsPage
ExpenseDetailsPage
Payouts
ClientDetails
Report
Subscription
Messages
Schedule
NewPost
SetUpBussiness
Settings


*/

export const availableRoutes = [
  {
    id: 0,
    name: "dashboard",
    path: "/",
    component: <Dashboard />,
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },
  {
    id: 1,
    name: "dashboard",
    path: "/overview",
    component: <Dashboard />,
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },
  {
    id: 2,
    name: "clients",
    path: "/clients",
    component: <ClientManagement />,
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },
  {
    id: 3,
    name: "Content Ideas",
    path: "/overview/content-ideas",
    component: <ContentIdeas />,
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },
  {
    id: 4,
    name: "finance",
    path: "/invoices-&-financials",
    component: <Financials />,
    isAvailable: ["business", "admin"],
  },
  {
    id: 5,
    name: "InvoiceDetailsPage",
    path: "/invoices-&-financials/invoice/:id",
    component: <InvoiceDetailsPage />,
    isAvailable: ["business", "admin"],
  },
  {
    id: 6,
    name: "ExpenseDetailsPage",
    path: "/invoices-&-financials/expense/:id",
    component: <ExpenseDetailsPage />,
    isAvailable: ["business", "admin"],
  },
  {
    id: 7,
    name: "Payouts",
    path: "/invoices-&-financials/payouts",
    component: <Payouts />,
    isAvailable: ["business", "admin"],
  },
  {
    id: 8,
    name: "ClientDetails",
    path: "/clients/client-details/:id",
    component: <ClientDetails />,
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },
  {
    id: 9,
    name: "Report",
    path: "/report",
    component: <Report />,
    isAvailable: ["business", "admin"],
  },
  {
    id: 10,
    name: "ReportDetails",
    path: "/client-report",
    component: <ReportDetails />,
    isAvailable: ["business", "admin"],
  },
  {
    id: 11,
    name: "Subscriptions",
    path: "/Subscriptions",
    component: <Subscription />,
    isAvailable: ["business", "admin"],
  },
  {
    id: 12,
    name: "Chat",
    path: "/Chat",
    component: <Messages />,
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },
  {
    id: 13,
    name: "Schedule",
    path: "/Schedule",
    component: <Schedule />,
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },
  {
    id: 14,
    name: "Schedule",
    path: "/schedule/newpost",
    component: <NewPost />,
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },
  {
    id: 15,
    name: "SetUpBusiness",
    path: "/setup-business",
    component: <SetUpBussiness />,
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },
  {
    id: 16,
    name: "Settings",
    path: "/settings",
    component: <Settings />,
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },
];
