import React, { useState, useEffect, useCallback } from "react";
import { CenteredModal } from "../Modal";
import { RiErrorWarningFill } from "react-icons/ri";
//redux
import { connect } from "react-redux";
import {
  getConnectedInstagramAccount,
  connectInstagramAccount,
} from "../../redux/actions";
import { toast } from "react-toastify";

const InstagramModal = ({
  modalState,
  setModalState,
  getConnectedInstagramAccount,
  connectInstagramAccount,
  connectInstagramLoading,
  connectInstagramMessage,
  connectInstagramError,
  accounts,
  id,
}) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [currentView, setCurrentView] = useState("info");

  const handleSelectedAccount = (data) => {
    setSelectedAccount(data);
  };

  const handleSubmit = () => {
    if (!selectedAccount) {
      toast.success("Please select an account");
    } else {
      const payload = {
        instagram_id: selectedAccount?.account_id,
        username: selectedAccount?.account_name,
        id,
      };
      connectInstagramAccount(payload);
    }
  };

  const handleCloseModal = useCallback(() => {
    setSelectedAccount(null);
    setModalState(false);
  }, [setSelectedAccount, setModalState]);

  useEffect(() => {
    getConnectedInstagramAccount(id);
  }, [getConnectedInstagramAccount, id]);

  useEffect(() => {
    if (!connectInstagramLoading && connectInstagramMessage?.length) {
      toast.success(connectInstagramMessage);
      handleCloseModal();
    } else if (!connectInstagramLoading && connectInstagramError?.length) {
      toast.error(connectInstagramError);
    }
  }, [
    connectInstagramMessage,
    connectInstagramLoading,
    connectInstagramError,
    handleCloseModal,
  ]);
  return (
    <CenteredModal modalState={modalState} setModalState={setModalState}>
      <h3 className="text-center instagram-modal header">
        Connect Instagram Account
      </h3>
      <div className="p-4 pt-0">
        {currentView === "info" && (
          <div>
            <p className="text-center instagram-modal subheader">
              Ensure you've completed the following steps
            </p>
            <ol style={{ paddingInlineStart: 0, marginLeft: "-10px" }}>
              <li className="my-4 ml-n2">
                Switch your Instagram account to a business account.{" "}
                <a
                  className="learn-more"
                  href="https://help.instagram.com/570895513091465"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </li>
              <li>
                Link the converted Instagram account to a Facebook page.{" "}
                <a
                  className="learn-more"
                  href="https://help.instagram.com/570895513091465"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </li>
            </ol>
            <div className="pt-2 pb-3">
              <button
                type="button"
                className="py-3 w-100 border-0 rounded-4 font-monospace instagram-modal gradient-button"
                onClick={() => setCurrentView("connect")}
              >
                Connect via Facebook
              </button>
            </div>
          </div>
        )}
        {currentView === "connect" ? (
          Array.isArray(accounts) && accounts?.length ? (
            <>
              {accounts?.map((item, index) => {
                return (
                  <div
                    className={`w-full p-3 border border-1 my-4 rounded-4 text-sm instagram-modal account-card ${
                      selectedAccount?.account_id === item?.account_id
                        ? "selected"
                        : ""
                    }`}
                    key={index}
                    onClick={() => handleSelectedAccount(item)}
                  >
                    {item?.account_name}
                  </div>
                );
              })}
              <div className="pt-2 pb-3">
                <button
                  type="button"
                  className="py-2 w-100 border-0 rounded-4 font-monospace instagram-modal button"
                  disabled={connectInstagramLoading}
                  onClick={handleSubmit}
                >
                  Connect
                </button>
              </div>
            </>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center mt-4">
              <RiErrorWarningFill size="30px" style={{ color: "#FFCC00" }} />
              <p
                style={{
                  fontSize: "13px",
                  marginTop: "1rem",
                  textAlign: "center",
                }}
              >
                Sorry, you have no connected accounts at the this time
              </p>
            </div>
          )
        ) : null}
      </div>
    </CenteredModal>
  );
};

const mapStateToProps = (state) => {
  return {
    accounts: state?.socials?.connectedInstagramAccount,
    connectInstagramLoading: state?.socials?.loading?.connectInstagramAccount,
    connectInstagramMessage: state?.socials?.message?.connectInstagramAccount,
    connectInstagramError: state?.socials?.error?.connectInstagramAccount,
  };
};
export default connect(mapStateToProps, {
  getConnectedInstagramAccount,
  connectInstagramAccount,
})(InstagramModal);
