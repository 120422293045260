import React, { useState } from "react";
import { socialChannelOptions } from "./utils";
import FacebookPost from "./socialChannel/Facebook";
import InstagramPost from "./socialChannel/InstagramPost";
import LinkedinPost from "./socialChannel/Linkedin";
import TwitterPost from "./socialChannel/TwitterPost";
import HorizontalTab from "../../../components/horizontaltab";

const SocialChannels = (props) => {
  const [socialChannel, setSocialChannel] = useState("INSTAGRAM");

  const { id } = props;
  const onChangeWalletTransaction = (value) => {
    const selectedValue = value;
    setSocialChannel(selectedValue);
  };
  return (
    <div>
      <HorizontalTab
        options={socialChannelOptions}
        onChange={onChangeWalletTransaction}
      />
      {/* LINKEDIN:  */}
      <div>
        {socialChannel === "INSTAGRAM" ? (
          <InstagramPost id={id} />
        ) : socialChannel === "FACEBOOK" ? (
          <FacebookPost id={id} />
        ) : socialChannel === "TWITTER" ? (
          <TwitterPost id={id} />
        ) : (
          <LinkedinPost id={id} />
        )}
      </div>
    </div>
  );
};

export default SocialChannels;
