import "react-calendar/dist/Calendar.css";
import React from "react";
import ScheduleSteps from "./schedulePost";

const NewPost = () => {

  return (
    <>
      <ScheduleSteps/>
    </>
  );
};

export default NewPost;
