import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
// import billing from "../../../assets/img/billingIcon.png";
// import MasterCard from "../../../assets/img/Mastercard.svg";
// import Visa from "../../../assets/img/visa-logo.svg";
// import { IoIosCheckmarkCircle } from "react-icons/io";
import SubscriptionHistory from "./SubscriptionHistory";
import AddCardModal from "./AddCardModal";

//redux
import { connect } from "react-redux";
import {
  getAllCards,
  getAllSubscriptionDetails,
  removeCard,
} from "../../../redux/actions";
import CurrentPlanCard from "./components/CurrentPlanCard";
import PaymentMethodCard from "./components/PaymentMethodCard";
import CancelPlanModal from "./components/CancelPlanModal";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { toast } from "react-toastify";

const Billing = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelCardModal, setCancelCardModal] = useState(false);

  const {
    getAllCards,
    getAllSubscriptionDetails,
    subscriptionDetails,
    removeCard,
    removeCardLoading,
    removeCardMessage,
    removeCardError,
    cards,
  } = props;

  const openAddCardModal = () => {
    setModalOpen(true);
  };

  const toggleCancelSubscriptionModal = () => {
    setCancelModal(!cancelModal);
  };

  const openCancelCardModal = () => {
    setCancelCardModal(true);
  };
  useEffect(() => {
    getAllCards();
    getAllSubscriptionDetails();
  }, [getAllCards, getAllSubscriptionDetails]);

  useEffect(() => {
    if (!removeCardLoading && removeCardMessage?.length) {
      toast.success(removeCardMessage);
    } else if (!removeCardLoading && removeCardError?.length) {
      toast.error(removeCardError);
    }
  }, [removeCardLoading, removeCardMessage, removeCardError]);
  return (
    <>
      <div className="billing-section mt-5">
        <Row>
          <Row className="g-3">
            <Col sm={6} md={6} lg={6}>
              <CurrentPlanCard
                toggleCancelSubscriptionModal={toggleCancelSubscriptionModal}
                subscriptionDetails={subscriptionDetails}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <PaymentMethodCard
                openAddCardModal={openAddCardModal}
                cards={cards}
                openCancelCardModal={openCancelCardModal}
              />
            </Col>
          </Row>
          <SubscriptionHistory />
        </Row>
      </div>

      <AddCardModal open={modalOpen} setModalState={setModalOpen} />
      <CancelPlanModal
        open={cancelModal}
        setModalState={setCancelModal}
        toggleCancelSubscriptionModal={toggleCancelSubscriptionModal}
      />
      <DeleteModal
        openModal={cancelCardModal}
        setOpenModal={setCancelCardModal}
        type="remove"
        deleteAction={removeCard}
        deleteloading={removeCardLoading}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    cards: state?.subscription?.cards?.card,
    subscriptionDetails: state?.subscription?.subscriptionDetails,
    removeCardLoading: state?.subscription?.loading?.removeCard,
    removeCardError: state?.subscription?.error?.removeCard,
    removeCardMessage: state?.subscription?.message?.removeCard,
  };
};
export default connect(mapStateToProps, {
  getAllCards,
  getAllSubscriptionDetails,
  removeCard,
})(Billing);
