export const availableCurrencies = [
  {
    currency: "USD",
    symbol: "$",
  },
  {
    currency: "NGN",
    symbol: "₦",
  },
  {
    currency: "CAD",
    symbol: "$",
  },
  {
    currency: "GBP",
    symbol: "£",
  },
];
