import React, { useEffect, useCallback, useState } from "react";
import { HiUser, HiPlusCircle } from "react-icons/hi";
import { CenteredModal as Modal } from "../../components/Modal";
import { ThreeDots } from "react-loader-spinner";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { createClient, createTag } from "../../redux/actions";

import { HiOutlineClipboardCopy } from "react-icons/hi";
// import Select from "react-select";
// import countryList from 'react-select-country-list'

import { GenerateString, copierHelper } from "../../utils/helper";

//firebase
import fireBaseStorage from "../../lib/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { toast } from "react-toastify";

const businessCategory = [
  "Agriculture",
  "Arts",
  "Beauty & Lifestyle",
  "Business services",
  "Coaching",
  "Construction",
  "Consulting",
  "Education",
  "Engineering",
  "Entertainment",
  "Events",
  "Financial services",
  "Health & Fitness",
  "Hotels & Hospitality",
  "HR & Recruiting",
  "Legal services",
  "Manufacturing",
  "Marketing",
  "Non-profit",
  "Pet services",
  "Photography",
  "Retail",
  "Technology",
  "Travel & Tourism",
  "Real estate",
  "Others",
];

const ManualAddClient = ({
  addState,
  createClient,
  setAddState,
  createClientError,
  createClientMessage,
  createClientLoading,
  userEmail,
  handleCloseAddModal,
}) => {
  // const options = useMemo(() => countryList().getData(), [])
  const [img, setImg] = useState(null);
  // const [percent, setPercent] = useState(0);

  // const uploadImage = (file) => {
  //   const storageRef = ref(fireBaseStorage, `/client-logo/${file.name}`);
  //   const uploadTask = uploadBytesResumable(storageRef, file);
  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       // const percent = Math.round(
  //       //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //       // ); // update progress
  //       // setPercent(percent);
  //     },
  //     (err) => console.log(err),
  //     () => {
  //       // download url
  //       getDownloadURL(uploadTask.snapshot.ref).then((url) => {
  //         formik.setFieldValue("logo", url);
  //       });
  //     }
  //   );
  // };
  const uploadImage = useCallback((file) => {
    const storageRef = ref(fireBaseStorage, `/client-logo/${file?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // const percent = Math.round(
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // ); // update progress
        // setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          formik.setFieldValue("logo", url);
        });
      }
    );
  }, []);
  //Handle ChangeProfileImage
  const handleProfilePictureChange = (e) => {
    const imageName = e.target.files[0];
    setImg(imageName);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.fullname) {
      errors.fullname = "Client Full name is required";
    }
    if (!values.client_business_name) {
      errors.client_business_name = "Client business name is required";
    }
    if (values.email) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Email is invalid";
      } else if (values?.email?.toLowerCase === userEmail) {
        errors.email = "Email already exists";
      }
    } else {
      errors.email = "Email is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    // if (!values.logo) {
    //   errors.logo = "Logo is required";
    // }
    if (!values.business_category) {
      errors.business_category = "Business Category is required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      client_business_name: "",
      email: "",
      password: "",
      business_category: "",
      logo: "",
    },
    validate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      formik.validateForm();
      // const formData = new FormData();
      // formData.append("fullname", values?.fullname);
      // formData.append("email", values?.email);
      // formData.append("password", values.password);
      // formData.append("business_category", values?.business_category);
      // formData.append("logo", values?.logo);

      createClient(values);
    },
  });

  const handleCloseModal = () => setAddState(false);
  useEffect(() => {
    if (img) {
      uploadImage(img);
    }
  }, [img, uploadImage]);
  useEffect(() => {
    if (createClientError?.length > 0 && !createClientLoading) {
      toast.error(createClientError);
    }
    if (createClientMessage?.length && !createClientLoading) {
      toast.success(createClientMessage);
      handleCloseModal();
      handleCloseAddModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createClientError,
    createClientMessage,
    createClientLoading,
    handleCloseAddModal,
  ]);

  return (
    <Modal modalState={addState} setModalState={setAddState}>
      <div className="add-client-wrapper text-center ">
        <div className="text-center user-icon-container ">
          <HiUser className=" text-center" color="#49A8F8" size="45px" />
        </div>
        <div className="add-client-text text-center mb-4">
          <h3>Add new client</h3>
        </div>
        <form
          className="business-form text-left"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          {formik?.errors?.fullname && formik?.touched?.fullname ? (
            <span className="text-danger text-left field-error">
              {formik?.errors?.fullname}
            </span>
          ) : null}
          <input
            type="text"
            name="fullname"
            placeholder="Full name"
            className={`w-100 ${formik.errors.fullname ? "border-danger" : ""}`}
            onChange={formik.handleChange}
            value={formik?.values?.fullname}
          />
          {formik?.errors?.client_business_name &&
          formik?.touched?.client_business_name ? (
            <span className="text-danger text-left field-error">
              {formik?.errors?.client_business_name}
            </span>
          ) : null}
          <input
            type="text"
            name="client_business_name"
            placeholder="Business Name"
            className={`w-100 ${
              formik.errors.client_business_name ? "border-danger" : ""
            }`}
            onChange={formik.handleChange}
            value={formik?.values?.client_business_name}
          />
          {formik?.errors.email && formik.touched.email ? (
            <span className="text-danger text-left field-error">
              {formik.errors.email}
            </span>
          ) : null}
          <input
            type="email"
            name="email"
            autoComplete="new-password"
            placeholder="Email Address"
            className={`w-100 ${formik?.errors?.email ? "border-danger" : ""}`}
            onChange={formik.handleChange}
            value={formik?.values?.email}
          />

          {/* {errors.country && (
            <span className="text-danger text-left">
              Select Business category
            </span>
          )} */}

          {/* <Controller
            control={control}
            // defaultValue={options.map(c => c.value)}
            name='country'
            className={`w-100 ${errors.country ? 'border-danger' : ''} mb-3`}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                placeholder='Country'
                inputRef={ref}
                // value={options.filter(c => value.includes(c.value))}
                className={`w-100 ${
                  errors.country ? 'border-danger' : ''
                } mb-3`}
                onChange={(e, val) => {
                  onChange(e.value)
                }}
                options={options}
              />
            )} */}
          {/* {...register('country', {
              required: true
            })}
          /> */}

          {formik?.errors?.business_category &&
          formik?.touched?.business_category ? (
            <span className="text-danger text-left field-error">
              {formik?.errors?.business_category}
            </span>
          ) : null}
          <select
            name="business_category"
            onChange={formik.handleChange}
            className={`w-100 ${
              formik.errors?.business_category ? "border-danger" : ""
            }`}
            value={formik?.values?.business_category}
          >
            <option value="">Business category</option>
            {businessCategory.map((el) => (
              <option value={el} key={el}>
                {el}
              </option>
            ))}

            {/* <option>Testing</option> */}
          </select>

          {formik.errors.password && formik.touched.password ? (
            <span className="text-danger text-left field-error">
              {formik.errors.password}
            </span>
          ) : null}
          <div
            className="d-flex flex-row align-items-center justify-content-between"
            style={{ flex: 1, gap: "10px" }}
          >
            <input
              type="text"
              name="password"
              placeholder="Password"
              className={`w-100 mb-0 ${
                formik.errors.password ? "border-danger" : ""
              }`}
              value={formik?.values?.password}
              disabled
            />
            <button
              type="button"
              onClick={() => {
                let generatedString = GenerateString(9);
                formik.setFieldValue("password", generatedString);
              }}
              className="ml-2"
            >
              Generate
            </button>
          </div>
          <div className="d-flex justify-content-start mt-1 mb-3">
            <span
              style={{ fontSize: "12px" }}
              onClick={() => copierHelper(formik.values.password, "Password")}
            >
              <HiOutlineClipboardCopy />
              Copy Password
            </span>
          </div>

          <div className="mb-2 d-flex justify-content-end">
            <div
              className="img-holder"
              style={{
                marginRight: "30px",
              }}
            >
              {img && (
                <img
                  src={URL.createObjectURL(img)}
                  alt="client-logo"
                  className="add-client-logo"
                />
              )}
            </div>
            <div>
              <div
                className="d-flex justify-content-center align-items-center"
                role="button"
              >
                <HiPlusCircle color="#2062F4" size="20" />
                <div role="button">
                  <label
                    role="button"
                    htmlFor="logo"
                    className="mb-0 cursor-pointer"
                    style={{
                      fontWeight: " 700",
                      fontSize: "15px",
                      color: "#2062F4",
                    }}
                  >
                    Select Logo
                  </label>
                </div>
              </div>
              <input
                type="file"
                name="logo"
                id="logo"
                accept=""
                className="d-none"
                onChange={handleProfilePictureChange}
              />
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "12px",
                }}
              >
                3MB max size (500 x 500)
              </p>
            </div>
          </div>
          {formik?.errors?.logo && formik?.touched?.logo ? (
            <p className="text-danger text-center field-error">
              {formik?.errors?.logo}
            </p>
          ) : null}

          <div className="pt-2 pb-3">
            <button
              className="w-100 btn-primary btn"
              type="submit"
              disabled={createClientLoading}
            >
              {createClientLoading ? (
                <div className="text-center w-100 align-items-center">
                  <ThreeDots
                    color="white"
                    height={"12px"}
                    wrapperStyle={{ display: "block" }}
                  />
                </div>
              ) : (
                "Add Client"
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    createClientError: state?.client?.error?.createClient,
    createClientMessage: state?.client?.message?.createClient,
    createClientLoading: state?.client?.loading?.createClient,
    userEmail: state?.auth?.currentUser?.email,
  };
};
export default connect(mapStateToProps, { createClient, createTag })(
  ManualAddClient
);
