import { availableCurrencies } from "../utils/currencies";

const useCurrency = (currentCurrency) => {
  const currencySymbol = availableCurrencies?.find(
    (curr) => curr.currency === currentCurrency
  )?.symbol;
  return { currencySymbol };
};

export default useCurrency;
