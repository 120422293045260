import React, { useCallback, useEffect } from "react";
import { CenteredModal } from "../../components/Modal";
import { IoWarningOutline } from "react-icons/io5";
import { capitalizeFirstLetter } from "../../utils/helper";

//redux
import { useDispatch, useSelector } from "react-redux";
import { changeSubscriptionPlan } from "../../redux/actions";
import { toast } from "react-toastify";

const SubscriptionModal = ({
  modalOpen,
  setModalOpen,
  subscriptionDetails,
  planDetails,
  planDuration,
}) => {
  const dispatch = useDispatch();
  const { loading, error, message } = useSelector(
    (state) => state?.subscription
  );

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const handleChangePlan = () => {
    const payload = { slug: planDetails?.name, is_annual: planDuration };
    dispatch(changeSubscriptionPlan(payload));
  };

  useEffect(() => {
    if (
      !loading?.changeSubscriptionPlan &&
      message?.changeSubscriptionPlan?.length
    ) {
      toast.success(message?.changeSubscriptionPlan);
      closeModal();
    } else if (
      !loading?.changeSubscriptionPlan &&
      error?.changeSubscriptionPlan?.length
    ) {
      toast.error(error?.changeSubscriptionPlan);
    }
  }, [
    loading?.changeSubscriptionPlan,
    error?.changeSubscriptionPlan,
    message?.changeSubscriptionPlan,
    closeModal,
  ]);
  return (
    <CenteredModal
      position="centered"
      modalState={modalOpen}
      setModalState={setModalOpen}
    >
      <div className="d-flex justify-content-center flex-column align-items-center">
        <div
          style={{
            backgroundColor: "#E8F7FF",
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "28px",
          }}
        >
          <IoWarningOutline color="#2062f4" />
        </div>
        <h4 className="text-center my-3">Change Plan</h4>
        <p className="font-14px">
          You are about to switch from the{" "}
          {capitalizeFirstLetter(subscriptionDetails?.plan)}(
          {subscriptionDetails?.is_annual ? "Yearly" : "Monthly"}) Plan to the{" "}
          {planDetails?.name}({planDuration ? "Yearly" : "Monthly"}). To
          Complete the process, click on the button below
        </p>
        <button
          className="btn btn-primary send px-5 py-2 w-100"
          type="button"
          onClick={handleChangePlan}
        >
          Change Plan
        </button>
      </div>
    </CenteredModal>
  );
};

export default SubscriptionModal;
