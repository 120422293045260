import { Route } from "react-router-dom";
import { role } from "./helper";
import { availableRoutes } from "./routes";

const AvailabeRoutes = (userType) => {
  return availableRoutes?.map((routes) => {
    return (
      routes.isAvailable?.includes(role(userType)) && (
        <Route
          exact
          path={routes.path}
          element={routes.component}
          key={routes.id}
        />
      )
    );
  });
};

export default AvailabeRoutes;
