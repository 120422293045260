import React from "react";

const FinetuneIconIG = ({ name, text, date }) => {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.toLocaleString("default", { month: "long" });
  return (
    <div className="finetuneWrapper">
      <div className="finetuneIcon_holder">
        <div className="love_share">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 26"
            fill="none"
            width="24px"
            height="20.58"
          >
            <path
              d="M15 25 3.067 12.807a6.867 6.867 0 0 1-1.976-3.783 6.79 6.79 0 0 1 .656-4.201c.5-.98 1.23-1.83 2.132-2.48a7.137 7.137 0 0 1 3.05-1.254 7.198 7.198 0 0 1 3.3.246 7.085 7.085 0 0 1 2.822 1.691L15 4.935l1.948-1.909a7.087 7.087 0 0 1 2.823-1.691 7.2 7.2 0 0 1 3.3-.246 7.139 7.139 0 0 1 3.051 1.255 6.954 6.954 0 0 1 2.133 2.479v0a6.795 6.795 0 0 1 .654 4.201 6.871 6.871 0 0 1-1.977 3.783L15 25Z"
              stroke="#344563"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            width="24px"
            height="24px"
          >
            <g clip-path="url(#a)">
              <path
                d="M23.267 24a.75.75 0 0 0 .678-1.072l-2.911-6.113a11.245 11.245 0 0 0 1.484-5.596C22.508 5.033 17.468 0 11.282 0h-.034c-2.101 0-4.155.587-5.94 1.696a11.172 11.172 0 0 0-5.011 7 11.175 11.175 0 0 0 1.397 8.494 11.176 11.176 0 0 0 6.998 5.016 11.208 11.208 0 0 0 8.139-1.189l6.112 2.911a.777.777 0 0 0 .324.072ZM11.282 1.5c5.36 0 9.727 4.361 9.735 9.721a9.724 9.724 0 0 1-1.47 5.158.745.745 0 0 0-.04.719l2.174 4.567-4.566-2.175a.747.747 0 0 0-.718.041 9.71 9.71 0 0 1-7.362 1.214 9.69 9.69 0 0 1-6.066-4.347C.129 11.832 1.535 5.808 6.1 2.97a9.74 9.74 0 0 1 5.148-1.47h.033Z"
                fill="#858FA1"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="m21.034 16.815 2.91 6.113a.75.75 0 0 1-1.001 1l-6.112-2.911a11.208 11.208 0 0 1-8.14 1.189 11.176 11.176 0 0 1-6.997-5.016A11.175 11.175 0 0 1 .297 8.696a11.172 11.172 0 0 1 5.01-7A11.247 11.247 0 0 1 11.248 0h.035c6.186 0 11.226 5.033 11.236 11.219a11.245 11.245 0 0 1-1.484 5.596ZM6.1 2.97a9.74 9.74 0 0 1 5.148-1.47h.033c5.36 0 9.727 4.361 9.735 9.721a9.724 9.724 0 0 1-1.47 5.158.745.745 0 0 0-.04.719l2.174 4.567-4.566-2.175a.747.747 0 0 0-.718.041 9.71 9.71 0 0 1-7.362 1.214 9.69 9.69 0 0 1-6.066-4.347C.129 11.832 1.535 5.808 6.1 2.97Z"
                fill="#344563"
              ></path>
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z"></path>
              </clipPath>
            </defs>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 26 26"
            fill="none"
            width="24px"
            height="24px"
          >
            <path
              d="M3.637 10.627a1.5 1.5 0 0 1-.074-2.864l20.275-6.615a.75.75 0 0 1 .945.946l-6.606 20.284a1.5 1.5 0 0 1-2.863-.074l-2.267-9.42-9.41-2.257ZM24.6 1.331 13.043 12.888"
              stroke="#344563"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 24"
            fill="none"
            width="18px"
            height="24px"
          >
            <g clip-path="url(#a)">
              <path
                d="M3.31.7h10.855a1.808 1.808 0 0 1 1.808 1.808l-.004 19.886a.904.904 0 0 1-1.543.64l-5.69-5.16-5.689 5.16a.904.904 0 0 1-1.542-.64V2.504A1.808 1.808 0 0 1 3.31.7v0Z"
                stroke="#344563"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0h18v24H0z"></path>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <p>
        <span className="finetuneName">{name}</span>&nbsp;&nbsp;
        <span
          style={{ fontSize: "14px", fontWeight: 300 }}
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(text)
              .replace(/\\n/g, "<br>")
              ?.replaceAll('"', ""),
          }}
        />
      </p>
      <p className="month_day">
        <span>{month}</span>&nbsp;
        <span>{day}</span>
      </p>
    </div>
  );
};

export default FinetuneIconIG;
