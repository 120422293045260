import React, { useState } from "react";
import { CenteredModal } from "../../../../components/Modal";
import GotItModal from "../schedulePostModal";
import FineTuneFacebook from "./fineTuneChannel/Facebook";
import FineTuneInstagram from "./fineTuneChannel/Instagram";
import FineTuneLinkedin from "./fineTuneChannel/Linkedin";
import FineTuneTwitter from "./fineTuneChannel/Twitter";
import twitter from "../../../../assets/img/twitter.png";
import instagram from "../../../../assets/img/instagram.png";
import facebook from "../../../../assets/img/facebook.png";
import linkedin from "../../../../assets/img/linkedin.png";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { BiArrowBack } from "react-icons/bi";

const FineTuneChannel = ({
  socialMedia,
  next,
  postDate,
  baseCaption,
  images,
  prev,
}) => {
  const [isOpenCreatePost, setIsOpenCreatePost] = useState(false);
  const [iGText, setiGText] = useState();
  const [iGDate, setiGDate] = useState();
  const [facebookText, setFacebookText] = useState();
  const [facebookDate, setFacebookDate] = useState();
  const [linkedinText, setlinkedinText] = useState();
  const [linkedinDate, setlinkedinDate] = useState();
  const [twitterText, setTwitterText] = useState();
  const [twitterDate, setTwitterDate] = useState();
  const [activeTab, setActiveTab] = useState(1);

  const navigate = useNavigate();
  const { state } = useLocation();

  const { userType } = state;
  const socials = useSelector(
    (state) => state?.socials?.businessSocialsConnectionStatus
  );
  const clientSocials = useSelector(
    (state) => state?.client?.socialConnectionStatus
  );
  const selectedAccountSocials =
    userType === "client" ? clientSocials : socials;

  if (!socialMedia.length) {
    alert("No social Channel selected");
    navigate("/Schedule");
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const InstagramData = (iGCaption, iGDate) => {
    setiGText(iGCaption);
    setiGDate(iGDate);
  };

  const twitterData = (twitterCaption, twitterDate) => {
    setTwitterText(twitterCaption);
    setTwitterDate(twitterDate);
  };

  const facebookData = (fbCaption, fbDate) => {
    setFacebookText(fbCaption);
    setFacebookDate(fbDate);
  };

  const linkedinData = (lnCaption, lnDate) => {
    setlinkedinText(lnCaption);
    setlinkedinDate(lnDate);
  };
  return (
    <div>
      <CenteredModal
        modalState={isOpenCreatePost}
        setModalState={setIsOpenCreatePost}
      >
        <GotItModal
          closeModal={setIsOpenCreatePost}
          next={() =>
            next(
              iGText,
              iGDate,
              facebookText,
              facebookDate,
              linkedinText,
              linkedinDate,
              twitterText,
              twitterDate
            )
          }
        />
      </CenteredModal>

      <div className="position">
        <div className="backBtn mb-4" onClick={prev}>
          <BiArrowBack size="24px" />
        </div>
        <Nav tabs>
          {socialMedia.map((item, index) => (
            <NavItem key={index}>
              <NavLink
                className={classnames({ active: activeTab === index + 1 })}
                onClick={() => toggle(index + 1)}
              >
                <div className="socialTab">
                  {item === "instagram" ? (
                    <img src={instagram} alt="instagram" />
                  ) : item === "twitter" ? (
                    <img src={twitter} alt={"twitter"} />
                  ) : item === "facebook" ? (
                    <img src={facebook} alt={"facebook"} />
                  ) : item === "linkedin" ? (
                    <img src={linkedin} alt="linkedin" />
                  ) : null}
                  <p> {item}</p>
                </div>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {socialMedia.map((item, index) => (
            <TabPane tabId={index + 1} key={index}>
              {item === "instagram" ? (
                <FineTuneInstagram
                  postDate={postDate}
                  caption={baseCaption}
                  socialchannel={socialMedia}
                  setOpenModal={setIsOpenCreatePost}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  instagramData={InstagramData}
                  selectedAccountSocials={selectedAccountSocials}
                  images={images}
                />
              ) : item === "facebook" ? (
                <FineTuneFacebook
                  socialchannel={socialMedia}
                  setOpenModal={setIsOpenCreatePost}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  facebookData={facebookData}
                  postDate={postDate}
                  caption={baseCaption}
                  selectedAccountSocials={selectedAccountSocials}
                  images={images}
                />
              ) : item === "twitter" ? (
                <FineTuneTwitter
                  socialchannel={socialMedia}
                  setOpenModal={setIsOpenCreatePost}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  images={images}
                  postDate={postDate}
                  caption={baseCaption}
                  selectedAccountSocials={selectedAccountSocials}
                  twitterData={twitterData}
                />
              ) : item === "linkedin" ? (
                <FineTuneLinkedin
                  socialchannel={socialMedia}
                  setOpenModal={setIsOpenCreatePost}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  linkedinData={linkedinData}
                  postDate={postDate}
                  caption={baseCaption}
                  selectedAccountSocials={selectedAccountSocials}
                  images={images}
                />
              ) : (
                ""
              )}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </div>
  );
};

export default FineTuneChannel;
