import { useCallback, useEffect, useState } from "react";
import styles from "./Integrations.module.css";
import flutterwaveImg from "./assets/Flutterwave.png";
import stripeImg from "./assets/Stripe.png";
import paystackImg from "./assets/Paystack.png";
import Method from "./components/Method";
import { connect } from "react-redux";
import {
  connectPaymentMethod,
  getAllPaymentMethods,
  updatePaymentMethod,
} from "../../../redux/Settings/action";
import { toast } from "react-toastify";

const methods = [
  { name: "paystack", logo: paystackImg },
  { name: "stripe", logo: stripeImg },
  { name: "flutterwave", logo: flutterwaveImg },
];
const Integrations = ({
  paymentMethods,
  message,
  getAllPaymentMethods,
  loading,
  updatePaymentMethod,
  connectPaymentMethod,
  disconnectLoading,
  disconnectError,
  disconnectMessage,
  connectError,
  connectLoading,
  connectMessage,
}) => {
  const [methodsObject, setMethodsObject] = useState({});

  const fetchAllPaymentMethods = useCallback(() => {
    getAllPaymentMethods();
  }, [getAllPaymentMethods]);

  useEffect(() => {
    fetchAllPaymentMethods();
  }, [fetchAllPaymentMethods]);

  useEffect(() => {
    if (paymentMethods?.length) {
      const methodObj = paymentMethods?.reduce(
        (a, method) => ({ ...a, [method.name || method.id]: method }),
        {}
      );
      setMethodsObject(methodObj);
    }
  }, [paymentMethods]);

  const onDisconnect = async (title) => {
    const theMethod = paymentMethods.find((meth) => meth.name === title);
    await updatePaymentMethod({
      id: theMethod.id,
      status: false,
    });
  };

  const onConnect = async (title, values) => {
    const existingMethod = paymentMethods.find((meth) => meth.name === title);
    if (existingMethod) {
      await updatePaymentMethod({
        id: existingMethod.id,
        status: true,
        ...values,
      });
    } else {
      await connectPaymentMethod({
        name: title,
        status: true,
        ...values,
      });
    }
  };

  useEffect(() => {
    if (!connectLoading && connectError) {
      toast.error(connectError);
    }
    if (!connectLoading && connectMessage) {
      toast.success(connectMessage);
    }
  }, [connectError, connectLoading, connectMessage]);

  useEffect(() => {
    if (!disconnectLoading && disconnectError) {
      toast.error(disconnectError);
    }
    if (!disconnectLoading && disconnectMessage) {
      toast.success(disconnectMessage);
    }
  }, [disconnectError, disconnectLoading, disconnectMessage]);

  return (
    <div className={styles.container}>
      <p className={styles.title}>Payment Gateway</p>
      <div className={styles.methods}>
        {methods?.map((method) => (
          <Method
            logo={method?.logo}
            title={method?.name}
            isConnected={methodsObject?.[method?.name]?.status}
            onDisconnectMethod={onDisconnect}
            onConnectMethod={onConnect}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  const { message, paymentMethods, loading, error } = settings;
  return {
    message,
    loading: loading?.getAllPaymentMethods,
    paymentMethods,
    disconnectLoading: loading?.updatePaymentMethod,
    disconnectMessage: message?.updatePaymentMethod,
    disconnectError: error?.updatePaymentMethod,
    connectLoading: loading?.connectPaymentMethod,
    connectMessage: message?.connectPaymentMethod,
    connectError: error?.connectPaymentMethod,
  };
};
export default connect(mapStateToProps, {
  getAllPaymentMethods,
  updatePaymentMethod,
  connectPaymentMethod,
})(Integrations);
