import { put, takeEvery, fork, all, call } from "redux-saga/effects";
import {
  GET_ALL_TEAM_MEMBERS,
  ADD_NEW_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  getAllTeamMembersSuccess,
  getAllTeamMembersError,
  addNewTeamMemberSuccess,
  addNewTeamMemberError,
  deleteTeamMemberSuccess,
  deleteTeamMemberError,
  clearMessages,
} from "../actions";
import Axios from "../../utils/Axios";

export function* GetAllTeamMembers() {
  try {
    const response = yield Axios.get(`/business/api/v1/manage-team/`);
    if (response?.status === 200) {
      yield put(getAllTeamMembersSuccess(response?.data));
    } else {
      yield put(getAllTeamMembersError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getAllTeamMembersError(message));
    yield put(clearMessages());
  }
}

export function* AddNewTeamMember({ payload }) {
  try {
    const response = yield Axios.post(`/business/api/v1/team-invite/`, payload);
    if (response?.status === 201) {
      yield put(addNewTeamMemberSuccess(response?.data?.msg));
      yield call(GetAllTeamMembers);
    } else {
      yield put(addNewTeamMemberError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.msg
        ? error?.response?.data?.msg
        : error?.response?.data?.role
        ? error?.response?.data?.role?.[0]
        : null;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(addNewTeamMemberError(message));
    yield put(clearMessages());
  }
}

export function* DeleteTeamMember({ payload }) {
  try {
    const response = yield Axios.delete(`/business/api/v1/manage-team/`, {
      data: {
        id: payload,
      },
    });
    if (response?.status === 200) {
      yield put(deleteTeamMemberSuccess(response?.data?.msg));
      yield call(GetAllTeamMembers);
    } else {
      yield put(deleteTeamMemberError(response?.data?.msg));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.msg
        ? error?.response?.data?.msg
        : error?.response?.data?.role
        ? error?.response?.data?.role?.[0]
        : null;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(deleteTeamMemberError(message));
    yield put(clearMessages());
  }
}
export function* watchGetAllTeamMembers() {
  yield takeEvery(GET_ALL_TEAM_MEMBERS, GetAllTeamMembers);
}
export function* watchAddNewTeamMember() {
  yield takeEvery(ADD_NEW_TEAM_MEMBER, AddNewTeamMember);
}
export function* watchDeleteTeamMember() {
  yield takeEvery(DELETE_TEAM_MEMBER, DeleteTeamMember);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetAllTeamMembers),
    fork(watchAddNewTeamMember),
    fork(watchDeleteTeamMember),
  ]);
}
