import React from "react";
import Step from "./Step";

const StepNavigation = (props) => {
  return (
    <div className="stepWrapper">
      {props.labelArray.map((item, index) => (
        <Step
          key={index}
          index={index}
          label={item.name}
          selected={
            props.currentStep === index + 1 || index <= props.currentStep - 1
          }
          updateStep={console.log}
          arr={props.labelArray}
        ></Step>
      ))}
    </div>
  );
};

export default StepNavigation;
