import React from "react";
import { Col, Row } from "reactstrap";
import Button from "../../../../components/Button";
import PostHeader from "../PostHeader";
import ButtonLoader from "../../../../components/Loaders/ButtonLoader";
import { BiArrowBack } from "react-icons/bi";

const CreatePost = ({ onSubmit, loading, prev }) => {
  return (
    <div className="createPost">
      <div className="backBtn" onClick={prev}>
        <BiArrowBack size="24px" />
      </div>
      <div className="content">
        <PostHeader title="Creating your post" />
        <br />
        <Row>
          <Col>
            <Button
              text={loading?.schedule ? <ButtonLoader /> : "Schedule"}
              className="Button_Wrap submitbtn"
              onButtonClick={() => onSubmit("scheduled")}
            />
          </Col>
          <Col>
            <Button
              text={loading?.pending ? <ButtonLoader /> : "Submit for Approval"}
              className="Button_Wrap submitbtn"
              onButtonClick={() => onSubmit("pending")}
            />
          </Col>
        </Row>
        <br />

        <Row>
          <div style={{ width: "50%", margin: "auto" }}>
            <Button
              text={
                loading?.draft ? (
                  <ButtonLoader color={"#0053F4"} />
                ) : (
                  "Save to Drafts"
                )
              }
              className="Button_Wrap draftbtn"
              onButtonClick={() => onSubmit("draft")}
            />
          </div>
          {/* <Col>
          <Button text="Publish Now" className="Button_Wrap draftbtn" onButtonClick={()=>onSubmit('published')} />
        </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default CreatePost;
