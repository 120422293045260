export const clientPortalValidate = (values) => {
  const errors = {};
  // if (!values.welcome_message) {
  //   errors.welcome_message = "Enter a message";
  // }
  // if (!values.extra_information) {
  //   errors.extra_information = "Enter a valid subtext";
  // }
  if (!values.brand_color || values.brand_color === "#2062F4") {
    errors.brand_color = "Select a theme color";
  }

  return errors;
};
