import {
  SCHEDULEPOST,
  SCHEDULEPOST_SUCCESS,
  SCHEDULEPOST_ERROR,
  GET_ONECLIENTPOST,
  GET_ONECLIENTPOST_SUCCESS,
  GET_ONECLIENTPOST_ERROR,
  RESET_MESSAGE,
  DELETE_FACEBOOK_POST,
  DELETE_FACEBOOK_POST_SUCCESS,
  DELETE_FACEBOOK_POST_ERROR,
  DELETE_INSTAGRAM_POST,
  DELETE_INSTAGRAM_POST_SUCCESS,
  DELETE_INSTAGRAM_POST_ERROR,
  DELETE_TWITTER_POST,
  DELETE_TWITTER_POST_SUCCESS,
  DELETE_TWITTER_POST_ERROR,
  DELETE_LINKEDIN_POST,
  DELETE_LINKEDIN_POST_SUCCESS,
  DELETE_LINKEDIN_POST_ERROR,
} from "../../actions";

const intialState = {
  loading: {
    draft: false,
    schedule: false,
    pending: false,
    deleteFacebookPost: false,
    deleteInstagramPost: false,
    deleteTwitterPost: false,
    deleteLinkedInPost: false,
  },
  message: {
    deleteFacebookPost: "",
    deleteInstagramPost: "",
    deleteTwitterPost: "",
    deleteLinkedInPost: "",
  },
  error: {
    deleteFacebookPost: "",
    deleteInstagramPost: "",
    deleteTwitterPost: "",
    deleteLinkedInPost: "",
  },
  postScheduleData: [],
  postScheduleMessage: "",
  postScheduleError: "",
  getOneClientPostLoading: false,
  getOneClientPost: [],
  getOneClientPostError: "",
};

const postScheduleReducer = (state = intialState, action) => {
  switch (action.type) {
    case SCHEDULEPOST:
      return {
        ...state,
        loading: {
          ...state.loading,
          schedule: action.payload.post_status === "scheduled" ? true : false,
          draft: action.payload.post_status === "draft" ? true : false,
          pending: action.payload.post_status === "pending" ? true : false,
        },
      };
    case SCHEDULEPOST_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          schedule: action.payload.post_status === "scheduled" ? false : false,
          draft: action.payload.post_status === "draft" ? false : false,
          pending: action.payload.post_status === "pending" ? false : false,
        },
        postScheduleMessage: action.payload.message,
        postScheduleData: action.payload,
      };
    case SCHEDULEPOST_ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          schedule: action.payload.post_status === "scheduled" ? false : false,
          draft: action.payload.post_status === "draft" ? false : false,
          pending: action.payload.post_status === "pending" ? false : false,
        },
        postScheduleError: action.payload.error,
      };
    case GET_ONECLIENTPOST:
      return {
        ...state,
        getOneClientPostLoading: true,
      };
    case GET_ONECLIENTPOST_SUCCESS:
      return {
        ...state,
        getOneClientPostLoading: false,
        getOneClientPost: action.payload,
      };
    case GET_ONECLIENTPOST_ERROR:
      return {
        ...state,
        getOneClientPostLoading: false,
        getOneClientPostError: action.payload,
      };
    case DELETE_FACEBOOK_POST:
      return {
        ...state,
        loading: { ...state?.loading, deleteFacebookPost: true },
      };
    case DELETE_FACEBOOK_POST_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, deleteFacebookPost: false },
        message: { ...state?.message, deleteFacebookPost: action.payload },
      };
    case DELETE_FACEBOOK_POST_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, deleteFacebookPost: false },
        error: { ...state?.error, deleteFacebookPost: action.payload },
      };
    case DELETE_TWITTER_POST:
      return {
        ...state,
        loading: { ...state?.loading, deleteTwitterPost: true },
      };
    case DELETE_TWITTER_POST_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, deleteTwitterPost: false },
        message: { ...state?.message, deleteTwitterPost: action.payload },
      };
    case DELETE_TWITTER_POST_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, deleteTwitterPost: false },
        error: { ...state?.error, deleteTwitterPost: action.payload },
      };
    case DELETE_INSTAGRAM_POST:
      return {
        ...state,
        loading: { ...state?.loading, deleteInstagramPost: true },
      };
    case DELETE_INSTAGRAM_POST_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, deleteInstagramPost: false },
        message: { ...state?.message, deleteInstagramPost: action.payload },
      };
    case DELETE_INSTAGRAM_POST_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, deleteInstagramPost: false },
        error: { ...state?.error, deleteInstagramPost: action.payload },
      };
    case DELETE_LINKEDIN_POST:
      return {
        ...state,
        loading: { ...state?.loading, deleteLinkedInPost: true },
      };
    case DELETE_LINKEDIN_POST_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, deleteLinkedInPost: false },
        message: { ...state?.message, deleteLinkedInPost: action.payload },
      };
    case DELETE_LINKEDIN_POST_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, deleteLinkedInPost: false },
        error: { ...state?.error, deleteLinkedInPost: action.payload },
      };
    case RESET_MESSAGE:
      return {
        ...state,
        loading: {
          ...state.loading,
          draft: false,
          schedule: false,
          pending: false,
          deleteFacebookPost: false,
          deleteInstagramPost: false,
          deleteTwitterPost: false,
          deleteLinkedInPost: false,
        },
        message: {
          deleteFacebookPost: "",
          deleteInstagramPost: "",
          deleteTwitterPost: "",
          deleteLinkedInPost: "",
        },
        error: {
          deleteFacebookPost: "",
          deleteInstagramPost: "",
          deleteTwitterPost: "",
          deleteLinkedInPost: "",
        },
        postScheduleError: "",
        postScheduleMessage: "",
        getOneClientPostError: "",
        getOneClientPostLoading: false,
      };
    default:
      return state;
  }
};

export default postScheduleReducer;
