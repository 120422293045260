import React from "react";
import { FaTimes, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import searchChat from "./../../assets/img/search-chat.svg";
import { Button } from "reactstrap";
import forwardChat from "./../../assets/img/forward-chat.svg";
import { Link } from "react-router-dom";
import { truncateText } from "../../utils/helper";
import dummyimg from "../../assets/img/dummyimg.png";
import { sliceItem } from "../../utils/func";

const ListAllClientDrawer = ({
  setShowClient,
  searchValue,
  onChangeSearchValue,
  getAllClientDetails,
  activeId,
  onChangeClientPost,
}) => {
  const businessOwner = useSelector((state) => state?.auth?.currentUser);

  return (
    <div className="clientlist-drawer">
      <div className="cancel-drawer" onClick={() => setShowClient(false)}>
        <FaTimes className="times" />
      </div>
      <div className="drawer-scheduleInputWrapper">
        <input
          id="searchClient"
          name="client"
          placeholder="Find Client"
          type="text"
          value={searchValue}
          onChange={onChangeSearchValue}
          className=""
        />
        <img src={searchChat} className="ms-5" alt="" />
      </div>

      <div className="my-4">
        <h6 className="chat-titles mb-4">Your Accounts</h6>
      </div>

      <div>
        <div className="drawer-clientsHolder">
          <ul>
            <li
              className={`drawer-client-chart ${activeId === 0 && "active"}`}
              onClick={() =>
                onChangeClientPost(0, businessOwner?.unique_code, "business")
              }
            >
              <div className="client_drawer_holder">
                <div className="drawer-chartIconHolder">
                  <img
                    src={businessOwner?.business_logo || dummyimg}
                    alt="barChart"
                  />
                </div>
                <div className="drawer-clientNameHolder">
                  <h6>{sliceItem(businessOwner?.business_name)}</h6>
                </div>
              </div>
              {activeId === 0 ? (
                <div>
                  <img src={forwardChat} alt="" className="mx-2" />
                </div>
              ) : null}
            </li>
            {getAllClientDetails?.length > 0 ? (
              getAllClientDetails.map((items, index) => (
                <li
                  className={`drawer-client-chart ${
                    activeId === index + 1 && "active"
                  }`}
                  onClick={() =>
                    onChangeClientPost(index + 1, items?.client_id, "client")
                  }
                  active={activeId}
                >
                  <div className="client_drawer_holder">
                    <div className="drawer-chartIconHolder">
                      <img
                        src={items?.logo || dummyimg}
                        alt={items?.fullname}
                      />
                    </div>
                    <div className="drawer-clientNameHolder">
                      <h6>{truncateText(items?.fullname)}</h6>
                    </div>
                  </div>
                  {activeId === index + 1 ? (
                    <div>
                      <img src={forwardChat} className="mx-2" alt="" />
                    </div>
                  ) : null}
                </li>
              ))
            ) : (
              <li className="drawer_invite">
                <div className="drawer-no-clients">
                  <p>You have no clients. Please invite one to get started </p>
                  <Link to="/clients">
                    <Button type="button" color="" className="drawer-addclient">
                      <div className="drawer-plus">
                        <FaPlus />
                      </div>
                      <span> Add new Client</span>
                    </Button>
                  </Link>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ListAllClientDrawer;
