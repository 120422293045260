import { useState, useEffect } from "react";
import { Card } from "reactstrap";
import RedCircle from "../../assets/img/finance-red-circle.svg";
import YellowCircle from "../../assets/img/finanace-yellow-circle.svg";
import BluePlus from "../../assets/img/bg-blue-plust.svg";
import AddNewClient from "./AddClient.js";
import ListView from "./ListView.js";
import ExpenseListView from "./ExpenseView.js";
import Select from "react-select";
//redux
import { connect } from "react-redux";
import {
  getAllInvoices,
  getInvoicesSummary,
  getInvoiceSettings,
} from "../../redux/actions";

//utils
import { formatAmount } from "../../utils/helper";
import CreateInvoiceModal from "./InvoiceActions/CreateInvoiceModal";
import RequestPayoutModal from "./InvoiceActions/RequestPayoutModal";
import AddVendor from "./ExpenseActions/AddVendor";
import AddExpenseModal from "./ExpenseActions/AddExpenseModal";
import useCurrency from "../../hooks/useCurrency";
import { toast } from "react-toastify";
import { availableCurrencies } from "../../utils/currencies";

const Finances = ({
  getAllInvoices,
  invoices,
  getInvoiceLoading,
  getInvoiceError,
  getInvoicesSummary,
  invoiceSummary,
  invoiceSettings,
}) => {
  const [view] = useState("list");
  const [addClient, setAddClient] = useState(false);
  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const [invoiceTab, setInvoiceTab] = useState("invoice");
  const [show, setShow] = useState(false);
  const [showExpense, setShowExpense] = useState(false);
  const [showVendor, setShowVendor] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState(null);

  const { currencySymbol } = useCurrency(invoiceSettings?.currency);

  // const currencyOptions = [
  //   {
  //     label: "United States Dollar",
  //     value: "USD",
  //   },
  //   {
  //     label: "Nigerian Naira",
  //     value: "NGN",
  //   },
  //   {
  //     label: "Canadian Dollar",
  //     value: "CAD",
  //   },
  //   {
  //     label: "Great Britain Pounds",
  //     value: "GBP",
  //   },
  // ];
  const countries = [
    {
      value: "CAD",
      label: "CAD",
      image:
        "https://res.cloudinary.com/dekillerj/image/upload/v1688313571/canada-flag.png",
    },
    {
      value: "NGN",
      label: "NGN",
      image:
        "https://res.cloudinary.com/dekillerj/image/upload/v1688313571/nigeria-flag.png",
    },
    {
      value: "GBP",
      label: "GBP",
      image:
        "https://res.cloudinary.com/dekillerj/image/upload/v1688313572/uk-flag.png",
    },
    {
      value: "USD",
      label: "USD",
      image:
        "https://res.cloudinary.com/dekillerj/image/upload/v1687100512/us-flag.png",
    },
  ];
  //useEffect
  useEffect(() => {
    getAllInvoices();
    getInvoicesSummary();
    getInvoiceSettings();
  }, [getAllInvoices, getInvoicesSummary]);

  useEffect(() => {
    if (!getInvoiceLoading && getInvoiceError?.length > 0) {
      toast.error(getInvoiceError);
    }
  }, [getInvoiceLoading, getInvoiceError]);
  //Modal Toggle Handlers
  const openInvoiceModal = () => {
    setShow(true);
  };
  const closeInvoiceModal = () => {
    setShow(false);
  };

  const openExpenseModal = () => {
    setShowExpense(true);
  };
  const closeExpenseModal = () => {
    setShowExpense(false);
  };
  // const openRequestPayoutModal = () => {
  //   setShowPayoutModal(true);
  // };
  const handleSelectCurrency = (value) => {
    // const { value } = e.target;
    setSelectedCurrency(value);
  };

  useEffect(() => {
    if (show) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "visible";
  }, [show]);

  useEffect(() => {
    if (typeof selectedCurrency === "string") {
      const getCurrencySymbol = availableCurrencies?.find(
        (curr) => curr?.currency === selectedCurrency
      );
      setSelectedCurrencySymbol(getCurrencySymbol?.symbol);
    } else if (
      Object.prototype.toString.call(selectedCurrency) === "[object Object]"
    ) {
      const getCurrencySymbol = availableCurrencies?.find(
        (curr) => curr?.currency === selectedCurrency?.value
      );
      setSelectedCurrencySymbol(getCurrencySymbol?.symbol);
    }
  }, [selectedCurrency]);
  useEffect(() => {
    if (invoiceSettings?.currency) {
      const activeCurrency = countries?.find((country) => {
        return country.value === invoiceSettings?.currency;
      });
      setSelectedCurrency(activeCurrency);
    }
  }, [invoiceSettings?.currency]);

  return (
    <>
      {show ? (
        <CreateInvoiceModal closeInvoiceModal={closeInvoiceModal} />
      ) : null}
      {showExpense ? (
        <AddExpenseModal closeExpenseModal={closeExpenseModal} />
      ) : null}
      {showVendor ? <AddVendor setShowVendor={setShowVendor} /> : null}
      <div className="dashboard dashboard-wrapper">
        {Object.keys(invoiceSummary?.total_received || {})?.length > 1 ? (
          <div className="d-flex justify-content-end mb-2">
            <Select
              value={selectedCurrency}
              onChange={handleSelectCurrency}
              options={countries}
              formatOptionLabel={(country) => (
                <div className="country-option">
                  <img
                    src={country.image}
                    alt="country"
                    style={{
                      width: "30px",
                      height: "30px",
                      objectFit: "fill",
                      marginRight: "0.5rem",
                    }}
                  />
                  <span>{country.label}</span>
                </div>
              )}
            />
          </div>
        ) : null}

        <div className="cards-container">
          <Card className="py-4 finances__top-cards">
            <div className="d-flex flex-row">
              <div className="finances__top-cards__left-half">
                <h6 className="finances__top-cards__title">Total Revenue</h6>
                <h6 className="finances__top-cards__amount-big">
                  <span className="mr-2 finances__top-cards__currency">
                    {selectedCurrencySymbol}
                  </span>{" "}
                  {formatAmount(
                    invoiceSummary?.[selectedCurrency?.value]?.total_received
                  ) || "0.00"}
                </h6>
                {/* <div>
                    <h6 className="finances__top-cards__bonus text-center py-0 mx-auto">
                      +10% In the past month
                    </h6>
                  </div> */}
              </div>
              <div className="pending-overdue px-3">
                <div className="finances__top-cards__title w-100">
                  <img src={YellowCircle} className="me-2" alt="" />
                  <h6 className="finances__top-cards__title my-auto mp-2">
                    Pending
                  </h6>
                </div>
                <h6 className="finances__top-cards__amount-small ms-3 ps-1">
                  {selectedCurrencySymbol}{" "}
                  {formatAmount(
                    invoiceSummary?.[selectedCurrency?.value]?.total_pending
                  ) || "0.00"}
                </h6>
                <div className="finances__top-cards__title d-flex mt-3 w-100">
                  <img src={RedCircle} className="me-2" alt="" />
                  <h6 className="finances__top-cards__title my-auto mp-2">
                    Overdue
                  </h6>
                </div>
                <h6 className="finances__top-cards__amount-small ms-3 ps-1">
                  {selectedCurrencySymbol}{" "}
                  {formatAmount(
                    invoiceSummary?.[selectedCurrency?.value]?.total_overdue
                  ) || "0.00"}
                </h6>
              </div>
            </div>
          </Card>
          <Card className="finances__top-cards">
            <h6 className="finances__top-cards__title">
              Current month revenue
            </h6>
            <h6 className="finances__top-cards__amount-big">
              <span className="mr-2 finances__top-cards__currency">
                {selectedCurrencySymbol}
              </span>{" "}
              {formatAmount(
                invoiceSummary?.[selectedCurrency?.value]?.current_month_revenue
              ) || "0.00"}
            </h6>
          </Card>
          <Card className="finances__top-cards">
            <h6 className="finances__top-cards__title">
              Expenses
            </h6>
            <h6 className="finances__top-cards__amount-big">
              <span className="mr-2 finances__top-cards__currency">
                {selectedCurrencySymbol}
              </span>{" "}
              {formatAmount(
                invoiceSummary?.[selectedCurrency?.value]
                  ?.current_month_expenses
              ) || "0.00"}
            </h6>
          </Card>
        </div>
        <div className="d-flex justify-content-between my-4 flex-wrap finance-tab">
          <div className="cursor-pointer d-flex align-items-center left-actions">
            <div
              className={`btn-lg d-flex align-items-center justify-content-center ${
                invoiceTab === "invoice" ? "create-button" : ""
              }`}
              onClick={() => {
                setInvoiceTab("invoice");
              }}
            >
              Invoice Overview
            </div>
            <div
              className={`btn-lg ${
                invoiceTab === "expenses" ? "create-button" : ""
              }  align-items-center justify-content-center`}
              onClick={() => {
                setInvoiceTab("expenses");
              }}
            >
              Expenses
            </div>
          </div>
          {invoiceTab === "invoice" && (
            <div className="d-flex justify-content-between align-items-center client-management-control">
              {/* <div className="d-inline-flex white-button py-1 px-4 me-4">
                <div
                  className="btn-lg w-auto "
                  onClick={openRequestPayoutModal}
                >
                  <h6 className="mb-0">Request Payout</h6>
                </div>
              </div> */}
              <div
                className="d-inline-flex white-button px-4 cursor-pointer"
                style={{ padding: "10px 15px" }}
                onClick={openInvoiceModal}
              >
                <img src={BluePlus} alt="" className="me-2" />
                <div className="btn-lg w-auto">
                  <h6 className="mb-0" style={{ fontSize: "14px" }}>
                    New Invoice
                  </h6>
                </div>
              </div>
            </div>
          )}
          {invoiceTab === "expenses" && (
            <div className="d-flex justify-content-between align-items-center client-management-control">
              <div
                className="d-inline-flex white-button cursor-pointer"
                style={{ padding: "10px 15px" }}
                onClick={openExpenseModal}
              >
                <img src={BluePlus} alt="" className="me-2" />
                <div className="btn-lg w-auto ">
                  <h6 className="mb-0 " style={{ fontSize: "14px" }}>
                    Add Expense
                  </h6>
                </div>
              </div>
              <div
                className="d-inline-flex white-button cursor-pointer"
                style={{ padding: "10px 15px" }}
                onClick={() => setShowVendor(true)}
              >
                <img src={BluePlus} alt="" className="me-2" />
                <div className="btn-lg w-auto ">
                  <h6 className="mb-0" style={{ fontSize: "14px" }}>
                    Add Vendor
                  </h6>
                </div>
              </div>
            </div>
          )}
        </div>
        {invoiceTab === "invoice" ? (
          view === "list" ? (
            <ListView
              invoices={invoices}
              loading={getInvoiceLoading}
              openInvoiceModal={openInvoiceModal}
              currencySymbol={currencySymbol}
            />
          ) : (
            <div className="client-inactive-state text-center">
              <Card className="client-inactive-state-card mx-auto">
                <h3 className="client-inactive-header-text mx-auto">
                  You currently have no client
                </h3>
                <h3 className="client-inactive-header-text mx-auto">
                  you can add a new client.
                </h3>
                <p className="client-inactive-subhead-text mx-auto">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <div
                  className=" client-create-button "
                  onClick={() => {
                    setAddClient(true);
                  }}
                >
                  <h6 className="mb-0">ADD A NEW CLIENT</h6>
                </div>
              </Card>
            </div>
          )
        ) : view === "list" ? (
          <ExpenseListView
            openExpenseModal={openExpenseModal}
            currencySymbol={currencySymbol}
          />
        ) : (
          <div className="client-inactive-state text-center">
            <Card className="client-inactive-state-card mx-auto">
              <h3 className="client-inactive-header-text mx-auto">
                You currently have no client
              </h3>
              <h3 className="client-inactive-header-text mx-auto">
                you can add a new client.
              </h3>
              <p className="client-inactive-subhead-text mx-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
              <div
                className=" client-create-button "
                onClick={() => {
                  setAddClient(true);
                }}
              >
                <h6 className="mb-0">ADD A NEW CLIENT</h6>
              </div>
            </Card>
          </div>
        )}
        {addClient && (
          <AddNewClient addState={addClient} setAddState={setAddClient} />
        )}
        <RequestPayoutModal
          showRequestPayout={showPayoutModal}
          setShowRequestPayout={setShowPayoutModal}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { invoice } = state;
  return {
    invoices: invoice?.invoices,
    getInvoiceLoading: invoice?.loading?.getInvoices,
    getInvoiceError: invoice?.error?.getInvoices,
    invoiceSummary: invoice?.invoiceSummary,
    invoiceSettings: state?.settings?.invoiceSettings,
  };
};
export default connect(mapStateToProps, {
  getAllInvoices,
  getInvoicesSummary,
  getInvoiceSettings,
})(Finances);
