import {
  SCHEDULEPOST,
  SCHEDULEPOST_SUCCESS,
  SCHEDULEPOST_ERROR,
  GET_ONECLIENTPOST,
  GET_ONECLIENTPOST_SUCCESS,
  GET_ONECLIENTPOST_ERROR,
  DELETE_FACEBOOK_POST,
  DELETE_FACEBOOK_POST_ERROR,
  DELETE_FACEBOOK_POST_SUCCESS,
  DELETE_TWITTER_POST,
  DELETE_TWITTER_POST_ERROR,
  DELETE_TWITTER_POST_SUCCESS,
  DELETE_LINKEDIN_POST,
  DELETE_LINKEDIN_POST_ERROR,
  DELETE_LINKEDIN_POST_SUCCESS,
  DELETE_INSTAGRAM_POST,
  DELETE_INSTAGRAM_POST_ERROR,
  DELETE_INSTAGRAM_POST_SUCCESS,
} from "../../actions";

export const scheduledPost = (credentials) => ({
  type: SCHEDULEPOST,
  payload: credentials,
});

export const scheduledPostSuccess = (data) => ({
  type: SCHEDULEPOST_SUCCESS,
  payload: data,
});

export const scheduledPostError = (error) => ({
  type: SCHEDULEPOST_ERROR,
  payload: error,
});

//ONECLIENTPOST

export const getOneClientPost = (id) => ({
  type: GET_ONECLIENTPOST,
  payload: { id },
});

export const getOneClientPostSuccess = (clientPost) => ({
  type: GET_ONECLIENTPOST_SUCCESS,
  payload: clientPost,
});

export const getOneClientPostError = (error) => ({
  type: GET_ONECLIENTPOST_ERROR,
  payload: error,
});

export const deleteFacebookPost = (credentials) => ({
  type: DELETE_FACEBOOK_POST,
  payload: credentials,
});
export const deleteFacebookPostSuccess = () => ({
  type: DELETE_FACEBOOK_POST_SUCCESS,
  payload: "Facbook Post Deleted Successfully",
});
export const deleteFacebookPostError = (error) => ({
  type: DELETE_FACEBOOK_POST_ERROR,
  payload: error,
});
export const deleteTwitterPost = (credentials) => ({
  type: DELETE_TWITTER_POST,
  payload: credentials,
});
export const deleteTwitterPostSuccess = () => ({
  type: DELETE_TWITTER_POST_SUCCESS,
  payload: "Twitter Post Deleted Successfully",
});
export const deleteTwitterPostError = (error) => ({
  type: DELETE_TWITTER_POST_ERROR,
  payload: error,
});

export const deleteLinkedInPost = (credentials) => ({
  type: DELETE_LINKEDIN_POST,
  payload: credentials,
});
export const deleteLinkedInPostSuccess = () => ({
  type: DELETE_LINKEDIN_POST_SUCCESS,
  payload: "LinkedIn Post Deleted Successfully",
});
export const deleteLinkedInPostError = (error) => ({
  type: DELETE_LINKEDIN_POST_ERROR,
  payload: error,
});

export const deleteInstagramPost = (credentials) => ({
  type: DELETE_INSTAGRAM_POST,
  payload: credentials,
});
export const deleteInstagramPostSuccess = () => ({
  type: DELETE_INSTAGRAM_POST_SUCCESS,
  payload: "Instagram Post Deleted Successfully",
});
export const deleteInstagramPostError = (error) => ({
  type: DELETE_INSTAGRAM_POST_ERROR,
  payload: error,
});
