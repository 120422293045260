import React, { useCallback, useEffect } from "react";
import { HiUser } from "react-icons/hi";
import { CenteredModal as Modal } from "../../components/Modal";
import { ThreeDots } from "react-loader-spinner";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { inviteClient, createTag } from "../../redux/actions";
import { toast } from "react-toastify";
// import Select from 'react-select'
// import countryList from 'react-select-country-list'

const InviteClient = ({
  addState,
  inviteClient,
  setAddState,
  creationError,
  creationMessage,
  creationLoading,
  userEmail,
  handleCloseAddModal,
}) => {
  // const options = useMemo(() => countryList().getData(), [])
  const businessCategory = [
    "Agriculture",
    "Arts",
    "Beauty & Lifestyle",
    "Business services",
    "Coaching",
    "Construction",
    "Consulting",
    "Education",
    "Engineering",
    "Entertainment",
    "Events",
    "Financial services",
    "Health & Fitness",
    "Hotels & Hospitality",
    "HR & Recruiting",
    "Legal services",
    "Manufacturing",
    "Marketing",
    "Non-profit",
    "Pet services",
    "Photography",
    "Retail",
    "Technology",
    "Travel & Tourism",
    "Real estate",
    "Others",
  ];

  const validate = (values) => {
    const errors = {};

    if (!values.client_business_name) {
      errors.client_business_name = "Client Name is required";
    }
    if (values.client_email) {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.client_email)
      ) {
        errors.client_email = "Email is invalid";
      } else if (values?.email?.toLowerCase === userEmail) {
        errors.email = "Email already exists";
      }
    } else {
      errors.client_email = "Email is required";
    }
    if (!values.business_category) {
      errors.business_category = "Business Category is required";
    }
    if (!values.client_name) {
      errors.client_name = "Client Name is required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      client_business_name: "",
      client_email: "",
      business_category: "",
      client_name: "",
    },
    validate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      formik.validateForm();
      inviteClient(values);
    },
  });

  const handleCloseModal = useCallback(() => setAddState(false), [setAddState]);

  useEffect(() => {
    if (creationError?.length > 0 && !creationLoading) {
      toast.error(creationError);
    }
    if (creationMessage?.length > 0 && !creationLoading) {
      toast.success(creationMessage);
      handleCloseAddModal();
      handleCloseModal();
    }
  }, [
    creationError,
    creationMessage,
    creationLoading,
    handleCloseModal,
    handleCloseAddModal,
  ]);

  return (
    <>
      <Modal modalState={addState} setModalState={setAddState}>
        <div className="add-client-wrapper text-center ">
          <div className="text-center user-icon-container ">
            <HiUser className=" text-center" color="#49A8F8" size="45px" />
          </div>
          <div className="add-client-text text-center">
            <h3>Invite new client</h3>
            <p
              style={{
                margin: "5px 0 29px 0",
              }}
            >
              Invite your client to set up their portal and connect their social
              media accounts
            </p>
          </div>
          <form
            className="business-form text-left"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            {formik?.errors?.client_name && formik?.touched?.client_name ? (
              <span className="text-danger text-left field-error">
                {formik?.errors?.client_name}
              </span>
            ) : null}
            <input
              type="text"
              name="client_name"
              placeholder="Client Name"
              className={`w-100 ${
                formik?.errors?.project_tag ? "border-danger" : ""
              }`}
              values={formik?.values?.client_name}
              onChange={formik.handleChange}
            />
            {formik?.errors?.client_business_name &&
            formik?.touched?.client_business_name ? (
              <span className="text-danger text-left field-error">
                {formik?.errors?.client_business_name}
              </span>
            ) : null}
            <input
              type="text"
              name="client_business_name"
              placeholder="Business Name"
              className={`w-100 ${
                formik?.errors?.client_business_name ? "border-danger" : ""
              }`}
              onChange={formik.handleChange}
              value={formik?.values?.client_business_name}
            />
            {formik?.errors?.client_email && formik?.touched?.client_email ? (
              <span className="text-danger text-left field-error">
                {formik?.errors?.client_email}
              </span>
            ) : null}
            <input
              type="email"
              name="client_email"
              placeholder="Email Address"
              className={`w-100 ${
                formik?.errors?.client_email ? "border-danger" : ""
              }`}
              value={formik?.values?.client_email}
              onChange={formik.handleChange}
            />

            {/* {errors.country && (
              <span className='text-danger text-left'>Select Country</span>
            )}

            <Controller
              control={control}
              // defaultValue={options.map(c => c.value)}
              name='country'
              className={`w-100 ${errors.country ? 'border-danger' : ''} mb-3`}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  inputRef={ref}
                  // value={options.filter(c => value.includes(c.value))}
                  className={`w-100 ${
                    errors.country ? 'border-danger' : ''
                  } mb-3`}
                  onChange={(e, val) => {
                    onChange(e.value)
                  }}
                  options={options}
                  // isMulti
                />
              )}
              {...register('country', {
                required: true
              })}
            /> */}

            {formik?.errors?.business_category &&
            formik?.touched?.business_category ? (
              <span className="text-danger text-left field-error">
                {formik?.errors?.business_category}
              </span>
            ) : null}
            <select
              name="business_category"
              onChange={formik.handleChange}
              className={`w-100 ${
                formik?.errors?.business_category ? "border-danger" : ""
              }`}
              value={formik?.values?.business_category}
            >
              <option value="">Business category</option>
              {businessCategory.map((el) => (
                <option value={el} key={el}>
                  {el}
                </option>
              ))}

              {/* <option>Testing</option> */}
            </select>

            <div className="pt-2 pb-5">
              <button
                className="w-100 btn-primary btn"
                type="submit"
                disabled={creationLoading}
              >
                {creationLoading ? (
                  <div className="text-center w-100 align-items-center">
                    <ThreeDots
                      color="white"
                      height={"12px"}
                      wrapperStyle={{ display: "block" }}
                    />
                  </div>
                ) : (
                  "Add Client"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ client, auth }) => {
  const { error, message, loading } = client;
  return {
    creationError: error?.inviteClient,
    creationMessage: message?.inviteClient,
    creationLoading: loading?.inviteClient,
    userEmail: auth?.currentUser?.email,
  };
};
export default connect(mapStateToProps, { inviteClient, createTag })(
  InviteClient
);
