import React, { useEffect } from "react";
import icon1 from "../../../assets/img/calendaric1.svg";
import EmptyDashboardState from "../../../components/Utilities/EmptyDashboardState";

import { HiArrowRight } from "react-icons/hi";

//redux
import { connect } from "react-redux";
import { getDashboardPostIdea } from "../../../redux/actions";
import { formatInvoiceIssueDate } from "../../../utils/helper";
import { Link } from "react-router-dom";
import { IoChevronForwardOutline } from "react-icons/io5";

const UpcomingEvents = (props) => {
  const { toggleEventState, getDashboardPostIdea, postIdeas } = props;

  useEffect(() => {
    getDashboardPostIdea();
  }, [getDashboardPostIdea]);
  return (
    <div>
      <div className="d-flex flex-row align-items-end justify-content-between">
        <h4 className="mx-3 upcoming-events-header  mb-0">Upcoming Events</h4>
        <Link className="text-decoration-none" to="/overview/content-ideas">
          See more <IoChevronForwardOutline />
        </Link>
      </div>
      {postIdeas?.length ? (
        postIdeas?.slice(0, 3)?.map((idea) => {
          return (
            <div
              className="upcoming-event mb-2 d-flex flex-wrap align-items-center"
              key={idea?.id}
            >
              {/* <div className='event-icon'> */}
              <img src={icon1} alt="icon" className="event-icon " />
              {/* </div> */}
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "75%", marginLeft: "1rem" }}
              >
                <div className="event-details">
                  <h4>{idea?.content_idea_name}</h4>
                  <p className="mb-0">{formatInvoiceIssueDate(idea?.date)}</p>
                </div>
                <div className="event-arrow-icon cursor-pointer">
                  <HiArrowRight
                    color="#49A8F8"
                    size="25px"
                    className="font-weight-bold"
                    onClick={() => toggleEventState(idea)}
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyDashboardState subHeaderText="No upcoming events" />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    postIdeas: state?.dashboard?.postIdeas,
  };
};
export default connect(mapStateToProps, { getDashboardPostIdea })(
  UpcomingEvents
);
