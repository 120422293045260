import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import { connect } from "react-redux";
import { setCurrentSection } from "../../redux/actions";
import { MdDelete } from "react-icons/md";

import ontriv from "../../assets/img/avatar.png";

import DeleteClientModal from "./DeleteClientModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ClientListView = ({
  setCurrentSection,
  clients,
  deleteClient,
  deleteClientError,
  deleteClientMessage,
  deleteClientLoading,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [clientId, setClientId] = useState("");

  const navigate = useNavigate();

  const toggleShowDelete = () => {
    setShowDelete(!showDelete);
  };

  const handleDelete = (id) => {
    deleteClient(id);
  };

  const openClientDetailsPage = (record) => {
    navigate(`/clients/client-details/${record?.id}`, {
      state: record,
    });
  };
  useEffect(() => {
    if (!deleteClientLoading && deleteClientMessage?.length > 0) {
      toggleShowDelete();
      toast.success(deleteClientMessage);
    } else if (!deleteClientLoading && deleteClientError?.length > 0) {
      toast.error(deleteClientError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteClientMessage, deleteClientLoading, deleteClientError]);

  const cols = React.useMemo(
    () => [
      {
        Header: "Logo",
        accessor: "logo",
        cellClass: "",
        Cell: (props) => (
          // <Link to={`/client-details/${props?.row?.values?.id}`}>
          <div>
            <img
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "10px",
              }}
              src={props?.value || ontriv}
              alt="client"
              onClick={() => {
                setCurrentSection("Client Details");
              }}
            />
          </div>
          // </Link>
        ),
      },
      {
        Header: "Name",
        accessor: "client_business_name",
        cellClass: "pt-4 list-client-item ",
        Cell: (props) => <>{props?.value || "Not Available"}</>,
      },
      {
        Header: "Email Address",
        accessor: "email",
        cellClass: "pt-4 list-client-item ",
        Cell: (props) => <>{props?.value || "Not Available"}</>,
      },
      // {
      //   Header: 'Project Timeline',
      //   accessor: 'projectTimeline',
      //   cellClass: 'pt-4 list-client-item ',
      //   Cell: props => <>{props.value}</>
      // },
      {
        Header: "Business category",
        accessor: "business_category",
        cellClass: "pt-4 list-client-item",
        Cell: (props) => <>{props?.value || "Not Available"}</>,
      },
      // {
      //   Header: "Project Tag",
      //   accessor: "projectTag",
      //   cellClass: "pt-4  list-client-item",
      //   Cell: (props) => (
      //     // <div className='list-client-tag text-center'>
      //     //   {/* <img src={tag} alt='project-tag' className='mr-2' />
      //     //   {'Content Calendar'} */}
      //     // </div>
      //     <> {"" || "Not Available"}</>
      //   ),
      // },

      {
        Header: "Action",
        accessor: "id",
        cellClass: "pt-4 list-client-delete list-client-item cursor-pointer",
        Cell: (props) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setClientId(props?.row?.original?.client_id);
                toggleShowDelete();
              }}
            >
              <MdDelete size="14px" className="pt-0" />
              <span className="pt-2 mb-0 text-underline">Delete</span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <div className="mb-0 mt-2 overflow-auto">
      <Table
        columns={cols}
        data={clients}
        divided
        defaultPageSize={6}
        pagePosition="left"
        rowOnClick={openClientDetailsPage}
      />

      <DeleteClientModal
        showDelete={showDelete}
        toggleShowDelete={toggleShowDelete}
        deleteClientLoading={deleteClientLoading}
        handleDelete={handleDelete}
        clientId={clientId}
      />
    </div>
  );
};

export default connect(null, { setCurrentSection })(ClientListView);
