import { useEffect, useMemo, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

import { CenteredModal } from "../../components/Modal";
import postImg from "../../assets/img/post.png";

//core components
import OverviewCards from "./DashboardCards/OverviewCards";
import FinancialCard from "./DashboardCards/FinancialCard";
import ClientCard from "./DashboardCards/ClientCard";
import PostsCard from "./DashboardCards/PostsCard";
import SetUpBussiness from "../Bussiness";

//redux
import { connect } from "react-redux";
import {
  getBusinessSocialsConnectionStatus,
  getAllDashboardMetrics,
  getInvoiceSettings,
} from "../../redux/actions";
import useCurrency from "../../hooks/useCurrency";

const Dashboard = (props) => {
  const [eventState, updateEventState] = useState(false);
  const [postState, updatePostState] = useState(false);
  const [view, setView] = useState("dashboard");
  const [postIdea, setPostIdea] = useState({});

  const navigate = useNavigate();

  const {
    getBusinessSocialsConnectionStatus,
    getAllDashboardMetrics,
    businessSocialsConnectionStatus,
    getInvoiceSettings,
    invoiceSettings,
    // id,
    businessUniquecode,
  } = props;

  const { currencySymbol } = useCurrency(invoiceSettings?.currency);

  const toggleEventState = (idea) => {
    setPostIdea(idea);
    updateEventState(!eventState);
  };

  const togglePostState = () => {
    updatePostState(!postState);
  };

  const handleAddClient = () => {
    navigate("/client-management");
  };

  const handleAddInvoice = () => {
    navigate("/invoices-&-financials");
  };

  const showDashboardView = useMemo(() => {
    return (
      businessSocialsConnectionStatus?.linkedin ||
      businessSocialsConnectionStatus?.facebook ||
      businessSocialsConnectionStatus?.instagram
    );
  }, [
    businessSocialsConnectionStatus?.linkedin,
    businessSocialsConnectionStatus?.facebook,
    businessSocialsConnectionStatus?.instagram,
  ]);

  useEffect(() => {
    getBusinessSocialsConnectionStatus(businessUniquecode);
  }, [getBusinessSocialsConnectionStatus, businessUniquecode]);

  useEffect(() => {
    if (showDashboardView) {
      setView("dashboard");
    } else {
      setView("setup");
    }
  }, [showDashboardView]);
  useEffect(() => {
    getAllDashboardMetrics();
  }, [getAllDashboardMetrics]);

  useEffect(() => {
    getInvoiceSettings();
  }, [getInvoiceSettings]);

  return (
    <div className="dashboard dashboard-wrapper">
      {view === "dashboard" ? (
        <Row>
          <Col md="12" sm="12" lg="8" xxl="8" className=" mb-3">
            <div>
              <OverviewCards currencySymbol={currencySymbol} />

              <Row className="gx-3">
                <FinancialCard
                  handleAddInvoice={handleAddInvoice}
                  currencySymbol={currencySymbol}
                  currency={invoiceSettings?.currency}
                />

                <ClientCard handleAddClient={handleAddClient} />
              </Row>
            </div>
          </Col>
          <PostsCard
            toggleEventState={toggleEventState}
            togglePostState={togglePostState}
          />
        </Row>
      ) : (
        <SetUpBussiness onCompleteAction={() => setView("dashboard")} />
      )}

      <CenteredModal
        position="centered"
        modalState={postState}
        setModalState={updatePostState}
      >
        <div className="post">
          <h2 className="post-title">10th April 2021</h2>
          <div className="py-3">
            <div className="post-details mb-0 ">
              <Row className="gx-5">
                <Col sm="4">
                  <div className="post-img-wrapper">
                    <img className="post-img" src={postImg} alt="post" />
                  </div>
                </Col>

                <Col sm="8">
                  <div className="pt-2">
                    <h3 className="post-text-header">UK villages</h3>
                    <p className="post-description">
                      Lorem ipsum dolor sit amet, cons ectetur adipiscing elit,
                      sedLorem ipsum dolor sit ame.
                    </p>
                    <div className="d-flex">
                      <div className="client-section">
                        <h2 className="client-section-header-text">Client</h2>
                        <div className="client-details d-flex">
                          <p className="client-logo">10px</p>
                          <p className="client-name">10px Socials</p>
                        </div>
                      </div>
                      <div className="channel-section">
                        <h4 className="channel-section-header-text">Channel</h4>
                        <div className="tags d-flex">
                          <p className="tag tag-1">Blog</p>
                          <p className="tag tag-2">Facebook</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <hr />
            <div className="post-details mb-0 ">
              <Row className="gx-5">
                <Col sm="4">
                  <div className="post-img-wrapper">
                    <img className="post-img" src={postImg} alt="post" />
                  </div>
                </Col>

                <Col sm="8">
                  <div className="pt-2">
                    <h3 className="post-text-header">UK villages</h3>
                    <p className="post-description">
                      Lorem ipsum dolor sit amet, cons ectetur adipiscing elit,
                      sedLorem ipsum dolor sit ame.
                    </p>
                    <div className="d-flex">
                      <div className="client-section">
                        <h2 className="client-section-header-text">Client</h2>
                        <div className="client-details d-flex">
                          <p className="client-logo">10px</p>
                          <p className="client-name">10px Socials</p>
                        </div>
                      </div>
                      <div className="channel-section">
                        <h4 className="channel-section-header-text">Channel</h4>
                        <div className="tags d-flex">
                          <p className="tag tag-1">Blog</p>
                          <p className="tag tag-2">Facebook</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <hr />
            <div className="post-details mb-0 ">
              <Row className="gx-5">
                <Col sm="4">
                  <div className="post-img-wrapper">
                    <img className="post-img" src={postImg} alt="post" />
                  </div>
                </Col>

                <Col sm="8" className="">
                  <div className="pl-3 pt-2">
                    <h3 className="post-text-header">UK villages</h3>
                    <p className="post-description">
                      Lorem ipsum dolor sit amet, cons ectetur adipiscing elit,
                      sedLorem ipsum dolor sit ame.
                    </p>
                    <div className="d-flex">
                      <div className="client-section">
                        <h4 className="client-section-header-text">Client</h4>
                        <div className="client-details d-flex">
                          <p className="client-logo">10px</p>
                          <p className="client-name">10px Socials</p>
                        </div>
                      </div>
                      <div className="channel-section">
                        <h4 className="channel-section-header-text">Channel</h4>
                        <div className="tags d-flex">
                          <p className="tag tag-1">Blog</p>
                          <p className="tag tag-2">Facebook</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mx-auto post-button-wrapper">
            <Button className="py-3 mx-auto post-button">View All Posts</Button>
          </div>
        </div>
      </CenteredModal>

      <CenteredModal
        position="centered"
        modalState={eventState}
        setModalState={updateEventState}
      >
        <div className="upcoming-event-modal">
          <div>
            <div className="event-img-wrapper ">
              <img src={postIdea?.image} className="event-img " alt="event" />
            </div>
            <div className="text-center">
              <h1 className="event-header-title mb-0">
                {postIdea?.content_idea_name}
              </h1>
              <p className="event-description">
                {postIdea?.content_idea_description}
              </p>
              <button
                className="btn btn-primary send px-5 py-2"
                style={{ whiteSpace: "nowrap" }}
                // onClick={onClick}
              >
                Create Content
              </button>
            </div>
          </div>
        </div>
      </CenteredModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    businessUniquecode: state?.auth?.currentUser?.unique_code,
    // membership_status: state?.auth?.currentUser?.membership_status,
    id: state?.auth?.currentUser?.id,
    businessSocialsConnectionStatus:
      state?.socials?.businessSocialsConnectionStatus,
    invoiceSettings: state?.settings?.invoiceSettings,
  };
};

export default connect(mapStateToProps, {
  getBusinessSocialsConnectionStatus,
  getAllDashboardMetrics,
  getInvoiceSettings,
})(Dashboard);
