import React, { useState, useEffect } from "react";
import XCancel from "../../../assets/img/x-cancel.svg";
import { BiPlus } from "react-icons/bi";
import { Form, Input, Row, Col } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { AiOutlineDelete } from "react-icons/ai";
import { calculateTotal } from "../../../utils/helper";
import moment from "moment";

//redux
import { connect } from "react-redux";
import { createNewInvoice, getClient } from "../../../redux/actions";
import { toast } from "react-toastify";
import useCurrency from "../../../hooks/useCurrency";

const CreateInvoiceModal = ({
  clients,
  closeInvoiceModal,
  createNewInvoice,
  getClient,
  loading,
  error,
  createInvoiceMessage,
  currency,
}) => {
  const [schedule, setSchedule] = useState({
    never_ending: "",
    interval: "",
    end_date: "",
  });
  const [formData, setFormData] = useState({});
  const [items, setItems] = useState([]);
  const [recurring, setRecurring] = useState(false);

  const { currencySymbol } = useCurrency(currency);

  //functions

  const getCurrentDate = () => {
    const date = new Date();
    return moment(date).format("YYYY-MM-DD");
  };
  const handleAddItem = () => {
    let id = items.length + 1;

    const newItem = {
      id: id,
      quantity: "0",
      rate: "0",
      amount: "0",
    };
    setItems([...items, newItem]);
  };
  const removeItem = (index) => {
    const newItems = [...items];
    newItems?.splice(index, 1);
    setItems(newItems);
  };

  const handleCloseInvoiceModal = () => {
    setSchedule({});
    setFormData({});
    setItems([]);
    closeInvoiceModal();
  };
  //Handle FormData Inputs
  //handle Never ending Select
  const handleNeverEndingChange = (e) => {
    const { name, value } = e.target;
    setSchedule({ ...schedule, [name]: value });
  };
  //handle Interval Select
  const handleIntervalChange = (e) => {
    const { name, value } = e.target;
    setSchedule({ ...schedule, [name]: value });
  };
  const handleItemInputChange = (e, index) => {
    const { name, value } = e.target;
    const newItems = items || [];
    newItems[index] = { ...newItems?.[index], [name]: value };
    newItems[index] = {
      ...newItems?.[index],
      amount: newItems?.[index]?.rate * newItems?.[index]?.quantity,
    };

    setItems([...newItems]);
  };

  //Recurring
  const handleRecurringCheck = () => {
    setRecurring(!recurring);
  };

  //handle ScheduleDates
  const handleDatesChange = (e) => {
    const { name, value } = e.target;
    setSchedule({ ...schedule, [name]: value });
  };

  //Handle Regular form Inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //handle Form Submission
  const handleCreateInvoice = (e) => {
    e.preventDefault();
    let payload = {};
    if (recurring) {
      payload = {
        ...formData,
        schedule: {
          interval: schedule?.interval || null,
          never_ending:
            schedule?.interval?.never_ending === "true" ? true : false || null,
          start_date: schedule?.start_date || null,
          end_date: schedule?.end_date || null,
        },
        items: items,
        recurring: recurring,
        currency,
        total: calculateTotal(items) || null,
        sub_total: calculateTotal(items) || null,
      };
    } else {
      payload = {
        ...formData,
        items: items,
        recurring: recurring,
        currency,
        total: calculateTotal(items) || null,
        sub_total: calculateTotal(items) || null,
      };
    }
    createNewInvoice(payload);
  };

  const intervalOptions = [
    {
      value: "weekly",
      label: "Weekly",
    },
    {
      value: "monthly",
      label: "Monthly",
    },
    {
      value: "quarterly",
      label: "Quarterly",
    },
    {
      value: "annually",
      label: "Annually",
    },
  ];
  const neverEndingOptions = [
    {
      value: true,
      label: "Never",
    },
    {
      value: false,
      label: "On date",
    },
  ];

  //useEffect
  useEffect(() => {
    setItems([
      {
        id: 1,
        item_description: "",
        quantity: "",
        rate: "",
        amount: "",
      },
    ]);
  }, []);
  useEffect(() => {
    if (error?.length > 0 && !loading) {
      toast.error(error);
    }
    if (createInvoiceMessage?.length > 0 && !loading) {
      toast.success(createInvoiceMessage);
      closeInvoiceModal();
    }
  }, [loading, error, createInvoiceMessage, closeInvoiceModal]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  console.log(":::", schedule?.never_ending);

  return (
    <div className="off-canvas-menu">
      <div className="off-canvas-menu__content px-3 py-4">
        <div className="d-inline-flex w-100">
          <div className="add-client-text text-center">
            <h5>Create new invoice</h5>
          </div>
          <img
            onClick={handleCloseInvoiceModal}
            className="ms-auto"
            src={XCancel}
            alt=""
          />
        </div>
        <Form onSubmit={handleCreateInvoice} className="overflow-auto">
          <div className="form-section">
            <div className="mt-4 mb-3">
              <label className="text-left w-100" htmlFor="client">
                Select Client
              </label>
              <Input
                className="off-canvas-menu__input py-2 px-3"
                type="select"
                name="client"
                id="client"
                value={formData?.client || ""}
                onChange={handleInputChange}
              >
                <option value="">Select Client</option>
                {clients?.map((client) => {
                  return (
                    <option value={client?.client_id} key={client?.id}>
                      {client?.client_business_name}
                    </option>
                  );
                })}
              </Input>
            </div>

            <div className="my-3">
              <label className="text-left w-100">Invoice Name</label>
              <Input
                type="text"
                name="description"
                onChange={handleInputChange}
                value={formData?.description || ""}
                placeholder="Invoice Name"
                className="off-canvas-menu__input py-2 px-3"
              />
            </div>

            <Row className="my-3">
              <Col md="6">
                <label className="text-left w-100">Issued on</label>
                <Input
                  type="date"
                  name="issued_on"
                  onChange={handleInputChange}
                  value={formData?.issued_on || ""}
                  min={getCurrentDate()}
                  placeholder="Issued On"
                  className="off-canvas-menu__input py-2 px-3"
                />
              </Col>
              <Col md="6">
                <label className="text-left w-100">Due Date</label>
                <Input
                  type="date"
                  name="due_date"
                  onChange={handleInputChange}
                  value={formData?.due_date || ""}
                  min={
                    moment(formData?.issued_on).format("YYYY-MM-DD") ||
                    getCurrentDate()
                  }
                  placeholder="Due Date"
                  className="off-canvas-menu__input py-2 px-3"
                />
              </Col>
            </Row>
            <div className="mt-1 d-inline-flex">
              <input
                type="checkbox"
                name="recurring"
                className="my-auto"
                checked={recurring}
                onChange={handleRecurringCheck}
              />
              <h6 className="fs-6 my-3 ms-2 fw-light">
                This is a recurring invoice (monthly)
              </h6>
            </div>
            {recurring && (
              <>
                <Row className="my-2">
                  <Col md="6">
                    <label className="text-left w-100">Repeat</label>
                    <Input
                      className="off-canvas-menu__input py-2 px-3"
                      type="select"
                      name="interval"
                      id="interval"
                      value={schedule?.interval || ""}
                      onChange={handleIntervalChange}
                    >
                      <option value="">Select Interval</option>
                      {intervalOptions?.map((interval, index) => {
                        return (
                          <option value={interval?.value} key={index}>
                            {interval?.label}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col md="6">
                    <label className="text-left w-100">End Repeat</label>
                    <Input
                      className="off-canvas-menu__input py-2 px-3"
                      type="select"
                      name="never_ending"
                      id="never_ending"
                      value={schedule?.never_ending || ""}
                      onChange={handleNeverEndingChange}
                    >
                      <option value="">Select option</option>
                      {neverEndingOptions?.map((item, index) => {
                        return (
                          <option value={Boolean(item?.value)} key={index}>
                            {item?.label}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </Row>
                {schedule?.never_ending === "true" ? null : (
                  <Row className="mt-2">
                    <Col md="12">
                      <label className="text-left w-100">End Date</label>
                      <Input
                        type="date"
                        name="end_date"
                        value={schedule?.end_date || ""}
                        onChange={handleDatesChange}
                        placeholder="End Date"
                        className="off-canvas-menu__input py-2 px-3"
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}

            <div className="items-section">
              <Row className="mt-2">
                <Col xs="4" md="4">
                  <label className="text-left w-100">Item </label>
                </Col>
                <Col xs="2" md="2">
                  <label className="text-left w-100">QTY</label>
                </Col>
                <Col xs="2" md="2">
                  <label className="text-left w-100">Unit Cost</label>
                </Col>
                <Col xs="2" md="2">
                  <label className="text-left w-100">Amount</label>
                </Col>
                <Col xs="1" md="1"></Col>
              </Row>
              <div
                style={{
                  height: "100px",
                  overflowY: "scroll",
                  paddingRight: "2rem",
                }}
              >
                {items?.map((item, index) => {
                  return (
                    <Row className="mt-2" key={item.id}>
                      <Col xs="4" md="4">
                        <Input
                          type="text"
                          name="item_description"
                          placeholder="Description"
                          className="off-canvas-menu__input py-2 px-2"
                          value={item?.item_description || ""}
                          onChange={(e) => handleItemInputChange(e, index)}
                        />
                      </Col>
                      <Col xs="2" md="2">
                        <Input
                          type="number"
                          name="quantity"
                          placeholder="QTY"
                          value={item.quantity || ""}
                          className="off-canvas-menu__input py-2 px-2"
                          onChange={(e) => handleItemInputChange(e, index)}
                        />
                      </Col>
                      <Col xs="2" md="2">
                        <Input
                          type="number"
                          name="rate"
                          placeholder="Rate"
                          value={item?.rate || ""}
                          className="off-canvas-menu__input py-2 px-2"
                          onChange={(e) => handleItemInputChange(e, index)}
                        />
                      </Col>
                      <Col xs="2" md="2">
                        <h6 className="pt-3">
                          {currencySymbol}
                          {item?.rate * item?.quantity || "0.00"}
                        </h6>
                      </Col>
                      <Col xs="1" md="1" className="pt-3">
                        <button
                          style={{
                            cursor: "pointer",
                            background: "transparent",
                            border: "none",
                          }}
                          onClick={() => removeItem(index)}
                          disabled={items?.length === 1}
                        >
                          <AiOutlineDelete color="red" size="20px" />
                        </button>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </div>

            <div className="d-inline-flex w-89 mt-4">
              <span className="add-item-btn me-auto" onClick={handleAddItem}>
                <span>
                  <BiPlus />
                </span>{" "}
                ADD ITEM
              </span>
              <h6 className="fw-light ms-auto me-4 my-auto">Total</h6>
              <h6 className="fw-bold ms-auto me-4 my-auto">
                {currencySymbol} {calculateTotal(items) || "0.00"}
              </h6>
            </div>
          </div>
          <div className="w-100 mt-4">
            <hr />
            <div className="d-flex justify-content-end mt-2 w-100 mb-4 ">
              <button
                type="submit"
                className="py-2 px-4 send"
                style={{ marginRight: "40px" }}
              >
                {loading ? (
                  <div className="text-center w-100 align-items-center">
                    <ThreeDots
                      color="white"
                      height={"12px"}
                      wrapperStyle={{ display: "block" }}
                    />
                  </div>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
          {/* <div className="d-inline-flex w-100 mt-4 justify-content-end">
            <button className="btn btn-primary px-4" disabled={loading}>
              {loading ? (
                <div className="text-center w-100 align-items-center">
                  <ThreeDots
                    color="white"
                    height={"12px"}
                    wrapperStyle={{ display: "block" }}
                  />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div> */}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { invoice } = state;
  return {
    clients: state?.client?.clients,
    loading: invoice?.loading?.createInvoice,
    error: invoice?.error?.createInvoice,
    createInvoiceMessage: invoice?.message?.createInvoice,
    currency: state?.settings?.invoiceSettings?.currency,
  };
};
export default connect(mapStateToProps, { createNewInvoice, getClient })(
  CreateInvoiceModal
);
