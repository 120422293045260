import React, { useState, useEffect } from "react";
import moment from "moment";
import { Input } from "reactstrap";
import { CenteredModal as Modal } from "../../../components/Modal";
import ButtonLoader from "../../../components/Loaders/ButtonLoader";
import { useNavigate } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { duplicateOneInvoice, getClient } from "../../../redux/actions";
import { toast } from "react-toastify";

const DuplicateInvoiceModal = ({
  showDuplicateInvoiceModal,
  setShowDuplicateInvoiceModal,
  clients,
  duplicateOneInvoice,
  duplicateInvoiceLoading,
  duplicateInvoiceError,
  duplicateInvoiceMessage,
  getClient,
  invoiceId,
}) => {
  const [formData, setFormData] = useState({});

  const navigate = useNavigate();
  const getCurrentDate = () => {
    const date = new Date();
    return moment(date).format("YYYY-MM-DD");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      extra_details_for_duplicate: {
        ...formData,
        old_invoice_number: invoiceId,
      },
    };
    duplicateOneInvoice(payload);
  };

  useEffect(() => {
    if (duplicateInvoiceMessage?.length && !duplicateInvoiceLoading) {
      toast.success(duplicateInvoiceMessage);
      setShowDuplicateInvoiceModal(false);
      navigate("/invoices-&-financials");
    } else if (duplicateInvoiceError?.length && !duplicateInvoiceLoading) {
      toast.error(duplicateInvoiceError);
    }
  }, [
    duplicateInvoiceMessage,
    setShowDuplicateInvoiceModal,
    duplicateInvoiceLoading,
    duplicateInvoiceError,
    navigate,
  ]);

  useEffect(() => {
    getClient();
  }, [getClient]);
  return (
    <Modal
      modalState={showDuplicateInvoiceModal}
      setModalState={setShowDuplicateInvoiceModal}
    >
      <div className="add-client-wrapper text-center ">
        <div className="add-client-text text-center">
          <h3>Duplicate Invoice</h3>
        </div>
        <form className="business-form mt-4" onSubmit={handleSubmit}>
          {/* <div className="w-50">
            <label className="text-left w-100">Invoice number</label>
            <Input
              type="text"
              name="old_invoice_number"
              onChange={handleInputChange}
            />
          </div> */}
          <div>
            <label className="text-left w-100">Select Client</label>
            <Input
              type="select"
              name="new_client"
              className="bank-select w-100 px-3 py-3 mb-2"
              onChange={handleInputChange}
            >
              <option value="">Select option</option>
              {clients?.map((client) => {
                return (
                  <option key={client?.client_id} value={client?.client_id}>
                    {client?.client_business_name}
                  </option>
                );
              })}
            </Input>
          </div>
          <div>
            <label className="text-left w-100">Due Date</label>
            <Input
              type="date"
              name="new_due_date"
              onChange={handleInputChange}
              min={getCurrentDate()}
            />
          </div>
          <div className="pt-2 pb-3">
            <button
              type="submit"
              className="px-5"
              disabled={duplicateInvoiceLoading}
            >
              {duplicateInvoiceLoading ? <ButtonLoader /> : "Make a copy"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { client } = state;
  return {
    clients: client?.clients,
    duplicateInvoiceLoading: state?.oneInvoice?.duplicateInvoiceLoading,
    duplicateInvoiceError: state?.oneInvoice?.duplicateInvoiceError,
    duplicateInvoiceMessage: state?.oneInvoice?.message?.duplicateInvoice,
  };
};
export default connect(mapStateToProps, { duplicateOneInvoice, getClient })(
  DuplicateInvoiceModal
);
