import React from "react";
import Error404 from "../../assets/img/error404.svg";

const ErrorPage = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 error">
      <img src={Error404} alt="eror404" />
      <h5>The page you're looking for doesn't exist or an error occurred.</h5>
    </div>
  );
};

export default ErrorPage;
