import React from "react";

//core component
import { CenteredModal as Modal } from "../../components/Modal";
import { ThreeDots } from "react-loader-spinner";

const DeleteClientModal = (props) => {
  const {
    showDelete,
    toggleShowDelete,
    deleteClientLoading,
    handleDelete,
    clientId,
  } = props;
  return (
    <Modal modalState={showDelete} setModalState={toggleShowDelete}>
      <div className="delete-client-wrapper text-center ">
        {/* <div className='text-center user-icon-container '>
        <HiUser className=' text-center' color='#49A8F8' size='45px' />
      </div> */}
        <div className="add-client-text text-center">
          <h3
            className="mt-2"
            style={{
              fontWeight: "700",
              fontSize: "30px",
            }}
          >
            Are you sure?
          </h3>
          <div className="my-4">
            <p
              className="mb-0 w-100"
              style={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "24px",

                textAlign: "center",

                color: "#17151E",

                opacity: "0.8",
              }}
            >
              You’ll be removing all information and access to their
            </p>
            <p
              className="mb-0 w-100"
              style={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "24px",

                textAlign: "center",

                color: "#17151E",

                opacity: "0.8",
              }}
            >
              social accounts
            </p>
          </div>
        </div>

        <div className="px-5">
          <div className="pt-2 pb-2 ">
            <button
              className="w-100 btn-primary btn"
              onClick={toggleShowDelete}
            >
              No, I’ve changed my mind
            </button>
          </div>
          <div className=" pb-2">
            <button
              className="w-100 bg-white "
              style={{
                background: "white",
                fontSize: "16px",
                letterSpacing: "0.75px",
                color: "#FF2154",
              }}
              onClick={() => {
                handleDelete(clientId);
              }}
              disabled={deleteClientLoading}
            >
              {deleteClientLoading ? (
                <div className="text-center w-100 align-items-center">
                  <ThreeDots
                    color="white"
                    height={"12px"}
                    wrapperStyle={{ display: "block" }}
                  />
                </div>
              ) : (
                "Yes, delete the client"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteClientModal;
