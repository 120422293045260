import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { AiOutlineMore } from "react-icons/ai";

const TableDropdown = ({ actions }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = (e) => {
    e.stopPropagation();
    setDropdownOpen((prevState) => !prevState);
  };
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
      <DropdownToggle style={{ border: "none" }}>
        <AiOutlineMore color="#000" size="20px" />
      </DropdownToggle>
      <DropdownMenu left="true" className="dropdown-container">
        {actions?.map((action, index) => (
          <DropdownItem
            className="dropdown-items"
            onClick={action?.action}
            key={index}
          >
            {action?.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default TableDropdown;
