import React, { useEffect, useState } from "react";
import { Row, Col, Nav, NavItem, Container } from "reactstrap";
import TitleModalLogoHere from "../../assets/img/businessLogo.svg";
import { Bars } from "react-loader-spinner";
//utils
import {
  stringDateFormat,
  invoicePaymentStatus,
  formatAmount,
  calculateVat,
  downloadPdf,
  pdfWithPrintJs,
} from "../../utils/helper";

//icons
import HrInvoice from "../../assets/img/hr-invoice.svg";
import { useLocation } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { getInvoice, getBusinessPaymentGateways } from "../../redux/actions";
import { toast } from "react-toastify";
import PaymentGatewayModal from "./PaymentGatewayModal";
import useCurrency from "../../hooks/useCurrency";

const InvoicePage = (props) => {
  const {
    getInvoice,
    getBusinessPaymentGateways,
    paymentGateways,
    invoice,
    loading,
    message,
    error,
  } = props;
  const [pageData, setPageData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const location = useLocation();
  const { currencySymbol } = useCurrency(pageData?.currency);
  //functions
  const DownloadInvoicePdf = () => {
    downloadPdf("invoice");
  };
  const getPDF = () => {
    pdfWithPrintJs("invoice", `invoice-${location?.pathname.split("/")?.[2]} `);
  };
  const openPaymentGatewayModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    if (!loading && message?.length > 0) {
      setTimeout(() => setPageData(invoice), 1000);
    } else if (!loading && error?.length > 0) {
      toast.error(error);
    } else {
      setPageData(null);
    }
  }, [loading, message, error, invoice]);
  useEffect(() => {
    getInvoice(location?.pathname?.split("/")?.[2]);
  }, [getInvoice, location?.pathname]);

  useEffect(() => {
    if (pageData?.business) {
      getBusinessPaymentGateways(pageData?.business);
    }
  }, [pageData?.business, getBusinessPaymentGateways]);
  console.log({ pageData });
  return (
    <>
      <Nav className="p-4 justify-content-end border border-bottom-1">
        <NavItem>
          <span className="outline-btn" onClick={DownloadInvoicePdf}>
            Download Invoice
          </span>
        </NavItem>
        <NavItem>
          <span className="outline-btn" onClick={getPDF}>
            Print Invoice
          </span>
        </NavItem>
      </Nav>
      <Container className="payment-page-container">
        <Row>
          <Col md="12">
            <div className="mt-5 p-sm-1 p-md-5 d-flex justify-content-end align-items-center">
              {paymentGateways?.length ? (
                <button
                  style={{
                    textDecoration: "none",
                    backgroundColor: "#2062F4",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 700,
                    padding: "0.7rem 2rem",
                    borderRadius: "8px",
                    cursor: "pointer",
                    border: 0,
                  }}
                  onClick={openPaymentGatewayModal}
                  disabled={pageData?.status?.toLowerCase() === "paid"}
                >
                  Pay Now
                </button>
              ) : null}
            </div>
          </Col>
          <Col md="12">
            {loading || !pageData ? (
              <div className="position-fixed top-50 start-50">
                <Bars height="100" width="100" color="#2062F4" />
              </div>
            ) : (
              <div id="invoice">
                <div className="add-client-wrapper-2 text-center ">
                  <div className="d-inline-flex" style={{ width: "100%" }}>
                    <img
                      className="me-auto mb-3"
                      src={
                        pageData?.extra_details?.business_logo
                          ? pageData?.extra_details?.business_logo
                          : TitleModalLogoHere
                      }
                      width="63px"
                      alt="business logo"
                    />
                    <h6 className="invoice-modal__title">
                      {pageData?.extra_details?.invoice_prefix
                        ? pageData?.extra_details?.invoice_prefix
                        : "INV"}{" "}
                      -{pageData?.id}
                    </h6>
                  </div>
                  <Row>
                    <Col sm="6" lg="6" xl="6">
                      <h6 className="invoice-modal__light text-left mb-3">
                        {pageData?.extra_details?.business_address}
                      </h6>
                    </Col>
                    <Col sm="6" lg="6" xl="6">
                      <div className="" style={{ textAlign: "right" }}>
                        <h6
                          className="invoice-modal__light text-right"
                          style={{ textAlign: "right" }}
                        >
                          Billed to,
                        </h6>
                        <h6
                          className="invoice-modal__bold text-right"
                          style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          {pageData?.client?.fullname}
                        </h6>
                        <h6
                          className="invoice-modal__light text-right"
                          style={{ textAlign: "right" }}
                        >
                          {pageData?.client?.client_email || "No Email"}
                        </h6>
                        <h6
                          className="invoice-modal__light text-right"
                          style={{ textAlign: "right" }}
                        >
                          {pageData?.client?.client_phone_number !== "Null"
                            ? pageData?.client?.client_phone_number
                            : ""}
                        </h6>
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-end mt-3">
                    <Col sm="6" lg="6" xl="6">
                      <div className="mb-4">
                        <h6 className="invoice-modal__light text-left">
                          Issue Date
                        </h6>
                        <h6 className="invoice-modal__bold text-left mb-3">
                          {stringDateFormat(pageData?.issued_on)}
                        </h6>
                      </div>
                      <div className="">
                        <h6 className="invoice-modal__light text-left">
                          Due Date
                        </h6>
                        <h6 className="invoice-modal__bold text-left">
                          {stringDateFormat(pageData?.due_date)}
                        </h6>
                      </div>
                    </Col>
                    <Col sm="6" lg="6" xl="6">
                      {invoicePaymentStatus(pageData?.status)}
                    </Col>
                  </Row>

                  <img src={HrInvoice} className="w-100" alt="" />
                  <div
                    className="mt-5 invoice-modal__grey-section w-100 py-4 px-4"
                    style={{ width: "100%", overflowX: "auto" }}
                  >
                    <table>
                      <thead style={{ borderBottom: "2px solid #F2F2F2" }}>
                        <th>
                          <h6 className="invoice-modal__qty">ITEM</h6>
                        </th>
                        <th>
                          <h6 className="invoice-modal__qty">RATE</h6>
                        </th>
                        <th>
                          <h6 className="invoice-modal__qty ">QTY</h6>
                        </th>
                        <th>
                          <h6 className="invoice-modal__qty">AMOUNT</h6>
                        </th>
                      </thead>
                      <tbody>
                        {pageData?.items?.map((item) => {
                          return (
                            <tr key={item?.id}>
                              <td style={{ width: "300px" }}>
                                <h6 className="invoice-modal__item">
                                  {item?.item_description}
                                </h6>
                              </td>
                              <td style={{ width: "300px" }}>
                                <h6 className="invoice-modal__item">
                                  {formatAmount(item?.rate)}
                                </h6>
                              </td>
                              <td style={{ width: "300px" }}>
                                <h6 className="invoice-modal__item ">
                                  {item?.quantity}
                                </h6>
                              </td>
                              <td style={{ width: "300px" }}>
                                <h6 className="invoice-modal__item">
                                  {currencySymbol} {formatAmount(item?.amount)}
                                </h6>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Row>
                    <Col className="ms-auto" xl="9">
                      <div className="mt-5 invoice-modal__grey-section py-4 px-4">
                        <Row>
                          <Col xl="6" lg="6" sm="6">
                            <h6 className="invoice-modal__qty ">Sub Total</h6>
                            <h6 className="invoice-modal__qty">
                              VAT({pageData?.vat}%)
                            </h6>
                          </Col>
                          <Col xl="6" lg="6" sm="6">
                            <h6
                              className="invoice-modal__item "
                              style={{ textAlign: "right" }}
                            >
                              {formatAmount(pageData?.sub_total)}
                            </h6>
                            <h6
                              className="invoice-modal__item"
                              style={{ textAlign: "right" }}
                            >
                              {currencySymbol}
                              {formatAmount(
                                calculateVat(pageData?.sub_total, pageData?.vat)
                              )}
                            </h6>
                          </Col>
                        </Row>
                        <Row className="invoice-modal__blue-section py-3">
                          <div className="d-inline-flex w-100">
                            <h6
                              className="invoice-modal__qty my-auto"
                              style={{ textAlign: "left" }}
                            >
                              Total
                            </h6>
                            <h6
                              className="invoice-modal__total ms-auto my-auto"
                              style={{ textAlign: "right" }}
                            >
                              {currencySymbol} {formatAmount(pageData?.total)}
                            </h6>
                          </div>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <img src={HrInvoice} className="my-5 w-100" alt="" />
                  <h6 className="add-item mb-5">
                    {pageData?.extra_details?.business_website_url ||
                      "www.yourwebsiteurl.com"}
                  </h6>
                </div>
              </div>
            )}
          </Col>

          <PaymentGatewayModal
            openModal={modalOpen}
            setOpenModal={setModalOpen}
            client={pageData}
            amount={pageData?.total}
            currency={pageData?.currency}
          />
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    invoice: state?.invoice?.invoice,
    loading: state?.invoice?.loading?.getInvoice,
    message: state?.invoice?.message?.getInvoice,
    error: state?.invoice?.error?.getInvoice,
    paymentGateways: state?.invoice?.paymentGateways,
  };
};
export default connect(mapStateToProps, {
  getInvoice,
  getBusinessPaymentGateways,
})(InvoicePage);
