import { put, takeEvery, fork, all, call } from "redux-saga/effects";
import {
  GET_ALL_SUBSCRIPTIONS,
  INITIATE_SUBSCRIPTION,
  GET_ALL_CARDS,
  CANCEL_SUBSCRIPTION,
  GET_SUBSCRIPTION_DETAILS,
  CHANGE_SUBSCRIPTION_PLAN,
  REMOVE_CARD,
  getAllSubscriptionsSuccess,
  getAllSubscriptionsError,
  initiateSubscriptionSuccess,
  initiateSubscriptionError,
  getAllCardsSuccess,
  getAllCardsError,
  cancelSubscriptionSuccess,
  cancelSubscriptionError,
  getAllSubscriptionDetailsSucess,
  getAllSubscriptionDetailsEror,
  changeSubscriptionPlanSucess,
  changeSubscriptionPlanError,
  removeCardSuccess,
  removeCardError,
  clearMessages,
} from "../actions";
import Axios from "../../utils/Axios";

export function* GetAllSubcriptions() {
  try {
    const response = yield Axios.get(`/payment/api/v1/subscription-history/`);
    if (response?.status === 200) {
      yield put(getAllSubscriptionsSuccess(response?.data));
    } else {
      yield put(getAllSubscriptionsError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error?.response?.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getAllSubscriptionsError(message));
    yield put(clearMessages());
  }
}
export function* InitiateSubscription({ payload }) {
  try {
    const response = yield Axios.post(`payment/api/v1/setup-intent/`, payload);
    if (response?.status === 200) {
      yield put(initiateSubscriptionSuccess());
      yield call(GetAllCards);
    } else {
      yield put(initiateSubscriptionError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.detail;
      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = errorMessage || "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error?.response?.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(initiateSubscriptionError(message));
    yield put(clearMessages());
  }
}
export function* GetAllCards() {
  try {
    const response = yield Axios.get(`/payment/api/v1/card/`);
    if (response?.status === 200) {
      yield put(getAllCardsSuccess(response?.data));
    } else {
      yield put(getAllCardsError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getAllCardsError(message));
    yield put(clearMessages());
  }
}
export function* CancelSubscription({ payload }) {
  try {
    const response = yield Axios.post(
      `/payment/api/v1/cancel-subscription/`,
      payload
    );
    if (response?.status === 200) {
      yield put(cancelSubscriptionSuccess());
      yield call(GetAllCards);
    } else {
      yield put(cancelSubscriptionError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.detail;
      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = errorMessage || "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error?.response?.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(cancelSubscriptionError(message));
    yield put(clearMessages());
  }
}

export function* GetSubscriptionDetails() {
  try {
    const response = yield Axios.get(`/payment/api/v1/membership/`);
    if (response?.status === 200) {
      yield put(getAllSubscriptionDetailsSucess(response?.data));
    } else {
      yield put(getAllSubscriptionDetailsEror(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error?.response?.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getAllSubscriptionDetailsEror(message));
    yield put(clearMessages());
  }
}
export function* ChangeSubscriptionPlan({ payload }) {
  try {
    const response = yield Axios.post(
      `/payment/api/v1/subscriptions/`,
      payload
    );
    if (response?.status === 200) {
      yield put(changeSubscriptionPlanSucess());
      yield call(GetSubscriptionDetails);
    } else {
      yield put(changeSubscriptionPlanError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.detail;
      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = errorMessage || "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error?.response?.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(changeSubscriptionPlanError(message));
    yield put(clearMessages());
  }
}
export function* RemoveCard() {
  try {
    const response = yield Axios.post(`/payment/api/v1/detach-payment-method/`);
    if (response?.status === 200) {
      yield put(removeCardSuccess());
      yield call(GetSubscriptionDetails);
    } else {
      yield put(removeCardError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.detail;
      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = errorMessage || "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error?.response?.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(removeCardError(message));
    yield put(clearMessages());
  }
}
export function* watchGetAllSubscriptions() {
  yield takeEvery(GET_ALL_SUBSCRIPTIONS, GetAllSubcriptions);
}
export function* watchInitiateSubscription() {
  yield takeEvery(INITIATE_SUBSCRIPTION, InitiateSubscription);
}
export function* watchCancelSubscription() {
  yield takeEvery(CANCEL_SUBSCRIPTION, CancelSubscription);
}
export function* watchGetAllCards() {
  yield takeEvery(GET_ALL_CARDS, GetAllCards);
}
export function* watchGetSubscriptionDetails() {
  yield takeEvery(GET_SUBSCRIPTION_DETAILS, GetSubscriptionDetails);
}
export function* watchChangeSubscriptionPlan() {
  yield takeEvery(CHANGE_SUBSCRIPTION_PLAN, ChangeSubscriptionPlan);
}
export function* watchRemoveCard() {
  yield takeEvery(REMOVE_CARD, RemoveCard);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetAllSubscriptions),
    fork(watchInitiateSubscription),
    fork(watchGetAllCards),
    fork(watchCancelSubscription),
    fork(watchGetSubscriptionDetails),
    fork(watchChangeSubscriptionPlan),
    fork(watchRemoveCard),
  ]);
}
