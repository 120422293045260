import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Row, Col, Card } from "reactstrap";
// import { FiArrowRight } from "react-icons/fi";
// import Table from '../../../components/Table'
// import ontriv from '../../../assets/img/ontriv.png'
import { connect } from "react-redux";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdAddCircle } from "react-icons/md";

// import { GoPrimitiveDot } from 'react-icons/go'
// import { HiPlus } from 'react-icons/hi';
// import cl from '../../../assets/img/cl.png'
import userAvatar from "../../../assets/img/avatar.png";

// import Calendar from 'react-calendar';
import Calendar from "../../../components/Calendar";
import { getClient, getClientInvoices } from "../../../redux/actions";
import { useParams } from "react-router-dom";
import UpdateDetailsModal from "./UpdateDetailsModal";
import InvoicesCard from "./Cards/InvoicesCard";
import SocialConnectionSection from "../../../components/Socials/SocialConnectionSection";
import { copierHelper } from "../../../utils/helper";
const ClientDetails = ({
  getClient,
  getClientInvoices,
  clientInvoices,
  getInvoiceLoading,
  clients,
}) => {
  const { id } = useParams();

  const [updateModal, setUpdateModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const openUpdateModal = (type) => {
    setUpdateModal(true);
    setModalType(type);
  };
  useEffect(() => {
    getClient();
  }, [getClient]);

  const currentClientDetails = useMemo(() => {
    return clients?.find((val) => {
      return val?.id === Number(id);
    });
  }, [clients, id]);
  const getAllClientInvoices = useCallback(() => {
    getClientInvoices(currentClientDetails?.client_id);
  }, [currentClientDetails, getClientInvoices]);
  useEffect(() => {
    getAllClientInvoices();
  }, [getAllClientInvoices]);
  return (
    <div className="client-details">
      <Row className=" mb-3">
        <Col sm="12">
          <Row>
            <Col sm="12" md="8">
              {/* <div className='client-profile-section'> */}
              <Card className="client-profile-card">
                {/* <div className="d-flex flex-xs-column flex--row justify-content-lg-around align-items-center"> */}
                <div
                  className="client-details-img-wrapper d-flex justify-content-center align-items-center"
                  style={{ width: "fit-content" }}
                >
                  <img
                    src={currentClientDetails?.logo || userAvatar}
                    alt="logo"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                </div>

                <div className="client-name" style={{ width: "fit-content" }}>
                  <h1>Client Name</h1>
                  <p className="mb-0">
                    {currentClientDetails?.client_business_name}
                  </p>
                </div>

                <div
                  className="business-category"
                  style={{ width: "fit-content" }}
                >
                  <h1>Business category</h1>
                  <p className="mb-0">
                    {currentClientDetails?.business_category || "N/A"}
                  </p>
                </div>

                <div className="client-portal" style={{ width: "fit-content" }}>
                  <h1>Client Portal</h1>
                  <div
                    onClick={() =>
                      copierHelper(
                        `https://main--dreamy-marshmallow-7002d8.netlify.app/?code=${currentClientDetails?.client_id}`,
                        "Client Portal"
                      )
                    }
                    className="mb-0 copy-link"
                  >
                    Copy link
                  </div>
                </div>
              </Card>
              <Card className="client-basic-information my-4">
                <h2 className="">Client Information</h2>
                <div>
                  <Row>
                    <Col sm="6" md="4">
                      <div
                        className="client-information-item cursor-pointer d-flex align-items-center justify-content-between"
                        onClick={() => {
                          openUpdateModal("notes");
                        }}
                      >
                        <h2>About</h2>
                        {currentClientDetails?.notes ? (
                          <IoIosCheckmarkCircle
                            className="information-icon"
                            color="#00D67D"
                          />
                        ) : (
                          <MdAddCircle
                            className="information-icon"
                            color="#9DA8B6"
                          />
                        )}
                      </div>
                    </Col>
                    <Col sm="6" xl="4">
                      <div
                        className="client-information-item cursor-pointer d-flex align-items-center justify-content-between"
                        onClick={() => {
                          openUpdateModal("website");
                        }}
                      >
                        <h2>Website</h2>
                        {currentClientDetails?.client_business_website ? (
                          <IoIosCheckmarkCircle
                            className="information-icon"
                            color="#00D67D"
                          />
                        ) : (
                          <MdAddCircle
                            className="information-icon"
                            color="#9DA8B6"
                          />
                        )}
                      </div>
                    </Col>
                    <Col sm="6" md="4">
                      <div
                        className="client-information-item cursor-pointer d-flex align-items-center justify-content-between"
                        onClick={() => {
                          openUpdateModal("usp");
                        }}
                      >
                        <h2>Unique value</h2>
                        {currentClientDetails?.USP ? (
                          <IoIosCheckmarkCircle
                            className="information-icon"
                            color="#00D67D"
                          />
                        ) : (
                          <MdAddCircle
                            className="information-icon"
                            color="#9DA8B6"
                          />
                        )}
                      </div>
                    </Col>
                    <Col sm="6" xl="4">
                      <div
                        className="client-information-item cursor-pointer d-flex align-items-center justify-content-between"
                        onClick={() => {
                          openUpdateModal("brandfont");
                        }}
                      >
                        <h2>Brand font</h2>
                        {currentClientDetails?.brand_font ? (
                          <IoIosCheckmarkCircle
                            className="information-icon"
                            color="#00D67D"
                          />
                        ) : (
                          <MdAddCircle
                            className="information-icon"
                            color="#9DA8B6"
                          />
                        )}
                      </div>
                    </Col>
                    <Col sm="6" xl="4">
                      <div
                        className="client-information-item cursor-pointer d-flex align-items-center justify-content-between"
                        onClick={() => {
                          openUpdateModal("brandcolor");
                        }}
                      >
                        <h2>Brand colors</h2>
                        {currentClientDetails?.brand_color ? (
                          <IoIosCheckmarkCircle
                            className="information-icon"
                            color="#00D67D"
                          />
                        ) : (
                          <MdAddCircle
                            className="information-icon"
                            color="#9DA8B6"
                          />
                        )}
                      </div>
                    </Col>
                    <Col sm="6" xl="4">
                      <div
                        className="client-information-item cursor-pointer d-flex align-items-center justify-content-between"
                        onClick={() => {
                          openUpdateModal("preferredtone");
                        }}
                      >
                        <h2>Brand voice</h2>
                        {currentClientDetails?.preferred_tone ? (
                          <IoIosCheckmarkCircle
                            className="information-icon"
                            color="#00D67D"
                          />
                        ) : (
                          <MdAddCircle
                            className="information-icon"
                            color="#9DA8B6"
                          />
                        )}
                      </div>
                    </Col>
                    <Col sm="6" xl="4">
                      <div
                        className="client-information-item cursor-pointer d-flex align-items-center justify-content-between"
                        onClick={() => {
                          openUpdateModal("targetaudience");
                        }}
                      >
                        <h2>Target audience</h2>
                        {currentClientDetails?.target_audience ? (
                          <IoIosCheckmarkCircle
                            className="information-icon"
                            color="#00D67D"
                          />
                        ) : (
                          <MdAddCircle
                            className="information-icon"
                            color="#9DA8B6"
                          />
                        )}
                      </div>
                    </Col>
                    <Col sm="6" xl="4">
                      <div
                        className="client-information-item cursor-pointer d-flex align-items-center justify-content-between"
                        onClick={() => {
                          openUpdateModal("maingoal");
                        }}
                      >
                        <h2>Goals</h2>
                        {currentClientDetails?.main_goal ? (
                          <IoIosCheckmarkCircle
                            className="information-icon"
                            color="#00D67D"
                          />
                        ) : (
                          <MdAddCircle
                            className="information-icon"
                            color="#9DA8B6"
                          />
                        )}
                      </div>
                    </Col>
                    <Col sm="2" md="4">
                      <div
                        className="client-information-item cursor-pointer d-flex align-items-center justify-content-between"
                        onClick={() => {
                          openUpdateModal("hashtag");
                        }}
                      >
                        <h2>Hashtag Sets</h2>
                        {currentClientDetails?.hashtag_sets ? (
                          <IoIosCheckmarkCircle
                            className="information-icon"
                            color="#00D67D"
                          />
                        ) : (
                          <MdAddCircle
                            className="information-icon"
                            color="#9DA8B6"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>

              <Col>
                {/* <div className='client-profile-section'> */}

                <InvoicesCard
                  clients={clientInvoices}
                  loading={getInvoiceLoading}
                />

                {/* </div> */}
              </Col>

              {/* </div> */}
            </Col>
            <Col sm="12" md="4">
              <Card className="content-calendar" style={{ height: "100%" }}>
                <Row>
                  <Col sm="12" className="first-section">
                    <h2 className="">Scheduled Post</h2>
                    <div
                      className="p-3"
                      style={{
                        border: "1px solid #E5ECF2",
                        borderRadius: "24px",
                      }}
                    >
                      <Calendar client_id={currentClientDetails?.client_id} />
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h2 className="mb-0">Connected accounts</h2>
                </div>
                <SocialConnectionSection
                  isClient={true}
                  clientUniqueCode={currentClientDetails?.client_id}
                  clientId={currentClientDetails?.id}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <UpdateDetailsModal
        open={updateModal}
        setState={setUpdateModal}
        type={modalType}
        clientDetails={currentClientDetails}
      />
    </div>
  );
};

const mapStateToProps = ({ client }) => {
  const { clientInvoices, clients } = client;
  return {
    clientInvoices,
    getInvoiceLoading: client?.loading?.getClientInvoices,
    clients,
  };
};
export default connect(mapStateToProps, {
  getClient,
  getClientInvoices,
})(ClientDetails);
