import React from "react";

export const ShowIcon = ({ color, bg, Icons }) => {
  return (
    <div className="icon" style={{ background: bg, color: color }}>
      <Icons fontSize={8} />
    </div>
  );
};

export const ShowIconComment = ({ text, Icons, gap, type }) => {
  return (
    <div className="text_comment" style={{ gap: gap }}>
      {type ? Icons : <Icons fontSize={18} />}
      <span>{text}</span>
    </div>
  );
};

const FinetuneIconFb = () => {
  return (
    <div className="finetuneWrapper">
      <div className="comment">
        <ShowIconComment
          Icons={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 17 17"
              height="14px"
              width="14px"
            >
              <path
                d="M16.03 10.424c0-.827-.275-1.062-.572-1.296.164-.276.327-.664.327-1.164 0-1.021-.796-1.665-2.072-1.665h-3.431c.092-.54.204-1.593.204-2.113 0-1.083-.255-2.42-1.246-2.951C8.944 1.082 8.7 1 8.332 1c-.572 0-.95.235-1.083.378a.423.423 0 0 0-.122.337l.081 2.236a.922.922 0 0 1-.102.5L5.381 7.892a.266.266 0 0 1-.123.112h-.01l-.01.01-.256.133a.645.645 0 0 0-.336.562v6.126c0 .194.081.368.224.49.072.062 1.042.97 1.991.97h5.565c.868 0 1.45-.296 1.756-.867.286-.531.235-1.032.123-1.41.48-.153.919-.51 1.051-1.164a1.566 1.566 0 0 0-.153-1.051c.43-.215.827-.552.827-1.379Z"
                stroke="#616770"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M3.88 6.993H1.266A.765.765 0 0 0 .5 7.76v8.475c0 .418.337.765.766.765H3.88a.765.765 0 0 0 .766-.765V7.759a.778.778 0 0 0-.766-.766Z"
                stroke="#616770"
                stroke-miterlimit="10"
              ></path>
            </svg>
          }
          type="svg"
          text={"Like"}
          gap="0.6rem"
          color="#616770"
        />
        <ShowIconComment
          Icons={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 17 16"
              height="14px"
              width="14px"
            >
              <path
                d="M5.03 16a.578.578 0 0 1-.568-.568v-2.796A4.052 4.052 0 0 1 .94 8.629V4.044A4.053 4.053 0 0 1 4.984 0h7.39a4.053 4.053 0 0 1 4.044 4.044V8.62a4.053 4.053 0 0 1-4.044 4.044h-3.01l-3.98 3.206a.492.492 0 0 1-.353.13ZM4.995 1.062a2.995 2.995 0 0 0-2.992 2.992v4.575a2.995 2.995 0 0 0 2.992 2.991.53.53 0 0 1 .53.531v2.274l3.309-2.665a.641.641 0 0 1 .382-.14h3.159a2.995 2.995 0 0 0 2.991-2.991V4.044a2.995 2.995 0 0 0-2.991-2.991h-7.38v.01Z"
                fill="#616770"
              ></path>
            </svg>
          }
          text={"Comment"}
          type="svg"
          gap="0.6rem"
          color="#616770"
        />
        <ShowIconComment
          Icons={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 16"
              height="14px"
              width="14px"
            >
              <path
                d="M1.352 16a.672.672 0 0 1-.672-.69c.106-4.137 1.238-7.232 3.36-9.177a8.003 8.003 0 0 1 4.81-2.086V.714c0-.283.176-.54.441-.654a.715.715 0 0 1 .787.141L16.85 7.16a.68.68 0 0 1 .203.495c0 .195-.08.371-.212.504l-6.763 6.666a.734.734 0 0 1-.787.15.72.72 0 0 1-.442-.654v-3.174c-2.184.027-4.447.336-6.914 4.51a.683.683 0 0 1-.583.344ZM9.265 5.09c-.451 0-2.653.097-4.527 1.83-1.636 1.512-2.617 3.846-2.909 6.94 2.458-3.474 4.8-3.775 7.55-3.775.292 0 .531.24.531.53v2.883l5.932-5.853L9.91 1.554V4.55c0 .15-.062.283-.168.389a.54.54 0 0 1-.398.141c0 .018-.026.01-.08.01Zm6.825 2.812.009.008-.01-.008Z"
                fill="#616770"
              ></path>
            </svg>
          }
          type="svg"
          text={"Share"}
          gap="0.6rem"
          color="#616770"
        />
      </div>
    </div>
  );
};

export default FinetuneIconFb;
