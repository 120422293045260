import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDFetg3q3MCr0lfzODQfmJ4ydEKKblTldQ",
  authDomain: "ontriv123.firebaseapp.com",
  projectId: "ontriv123",
  storageBucket: "ontriv123.appspot.com",
  messagingSenderId: "517622118377",
  appId: "1:517622118377:web:ab299fccc9e6f23645bd78"
};

const fireBaseApp = initializeApp(firebaseConfig);

const fireBaseStorage = getStorage(fireBaseApp)

export default fireBaseStorage