import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Card, Col, Button, Input } from "reactstrap";
import Steps, { Step } from "rc-steps";
import { ThreeDots } from "react-loader-spinner";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import { TiEye } from "react-icons/ti";
import { IoMdEyeOff } from "react-icons/io";
import { connect } from "react-redux";
import { BiArrowBack } from "react-icons/bi";
import logo from "../../assets/img/logo.png";
import greenMark from "../../assets/img/green-icon.svg";
import stripeSafelock from "../../assets/img/stripe-safelock-badge.svg";
import { useFormik } from "formik";
import { registerUser } from "../../redux/actions";
// import selectIcon from "../../assets/img/select.png";
import countryList from "react-select-country-list";

//utils
import { plans } from "../../utils/Plans";
import { patterns } from "../../validate";
import SelectInput from "../../components/Select/SelectInput";
import { toast } from "react-toastify";

const Signup = ({ registerUser, error, message, loading }) => {
  const [activeState, setActiveState] = useState(0);
  const [inputType1, setInputType1] = useState("password");
  const [inputType2, setInputType2] = useState("password");
  const [durationState, updateDurationState] = useState(false);

  const options = useMemo(() => countryList().getData(), []);
  const roleOptions = [
    {
      label: "Founder/CEO",
      value: "Founder/CEO",
    },
    {
      label: "Marketing Manager",
      value: "Marketing Manager",
    },
    {
      label: "Account Manager",
      value: "Account Manager",
    },
    {
      label: "Directors of operations",
      value: "Directors of operations",
    },
  ];

  const informationOptions = [
    {
      label: "Promotion",
      value: "PROMOTION",
    },
    {
      label: "Referral",
      value: "REFERRAL",
    },
    {
      label: "Social media",
      value: "SOCIAL",
    },
    {
      label: "Other",
      value: "OTHER",
    },
  ];

  //functions
  const toggleDurationState = () => {
    updateDurationState(!durationState);
  };

  const validate = (values) => {
    const errors = {};
    if (activeState === 0) {
      if (!values.fullname) {
        errors.fullname = "Fullname is required";
      }
      if (values.email) {
        if (!patterns.email.test(values.email)) {
          errors.email = "Email is invalid";
        }
      } else {
        errors.email = "Email is required";
      }
      if (values.password) {
        if (values.password.length < 8) {
          errors.password = "Minimum Password length is 8 characters ";
        }
      }
      if (!values.password) {
        errors.password = "Password is required";
      }
      if (values.confirmPassword) {
        if (values.confirmPassword !== values.password) {
          errors.confirmPassword = "Password does not match";
        }
      } else {
        errors.confirmPassword = "Please confirm your password";
      }
      if (!values.country) {
        errors.country = "Country is required";
      }
    } else if (activeState === 1) {
      if (!values.business_name) {
        errors.business_name = "Business Name is required";
      }
      if (!values.website) {
        errors.website = "Website url cannot be empty";
      }
      // else if (values.website) {
      //   if (!patterns?.url?.test(values?.website)) {
      //     errors.website = "Website url is invalid";
      //   }
      // }
      if (!values.role) {
        errors.role = "Role is required";
      }
      // if (!values.referral_code) {
      //   errors.referral_code = "Referral Code is required";
      // }
      if (!values.information) {
        errors.information = "This is importannt to us";
      }
      if (values.agree === "") {
        errors.agree = "Check to agree to terms and condition";
      }
    } else if (activeState === 2) {
      if (!values.slug) {
        errors.slug = "Please select a plan to proceed";
      }
    } else if (activeState === 3) {
      if (!values.number) {
        errors.number = "Card Number is required";
      }
      if (!values.expiration_date) {
        errors.expiration_date = "Expiration Date is required";
      }
      if (!values.cvc) {
        errors.cvc = "Security Code is required";
      }
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      country: "",
      password: "",
      confirmPassword: "",

      business_name: "",
      website: "",
      role: "",
      referral_code: "",
      information: "",
      agree: "",

      slug: "",

      expiration_date: "",
      cvc: "",
      number: "",
    },
    validate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const exp_month = values?.expiration_date?.split("-")?.[1];
      const exp_year = values?.expiration_date?.split("-")?.[0];
      const payload = {
        fullname: values?.fullname,
        email: values?.email,
        country: values?.country?.value,
        password: values?.password,
        buz: [
          {
            business_name: values?.business_name,
            website: values?.website,
            role: values?.role?.value,
            referral_code: values?.referral_code,
            information: values?.information?.value,
          },
        ],
        payment_method: {
          number: values?.number,
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: values?.cvc,
          slug: values?.slug?.toLowerCase(),
          is_annual: durationState,
        },
      };
      if (activeState === 3) {
        registerUser(payload);
      } else {
        setActiveState(activeState + 1);
      }
    },
  });
  useEffect(() => {
    if (!loading && error?.length) {
      toast.error(error);
    }
    if (!loading && message?.length) {
      toast.info(message);
    }
  }, [error, message, loading]);

  return (
    <div className="auth">
      <Row className="gx-0">
        <Col lg="3" sm="12" className="pr-0">
          <div className="signup-left">
            <div className="signup-text text-white  mx-auto">
              <Container>
                <h2 className=" mt-5 mb-3">Sign up to Ontriv</h2>
                <p className="h4">
                  The ultimate tool to run your social media agency
                </p>
              </Container>
            </div>
          </div>
        </Col>
        <Col lg="9" sm="12" className="pl-0">
          <div className="signup-right">
            <div className="signup-form-wrapper mx-auto">
              {/* <div className=' ex'> */}
              <div className="signin-logo text-center my-2 mb-4">
                <img src={logo} alt="ontriv-logo" width="200px" />
              </div>
              <Row className="gx-0 px-md-6 ">
                <Col sm="12" md="4">
                  <div
                    className="d-md-block d-none my-2 mb-4"
                    style={{
                      marginLeft: "-30px",
                    }}
                  >
                    <img src={logo} alt="ontriv-logo" width="200px" />
                  </div>
                  <Card className="card-1">
                    <Steps direction="vertical" current={activeState}>
                      <Step
                        title="Personal Details"
                        onClick={() => setActiveState(0)}
                      />
                      <Step
                        title="Business Details"
                        onClick={() => setActiveState(1)}
                      />
                      <Step
                        title="Select a plan"
                        onClick={() => setActiveState(2)}
                      />
                    </Steps>
                  </Card>
                </Col>
                <Col sm="12" md="8">
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Card className="signup-form-card">
                      {activeState ? (
                        <div
                          className="cta"
                          onClick={() => setActiveState(activeState - 1)}
                          style={{ cursor: "pointer" }}
                        >
                          <BiArrowBack size="24px" />
                        </div>
                      ) : null}
                      {activeState === 0 ? (
                        <div className="mx-auto px-sm-2 px-lg-5 pt-sm-2 pt-lg-5">
                          {formik?.errors?.fullname &&
                          formik?.touched?.fullname ? (
                            <span className="text-danger text-left field-error">
                              {formik?.errors?.fullname}
                            </span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="Full name"
                            name="fullname"
                            autoComplete="new-password"
                            id="fullname"
                            className={`py-3  w-100 ${
                              formik?.errors?.fullname ? "border-danger" : ""
                            }`}
                            value={formik?.values?.fullname}
                            onChange={formik.handleChange}
                          />
                          {formik?.errors?.email && formik?.touched?.email ? (
                            <span className="text-danger text-left field-error">
                              {formik?.errors?.email}
                            </span>
                          ) : null}
                          <input
                            type="email"
                            name="email"
                            autoComplete="new-password"
                            id="email"
                            placeholder="Email address"
                            className={`w-100 py-3 ${
                              formik?.errors?.country &&
                              formik?.touched?.country
                                ? ""
                                : "mb-0"
                            } ${formik?.errors?.email ? "border-danger" : ""}`}
                            onChange={formik.handleChange}
                            value={formik?.values?.email}
                          />

                          {formik?.errors?.country &&
                          formik?.touched?.country ? (
                            <span className="text-danger text-left field-error">
                              Select Country
                            </span>
                          ) : null}
                          {/* <div className="position-relative"> */}
                          <SelectInput
                            showSearch={true}
                            options={options}
                            onChange={(value) =>
                              formik.setFieldValue("country", value)
                            }
                            value={formik?.values?.country || undefined}
                            name="country"
                            placeholder="Select country"
                            className={`w-100 ${
                              formik?.errors?.country &&
                              formik?.touched?.country
                                ? "pb-3"
                                : "py-3"
                            }  ${
                              formik?.errors.country ? "border-danger" : ""
                            } `}
                          />
                          {/* </div> */}
                          {formik?.errors.password &&
                          formik?.touched?.password ? (
                            <span className="text-danger text-left field-error">
                              {formik?.errors?.password}
                            </span>
                          ) : null}
                          <div className="password-container">
                            <div className="password-icon">
                              {inputType1 === "password" ? (
                                <TiEye
                                  color="#E5E9F2"
                                  size="30px"
                                  onClick={() => {
                                    setInputType1("text");
                                  }}
                                />
                              ) : (
                                <IoMdEyeOff
                                  color="#000"
                                  size="25px"
                                  onClick={() => {
                                    setInputType1("password");
                                  }}
                                />
                              )}
                            </div>
                            <input
                              type={inputType1}
                              placeholder="Create password"
                              name="password"
                              autoComplete="new-password"
                              value={formik?.values?.password}
                              onChange={formik.handleChange}
                              className={`py-3 mb- w-100 ${
                                formik?.errors?.password ? "border-danger" : ""
                              }`}
                            />
                          </div>

                          {formik?.errors?.confirmPassword &&
                          formik?.touched?.confirmPassword ? (
                            <span className="text-danger text-left field-error">
                              {formik?.errors?.confirmPassword}
                            </span>
                          ) : null}
                          <div className="password-container">
                            <div className="password-icon">
                              {inputType2 === "password" ? (
                                <TiEye
                                  color="#E5E9F2"
                                  size="30px"
                                  onClick={() => {
                                    setInputType2("text");
                                  }}
                                />
                              ) : (
                                <IoMdEyeOff
                                  color="#000"
                                  size="25px"
                                  onClick={() => {
                                    setInputType2("password");
                                  }}
                                />
                              )}
                            </div>
                            <input
                              type={inputType2}
                              name="confirmPassword"
                              placeholder="Confirm password"
                              autoComplete="new-password"
                              className={`py-3  w-100 ${
                                formik?.errors?.confirmPassword
                                  ? "border-danger"
                                  : ""
                              }`}
                              value={formik?.values?.confirmPassword}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                      ) : activeState === 1 ? (
                        <div className="business-form px-sm-2 px-lg-5 pt-sm-2 pt-lg-3">
                          {formik?.errors?.business_name &&
                          formik?.touched?.business_name ? (
                            <span className="text-danger text-left field-error">
                              {formik?.errors?.business_name}
                            </span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="Business name"
                            name="business_name"
                            autoComplete="new_password"
                            id="business_name"
                            className={`py-3  w-100 ${
                              formik?.errors?.business_name
                                ? "border-danger"
                                : ""
                            }`}
                            onChange={formik.handleChange}
                            value={formik?.values?.business_name}
                          />

                          {formik?.errors?.website &&
                          formik?.touched?.website ? (
                            <span className="text-danger text-left field-error">
                              {formik?.errors?.website}
                            </span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="Business website"
                            name="website"
                            autocomplete="new_password"
                            id="website"
                            className={`py-3  w-100 ${
                              formik?.errors?.website ? "border-danger" : ""
                            }`}
                            value={formik?.values?.website}
                            onChange={formik.handleChange}
                          />

                          {formik?.errors?.role && formik?.touched?.role ? (
                            <span className="text-danger text-left field-error">
                              {formik?.errors?.role}
                            </span>
                          ) : null}
                          <SelectInput
                            options={roleOptions}
                            showSearch={true}
                            placeholder="Role"
                            name="role"
                            value={formik?.values?.role || undefined}
                            onChange={(value) =>
                              formik.setFieldValue("role", value)
                            }
                            className={`w-100 ${
                              formik?.errors?.role && formik?.touched?.role
                                ? "mb-3"
                                : "mb-3"
                            }  ${
                              formik?.errors.country ? "border-danger" : ""
                            } `}
                          />

                          {formik?.errors?.referral_code &&
                          formik?.touched?.referral_code ? (
                            <span className="text-danger text-left field-error">
                              {formik?.errors?.referral_code}
                            </span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="Referral Code"
                            name="referral_code"
                            autoComplete="new_password"
                            className={`py-3 w-100 ${
                              formik?.errors?.referral_code
                                ? "border-danger"
                                : ""
                            }`}
                            onChange={formik.handleChange}
                            value={formik?.values?.referral_code}
                          />

                          {formik?.errors?.information &&
                          formik?.touched?.information ? (
                            <span className="text-danger text-left field-error">
                              Select an option
                            </span>
                          ) : null}
                          <SelectInput
                            showSearch={true}
                            options={informationOptions}
                            name="information"
                            value={formik?.values?.information || undefined}
                            onChange={(value) =>
                              formik.setFieldValue("information", value)
                            }
                            placeholder="How do you hear about us?"
                            className={`mb-3 w-100 ${
                              formik?.errors?.information ? "border-danger" : ""
                            } `}
                          />
                          <div className="d-flex align-items-center mb-3 ">
                            <div className="input-check">
                              <input
                                type="checkbox"
                                id="agree"
                                name="agree"
                                value={formik?.values?.agree}
                                onChange={formik.handleChange}
                                className="border-dark"
                                style={{
                                  marginRight: "10px",
                                }}
                              />
                            </div>
                            <div className="ml-3 ">
                              <p className="mb-0" style={{ fontSize: "12px" }}>
                                I agree to Ontriv’s
                                <span className="register-link">
                                  <a
                                    href="https://ontriv.com/terms-of-service/"
                                    style={{
                                      textDecoration: "none",
                                      fontWeight: 500,
                                      color: "#2062f4",
                                    }}
                                  >
                                    {" "}
                                    Terms of use
                                  </a>
                                </span>
                                {` and `}
                                <span className="register-link">
                                  <a
                                    href="https://ontriv.com/privacy-policy/"
                                    style={{
                                      textDecoration: "none",
                                      fontWeight: 500,
                                      color: "#2062f4",
                                    }}
                                  >
                                    {" "}
                                    Privacy Policy
                                  </a>
                                </span>
                              </p>
                            </div>
                          </div>
                          {formik?.errors?.agree && formik?.touched?.agree ? (
                            <span className="text-danger text-left field-error">
                              {formik?.errors?.agree}
                            </span>
                          ) : null}
                        </div>
                      ) : activeState === 2 ? (
                        <div className="px-sm-2 px-lg-5 pt-sm-1 pt-lg-3">
                          <p
                            style={{
                              fontWeight: 600,
                              fontSize: "20px",
                              lineHeight: "28px",
                              letterSpacing: "0.35px",
                              color: "#111317",
                              opacity: 0.8,
                              textAlign: "center",
                              margin: 0,
                            }}
                          >
                            Select a trial plan
                          </p>
                          <div className="d-flex justify-content-center align-items-center my-4">
                            <label class="switch">
                              <input
                                type="checkbox"
                                onChange={toggleDurationState}
                                value={durationState}
                              />
                              <span class="slider round"></span>
                            </label>
                            <span
                              style={{
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#032041",
                                paddingLeft: "1rem",
                              }}
                            >
                              <strong>Annual</strong> (Get 2 months Free)
                            </span>
                          </div>
                          {formik?.errors?.slug && formik?.touched?.slug ? (
                            <span className="text-danger text-left field-error">
                              {formik?.errors?.slug}
                            </span>
                          ) : null}
                          {plans?.map((plan) => {
                            return (
                              <div
                                className={`plan my-3 ${
                                  plan.name === formik?.values?.slug
                                    ? "plan__active"
                                    : ""
                                }`}
                                onClick={() =>
                                  formik.setFieldValue("slug", plan.name)
                                }
                                key={plan.name}
                              >
                                <div className="d-flex justify-content-between w-100">
                                  <div>
                                    <p className="plan__name">{plan.name}</p>
                                    <p className="plan__description">
                                      {plan.description}
                                    </p>
                                  </div>
                                  <div>
                                    <span className="plan__price-amount">
                                      $
                                      {durationState
                                        ? plan.price * 10
                                        : plan.price}
                                    </span>
                                    <span
                                      className={`plan__price-duration ${
                                        plan.name === formik?.values?.slug
                                          ? "plan__price-duration_active"
                                          : ""
                                      }`}
                                    >
                                      /{durationState ? "Year" : "Month"}
                                    </span>
                                  </div>
                                </div>
                                <p className="plan__features w-100 mt-3">
                                  {plan.features
                                    .join(",")
                                    ?.replaceAll(",", ", ")}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="business-form px-sm-2 px-lg-5 pt-4">
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: "20px",
                              lineHeight: "28px",
                              letterSpacing: "0.35px",
                              color: "#111317",
                              opacity: 0.8,
                            }}
                          >
                            You're almost done!
                          </span>
                          <Card className="my-3">
                            <main className="payment-intiation-card w-100">
                              <div className="d-flex align-items-center justify-content-between header px-4 pt-2">
                                <h1>Your Plan</h1>
                                <div className="d-flex justify-content-center align-items-center">
                                  <span>{formik?.values?.slug}</span>
                                  <img
                                    src={greenMark}
                                    style={{ height: "60px" }}
                                    // className="my-auto"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="px-4 pt-4">
                                <Row>
                                  {formik?.errors?.number &&
                                  formik?.touched?.number ? (
                                    <span className="text-danger text-left field-error">
                                      {formik?.errors?.number}
                                    </span>
                                  ) : null}
                                  <Col sm="12" md="12" lg="12" className="my-1">
                                    <label className="text-left w-100">
                                      Card Number
                                    </label>
                                    <Input
                                      type="text"
                                      name="number"
                                      onChange={formik.handleChange}
                                      value={formik?.values?.number}
                                      placeholder="0000 0000 0000 0000"
                                      className="off-canvas-menu__input py-3 px-3"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="6" md="6" lg="6" className="my-1">
                                    <div>
                                      {formik?.errors?.expiration_date &&
                                      formik?.touched?.expiration_date ? (
                                        <span className="text-danger text-left field-error">
                                          {formik?.errors?.expiration_date}
                                        </span>
                                      ) : null}
                                    </div>
                                    <label className="text-left w-100">
                                      Expiration Date
                                    </label>
                                    <Input
                                      type="month"
                                      name="expiration_date"
                                      onChange={formik.handleChange}
                                      value={formik?.values?.expiration_date}
                                      placeholder="MM/YYY"
                                      className="off-canvas-menu__input py-3"
                                    />
                                  </Col>
                                  <Col sm="6" md="6" lg="6" className="my-1">
                                    <div>
                                      {formik?.errors?.cvc &&
                                      formik?.touched?.cvc ? (
                                        <span className="text-danger text-left field-error">
                                          {formik?.errors?.cvc}
                                        </span>
                                      ) : null}
                                    </div>
                                    <label className="text-left w-100">
                                      Security code
                                    </label>
                                    <Input
                                      type="text"
                                      name="cvc"
                                      onChange={formik.handleChange}
                                      value={formik?.values?.cvc}
                                      placeholder="***"
                                      className="off-canvas-menu__input py-3"
                                    />
                                  </Col>
                                </Row>
                                <div>
                                  <img
                                    src={stripeSafelock}
                                    width="100%"
                                    height="85px"
                                    alt="stripe Safe lock badge"
                                  />
                                </div>
                              </div>
                            </main>
                          </Card>
                          <p
                            className="text-center px-3"
                            style={{
                              fontWeight: 500,
                              fontSize: "12px",
                              lineHeight: "22px",
                            }}
                          >
                            You will not be charged until after your free trial
                            has ended and you can cancel at any time.
                          </p>
                          <div className="pt-2 pb-3">
                            <Button className="w-100" type="submit">
                              {loading ? (
                                <div className="text-center w-100 align-items-center">
                                  <ThreeDots
                                    color="white"
                                    height={"12px"}
                                    wrapperStyle={{ display: "block" }}
                                  />
                                </div>
                              ) : (
                                "Sign Up"
                              )}
                            </Button>
                          </div>
                        </div>
                      )}
                      {activeState <= 2 ? (
                        <div className="pt-3 pb-sm-3 pb-lg-4 d-flex justify-content-between align-items-center mx-auto wizard-control-buttons">
                          <div className="mb-0">
                            <button
                              type="button"
                              className={`${
                                activeState >= 0 ? "btn-primary text-white" : ""
                              }`}
                              onClick={() => {
                                setActiveState(activeState - 1);
                              }}
                              disabled={activeState === 0}
                            >
                              Prev
                            </button>
                          </div>
                          <div className="mb-0">
                            <button
                              type="submit"
                              className={`btn-${
                                activeState <= 2 ? "primary text-white" : ""
                              }`}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </Card>
                  </form>

                  {activeState < 2 ? (
                    <div className="no-account mt-4 py-3 text-center">
                      <p className="mb-0">
                        Already have an account yet?
                        <span className="register-link">
                          <a href="/auth/login"> Sign In</a>
                        </span>
                      </p>
                    </div>
                  ) : null}
                </Col>
              </Row>

              {/* </div> */}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { error, message, loading } = auth;
  return {
    error: error?.register,
    message: message?.register,
    loading: loading?.register,
  };
};
export default connect(mapStateToProps, { registerUser })(Signup);
