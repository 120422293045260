import { put, takeEvery, fork, all } from "redux-saga/effects";
import {
  GET_BUSINESS_SOCIALS_CONNECTION_STATUS,
  ADD_NEW_NOTIFICATION,
  GET_LINKEDIN_CALLBACK_URL,
  GET_FACEBOOK_CALLBACK_URL,
  SEND_LINKEDIN_CODE_STATE,
  SEND_FACEBOOK_CODE_STATE,
  GET_CONNECTED_INSTAGRAM_ACCOUNT,
  CONNECT_INSTAGRAM_ACCOUNT,
  GET_TWITTER_CALLBACK_URL,
  SEND_TWITTER_CODE_STATE,
  GET_TWITTER_V1_CALLBACK_URL,
  SEND_TWITTER_V1_CODE_STATE,
  CONNECT_SELECTED_FACEBOOK_PAGE,
  getBusinessSocialsConnectionStatus,
  getBusinessSocialsConnectionStatusSuccess,
  getBusinessSocialsConnectionStatusError,
  // addNewNotificationSuccess,
  // addNewNotificationError,
  connectSelectedFacebookPageSuccess,
  connectSelectedFacebookPageError,
  getLinkedInCallbackURLSuccess,
  getLinkedInCallbackURLError,
  getFacebookCallbackURLSuccess,
  getFacebookCallbackURLError,
  sendLinkedInCodeAndStateSuccess,
  sendLinkedInCodeAndStateError,
  sendFacebookCodeAndStateSuccess,
  sendFacebookCodeAndStateError,
  getConnectedInstagramAccountSuccess,
  getConnectedInstagramAccountError,
  connectInstagramAccountSuccess,
  connectInstagramAccountError,
  getTwitterCallbackURLSuccess,
  getTwitterCallbackURLError,
  sendTwitterCodeAndStateSuccess,
  sendTwitterCodeAndStateError,
  getTwitterV1CallbackURLSuccess,
  getTwitterV1CallbackURLError,
  sendTwitterV1CodeAndStateSuccess,
  sendTwitterV1CodeAndStateError,
  clearMessages,
  connectLinkedInPageSuccess,
  connectLinkedInPageError,
  CONNECT_LINKEDIN_PAGE,
} from "../actions";
import Axios from "../../utils/Axios";

export const websockets = {};
// web socket connection
export function* GetNewNotifications({ payload }) {
  try {
    const socket = new WebSocket(
      `wss://ontriv.spinxe.com/ws/socket-server/${parseInt(payload)}/`
    );
    // save it to a state so it can be accessed anywhere in the file.
    websockets.notifications = socket;
    let notification;
    socket.addEventListener("message", (res) => {
      const data = JSON.parse(res?.data);
      notification = data;
    });
    if (notification?.data?.length) {
      yield put(getBusinessSocialsConnectionStatus(notification?.user));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* GetBusinessSocialsConnectionStatus({ payload }) {
  try {
    const response = yield Axios.get(
      `/client/api/v1/user/${payload}/social/accounts/`
    );
    if (response?.status === 200) {
      yield put(getBusinessSocialsConnectionStatusSuccess(response?.data));
      // if (!websockets.notifications) {
      //   yield put(getBusinessSocialsConnectionStatusSuccess(response?.data));
      // }
    } else {
      yield put(
        getBusinessSocialsConnectionStatusError(response?.data?.message)
      );
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getBusinessSocialsConnectionStatusError(message));
    yield put(clearMessages());
  }
}

export function* GetLinkedInCallbackURL() {
  try {
    const response = yield Axios.get(`/linkedin/api/v1/auth/`);
    if (response?.status === 200) {
      yield put(getLinkedInCallbackURLSuccess(response?.data));
    } else {
      yield put(getLinkedInCallbackURLError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getLinkedInCallbackURLError(message));
    yield put(clearMessages());
  }
}
export function* SendLinkedInCodeState({ payload }) {
  console.log("linkedin", payload);
  try {
    const response = yield Axios.get(
      `/linkedin/api/v1/auth/linkedin/callback/1/${payload?.id}/?code=${payload?.payload?.code}&state=${payload?.payload?.state}`
    );
    if (response?.status === 200) {
      yield put(
        sendLinkedInCodeAndStateSuccess({
          message: response?.data?.message,
          data: response?.data,
        })
      );
    } else {
      yield put(sendLinkedInCodeAndStateError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data?.error;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(sendLinkedInCodeAndStateError(message));
    yield put(clearMessages());
  }
}
export function* ConnectLinkedInPage({ payload }) {
  try {
    const response = yield Axios.post(
      `/linkedin/api/v1/auth/linkedin/callback/1/${payload?.id}/?code=${payload?.credentials?.code}&state=${payload?.credentials?.state}`,
      payload?.data
    );
    if (response?.status === 200) {
      yield put(connectLinkedInPageSuccess(response?.data?.message));
    } else {
      yield put(connectLinkedInPageError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data?.error;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(connectLinkedInPageError(message));
    yield put(clearMessages());
  }
}
export function* GetFacebookCallbackURL() {
  try {
    const response = yield Axios.get(`/instagram/api/v1/auth/`);
    if (response?.status === 200) {
      yield put(getFacebookCallbackURLSuccess(response?.data));
    } else {
      yield put(getFacebookCallbackURLError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getFacebookCallbackURLError(message));
    yield put(clearMessages());
  }
}

export function* SendFacebookCodeState({ payload }) {
  try {
    const response = yield Axios.get(
      `/instagram/api/v1/auth/facebook/callback/${payload?.id}/?code=${payload?.credentials?.code}&state=${payload?.credentials?.state}`
    );
    if (response?.status === 200) {
      yield put(
        sendFacebookCodeAndStateSuccess({
          message: response?.data?.message,
          data: {
            page: response?.data?.pages,
            user_access_token: response?.data?.user_access_token,
          },
        })
      );
    } else {
      yield put(sendFacebookCodeAndStateError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data?.error;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(sendFacebookCodeAndStateError(message));
    yield put(clearMessages());
  }
}
export function* ConnectSelectedFacebookPage({ payload }) {
  try {
    const response = yield Axios.post(
      `/instagram/api/v1/auth/facebook/callback/${payload?.id}/?code=${payload?.credentials?.code}&state=${payload?.credentials?.state}`,
      payload?.data
    );
    if (response?.status === 200) {
      yield put(
        connectSelectedFacebookPageSuccess({
          message: response?.data?.message,
        })
      );
    } else {
      yield put(connectSelectedFacebookPageError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data?.error;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(connectSelectedFacebookPageError(message));
    yield put(clearMessages());
  }
}
export function* GetConnectedInstagramAccount({ payload }) {
  try {
    const response = yield Axios.get(
      `/instagram/api/v1/auth/instagram/login/${payload}/`
    );
    if (response?.status === 200) {
      yield put(getConnectedInstagramAccountSuccess(response?.data));
    } else {
      yield put(getConnectedInstagramAccountError(response?.data));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getConnectedInstagramAccountError(message));
    yield put(clearMessages());
  }
}

export function* ConnectInstagramAccount({ payload }) {
  try {
    const response = yield Axios.post(
      `/instagram/api/v1/auth/instagram/login/${payload?.id}/`,
      {
        instagram_id: payload?.instagram_id,
        username: payload?.username,
        profile_image: payload?.profile_image,
      }
    );
    if (response?.status === 200) {
      yield put(connectInstagramAccountSuccess(response?.data?.message));
    } else {
      yield put(connectInstagramAccountError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.error;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(connectInstagramAccountError(message));
    yield put(clearMessages());
  }
}

export function* GetTwitterCallbackURL() {
  try {
    const response = yield Axios.get(`/twitter/api/v1/auth/v2`);
    if (response?.status === 200) {
      yield put(getTwitterCallbackURLSuccess(response?.data));
    } else {
      yield put(getTwitterCallbackURLError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getTwitterCallbackURLError(message));
    yield put(clearMessages());
  }
}

export function* GetTwitterV1CallbackURL() {
  try {
    const response = yield Axios.get(`/twitter/api/v1/auth/v1/`);
    if (response?.status === 200) {
      yield put(getTwitterV1CallbackURLSuccess(response?.data));
    } else {
      yield put(getTwitterV1CallbackURLError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getTwitterV1CallbackURLError(message));
    yield put(clearMessages());
  }
}

export function* SendTwitterCodeState({ payload }) {
  try {
    const response = yield Axios.get(
      `/twitter/api/v1/auth/twitter/callback/v2/${payload?.id}/?code=${payload?.credentials?.code}&state=${payload?.credentials?.state}`
    );
    if (response?.status === 200) {
      yield put(sendTwitterCodeAndStateSuccess(response?.data?.message));
    } else {
      yield put(sendTwitterCodeAndStateError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data?.error;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(sendTwitterCodeAndStateError(message));
    yield put(clearMessages());
  }
}

export function* SendTwitterV1CodeState({ payload }) {
  try {
    const response = yield Axios.get(
      `/twitter/api/v1/auth/twitter/callback/v1/${payload?.id}/?oauth_token=${payload?.credentials?.oauth_token}&oauth_verifier=${payload?.credentials?.oauth_verifier}`
    );
    if (response?.status === 200) {
      yield put(sendTwitterV1CodeAndStateSuccess(response?.data?.message));
    } else {
      yield put(sendTwitterV1CodeAndStateError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.error;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(sendTwitterV1CodeAndStateError(message));
    yield put(clearMessages());
  }
}

export function* watchGetBusinessSocialsConnectionStatus() {
  yield takeEvery(
    GET_BUSINESS_SOCIALS_CONNECTION_STATUS,
    GetBusinessSocialsConnectionStatus
  );
}

export function* watchSendLinkedInCodeState() {
  yield takeEvery(SEND_LINKEDIN_CODE_STATE, SendLinkedInCodeState);
}

export function* watchAddNewNotifications() {
  yield takeEvery(ADD_NEW_NOTIFICATION, GetNewNotifications);
}
export function* watchGetLinkedInCallbackURL() {
  yield takeEvery(GET_LINKEDIN_CALLBACK_URL, GetLinkedInCallbackURL);
}
export function* watchGetFacebookCallbackURL() {
  yield takeEvery(GET_FACEBOOK_CALLBACK_URL, GetFacebookCallbackURL);
}
export function* watchSendFacebookCodeState() {
  yield takeEvery(SEND_FACEBOOK_CODE_STATE, SendFacebookCodeState);
}

export function* watchGetConnectedInstagramAccount() {
  yield takeEvery(
    GET_CONNECTED_INSTAGRAM_ACCOUNT,
    GetConnectedInstagramAccount
  );
}
export function* watchGetTwitterCallbackURL() {
  yield takeEvery(GET_TWITTER_CALLBACK_URL, GetTwitterCallbackURL);
}
export function* watchSendTwitterCodeState() {
  yield takeEvery(SEND_TWITTER_CODE_STATE, SendTwitterCodeState);
}
export function* watchGetTwitterV1CallbackURL() {
  yield takeEvery(GET_TWITTER_V1_CALLBACK_URL, GetTwitterV1CallbackURL);
}
export function* watchSendTwitterV1CodeState() {
  yield takeEvery(SEND_TWITTER_V1_CODE_STATE, SendTwitterV1CodeState);
}
export function* watchConnectInstagramAccount() {
  yield takeEvery(CONNECT_INSTAGRAM_ACCOUNT, ConnectInstagramAccount);
}
export function* watchConnectSelectedFacebookPage() {
  yield takeEvery(CONNECT_SELECTED_FACEBOOK_PAGE, ConnectSelectedFacebookPage);
}
export function* watchConnectLinkedInPage() {
  yield takeEvery(CONNECT_LINKEDIN_PAGE, ConnectLinkedInPage);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetBusinessSocialsConnectionStatus),
    fork(watchAddNewNotifications),
    fork(watchGetLinkedInCallbackURL),
    fork(watchSendLinkedInCodeState),
    fork(watchGetFacebookCallbackURL),
    fork(watchGetLinkedInCallbackURL),
    fork(watchSendFacebookCodeState),
    fork(watchGetConnectedInstagramAccount),
    fork(watchConnectInstagramAccount),
    fork(watchGetTwitterCallbackURL),
    fork(watchSendTwitterCodeState),
    fork(watchGetTwitterV1CallbackURL),
    fork(watchSendTwitterV1CodeState),
    fork(watchConnectSelectedFacebookPage),
    fork(watchConnectLinkedInPage),
  ]);
}
