import React from "react";
import Lottie from "react-lottie";
import animationData from "../.././assets/lotties/coming-soon";
const Construction = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "3rem",
        height: "60vh",
      }}
    >
      <Lottie options={defaultOptions} height={400} width={400} />
    </div>
  );
};

export default Construction;
