import React, { useCallback, useEffect, useMemo } from "react";
import { CenteredModal as Modal } from "../../../components/Modal";
import { Button, Input } from "reactstrap";
import { getClientModalDetails } from "../../../utils/helper";
import { ThreeDots } from "react-loader-spinner";

//form validation
import { useFormik } from "formik";
//redux
import { useDispatch, connect } from "react-redux";
import { updateClientDetails } from "../../../redux/actions";
import { toast } from "react-toastify";

const UpdateDetailsModal = ({
  open,
  setState,
  type,
  clientDetails,
  updateDetailsLoading,
  updateDetailsMessage,
  updateDetailsError,
}) => {
  const dispatch = useDispatch();

  const modalDetails = useMemo(() => getClientModalDetails(type), [type]);

  const handleCloseModal = useCallback(() => {
    setState(false);
  }, [setState]);

  const formValidate = (values) => {
    const errors = {};
    if (!values[modalDetails?.fieldName]) {
      errors[modalDetails?.fieldName] = `Enter your ${modalDetails?.header}`;
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      [modalDetails?.fieldName]: clientDetails?.[modalDetails?.fieldName] || "",
    },
    enableReinitialize: true,
    validate: formValidate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      console.log({ ...values, id: clientDetails?.id });
      dispatch(updateClientDetails({ ...values, id: clientDetails?.id }));
    },
  });

  useEffect(() => {
    if (!updateDetailsLoading && updateDetailsMessage?.length) {
      handleCloseModal();
      toast.success(updateDetailsMessage);
    } else if (!updateDetailsLoading && updateDetailsError?.length) {
      toast.error(updateDetailsError);
    }
  }, [
    updateDetailsLoading,
    updateDetailsMessage,
    updateDetailsError,
    handleCloseModal,
  ]);
  return (
    <Modal
      modalState={open}
      setModalState={setState}
      showClosebtn
      contentPadding
    >
      <div className="client-details-settings-modal text-center">
        <h1>{modalDetails?.header}</h1>
        <p>{modalDetails?.subHeader}</p>
        <form onSubmit={formik.handleSubmit} noValidate>
          {formik?.errors?.[modalDetails?.fieldName] &&
          formik?.touched?.[modalDetails?.fieldName] ? (
            <span className="text-danger text-left field-error">
              {formik?.errors?.[modalDetails?.fieldName]}
            </span>
          ) : null}
          {modalDetails?.fieldType === "text" ? (
            <input
              placeholder={modalDetails?.fieldPlaceholder}
              type="text"
              name={modalDetails?.fieldName}
              value={formik.values?.[modalDetails?.fieldName]}
              onChange={formik.handleChange}
            />
          ) : (
            <Input
              type="textarea"
              className="w-100 mb-5 border-radius-16"
              placeholder={modalDetails?.fieldPlaceholder || ""}
              name={modalDetails?.fieldName}
              value={formik.values?.[modalDetails?.fieldName]}
              onChange={formik.handleChange}
              rows={6}
            />
          )}
          <div>
            <Button
              className={`py-3 ${
                clientDetails?.[modalDetails?.fieldName]
                  ? "outlined"
                  : "contained"
              }`}
              type="submit"
            >
              {updateDetailsLoading ? (
                <div className="text-center w-100 align-items-center">
                  <ThreeDots
                    color="white"
                    height={"12px"}
                    wrapperStyle={{ display: "block" }}
                  />
                </div>
              ) : clientDetails?.[modalDetails?.fieldName] ? (
                "Update"
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ client }) => {
  const { loading, message, error } = client;
  return {
    updateDetailsLoading: loading?.updateClientDetails,
    updateDetailsMessage: message?.updateClientDetails,
    updateDetailsError: error?.updateClientDetails,
  };
};
export default connect(mapStateToProps, {})(UpdateDetailsModal);
