import React from "react";

const PostCard = ({ post }) => {
  return (
    <div className="scheduled-post-card py-3">
      <div class="post-media">
        <img src={post?.post_details?.media} alt="caption" />
      </div>
      <div className="post-content">
        <p>{post?.post_details?.caption}</p>
        <div className="client-details-container">
          <span>{post?.post_details?.owner_business_name}</span>
          <img
            src={post?.post_details?.owner_business_logo}
            style={{ width: "14px", height: "14px" }}
            alt={`${post?.post_details?.owner_business_name} logo`}
          />
        </div>
      </div>
    </div>
  );
};

export default PostCard;
