import React from "react";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { sideBarMenu } from "../../../../utils/sidebarMenu";
// import { ChevronRight } from "react-feather";
import { getUserType, role } from "../../../../utils/helper";

const SideMenuContent = (props) => {
  // Loop over sidebar menu
  // eslint-disable-next-line
  const location = useLocation();

  const currentLocation = location?.pathname?.split("/")?.[1];
  const userType = getUserType();

  const isActiveLink = (hoverIndex, navLink) => {
    if (currentLocation === "" && navLink === "/overview") {
      return true;
    } else if (currentLocation === "" && navLink !== "/overview") {
      return false;
    }
    return hoverIndex === navLink || navLink?.includes(currentLocation);
  };

  const menuItems = sideBarMenu.map((item, i) => {
    let renderItem = (
      <li
        className={classnames("nav-item my-3", {
          activeNav: isActiveLink(props.hoverIndex, item?.navLink),
        })}
        key={item.id}
      >
        <Link
          to={item.navLink}
          onClick={(e) => {
            e.stopPropagation();

            props.handleSidebarMouseEnter(item.id, item.navLink, "sideBarMenu");
          }}
          href=""
          className={`d-flex justify-content-start align-items-center`}
          key={item.id}
        >
          <div className="menu-text">
            <span
              className={classnames(
                `menu-icon-${i % 2 === 0 ? "even" : "odd"}`,
                {
                  activeNav: isActiveLink(props.hoverIndex, item?.navLink),
                }
              )}
            >
              {item.icon}
            </span>
            <span
              className={classnames("menu-title", {
                activeNav: isActiveLink(props.hoverIndex, item?.navLink),
              })}
            >
              {item.title}
            </span>
          </div>
        </Link>
      </li>
    );

    return item?.isAvailable?.includes(role(userType)) ? renderItem : null;
  });

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default SideMenuContent;
