import React from "react";
import { useState } from "react";
// import "react-datepicker/dist/react-datepicker.css";
import { Col, Row } from "reactstrap";
import Button from "../../../../../components/Button";
import TextArea from "../../../../../components/textArea/TextArea";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FinetuneIconIG from "./previewSetting/FinetuneIcon";
import DateTimePicker from "react-datetime-picker";
import dummyimg from "../../../../../assets/img/dummyimg.png";
import { useSelector } from "react-redux";

const FineTuneInstagram = ({
  socialchannel,
  setOpenModal,
  activeTab,
  setActiveTab,
  postDate,
  caption,
  instagramData,
  images,
  selectedAccountSocials,
}) => {
  const [instagramstartDate, setInstagramStartDate] = useState(postDate);
  const [instagramCaption, setInstagramCaption] = useState(caption);
  const [count, setCount] = useState(2200);
  const socialsPreview = useSelector(
    (state) => state?.schedule?.getSocialDp?.instagram
  );

  const onChangeTextArea = (e) => {
    const value = e.target.value;
    const valueLength = e.target.value.length;
    setInstagramCaption(value);
    setCount(2200 - valueLength);
  };

  const nextButton = (tab) => {
    if (socialchannel.length === activeTab) {
      instagramData(instagramCaption, instagramstartDate);
      setOpenModal(true);
    } else {
      if (activeTab !== tab) {
        instagramData(instagramCaption, instagramstartDate);
        setActiveTab(tab);
      }
    }
  };
  return (
    <div className="finetuneTabContent">
      <Row>
        <Col xs="12" md="7" sm="12">
          <div className="previewWrapper">
            <h2> Preview</h2>
            <div className="preview-iG-wrapper">
              <div className="preview-dp-wrapper mb-3">
                <div className="dp">
                  <img
                    src={
                      selectedAccountSocials?.instagram?.profile_image ||
                      dummyimg
                    }
                    alt="dp"
                  />
                </div>
                <div>
                  {selectedAccountSocials?.instagram?.username ? (
                    <span>{selectedAccountSocials?.instagram?.username}</span>
                  ) : null}
                </div>
              </div>
              <div>
                <Carousel showThumbs={false} infiniteLoop={true} autoPlay>
                  {images?.map((image, index) => {
                    return (
                      <div className="img-holder" key={index}>
                        {image?.type.slice(0, 5) === "video" ? (
                          <video width="100%" height="100%" controls autoplay>
                            <source
                              src={URL.createObjectURL(image)}
                              type={`video/${image?.name.slice(-3)}`}
                            />
                          </video>
                        ) : (
                          <img src={URL.createObjectURL(image)} alt="" />
                        )}
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              <FinetuneIconIG
                name={socialsPreview?.name}
                text={instagramCaption}
                date={new Date(instagramstartDate)}
              />
            </div>
          </div>
        </Col>
        <Col xs="12" md="5" sm="12">
          <div>
            <DateTimePicker
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yy"
              hourPlaceholder="hh"
              minutePlaceholder="mm"
              onChange={(value) => setInstagramStartDate(value)}
              value={instagramstartDate}
              clearIcon={null}
              calendarIcon={null}
              disableClock={true}
              minDate={new Date()}
              className="scheduleDateInput"
            />
            <br />
            <br />
            <TextArea
              maxLength="2200"
              placeholder="Caption"
              value={instagramCaption}
              onChangeTextArea={onChangeTextArea}
              count={count}
            />
            <Button
              text="Next"
              onButtonClick={() => nextButton(activeTab + 1)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FineTuneInstagram;
