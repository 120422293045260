import React, { useEffect } from "react";
import { Container, Card, Form, Button } from "reactstrap";
import logo from "../../assets/img/logo.png";
import { ThreeDots } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { sendVerificationEmail } from "../../redux/actions";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const VerifyEmail = ({ loading, sendVerificationEmail, error, message }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const handlePasswordReset = (values) => {
    // const token = localStorage.getItem('ontrivUserToken')
    // const uid = JSON.parse(localStorage.getItem('ontrivCurrentUser')).pk
    sendVerificationEmail(values);
  };

  useEffect(() => {
    if (!loading && error?.length) {
      toast.error(error);
    }
    if (!loading && message?.length) {
      toast.success("Verification email has been sent to your email");
    }
  }, [loading, message, error]);
  return (
    <div className="auth">
      <Link to="/auth/login" className="auth-logo">
        <img src={logo} alt="ontriv-logo" width="150px" />
      </Link>
      <div className="reset-password">
        <Container>
          <div className="mx-auto reset-password-wrapper">
            <div className="text-center reset-password-text">
              <h3 className="mb-0">Verify your account</h3>
              <div className="mt-4 mb-5">
                <p
                  className="mb-0"
                  style={{
                    fontSize: "14px",
                    color: "#111317",
                    opacity: "0.8",
                  }}
                >
                  Please enter your email to receive a <br /> verification email
                </p>
              </div>
            </div>
            <Card className="signin-form-card ">
              <Form onSubmit={handleSubmit(handlePasswordReset)}>
                {errors.email && (
                  <span className="text-danger text-left">
                    Enter a valid email address
                  </span>
                )}
                <div className="password-container">
                  <input
                    style={{
                      border: "1px solid #49A8F8",
                    }}
                    type="email"
                    placeholder="Email address"
                    name="email"
                    className={`w-100 ${errors.email ? "border-danger" : ""}`}
                    {...register("email", {
                      required: true,
                    })}
                  />
                </div>

                <div>
                  <Button className="py-3  mt-1" disabled={loading}>
                    {loading ? (
                      <div className="text-center w-100 align-items-center">
                        <ThreeDots
                          color="white"
                          height={"12px"}
                          wrapperStyle={{ display: "block" }}
                        />
                      </div>
                    ) : (
                      "Resend email"
                    )}
                  </Button>
                </div>
              </Form>
            </Card>
            <div className="no-account my-4 py-4 text-center">
              <p
                className="mb-0"
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                Remember Password?
                <span className="register-link">
                  <a
                    href="/auth/login"
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    Login
                  </a>
                </span>
              </p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { error, message, loading } = auth;
  return {
    error: error?.sendVerificationEmail,
    message: message?.sendVerificationEmail,
    loading: loading?.sendVerificationEmail,
  };
};
export default connect(mapStateToProps, { sendVerificationEmail })(VerifyEmail);
