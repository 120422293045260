import {
  SCHEDULEPOST,
  GET_ONECLIENTPOST,
  DELETE_FACEBOOK_POST,
  DELETE_TWITTER_POST,
  DELETE_LINKEDIN_POST,
  scheduledPostSuccess,
  scheduledPostError,
  clearMessages,
  getOneClientPostSuccess,
  getOneClientPostError,
  getOneClientPost,
  deleteFacebookPostSuccess,
  deleteFacebookPostError,
  deleteTwitterPostSuccess,
  deleteTwitterPostError,
  deleteLinkedInPostSuccess,
  deleteLinkedInPostError,
  deleteInstagramPostSuccess,
  deleteInstagramPostError,
  DELETE_INSTAGRAM_POST,
} from "../../actions";
import Axios from "../../../utils/Axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

export function* GetOneClientPost({ payload }) {
  const { id } = payload;
  try {
    const response = yield Axios.get(`client/api/v1/user/${id}/posts/`);
    if (response?.status === 200) {
      yield put(getOneClientPostSuccess(response?.data));
    } else {
      yield put(getOneClientPostError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getOneClientPostError(message));
    yield put(clearMessages());
  }
}

export function* SchedulePost({ payload }) {
  const { post_status } = payload;
  try {
    const response = yield Axios.post(`/client/api/v1/post/create/`, payload);
    if (response?.status === 200) {
      yield put(
        scheduledPostSuccess({
          ...response.data,
          post_status,
          message: "Post created successfully",
        })
      );
      yield call(GetOneClientPost(payload.user));
    } else {
      yield put(scheduledPostError({ error: response?.data?.message }));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = "400 error";
          break;
        default:
          message = "cannot post";
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(scheduledPostError(message));
    yield put(clearMessages());
  }
}
export function* DeleteFaceBookPost({ payload }) {
  const { userID, postId } = payload;
  try {
    const response = yield Axios.delete(
      `/instagram/api/v1/facebook/post/${postId}/`
    );
    if (response?.status === 204) {
      yield put(deleteFacebookPostSuccess());
      yield put(getOneClientPost(userID));
    } else {
      yield put(deleteFacebookPostError(response?.data?.message));
    }
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(deleteFacebookPostError(message));
  }
}
export function* DeleteTwitterPost({ payload }) {
  const { userID, postId } = payload;
  try {
    const response = yield Axios.delete(`/twitter/api/v1/tweet/${postId}/`);
    if (response?.status === 204) {
      yield put(deleteTwitterPostSuccess());
      yield put(getOneClientPost(userID));
    } else {
      yield put(deleteTwitterPostError(response?.data?.message));
    }
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(deleteTwitterPostError(message));
  }
}
export function* DeleteLinkedInPost({ payload }) {
  const { userID, postId } = payload;
  try {
    const response = yield Axios.delete(`/linkedin/api/v1/post/${postId}/`);
    if (response?.status === 204) {
      yield put(deleteLinkedInPostSuccess());
      yield put(getOneClientPost(userID));
    } else {
      yield put(deleteLinkedInPostError(response?.data?.message));
    }
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(deleteLinkedInPostError(message));
  }
}
export function* DeleteInstagramPost({ payload }) {
  const { userID, postId } = payload;
  try {
    const response = yield Axios.delete(
      `/instagram/api/v1/instagram/post/${postId}/`
    );
    if (response?.status === 204) {
      yield put(deleteInstagramPostSuccess());
      yield put(getOneClientPost(userID));
    } else {
      yield put(deleteInstagramPostError(response?.data?.message));
    }
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(deleteInstagramPostError(message));
  }
}
export function* watchGetClientPost() {
  yield takeEvery(GET_ONECLIENTPOST, GetOneClientPost);
}

export function* watchDeleteFacebookPost() {
  yield takeEvery(DELETE_FACEBOOK_POST, DeleteFaceBookPost);
}
export function* watchDeleteTwitterPost() {
  yield takeEvery(DELETE_TWITTER_POST, DeleteTwitterPost);
}
export function* watchDeleteLinkedInPost() {
  yield takeEvery(DELETE_LINKEDIN_POST, DeleteLinkedInPost);
}
export function* watchDeleteInstagramPost() {
  yield takeEvery(DELETE_INSTAGRAM_POST, DeleteInstagramPost);
}

export function* watchSchedulePost() {
  yield takeEvery(SCHEDULEPOST, SchedulePost);
}

export default function* rootSaga() {
  yield all([
    fork(watchSchedulePost),
    fork(watchGetClientPost),
    fork(watchDeleteFacebookPost),
    fork(watchDeleteInstagramPost),
    fork(watchDeleteLinkedInPost),
    fork(watchDeleteTwitterPost),
  ]);
}
