import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { plans } from "../../../../utils/Plans";
const CurrentPlanCard = ({
  toggleCancelSubscriptionModal,
  subscriptionDetails,
}) => {
  const navigate = useNavigate();

  const handleChangePlan = () => {
    navigate("/Subscriptions");
  };

  const activePlan = useMemo(() => {
    return plans?.find(
      (plan) => plan.name.toLowerCase() === subscriptionDetails?.plan
    );
  }, [subscriptionDetails?.plan]);

  return (
    <div className="billing-card">
      <div className="d-flex justify-content-between">
        <div className="plan-name">
          <span>Current Plan</span>
          <p>{activePlan?.name}</p>
        </div>
        <div className="plan-amount">
          $
          {subscriptionDetails?.is_annual
            ? activePlan?.price * 10
            : activePlan?.price}
          <span className="plan-duration">
            /{subscriptionDetails?.is_annual ? "year" : "month"}
          </span>
        </div>
      </div>
      <p className="plan-description">{activePlan?.features?.join(", ")}</p>
      <div className="d-flex justify-content-end card-actions">
        <button
          className="me-3 btn btn-no-bg"
          onClick={toggleCancelSubscriptionModal}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary btn-border-radius"
          onClick={handleChangePlan}
        >
          Change Plan
        </button>
      </div>
    </div>
  );
};

export default CurrentPlanCard;
