export const getDate = (time) => {
    let date = new Date(time)
    let formatDate = date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()
    
    return formatDate;
}

export const getTime = (time) => {
   let newTime = new Date(time)
   let formatTime = newTime.getHours() + ":" + newTime.getMinutes()

   return formatTime;
}

export const sortItem = (item) => {
    if(item?.length > 0){
        const sortedItem =  item?.sort((a, b) => {
            let _a = new Date(b?.created_at);
            let _b = new Date(a?.created_at);
            return _a === _b ? 0 : _a > _b ? 1 : -1;
          })
          return sortedItem;
    }
    }

export const sliceItem = (str)=>{
    if(str.length > 0){
        if(str.length <=10) return str
        return str.slice(0,11) + '....'
    }
    }

export const capitalizeWord = (str) => {
        if (str?.length) {
            let transformWord = str[0].toUpperCase() + str.substring(1).toLowerCase();
            return transformWord;
        }
    
    }