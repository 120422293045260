import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import bluePlus from "../../../assets/img/blue-plus-bus-icon.svg";
import { TiEye } from "react-icons/ti";
import { IoMdEyeOff } from "react-icons/io";
import { useForm } from "react-hook-form";
import {
  resetPassword,
  updateUserDetails,
  changeProfileImage,
} from "../../../redux/actions";
import PersonalDetailsForm from "./PersonalDetailsForm";

//firebase
import fireBaseStorage from "../../../lib/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

//redux
import { connect } from "react-redux";
import { toast } from "react-toastify";

const PersonalDetails = ({
  userDetails,
  message,
  error,
  loading,
  resetPassword,
  updateUserDetails,
  changeProfileImage,
}) => {
  const [inputType1, setInputType1] = useState("password");
  const [inputType2, setInputType2] = useState("password");
  // const [percent, setPercent] = useState(0);
  const [img, setImg] = useState(null);

  //Handle Input Change
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  //fileInput Ref
  const inputRef = useRef(null);

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };

  //Handle Update Password
  const handleUpdatePassword = (data) => {
    if (data?.new_password1 !== data?.new_password2) {
      toast.error("Passwords doesn't match");
    } else {
      resetPassword(data);
      if (message?.length > 0 && loading === false) {
        reset();
      }
    }
  };

  //Handle Update personal details
  const handleUpdatePersonalDetails = (data) => {
    updateUserDetails(data);
  };

  const uploadImage = useCallback(
    (file) => {
      const storageRef = ref(
        fireBaseStorage,
        `/profile-pictures/${file?.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const percent = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // ); // update progress
          // setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            changeProfileImage({ profile_image: url });
          });
        }
      );
    },
    [changeProfileImage]
  );
  //Handle ChangeProfileImage
  const handleProfilePictureChange = (e) => {
    const imageName = e.target.files[0];
    setImg(imageName);
  };
  useEffect(() => {
    // console.log(loginError, message, loading);
    if (!loading?.changeProfileImage && message?.changeProfileImage) {
      toast.success(message?.changeProfileImage);
    }
    if (!loading?.changeProfileImage && error?.changeProfileImage) {
      toast.error(error?.changeProfileImage);
    }
    if (!loading?.updateUserDetails && error?.updateUserDetails) {
      toast.error(error?.updateUserDetails);
    }
    if (!loading?.updateUserDetails && message?.updateUserDetails) {
      toast.success(message?.updateUserDetails);
    }
    if (!loading?.resetPassword && error?.resetPassword) {
      toast.error(error?.resetPassword);
    }
    if (!loading?.resetPassword && message?.resetPassword) {
      toast.success(message?.resetPassword);
    }
  }, [
    error?.changeProfileImage,
    loading?.changeProfileImage,
    message?.changeProfileImage,
    loading?.updateUserDetails,
    message?.updateUserDetails,
    error?.updateUserDetails,
    loading?.resetPassword,
    message?.resetPassword,
    error?.resetPassword,
  ]);

  //Reset Password
  useEffect(() => {
    if (!loading?.resetPassword && error?.resetPassword) {
      toast.error(error?.resetPassword);
    }
    if (!loading?.resetPassword && message?.resetPassword) {
      toast.success(message?.resetPassword);
    }
  }, [error?.resetPassword, message?.resetPassword, loading?.resetPassword]);

  //useEffect
  useEffect(() => {
    if (img) {
      uploadImage(img);
    }
  }, [uploadImage, img]);
  return (
    <div className="personal-details mt-4">
      <Row>
        <Col xl="6" className="mt-3 mb-3">
          <Card className="upload-picture-container mb-4">
            <div className="upload-text-wrapper">
              <h1>Upload Profile Picture</h1>
              <div className="d-flex ">
                {loading?.changeProfileImage ? (
                  <div>loading!!!!</div>
                ) : img ? (
                  <img
                    src={URL.createObjectURL(img)}
                    className="img-container"
                    style={{ objectFit: "cover" }}
                    alt="business logo"
                  />
                ) : userDetails?.profile_image ? (
                  <img
                    src={userDetails?.profile_image}
                    className="img-container"
                    style={{ objectFit: "cover" }}
                    alt="business logo"
                  />
                ) : (
                  <div className="img-container" alt="company logo" />
                )}

                <div className="upload-text-wrapper">
                  <h1>Profile Picture</h1>
                  <p className="mb-0">Image format with max size of 3mb</p>
                  <div className="d-flex flex-row">
                    <button className="d-flex align-items-center bg-transparent border-0">
                      <img src={bluePlus} alt="plus icon" />
                      <h6
                        className="pt-2 ms-1 business__right-section__image-upload-placeholder__image-upload-blue text-nowrap"
                        onClick={handleClick}
                      >
                        Upload Photo
                      </h6>
                    </button>
                  </div>

                  <input
                    type="file"
                    className="w-100 file-upload-button"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleProfilePictureChange}
                  />
                </div>
              </div>
            </div>
          </Card>
          <PersonalDetailsForm
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            userDetails={userDetails}
            updateUserLoading={loading?.updateUserDetails}
          />
        </Col>
        <Col xl="6" className="mb-2">
          <div>
            <Card className="personal-details-form-card reset-password-form">
              <h1>Reset Password</h1>
              <Form onSubmit={handleSubmit(handleUpdatePassword)}>
                <div className="password-container">
                  <div className="password-icon">
                    {inputType1 === "password" ? (
                      <TiEye
                        color="#E5E9F2"
                        size="30px"
                        onClick={() => {
                          setInputType1("text");
                        }}
                      />
                    ) : (
                      <IoMdEyeOff
                        color="#000"
                        size="25px"
                        onClick={() => {
                          setInputType1("password");
                        }}
                      />
                    )}
                  </div>
                  <input
                    type={inputType1}
                    name="new_password1"
                    placeholder="Create password"
                    className={`w-100 ${
                      errors.new_password1 ? "border-danger" : ""
                    }`}
                    {...register("new_password1", {
                      required: true,
                    })}
                  />
                  {errors.new_password1 && (
                    <span className="text-danger text-left">
                      Password is required
                    </span>
                  )}
                </div>

                <div className="password-container">
                  <div className="password-icon">
                    {inputType2 === "password" ? (
                      <TiEye
                        color="#E5E9F2"
                        size="30px"
                        onClick={() => {
                          setInputType2("text");
                        }}
                      />
                    ) : (
                      <IoMdEyeOff
                        color="#000"
                        size="25px"
                        onClick={() => {
                          setInputType2("password");
                        }}
                      />
                    )}
                  </div>
                  <input
                    type={inputType2}
                    placeholder="Confirm password"
                    name="new_password2"
                    className={`w-100 ${
                      errors.new_password2 ? "border-danger" : ""
                    }`}
                    {...register("new_password2", {
                      required: true,
                    })}
                  />
                  {errors.new_password2 && (
                    <span className="text-danger text-left">
                      Confirm Password is required
                    </span>
                  )}
                </div>
                <button
                  className="w-50 delete-btn"
                  type="submit"
                  disabled={loading?.resetPassword}
                >
                  {loading?.resetPassword ? (
                    <div className="text-center w-100 align-items-center">
                      <ThreeDots
                        color="white"
                        height={"12px"}
                        wrapperStyle={{ display: "block" }}
                      />
                    </div>
                  ) : (
                    "Update"
                  )}
                </button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ general, settings }) => {
  const { userDetails } = general;
  const { message, error, loading } = settings;
  return {
    userDetails,
    message,
    error,
    loading,
  };
};
export default connect(mapStateToProps, {
  resetPassword,
  updateUserDetails,
  changeProfileImage,
})(PersonalDetails);
