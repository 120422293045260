import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { useFormik } from "formik";
import SelectInput from "../../../components/Select/SelectInput";

//redux
import { connect } from "react-redux";
import {
  getInvoiceSettings,
  updateInvoiceSetting,
} from "../../../redux/actions";
import { toast } from "react-toastify";

const Invoice = ({
  getInvoiceSettings,
  invoiceSettings,
  updateInvoiceSetting,
  updateInvoiceSettingsLoading,
  updateInvoiceSettingsMessage,
  updateInvoiceSettingsError,
}) => {
  const currencyOptions = [
    {
      label: "United States Dollar",
      value: "USD",
    },
    {
      label: "Nigerian Naira",
      value: "NGN",
    },
    {
      label: "Canadian Dollar",
      value: "CAD",
    },
    {
      label: "Great Britain Pounds",
      value: "GBP",
    },
  ];
  const validate = (values) => {
    const errors = {};
    // if (!values.footer_note) {
    //   errors.footer_note = "Footer note cannot be empty";
    // }
    if (!values.invoice_prefix) {
      errors.invoice_prefix = "Invoice prefix is required";
    }
    if (!values.currency) {
      errors.currency = "Currency is required";
    }
    // if (!values.vat) {
    //   errors.vat = "Vat is required";
    // }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      footer_note: invoiceSettings?.footer_note || "",
      invoice_prefix: invoiceSettings?.invoice_prefix || "",
      currency: invoiceSettings?.currency || undefined,
      vat: invoiceSettings?.vat || "",
    },
    validate,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      updateInvoiceSetting(values);
    },
  });

  useEffect(() => {
    getInvoiceSettings();
  }, [getInvoiceSettings]);

  useEffect(() => {
    if (!updateInvoiceSettingsLoading && updateInvoiceSettingsMessage?.length) {
      toast.success(updateInvoiceSettingsMessage);
    } else if (
      !updateInvoiceSettingsLoading &&
      updateInvoiceSettingsError?.length
    ) {
      toast.error(updateInvoiceSettingsError);
    }
  }, [
    updateInvoiceSettingsMessage,
    updateInvoiceSettingsError,
    updateInvoiceSettingsLoading,
  ]);
  return (
    <div className="invoice-form">
      <h1 className="my-5">Invoice</h1>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Row>
          <Col md={6} sm={12} lg={6}>
            <label>Currency</label>
            <SelectInput
              showSearch={false}
              name="currency"
              className="w-100"
              value={formik?.values?.currency}
              onChange={(value) => formik.setFieldValue("currency", value)}
              options={currencyOptions}
            />
            {formik?.errors?.currency && formik?.touched?.currency ? (
              <span className="text-danger text-left field-error">
                {formik?.errors?.currency}
              </span>
            ) : null}
          </Col>
          <Col md={6} sm={12} lg={6}>
            <Row>
              <Col md={6} sm={12} lg={6}>
                <label>Invoice prefix</label>
                <input
                  type="text"
                  name="invoice_prefix"
                  placeholder="INV"
                  className="w-100"
                  style={{ paddingTop: "1.2rem", paddingBottom: "1.2rem" }}
                  value={formik?.values?.invoice_prefix}
                  onChange={formik.handleChange}
                />
                {formik?.errors?.invoice_prefix &&
                formik?.touched?.invoice_prefix ? (
                  <span className="text-danger text-left field-error">
                    {formik?.errors?.invoice_prefix}
                  </span>
                ) : null}
              </Col>
              <Col md={6} sm={12} lg={6}>
                <label>Tax rate (%)</label>
                <input
                  type="text"
                  placeholder="vat"
                  className="w-100"
                  style={{ paddingTop: "1.2rem", paddingBottom: "1.2rem" }}
                  name="vat"
                  value={formik?.values?.vat}
                  onChange={formik.handleChange}
                />
                {formik?.errors?.vat && formik?.touched?.vat ? (
                  <span className="text-danger text-left field-error">
                    {formik?.errors?.vat}
                  </span>
                ) : null}
              </Col>
            </Row>
          </Col>
          <Col>
            <label>Footer note</label>
            <textarea
              name="footer_note"
              className="w-100"
              rows={4}
              value={formik?.values?.footer_note}
              onChange={formik.handleChange}
            />
            {formik?.errors?.footer_note && formik?.touched?.footer_note ? (
              <span className="text-danger text-left field-error">
                {formik?.errors?.footer_note}
              </span>
            ) : null}
          </Col>
        </Row>
        <button className="delete-btn" type="submit">
          {updateInvoiceSettingsLoading ? (
            <div className="text-center w-100 align-items-center">
              <ThreeDots
                color="white"
                height={"12px"}
                wrapperStyle={{ display: "block" }}
              />
            </div>
          ) : (
            "Update"
          )}
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    invoiceSettings: state?.settings?.invoiceSettings,
    updateInvoiceSettingsLoading:
      state?.settings?.loading?.updateInvoiceSettings,
    updateInvoiceSettingsMessage:
      state?.settings?.message?.updateInvoiceSettings,
    updateInvoiceSettingsError: state?.settings?.error?.updateInvoiceSettings,
  };
};

export default connect(mapStateToProps, {
  getInvoiceSettings,
  updateInvoiceSetting,
})(Invoice);
