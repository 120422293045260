import { Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import React, { useEffect, useState, useCallback } from "react";
import TitleModalLogoHere from "../../assets/img/businessLogo.svg";
import { Bars } from "react-loader-spinner";
import InvoiceSettingsModal from "./InvoiceActions/InvoiceSettingsModal";
import RecordPaymentModal from "./InvoiceActions/RecordPaymentModal";
import ScheduleModal from "./InvoiceActions/ScheduleModal";
import DuplicateInvoiceModal from "./InvoiceActions/DuplicateInvoiceModal";
import InvoiceDetails from "./InvoiceDetails";
import DeleteModal from "../../components/Modal/DeleteModal";

//icons
import HrInvoice from "../../assets/img/hr-invoice.svg";
import PlusSign from "../../assets/img/plus-sign.svg";
import LockKey from "../../assets/img/lock-key.svg";
//redux
import { connect } from "react-redux";
import {
  getOneInvoice,
  getInvoiceSettings,
  deleteInvoice,
} from "../../redux/actions";

//utils
import {
  stringDateFormat,
  invoicePaymentStatus,
  formatAmount,
  pdfWithPrintJs,
  copierHelper,
  calculateVat,
  downloadPdf,
} from "../../utils/helper";
import useCurrency from "../../hooks/useCurrency";
//react-router
import { useLocation, useNavigate } from "react-router-dom";
import CreateInvoiceModal from "./InvoiceActions/CreateInvoiceModal";
import EditInvoiceModal from "./InvoiceActions/EditInvoiceModal";
import SendInvoiceModal from "./InvoiceActions/SendInvoiceModal";
import SendReceiptModal from "./InvoiceActions/SendReceiptModal";

const InvoiceDetailsPage = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showRecordPayment, setShowRecordPayment] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [showDuplicateInvoiceModal, setShowDuplicateInvoiceModal] =
    useState(false);
  const [showPreviewInvoiceModal, setShowPreviewInvoiceModal] = useState(false);
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSendInvoiceModal, setshowSendInvoiceModal] = useState(false);
  const [showSendReceiptModal, setshowSendReceiptModal] = useState(false);
  const [type, setType] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //props
  const {
    getOneInvoice,
    invoiceDetails,
    getOneInvoiceLoading,
    getInvoiceMessage,
    getInvoiceSettings,
    logo,
    website_url,
    footer_note,
    // invoiceSettings,
    deleteError,
    deleteMessage,
    deleteloading,
    deleteInvoice,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const { currencySymbol } = useCurrency(pageData?.currency);
  const getTotalAmountPaid = useCallback(() => {
    let total = 0;
    pageData?.payment_record?.forEach((record) => {
      total += parseInt(record?.amount_paid);
    });
    return total;
  }, [pageData?.payment_record]);

  const getPDF = () => {
    setShowOptions(false);
    pdfWithPrintJs("invoice", "invoice");
  };

  const DownloadInvoicePdf = () => {
    downloadPdf("invoice");
    setShowOptions(false);
  };

  const toggleInvoicePreview = () => {
    setShowPreviewInvoiceModal(!showPreviewInvoiceModal);
  };

  const OpenCreateInvoiceModal = () => {
    setShowCreateInvoiceModal(true);
    setShowOptions(false);
  };
  const CloseCreateInvoiceModal = () => {
    setShowCreateInvoiceModal(false);
  };

  const openEditInvoiceModal = () => {
    setShowEditModal(true);
  };
  const closeEditInvoiceModal = () => {
    setShowEditModal(false);
  };

  const openSendInvoiceModal = (type) => {
    setshowSendInvoiceModal(true);
    setType(type);
  };
  const openSendReceiptModal = (type) => {
    setshowSendReceiptModal(true);
    setType(type);
  };

  const openDeleteModal = (record) => {
    setShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = () => {
    deleteInvoice(location?.state?.id);
  };
  useEffect(() => {
    if (!deleteloading && deleteMessage?.length > 0) {
      closeDeleteModal();
      navigate(-1);
    } else if (!deleteloading && deleteError?.length > 0) {
      toast.error(deleteError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteError, deleteMessage, deleteloading]);
  useEffect(() => {
    getTotalAmountPaid();
  }, [pageData?.payment_record, getTotalAmountPaid]);
  useEffect(() => {
    getOneInvoice(location?.state?.id);
  }, [getOneInvoice, location?.state?.id]);

  useEffect(() => {
    if (!getOneInvoiceLoading && getInvoiceMessage?.length > 0) {
      setTimeout(() => setPageData(invoiceDetails), 1000);
    } else {
      setPageData(null);
    }
  }, [getInvoiceMessage, getOneInvoiceLoading, invoiceDetails]);

  useEffect(() => {
    if (showEditModal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "visible";
  }, [showEditModal]);

  useEffect(() => {
    getInvoiceSettings();
  }, [getInvoiceSettings]);

  console.log(pageData);
  return (
    <>
      {showCreateInvoiceModal ? (
        <CreateInvoiceModal closeInvoiceModal={CloseCreateInvoiceModal} />
      ) : null}
      {showEditModal ? (
        <EditInvoiceModal
          closeInvoiceModal={closeEditInvoiceModal}
          showEditModal={showEditModal}
          payload={pageData}
        />
      ) : null}
      <div className="dashboard dashboard-wrapper px-2 position-relative">
        {getOneInvoiceLoading && !pageData ? (
          <div className="position-fixed top-50 start-50">
            <Bars height="100" width="100" color="#2062F4" />
          </div>
        ) : (
          <Row>
            <Col xl="8" className="">
              <div
                className="bg-white rounded-5"
                style={{ borderRadius: "10px" }}
              >
                <div id="invoice">
                  <div className="add-client-wrapper-2 text-center ">
                    <div className="d-inline-flex" style={{ width: "100%" }}>
                      <img
                        className="me-auto mb-3"
                        src={logo ? logo : TitleModalLogoHere}
                        width="100px"
                        alt="business logo"
                      />
                      <h6 className="invoice-modal__title">
                        {pageData?.extra_details?.invoice_prefix
                          ? pageData?.extra_details?.invoice_prefix
                          : "INV"}{" "}
                        -{pageData?.invoice_number}
                      </h6>
                    </div>
                    <Row>
                      <Col sm="6" lg="6" xl="6">
                        <h6 className="invoice-modal__light text-left mb-3">
                          {pageData?.extra_details?.business_address}
                        </h6>
                      </Col>
                      <Col sm="6" lg="6" xl="6">
                        <div className="" style={{ textAlign: "right" }}>
                          <h6
                            className="invoice-modal__light text-right"
                            style={{ textAlign: "right" }}
                          >
                            Billed to,
                          </h6>
                          <h6
                            className="invoice-modal__bold text-right"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            {pageData?.client?.fullname}
                          </h6>
                          <h6
                            className="invoice-modal__light text-right"
                            style={{ textAlign: "right" }}
                          >
                            {pageData?.client?.client_email || "No Email"}
                          </h6>
                          <h6
                            className="invoice-modal__light text-right"
                            style={{ textAlign: "right" }}
                          >
                            {pageData?.client?.client_phone_number !== "Null"
                              ? pageData?.client?.client_phone_number
                              : ""}
                          </h6>
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-end mt-3">
                      <Col sm="6" lg="6" xl="6">
                        <div className="mb-4">
                          <h6 className="invoice-modal__light text-left">
                            Issue Date
                          </h6>
                          <h6 className="invoice-modal__bold text-left mb-3">
                            {stringDateFormat(pageData?.issued_on)}
                          </h6>
                        </div>
                        <div className="">
                          <h6 className="invoice-modal__light text-left">
                            Due Date
                          </h6>
                          <h6 className="invoice-modal__bold text-left">
                            {stringDateFormat(pageData?.due_date)}
                          </h6>
                        </div>
                      </Col>
                      <Col sm="6" lg="6" xl="6">
                        {invoicePaymentStatus(pageData?.status)}
                      </Col>
                    </Row>

                    <img src={HrInvoice} className="w-100" alt="" />
                    <div
                      className="mt-5 invoice-modal__grey-section w-100 py-4 px-4"
                      style={{ width: "100%", overflowX: "auto" }}
                    >
                      <table>
                        <thead style={{ borderBottom: "2px solid #F2F2F2" }}>
                          <th>
                            <h6 className="invoice-modal__qty">ITEM</h6>
                          </th>
                          <th>
                            <h6 className="invoice-modal__qty">RATE</h6>
                          </th>
                          <th>
                            <h6 className="invoice-modal__qty ">QTY</h6>
                          </th>
                          <th>
                            <h6 className="invoice-modal__qty">AMOUNT</h6>
                          </th>
                        </thead>
                        <tbody>
                          {pageData?.items?.map((item) => {
                            return (
                              <tr key={item?.id}>
                                <td style={{ width: "300px" }}>
                                  <h6 className="invoice-modal__item">
                                    {item?.item_description}
                                  </h6>
                                </td>
                                <td style={{ width: "300px" }}>
                                  <h6 className="invoice-modal__item">
                                    {formatAmount(item?.rate)}
                                  </h6>
                                </td>
                                <td style={{ width: "300px" }}>
                                  <h6 className="invoice-modal__item ">
                                    {item?.quantity}
                                  </h6>
                                </td>
                                <td style={{ width: "300px" }}>
                                  <h6 className="invoice-modal__item">
                                    {currencySymbol}{" "}
                                    {formatAmount(item?.amount)}
                                  </h6>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <Row>
                      <Col className="ms-auto" xl="9">
                        <div className="mt-5 invoice-modal__grey-section py-4 px-4">
                          <Row>
                            <Col xl="6" lg="6" sm="6">
                              <h6 className="invoice-modal__qty ">Sub Total</h6>
                              <h6 className="invoice-modal__qty">
                                VAT({pageData?.vat}%)
                              </h6>
                            </Col>
                            <Col xl="6" lg="6" sm="6">
                              <h6
                                className="invoice-modal__item "
                                style={{ textAlign: "right" }}
                              >
                                {formatAmount(pageData?.sub_total)}
                              </h6>
                              <h6
                                className="invoice-modal__item"
                                style={{ textAlign: "right" }}
                              >
                                {currencySymbol}
                                {formatAmount(
                                  calculateVat(
                                    pageData?.sub_total,
                                    pageData?.vat
                                  ) || "0"
                                )}
                              </h6>
                            </Col>
                          </Row>
                          <Row className="invoice-modal__blue-section py-3">
                            <div className="d-inline-flex w-100">
                              <h6
                                className="invoice-modal__qty my-auto"
                                style={{ textAlign: "left" }}
                              >
                                Total
                              </h6>
                              <h6
                                className="invoice-modal__total ms-auto my-auto"
                                style={{ textAlign: "right" }}
                              >
                                {currencySymbol} {formatAmount(pageData?.total)}
                              </h6>
                            </div>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <img src={HrInvoice} className="my-5 w-100" alt="" />
                    <h6 className="add-item mb-5">
                      {website_url || "www.yourwebsiteurl.com"}
                    </h6>
                  </div>
                </div>
                <div className="invoice-cta">
                  <div className="me-auto">
                    <div
                      className="d-inline-flex w-full"
                      role="button"
                      onClick={DownloadInvoicePdf}
                    >
                      <img src={PlusSign} className="" alt="" />
                      <h6 className="add-item ms-3 me-auto my-auto">
                        Dowload Invoice
                      </h6>
                    </div>
                    <h6
                      className="text-left fw-light"
                      style={{ fontSize: "14px" }}
                    >
                      {footer_note}
                    </h6>
                  </div>
                  <div className="buttons">
                    <button className="save-pdf  px-5" onClick={getPDF}>
                      Print
                    </button>
                    <button
                      className="ms-3 px-5 send align-items-center "
                      onClick={() =>
                        openSendInvoiceModal(
                          pageData?.status === "Pending" ||
                            pageData?.status === "Overdue"
                            ? "invoice"
                            : "receipt"
                        )
                      }
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
              {pageData?.payment_record?.length > 0 && (
                <div
                  className="bg-white rounded-5 py-4 px-5 mt-5"
                  style={{ borderRadius: "10px", fontSize: "12px" }}
                >
                  <h6 className="payment-records-header fs-5">
                    Invoice Payment
                  </h6>
                  <Row className="mt-3">
                    <Col sm="3" lg="3" md="3">
                      <h6>Client</h6>
                    </Col>
                    <Col sm="2" lg="2" md="2">
                      <h6>Amount</h6>
                    </Col>
                    <Col sm="3" lg="3" md="3">
                      <h6>Payment date</h6>
                    </Col>
                    <Col sm="4" lg="4" md="4">
                      <h6>Payment method</h6>
                    </Col>
                  </Row>
                  {pageData?.payment_record?.map((record) => {
                    return (
                      <Row
                        key={record?.id}
                        className="align-items-center justify-content-center mt-4"
                      >
                        <Col sm="3" lg="3" md="3">
                          <h6
                            className="fw-light payment-records-item"
                            style={{ color: "#9DA8B6" }}
                          >
                            {invoiceDetails?.client?.fullname}
                          </h6>
                        </Col>
                        <Col sm="2" lg="2" md="2">
                          <h6
                            className="fw-light payment-records-item"
                            style={{ color: "#9DA8B6" }}
                          >
                            {formatAmount(record?.amount_paid)}
                          </h6>
                        </Col>
                        <Col sm="3" lg="3" md="3">
                          <h6
                            className="fw-light payment-records-item"
                            style={{ color: "#9DA8B6" }}
                          >
                            {record?.payment_date}
                          </h6>
                        </Col>
                        <Col sm="4" lg="4" md="4">
                          <div className="list-client-tag-paid fs-6 py-2 text-center w-75">
                            {record?.payment_method === "Bank Transfer"
                              ? "Transfer"
                              : record?.payment_method}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              )}
            </Col>
            <Col xl="4" className="position-relative ">
              <div className="d-inline-flex w-10t margin-top">
                <button
                  className="py-2 ms-3 px-4 me-2 send align-items-center"
                  onClick={openEditInvoiceModal}
                >
                  Edit Invoice
                </button>
                <button
                  className="blue-btn py-2 px-4"
                  onClick={() => setShowOptions(!showOptions)}
                >
                  More Actions
                </button>
              </div>
              {showOptions ? (
                <div className="more-actions-container">
                  <h6
                    className="px-4 slightly-black action-menu py-3"
                    onClick={OpenCreateInvoiceModal}
                  >
                    New Invoice
                  </h6>
                  <h6
                    className="px-4 slightly-black action-menu py-3"
                    onClick={() => {
                      setShowDuplicateInvoiceModal(true);
                      setShowOptions(false);
                    }}
                  >
                    Make A copy
                  </h6>
                  <h6
                    onClick={() => {
                      setShowSettings(true);
                      setShowOptions(false);
                    }}
                    className="px-4 slightly-black action-menu py-3"
                  >
                    Invoice Settings
                  </h6>
                  <h6
                    className="px-4 slightly-black action-menu py-3"
                    onClick={getPDF}
                  >
                    Print Invoice
                  </h6>
                  <h6
                    className="px-4 slightly-black action-menu py-3"
                    onClick={DownloadInvoicePdf}
                  >
                    Download PDF
                  </h6>
                  <h6
                    className="px-4 slightly-black action-menu py-3"
                    onClick={openDeleteModal}
                  >
                    Delete Invoice
                  </h6>
                </div>
              ) : null}
              <div className="my-4 py-5 px-4 bg-white">
                <h6 className="text-center fw-light slightly-black mb-2">
                  The invoice can be sent to any email besides the client's
                  registered email address.
                </h6>
                <div className="d-inline-flex w-100">
                  {pageData?.status === "Pending" ||
                  pageData?.status === "Overdue" ? (
                    <button
                      className="py-2 mx-auto mt-3 px-4 send align-items-center"
                      onClick={() => openSendInvoiceModal("invoice")}
                    >
                      Send Invoice
                    </button>
                  ) : (
                    <button
                      className="py-2 mx-auto mt-3 px-4 send align-items-center"
                      onClick={() => openSendReceiptModal("receipt")}
                    >
                      Send Receipt
                    </button>
                  )}
                </div>
              </div>
              <div className="my-4 py-5 px-4 bg-white">
                <h6 className="text-center fw-light slightly-black mb-2">
                  Did the Client make an offline payment? You can record your
                  payment by clicking the button below
                </h6>
                <div className="d-inline-flex w-100">
                  <button
                    className="py-2 mx-auto mt-3 px-4 send align-items-center "
                    onClick={() => {
                      setShowRecordPayment(true);
                    }}
                    disabled={
                      parseInt(pageData?.total) === getTotalAmountPaid()
                    }
                  >
                    Record Payment
                  </button>
                </div>
              </div>
              <div className="my-4 py-2 px-2 bg-white">
                <div className="light-blue-bg d-flex justify-content-between w-100 px-3 py-3">
                  <h6 className="my-auto fw-light slightly-black">
                    Invoice Link
                  </h6>
                  <button
                    className="btn btn-link"
                    onClick={() =>
                      copierHelper(
                        `${window.location.href.split("/")?.[2]}/invoice/${
                          window.location.href.split("/")?.[5]
                        }`,
                        "Invoice Link"
                      )
                    }
                  >
                    <img src={LockKey} className="ms-auto" alt="" />
                  </button>
                </div>
              </div>
              <button
                className="py-3 text-center px-4 recurring w-100 mb-4"
                onClick={() => setShowDuplicateInvoiceModal(true)}
              >
                Make Recurring
              </button>
              <button
                className="blue-btn py-3 px-4 w-100 margin-bottom"
                onClick={toggleInvoicePreview}
              >
                Preview as Client
              </button>
            </Col>
          </Row>
        )}
      </div>

      <InvoiceSettingsModal
        showSettings={showSettings}
        setShowSettings={setShowSettings}
        invoiceId={location?.state?.id}
      />
      <RecordPaymentModal
        showRecordPayment={showRecordPayment}
        setShowRecordPayment={setShowRecordPayment}
        id={invoiceDetails?.id}
        totalAmountPaid={getTotalAmountPaid}
        invoiceTotal={parseInt(pageData?.total)}
      />
      <ScheduleModal
        showSchedule={showSchedule}
        setShowSchedule={setShowSchedule}
      />
      <DuplicateInvoiceModal
        showDuplicateInvoiceModal={showDuplicateInvoiceModal}
        setShowDuplicateInvoiceModal={setShowDuplicateInvoiceModal}
        invoiceId={location?.state?.id}
      />
      <InvoiceDetails
        toggleInvoicePreview={toggleInvoicePreview}
        showModal={showPreviewInvoiceModal}
        details={location?.state}
      />
      <SendInvoiceModal
        showSendInvoiceModal={showSendInvoiceModal}
        setshowSendInvoiceModal={setshowSendInvoiceModal}
        invoiceId={location?.state?.id}
        type={type}
      />
      <SendReceiptModal
        showSendReceiptModal={showSendReceiptModal}
        setshowSendReceiptModal={setshowSendReceiptModal}
        invoiceId={location?.state?.id}
        type={type}
      />

      <DeleteModal
        openModal={showDeleteModal}
        setOpenModal={setShowDeleteModal}
        deleteAction={handleDelete}
        deleteloading={deleteloading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    invoiceDetails: state?.oneInvoice?.details,
    getOneInvoiceLoading: state?.oneInvoice?.getOneInvoiceLoading,
    getInvoiceMessage: state?.oneInvoice?.message?.getOneInvoice,
    logo: state?.settings?.businessDetails?.logo,
    website_url: state?.settings?.businessDetails?.website,
    footer_note: state?.settings?.invoiceSettings?.footer_note,
    invoiceSettings: state?.settings?.invoiceSettings,
    deleteloading: state?.invoice?.loading?.deleteInvoice,
    deleteMessage: state?.invoice?.message?.deleteInvoice,
    deleteError: state?.invoice?.error?.deleteInvoice,
  };
};
export default connect(mapStateToProps, {
  getOneInvoice,
  getInvoiceSettings,
  deleteInvoice,
})(InvoiceDetailsPage);
