import React, { useEffect } from "react";
import { Container } from "reactstrap";
import logo from "../../assets/img/logo.png";
import checkedIcon from "../../assets/img/checked.svg";
import { Link, useLocation } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { verifyEmail } from "../../redux/actions";
import { toast } from "react-toastify";

const VerifyEmailSuccess = ({ verifyEmail, loading, error, message }) => {
  const location = useLocation();
  useEffect(() => {
    const uniqueCodeAndToken = location.pathname?.split("/")?.[3]?.split("&");
    let user = uniqueCodeAndToken?.[0]?.slice(12);
    let token = uniqueCodeAndToken?.[1]?.slice(6);
    const credentials = { user, token };

    verifyEmail(credentials);
  }, [location.pathname, verifyEmail]);

  useEffect(() => {
    if (!loading && message?.length) {
      toast.success(message);
    } else if (!loading && error?.length) {
      toast.error(error);
    }
  }, [loading, error, message]);
  return (
    <div className="auth">
      <Link className="auth-logo">
        <img src={logo} alt="ontriv-logo" width="150px" />
      </Link>
      <div className="reset-password">
        <Container>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "500px" }}
          >
            <img
              src={checkedIcon}
              alt="checked icon"
              style={{ width: "55px", marginBottom: "20px" }}
            />
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-center reset-password-text">
                <h3
                  className="mb-0"
                  style={{
                    fontSize: "34px",
                    opacity: "0.8",
                  }}
                >
                  Verfication Successful
                </h3>
                <div className="mt-4 mb-5">
                  <p
                    className="mb-0"
                    style={{
                      fontSize: "14px",
                      color: "#17151E",
                      opacity: "0.8",
                    }}
                  >
                    Welcome aboard! You can now start using the Ontriv app. We
                    look <br /> forward to seeing you explore its features!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.loading?.verifyEmail,
    message: state?.message?.verifyEmail,
    error: state?.error?.verifyEmail,
  };
};

export default connect(mapStateToProps, { verifyEmail })(VerifyEmailSuccess);
