import React from "react";
import { FaPlus } from "react-icons/fa";
import { Button } from "reactstrap";
import searchChat from "./../../assets/img/search-chat.svg";
import forwardChat from "./../../assets/img/forward-chat.svg";
import { Link } from "react-router-dom";
import { truncateText } from "../../utils/helper";
import { useSelector } from "react-redux";
import dummyimg from "../../assets/img/dummyimg.png";
import { sliceItem } from "../../utils/func";

const ListAllClient = ({
  getAllClientDetails,
  activeId,
  onChangeClientPost,
  searchValue,
  onChangeSearchValue,
}) => {
  const businessOwner = useSelector((state) => state?.auth?.currentUser);

  return (
    <>
      <div className="clientWrapper">
        <div className="scheduleInputWrapper">
          <input
            id="searchClient"
            name="client"
            placeholder="Find Client"
            type="text"
            value={searchValue}
            onChange={onChangeSearchValue}
            className=""
          />
          <img src={searchChat} className="ms-5" alt="" />
        </div>
        <div className="my-4">
          <h6 className="chat-titles mb-4">Your Accounts</h6>
        </div>

        <div>
          <div className="clientsHolder">
            <ul>
              <li
                className={`client-chart ${activeId === 0 && "active"}`}
                active={activeId}
                onClick={() =>
                  onChangeClientPost(0, businessOwner?.unique_code, "business")
                }
              >
                <div className="name_logo_holder">
                  <div className="chartIconHolder">
                    <img
                      src={businessOwner?.business_logo || dummyimg}
                      alt="barChart"
                    />
                  </div>
                  <div className="clientNameHolder">
                    <h6>{sliceItem(businessOwner?.business_name)}</h6>
                    <p className="owner">Owner</p>
                  </div>
                </div>
                {activeId === 0 ? (
                  <div>
                    <img src={forwardChat} className="mx-2" alt="" />
                  </div>
                ) : null}
              </li>
            </ul>
            <ul style={{ overflowY: "auto", height: "calc(100vh - 350px)" }}>
              {getAllClientDetails?.length > 0 ? (
                getAllClientDetails?.map((items, index) => (
                  <li
                    className={`client-chart ${
                      activeId === index + 1 && "active"
                    }`}
                    onClick={() =>
                      onChangeClientPost(index + 1, items?.client_id, "client")
                    }
                    active={activeId}
                    key={index}
                  >
                    <div className="name_logo_holder">
                      <div className="chartIconHolder">
                        <img
                          src={items.logo || dummyimg}
                          alt={items.fullname}
                        />
                      </div>
                      <div className="clientNameHolder">
                        <h6>{truncateText(items?.client_business_name)}</h6>
                      </div>
                    </div>
                    {activeId === index + 1 ? (
                      <div>
                        <img src={forwardChat} className="mx-2" alt="" />
                      </div>
                    ) : null}
                  </li>
                ))
              ) : (
                <li>
                  <div className="no-clients">
                    <p>
                      You have no clients. Please invite one to get started{" "}
                    </p>
                    <Link to="/clients">
                      <Button type="button" color="" className="addclient">
                        <div className="plus">
                          <FaPlus />
                        </div>
                        <span> Add New Client</span>
                      </Button>
                    </Link>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListAllClient;
