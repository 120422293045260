import React, { useEffect } from "react";
import { Col, Card } from "reactstrap";
import EmptyDashboardState from "../../../components/Utilities/EmptyDashboardState";
import digitalCreator from "../../../assets/img/avatar.png";
import forwardChat from "../../../assets/img/forward-chat.svg";
import { HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
//react-redux
import { connect } from "react-redux";
import { getClient } from "../../../redux/actions";

const ClientCard = (props) => {
  const { handleAddClient, getClient, clients } = props;

  const navigate = useNavigate();

  useEffect(() => {
    getClient();
  }, [getClient]);
  return (
    <Col lg="12" md="5" xl="5" className="mb-3 col-cstm-xl-4">
      <Card className="client-card">
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="pl-2 client-overview-header mb-0">Clients</h4>
            <p
              className="mb-0"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/clients")}
            >
              See all{" "}
              <span>
                <HiChevronRight size="24px" color="#49A8F8" />
              </span>
            </p>
          </div>
        </div>
        {/* ${activeId === index && "active"} */}
        {clients?.length ? (
          <div className="clientWrapper p-1 mt-1">
            <div className="clientsHolder">
              <ul>
                {clients?.slice(0, 6)?.map((client) => {
                  return (
                    <li
                      className={`client-chart `}
                      style={{
                        background: "#F9FAFC",
                        // paddingRight: "0.5rem",
                        margin: "0.8rem 0",
                      }}
                      onClick={() =>
                        navigate(`/clients/client-details/${client?.id}`)
                      }
                      // active={activeId}
                      key={client?.id}
                    >
                      <div className="d-flex">
                        <div className="">
                          <img
                            src={client?.logo || digitalCreator}
                            alt="test"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                        <div className="clientNameHolder ms-3">
                          <h6 className="text-capitalize">
                            {client?.client_business_name}
                          </h6>
                        </div>
                      </div>

                      <div>
                        <img
                          src={forwardChat}
                          className="ms-auto"
                          style={{ width: "14px" }}
                          alt=""
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          <EmptyDashboardState
            subHeaderText="Add a new client to get started"
            buttonText="Add a New client"
            onClick={handleAddClient}
          />
        )}
      </Card>
    </Col>
  );
};

const mapStateToProps = ({ client }) => {
  const { clients } = client;
  return {
    clients,
  };
};
export default connect(mapStateToProps, { getClient })(ClientCard);
