import {
  GET_ALL_SUBSCRIPTIONS,
  GET_ALL_SUBSCRIPTIONS_SUCCESS,
  GET_ALL_SUBSCRIPTIONS_ERROR,
  INITIATE_SUBSCRIPTION,
  INITIATE_SUBSCRIPTION_SUCCESS,
  INITIATE_SUBSCRIPTION_ERROR,
  GET_ALL_CARDS,
  GET_ALL_CARDS_SUCCESS,
  GET_ALL_CARDS_ERROR,
  CHANGE_SUBSCRIPTION_PLAN,
  CHANGE_SUBSCRIPTION_PLAN_SUCCESS,
  CHANGE_SUBSCRIPTION_PLAN_ERROR,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
  GET_SUBSCRIPTION_DETAILS,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_ERROR,
  REMOVE_CARD,
  REMOVE_CARD_SUCCESS,
  REMOVE_CARD_ERROR,
  RESET_MESSAGE,
} from "../actions";

const initialState = {
  loading: {
    getAllSubscriptions: false,
    cancelSubscription: false,
    changeSubscriptionPlan: false,
    initiateSubscription: false,
    getAllCards: false,
    getSubscriptionDetails: false,
    removeCard: false,
  },
  error: {
    getAllSubscriptions: "",
    cancelSubscription: "",
    changeSubscriptionPlan: "",
    initiateSubscription: "",
    getAllCards: "",
    getSubscriptionDetails: "",
    removeCard: "",
  },
  message: {
    getAllSubscriptions: "",
    cancelSubscription: "",
    changeSubscriptionPlan: "",
    initiateSubscription: "",
    getAllCards: "",
    getSubscriptionDetails: "",
    removeCard: "",
  },
  subscriptions: [],
  cards: [],
  subscriptionDetails: {},
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTIONS:
      return {
        ...state,
        loading: { ...state?.loading, getAllSubscriptions: true },
      };
    case GET_ALL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getAllSubscriptions: false },
        subscriptions: action.payload,
      };
    case GET_ALL_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getAllSubscriptions: false },
        error: { ...state?.error, getAllSubscriptions: action.payload.error },
      };
    case GET_ALL_CARDS:
      return {
        ...state,
        loading: { ...state?.loading, getAllCards: true },
      };
    case GET_ALL_CARDS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getAllCards: false },
        cards: action?.payload,
      };
    case GET_ALL_CARDS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getAllCards: false },
        error: action.payload,
      };
    case INITIATE_SUBSCRIPTION:
      return {
        ...state,
        loading: { ...state?.loading, initiateSubscription: true },
      };
    case INITIATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, initiateSubscription: false },
        message: { ...state?.message, initiateSubscription: action.payload },
      };
    case INITIATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, initiateSubscription: false },
        error: { ...state?.error, initiateSubscription: action.payload },
      };

    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        loading: { ...state?.loading, cancelSubscription: true },
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, cancelSubscription: false },
        message: { ...state?.message, cancelSubscription: action.payload },
      };
    case CANCEL_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, cancelSubscription: false },
        error: { ...state?.error, cancelSubscription: action.payload },
      };
    case GET_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        loading: { ...state?.loading, getSubscriptionDetails: true },
      };
    case GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getSubscriptionDetails: false },
        subscriptionDetails: action.payload,
      };
    case GET_SUBSCRIPTION_DETAILS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getSubscriptionDetails: false },
        error: { ...state?.error, getSubscriptionDetails: action.payload },
      };
    case CHANGE_SUBSCRIPTION_PLAN:
      return {
        ...state,
        loading: { ...state?.loading, changeSubscriptionPlan: true },
      };
    case CHANGE_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, changeSubscriptionPlan: false },
        message: { ...state?.message, changeSubscriptionPlan: action.payload },
      };
    case CHANGE_SUBSCRIPTION_PLAN_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, changeSubscriptionPlan: false },
        error: { ...state?.error, changeSubscriptionPlan: action.payload },
      };
    case REMOVE_CARD:
      return {
        ...state,
        loading: { ...state?.loading, removeCard: true },
      };
    case REMOVE_CARD_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, removeCard: false },
        message: { ...state?.message, removeCard: action.payload },
      };
    case REMOVE_CARD_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, removeCard: false },
        error: { ...state?.message, removeCard: action.payload },
      };
    case RESET_MESSAGE:
      return {
        ...state,
        error: {
          getAllSubscriptions: "",
          cancelSubscription: "",
          changeSubscriptionPlan: "",
          initiateSubscription: "",
          getAllCards: "",
          getSubscriptionDetails: "",
        },
        message: {
          getAllSubscriptions: "",
          cancelSubscription: "",
          changeSubscriptionPlan: "",
          initiateSubscription: "",
          getAllCards: "",
          getSubscriptionDetails: "",
        },
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
