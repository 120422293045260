import React from "react";
import { BsFillGridFill, BsCalendar2MinusFill } from "react-icons/bs";
import { IoSettingsSharp } from "react-icons/io5";
// import { BsFillCalendar2MinusFill } from "react-icons/bs";
import { FaAward, FaUserFriends } from "react-icons/fa";
// import { AiFillFileText } from "react-icons/ai";
import { RiPieChartFill } from "react-icons/ri";
// import { HiCalendar } from "react-icons/hi";

export const sideBarMenu = [
  {
    id: "Overview",
    title: "Dashboard",
    type: "item",
    icon: <BsFillGridFill size={20} />,
    navLink: "/overview",
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },

  {
    id: "Clients",
    title: "Clients",
    type: "item",
    icon: <FaUserFriends size={20} />,
    navLink: "/clients",
    isAvailable: ["business", "admin", "client-manager"],
  },
  // {
  //   id: "Content Ideas",
  //   title: "Content Ideas",
  //   type: "item",
  //   icon: <BsFillCalendar2MinusFill size={20} />,
  //   navLink: "/content-ideas",
  //   isAvailable: ["business", "admin", "client-manager"],
  // },
  {
    id: "Post Scheduling",
    title: "Scheduling",
    type: "item",
    icon: <BsCalendar2MinusFill size={17} />,
    navLink: "/schedule",
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },

  {
    id: "Invoices & Financials",
    title: "Finance",
    icon: <RiPieChartFill size={20} />,
    type: "item",
    navLink: "/invoices-&-financials",
    isAvailable: ["business", "admin"],
  },

  // {
  //   id: "Reports",
  //   title: "Reports",
  //   type: "item",
  //   icon: <AiFillFileText size={20} />,
  //   navLink: "/report",
  // },

  // {
  //   id: "Messages",
  //   title: "Messages",
  //   type: "item",
  //   icon: <RiBookFill size={20} />,
  //   navLink: "/Chat",
  // },
  // {
  //   id: "Post Ideas",
  //   title: "Post Ideas",
  //   type: "item",
  //   icon: <HiCalendar size={20} />,
  //   navLink: "/Chat",
  //   isAvailable: ["business", "admin", "client-manager", "content-manager"],
  // },
  {
    id: "Settings",
    title: "Settings",
    type: "item",
    navLink: "/settings",
    icon: <IoSettingsSharp size={20} />,
    isAvailable: ["business", "admin", "client-manager", "content-manager"],
  },
  // {
  //   id: "Subscription",
  //   type: "item",
  //   title: "Subscription",
  //   icon: <FaAward size={18} />,
  //   navLink: "/Subscriptions",
  //   isAvailable: ["business", "admin"],
  // },
];

export const bottomSideBarMenu = [
  {
    id: "Settings",
    title: "Settings",
    type: "item",
    navLink: "/settings",
    icon: <IoSettingsSharp size={20} />,
  },

  {
    id: "Help & Support",
    type: "item",
    title: "Help & Support",
    icon: <FaAward size={18} />,
    navLink: "/help",
  },
];
