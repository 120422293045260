import React, { useState } from "react";
import { TabContent, TabPane } from "reactstrap";
import { useNavigate } from "react-router-dom";
import EmptyPosts from "./EmptyPosts";

import { Drawer } from "antd";
import moment from "moment";
import PostCard from "./PostCard";

const ScheduledPostDrawer = (props) => {
  const [activeTab, setActiveTab] = useState("instagram");

  const navigate = useNavigate();

  const { open, handleClose, selectedDate, scheduledPosts } = props;

  const schedulePostAction = () => {
    navigate("/Schedule");
  };

  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={handleClose}
      open={open}
      size="large"
      key="right"
    >
      <div
        style={{
          fontWeight: 600,
          fontSize: "20px",
          lineHeight: "28px",
          letterSpacing: "0.35px",
          color: "#111317",
          marginBottom: "1rem",
        }}
      >
        {moment(selectedDate).format("Do MMMM YYYY")}
      </div>
      <div className="border-bottom tab-container">
        {["Instagram", "Facebook", "Linkedin", "Twitter"].map((tab, index) => {
          return (
            <div
              key={index}
              className={`tab ${
                tab.toLowerCase() === activeTab ? "active-tab" : ""
              }`}
              onClick={() => setActiveTab(tab?.toLocaleLowerCase())}
            >
              {tab}
            </div>
          );
        })}
      </div>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="instagram">
          <div className="mt-3">
            {scheduledPosts?.instagram_post?.length ? (
              scheduledPosts?.instagram_post?.slice(0, 3)?.map((post) => {
                return (
                  <React.Fragment key={post?.id}>
                    <PostCard post={post} />
                  </React.Fragment>
                );
              })
            ) : (
              <EmptyPosts
                handleClose={handleClose}
                schedulePostAction={schedulePostAction}
              />
            )}
            {scheduledPosts?.instagram_post?.length > 0 && (
              <div className="d-flex align-items-center justify-content-center mt-4">
                <div
                  className=" text-white  create-button align-items-center mb-3"
                  role="button"
                  onClick={handleClose}
                  style={{
                    width: "70%",
                    borderRadius: "10px",
                    fontSize: "14px",
                    lineHeight: "21px",
                    letterSpacing: "1.25px",
                    color: " #F7F7FC",
                  }}
                >
                  View All Posts
                </div>
              </div>
            )}
          </div>
        </TabPane>
        <TabPane tabId="facebook">
          {scheduledPosts?.facebook_post?.length ? (
            scheduledPosts?.facebook_post?.slice(0, 3)?.map((post) => {
              return (
                <React.Fragment key={post?.id}>
                  <PostCard post={post} />
                </React.Fragment>
              );
            })
          ) : (
            <EmptyPosts
              handleClose={handleClose}
              schedulePostAction={schedulePostAction}
            />
          )}
          {scheduledPosts?.facebook_post?.length > 0 && (
            <div className="d-flex align-items-center justify-content-center mt-4">
              <div
                className=" text-white  create-button align-items-center mb-3"
                role="button"
                onClick={handleClose}
                style={{
                  width: "70%",
                  borderRadius: "10px",
                  fontSize: "14px",
                  lineHeight: "21px",
                  letterSpacing: "1.25px",
                  color: " #F7F7FC",
                }}
              >
                View All Posts
              </div>
            </div>
          )}
        </TabPane>
        <TabPane tabId="linkedin">
          {scheduledPosts?.linkedin_post?.length ? (
            scheduledPosts?.linkedin_post?.slice(0, 3)?.map((post) => {
              return (
                <React.Fragment key={post?.id}>
                  <PostCard post={post} />
                </React.Fragment>
              );
            })
          ) : (
            <EmptyPosts
              handleClose={handleClose}
              schedulePostAction={schedulePostAction}
            />
          )}
          {scheduledPosts?.linkedin_post?.length > 0 && (
            <div className="d-flex align-items-center justify-content-center mt-4">
              <div
                className=" text-white  create-button align-items-center mb-3"
                role="button"
                onClick={handleClose}
                style={{
                  width: "70%",
                  borderRadius: "10px",
                  fontSize: "14px",
                  lineHeight: "21px",
                  letterSpacing: "1.25px",
                  color: " #F7F7FC",
                }}
              >
                View All Posts
              </div>
            </div>
          )}
        </TabPane>
        <TabPane tabId="twitter">
          {scheduledPosts?.Tweet?.length ? (
            scheduledPosts?.Tweet?.slice(0, 3)?.map((post) => {
              return (
                <React.Fragment key={post?.id}>
                  <PostCard post={post} />
                </React.Fragment>
              );
            })
          ) : (
            <EmptyPosts
              handleClose={handleClose}
              schedulePostAction={schedulePostAction}
            />
          )}
          {scheduledPosts?.Tweet?.length > 0 && (
            <div className="d-flex align-items-center justify-content-center mt-4">
              <div
                className=" text-white  create-button align-items-center mb-3"
                role="button"
                onClick={handleClose}
                style={{
                  width: "70%",
                  borderRadius: "10px",
                  fontSize: "14px",
                  lineHeight: "21px",
                  letterSpacing: "1.25px",
                  color: " #F7F7FC",
                }}
              >
                View All Posts
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </Drawer>
  );
};

export default ScheduledPostDrawer;
