import React, { useState } from "react";
import ContentItem from "./ContentItem";
import { IoChevronBackOutline } from "react-icons/io5";
import { IoChevronForwardOutline } from "react-icons/io5";
import { months } from "../../utils/helper";
import { useEffect } from "react";

//redux
import { useDispatch, useSelector } from "react-redux";
import { getAllContentIdeas } from "../../redux/actions";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import EmptyDashboardState from "../../components/Utilities/EmptyDashboardState";
import ContentIdeaDetails from "./ContentIdeaDetails";

const ContentIdeas = () => {
  const date = new Date();
  const [currentMonth, setCurrentMonth] = useState(date.getMonth());
  const [currentYear, setCurrentYear] = useState(date.getFullYear());
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [modalPayload, setModalPayload] = useState({});

  //redux hooks
  const dispatch = useDispatch();
  const contentIdeas = useSelector(
    (state) => state?.contentIdeas?.contentIdeas
  );
  const loadingState = useSelector(
    (state) => state?.contentIdeas?.loading?.getAllContentIdeas
  );
  //Button actions
  const goToPreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth((prev) => prev - 1);
    }
  };
  const goToNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth((prev) => prev + 1);
    }
  };

  //function
  const getDayFromDate = (date) => {
    return moment(date).format("Do");
  };

  const openViewModal = (data) => {
    setViewModalOpen(true);
    setModalPayload(data);
  };
  // const closeViewModal = () => {
  //   setViewModalOpen(false);
  //   setModalPayload({});
  // };

  useEffect(() => {
    dispatch(getAllContentIdeas({ month: currentMonth, year: currentYear }));
  }, [dispatch, currentMonth, currentYear]);
  return (
    <div className="content-ideas">
      <div>
        <div className="date-select-container">
          <div className="actions">
            <button onClick={goToPreviousMonth}>
              <IoChevronBackOutline />
            </button>
            <p>
              {months[currentMonth]} {currentYear}
            </p>
            <button onClick={goToNextMonth}>
              <IoChevronForwardOutline />
            </button>
          </div>
        </div>
      </div>
      <div className="content-days-container">
        {loadingState ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: "6rem" }}
          >
            <Bars height="100" width="100" color="#2062F4" />
          </div>
        ) : contentIdeas?.length ? (
          <div className="available-days">
            {contentIdeas?.map((idea, index) => {
              return (
                <ContentItem
                  icon={idea?.icon || "/green-salad.svg"}
                  title={idea?.content_idea_name}
                  day={getDayFromDate(idea?.date)}
                  key={index}
                  onClick={() => openViewModal(idea)}
                />
              );
            })}
          </div>
        ) : (
          <EmptyDashboardState subHeaderText="No upcoming events" />
        )}
      </div>

      <ContentIdeaDetails
        postIdea={modalPayload}
        eventState={viewModalOpen}
        updateEventState={setViewModalOpen}
      />
    </div>
  );
};

export default ContentIdeas;
