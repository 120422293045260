import {
  GET_BUSINESS_SOCIALS_CONNECTION_STATUS,
  GET_BUSINESS_SOCIALS_CONNECTION_STATUS_SUCCESS,
  GET_BUSINESS_SOCIALS_CONNECTION_STATUS_ERROR,
  GET_LINKEDIN_CALLBACK_URL,
  GET_LINKEDIN_CALLBACK_URL_SUCCESS,
  GET_LINKEDIN_CALLBACK_URL_ERROR,
  GET_FACEBOOK_CALLBACK_URL,
  GET_FACEBOOK_CALLBACK_URL_SUCCESS,
  GET_FACEBOOK_CALLBACK_URL_ERROR,
  ADD_NEW_NOTIFICATION,
  ADD_NEW_NOTIFICATION_SUCCESS,
  ADD_NEW_NOTIFICATION_ERROR,
  SEND_LINKEDIN_CODE_STATE,
  SEND_LINKEDIN_CODE_STATE_ERROR,
  SEND_LINKEDIN_CODE_STATE_SUCCESS,
  SEND_FACEBOOK_CODE_STATE,
  SEND_FACEBOOK_CODE_STATE_SUCCESS,
  SEND_FACEBOOK_CODE_STATE_ERROR,
  CONNECT_SELECTED_FACEBOOK_PAGE,
  CONNECT_SELECTED_FACEBOOK_PAGE_SUCCESS,
  CONNECT_SELECTED_FACEBOOK_PAGE_ERROR,
  GET_CONNECTED_INSTAGRAM_ACCOUNT,
  GET_CONNECTED_INSTAGRAM_ACCOUNT_SUCCESS,
  GET_CONNECTED_INSTAGRAM_ACCOUNT_ERROR,
  CONNECT_INSTAGRAM_ACCOUNT,
  CONNECT_INSTAGRAM_ACCOUNT_SUCCESS,
  CONNECT_INSTAGRAM_ACCOUNT_ERROR,
  GET_TWITTER_CALLBACK_URL,
  GET_TWITTER_CALLBACK_URL_SUCCESS,
  GET_TWITTER_CALLBACK_URL_ERROR,
  SEND_TWITTER_CODE_STATE,
  SEND_TWITTER_CODE_STATE_SUCCESS,
  SEND_TWITTER_CODE_STATE_ERROR,
  GET_TWITTER_V1_CALLBACK_URL,
  GET_TWITTER_V1_CALLBACK_URL_SUCCESS,
  GET_TWITTER_V1_CALLBACK_URL_ERROR,
  SEND_TWITTER_V1_CODE_STATE,
  SEND_TWITTER_V1_CODE_STATE_SUCCESS,
  SEND_TWITTER_V1_CODE_STATE_ERROR,
  CONNECT_LINKEDIN_PAGE,
  CONNECT_LINKEDIN_PAGE_SUCCESS,
  CONNECT_LINKEDIN_PAGE_ERROR,
  RESET_MESSAGE,
} from "../actions";

const initialState = {
  loading: {
    getBusinessSocialsConnectionStatus: false,
    getLinkedInCallbackURL: false,
    getFacebookCallbackURL: false,
    getTwitterCallbackURL: false,
    getTwitterV1CallbackURL: false,
    addNewNotification: false,
    sendLinkedInCodeState: false,
    sendFacebookCodeState: false,
    sendTwitterCodeState: false,
    sendTwitterV1CodeState: false,
    getConnectedInstagramAccount: false,
    connectInstagramAccount: false,
    connectSelectedFacebookPage: false,
    connectLinkedInPage: false,
  },
  error: {
    getBusinessSocialsConnectionStatus: "",
    getLinkedInCallbackURL: "",
    getFacebookCallbackURL: "",
    getTwitterCallbackURL: "",
    getTwitterV1CallbackURL: "",
    addNewNotification: "",
    sendLinkedInCodeState: "",
    sendFacebookCodeState: "",
    sendTwitterCodeState: "",
    sendTwitterV1CodeState: "",
    getConnectedInstagramAccount: "",
    connectInstagramAccount: "",
    connectSelectedFacebookPage: "",
    connectLinkedInPage: "",
  },
  message: {
    getBusinessSocialsConnectionStatus: "",
    getLinkedInCallbackURL: "",
    getFacebookCallbackURL: "",
    getTwitterCallbackURL: "",
    getTwitterV1CallbackURL: "",
    addNewNotification: "",
    sendLinkedInCodeState: "",
    sendFacebookCodeState: "",
    connectInstagramAccount: "",
    sendTwitterCodeState: "",
    sendTwitterV1CodeState: "",
    connectSelectedFacebookPage: "",
    connectLinkedInPage: "",
  },
  businessSocialsConnectionStatus: {},
  connectedInstagramAccount: [],
  callbackURL: {
    linkedIn: "",
    facebook: "",
    twitter: "",
    twitterV1: "",
  },
  facebookData: {},
  linkedInPages: [],
};

const socialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUSINESS_SOCIALS_CONNECTION_STATUS:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getBusinessSocialsConnectionStatus: true,
        },
      };
    case GET_BUSINESS_SOCIALS_CONNECTION_STATUS_SUCCESS:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getBusinessSocialsConnectionStatus: false,
        },
        businessSocialsConnectionStatus: action?.payload?.response,
        message: {
          ...state?.message,
          getBusinessSocialsConnectionStatus: action?.payload?.message,
        },
      };
    case GET_BUSINESS_SOCIALS_CONNECTION_STATUS_ERROR:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getBusinessSocialsConnectionStatus: false,
        },
        error: {
          ...state?.error,
          getBusinessSocialsConnectionStatus: action.payload,
        },
      };
    case GET_LINKEDIN_CALLBACK_URL:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getLinkedInCallbackURL: true,
        },
      };
    case GET_LINKEDIN_CALLBACK_URL_SUCCESS:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getLinkedInCallbackURL: false,
        },
        message: {
          ...state?.message,
          getLinkedInCallbackURL: action?.payload?.message,
        },
        callbackURL: {
          ...state?.callbackURL,
          linkedIn: action?.payload?.response,
        },
      };
    case GET_LINKEDIN_CALLBACK_URL_ERROR:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getLinkedInCallbackURL: false,
        },
        error: {
          ...state?.error,
          getLinkedInCallbackURL: action?.payload,
        },
      };
    case GET_FACEBOOK_CALLBACK_URL:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getFacebookCallbackURL: true,
        },
      };
    case GET_FACEBOOK_CALLBACK_URL_SUCCESS:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getFacebookCallbackURL: false,
        },
        message: {
          ...state?.message,
          getFacebookCallbackURL: action?.payload?.message,
        },
        callbackURL: {
          ...state?.callbackURL,
          facebook: action?.payload?.response,
        },
      };
    case GET_FACEBOOK_CALLBACK_URL_ERROR:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getFacebookCallbackURL: false,
        },
      };
    case ADD_NEW_NOTIFICATION:
      return {
        ...state,
        loading: {
          ...state?.loading,
          addNewNotification: true,
        },
      };
    case ADD_NEW_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: {
          ...state?.loading,
          addNewNotification: false,
        },
        message: { ...state?.message, addNewNotification: action?.payload },
      };
    case ADD_NEW_NOTIFICATION_ERROR:
      return {
        ...state,
        loading: {
          ...state?.loading,
          addNewNotification: false,
        },
        error: { ...state?.error, addNewNotification: action?.payload },
      };
    case SEND_LINKEDIN_CODE_STATE:
      return {
        ...state,
        loading: { ...state?.loading, sendLinkedInCodeState: true },
      };
    case SEND_LINKEDIN_CODE_STATE_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, sendLinkedInCodeState: false },
        message: {
          ...state?.message,
          sendLinkedInCodeState: action?.payload?.message,
        },
        linkedInPages: action?.payload?.data,
      };
    case SEND_LINKEDIN_CODE_STATE_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, sendLinkedInCodeState: false },
        error: { ...state?.error, sendLinkedInCodeState: action?.payload },
      };
    case CONNECT_LINKEDIN_PAGE:
      return {
        ...state,
        loading: { ...state?.loading, connectLinkedInPage: true },
      };
    case CONNECT_LINKEDIN_PAGE_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, connectLinkedInPage: false },
        message: { ...state?.message, connectLinkedInPage: action.payload },
      };
    case CONNECT_LINKEDIN_PAGE_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, connectLinkedInPage: false },
        error: { ...state?.error, connectLinkedInPage: action.payload },
      };
    case SEND_FACEBOOK_CODE_STATE:
      return {
        ...state,
        loading: { ...state?.loading, sendFacebookCodeState: true },
      };
    case SEND_FACEBOOK_CODE_STATE_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, sendFacebookCodeState: false },
        message: {
          ...state?.message,
          sendFacebookCodeState: action?.payload?.message,
        },
        facebookData: action?.payload?.data,
      };
    case SEND_FACEBOOK_CODE_STATE_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, sendFacebookCodeState: false },
        error: { ...state?.error, sendFacebookCodeState: action?.payload },
      };
    case CONNECT_SELECTED_FACEBOOK_PAGE:
      return {
        ...state,
        loading: { ...state?.loading, connectSelectedFacebookPage: true },
      };
    case CONNECT_SELECTED_FACEBOOK_PAGE_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, connectSelectedFacebookPage: false },
        message: {
          ...state?.message,
          connectSelectedFacebookPage: action.payload.message,
        },
      };
    case CONNECT_SELECTED_FACEBOOK_PAGE_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, connectSelectedFacebookPage: false },
        error: {
          ...state?.error,
          connectSelectedFacebookPage: action.payload,
        },
      };
    case GET_CONNECTED_INSTAGRAM_ACCOUNT:
      return {
        ...state,
        loading: { ...state?.loading, getConnectedInstagramAccount: true },
      };
    case GET_CONNECTED_INSTAGRAM_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getConnectedInstagramAccount: false },
        connectedInstagramAccount: action.payload,
      };
    case GET_CONNECTED_INSTAGRAM_ACCOUNT_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getConnectedInstagramAccount: false },
        error: {
          ...state?.error,
          getConnectedInstagramAccount: action.payload,
        },
      };
    case CONNECT_INSTAGRAM_ACCOUNT:
      return {
        ...state,
        loading: { ...state?.loading, connectInstagramAccount: true },
      };
    case CONNECT_INSTAGRAM_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, connectInstagramAccount: false },
        message: {
          ...state?.message,
          connectInstagramAccount: action?.payload,
        },
      };
    case CONNECT_INSTAGRAM_ACCOUNT_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, connectInstagramAccount: false },
        error: {
          ...state?.error,
          connectInstagramAccount: action?.payload,
        },
      };
    case GET_TWITTER_CALLBACK_URL:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getTwitterbackURL: true,
        },
      };
    case GET_TWITTER_CALLBACK_URL_SUCCESS:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getTwitterCallbackURL: false,
        },
        message: {
          ...state?.message,
          getTwitterCallbackURL: action?.payload?.message,
        },
        callbackURL: {
          ...state?.callbackURL,
          twitter: action?.payload?.response,
        },
      };
    case GET_TWITTER_CALLBACK_URL_ERROR:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getTwitterCallbackURL: false,
        },
        error: {
          ...state?.error,
          getTwitterCallbackURL: action?.payload,
        },
      };
    case SEND_TWITTER_CODE_STATE:
      return {
        ...state,
        loading: { ...state?.loading, sendTwitterCodeState: true },
      };
    case SEND_TWITTER_CODE_STATE_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, sendTwitterCodeState: false },
        message: { ...state?.message, sendTwitterCodeState: action?.payload },
      };
    case SEND_TWITTER_CODE_STATE_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, sendTwitterCodeState: false },
        error: { ...state?.error, sendTwitterCodeState: action?.payload },
      };
    case GET_TWITTER_V1_CALLBACK_URL:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getTwitterV1CallbackURL: true,
        },
      };
    case GET_TWITTER_V1_CALLBACK_URL_SUCCESS:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getTwitterV1CallbackURL: false,
        },
        message: {
          ...state?.message,
          getTwitterV1CallbackURL: action?.payload?.message,
        },
        callbackURL: {
          ...state?.callbackURL,
          twitterV1: action?.payload?.response,
        },
      };
    case GET_TWITTER_V1_CALLBACK_URL_ERROR:
      return {
        ...state,
        loading: {
          ...state?.loading,
          getTwitterV1CallbackURL: false,
        },
        error: {
          ...state?.error,
          getTwitterV1CallbackURL: action?.payload,
        },
      };
    case SEND_TWITTER_V1_CODE_STATE:
      return {
        ...state,
        loading: { ...state?.loading, sendTwitterV1CodeState: true },
      };
    case SEND_TWITTER_V1_CODE_STATE_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, sendTwitterV1CodeState: false },
        message: { ...state?.message, sendTwitterV1CodeState: action?.payload },
      };
    case SEND_TWITTER_V1_CODE_STATE_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, sendTwitterV1CodeState: false },
        message: { ...state?.message, sendTwitterV1CodeState: action?.payload },
      };
    case RESET_MESSAGE:
      return {
        ...state,
        error: {
          getBusinessSocialsConnectionStatus: "",
          getLinkedInCallbackURL: "",
          getFacebookCallbackURL: "",
          getTwitterCallbackURL: "",
          getTwitterV1CallbackURL: "",
          addNewNotification: "",
          sendLinkedInCodeState: "",
          sendFacebookCodeState: "",
          sendTwitterCodeState: "",
          sendTwitterV1CodeState: "",
          getConnectedInstagramAccount: "",
          connectInstagramAccount: "",
          connectSelectedFacebookPage: "",
          connectLinkedInPage: "",
        },
        message: {
          getBusinessSocialsConnectionStatus: "",
          getLinkedInCallbackURL: "",
          getFacebookCallbackURL: "",
          getTwitterCallbackURL: "",
          getTwitterV1CallbackURL: "",
          addNewNotification: "",
          sendLinkedInCodeState: "",
          sendFacebookCodeState: "",
          sendTwitterCodeState: "",
          sendTwitterV1CodeState: "",
          getConnectedInstagramAccount: "",
          connectInstagramAccount: "",
          connectSelectedFacebookPage: "",
          connectLinkedInPage: "",
        },
        callbackURL: {
          linkedIn: "",
          facebook: "",
          twitter: "",
          twitterV1: "",
        },
      };
    default:
      return state;
  }
};

export default socialsReducer;
