import React from "react";
import MasterCard from "../../../../assets/img/Mastercard.svg";
import Visa from "../../../../assets/img/visa-logo.svg";

const PaymentMethodCard = ({
  openAddCardModal,
  cards,
  openCancelCardModal,
}) => {
  // const cardDetails = {
  //   brand: "Mastercard",
  //   last: "1344",
  // };
  return (
    <div className="billing-card">
      <h4 className="payment-card-title mb-1">Payment Method</h4>
      <p className="payment-card-subtitle mb-2">
        You can add or remove a new card
      </p>
      {cards?.last4 ? (
        <div className="billing-info-card mb-3 d-flex align-items-center justify-content-between ">
          <div className="d-flex align-items-center">
            <img
              src={
                cards?.brand?.toLowerCase() === "mastercard" ? MasterCard : Visa
              }
              className="me-3"
              alt={`${cards?.brand}`}
            />
            <p className="card-info mb-0 semi-bold me-3">
              {cards?.brand?.toLowerCase() === "mastercard"
                ? "Master card"
                : "Visa Card"}
            </p>
            <p className="card-info mb-0 semi-bold me-3">{cards?.last4}</p>
          </div>

          <div className="">
            <button className=" btn btn-no-bg" onClick={openCancelCardModal}>
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="billing-info-card mb-3 d-flex align-items-center justify-content-between ">
          {/* <div className="d-flex align-items-center">
            <img
              src={
                cardDetails?.brand?.toLowerCase() === "mastercard"
                  ? MasterCard
                  : Visa
              }
              className="me-5"
              alt={`${cards?.brand}`}
            />
            <p className="card-info mb-0 semi-bold me-5">
              {cardDetails?.brand?.toLowerCase() === "mastercard"
                ? "Master card"
                : "Visa Card"}
            </p>
            <p className="card-info mb-0 semi-bold me-5">
              {cardDetails?.last4}
            </p>
          </div>

          <div className="">
            <button className=" btn btn-no-bg" onClick={openCancelCardModal}>
              Cancel
            </button>
          </div> */}
          <p>Not Available</p>
        </div>
      )}
      <p className="plan-description"></p>
      <div className="d-flex justify-content-end mt-4">
        <button
          className="btn btn-primary btn-border-radius"
          onClick={openAddCardModal}
        >
          Change Card
        </button>
      </div>
    </div>
  );
};

export default PaymentMethodCard;
