import { GET_ALLCLIENT, GET_ALLCLIENT_SUCCESS, GET_ALLCLIENT_ERROR,GETSOCIALDP,GETSOCIALDP_SUCCESS,GETSOCIALDP_ERROR } from "../actions";

export const getAllClient = () => ({
    type: GET_ALLCLIENT,
  });

  export const getAllClientSuccess = (clients) => ({
    type: GET_ALLCLIENT_SUCCESS,
    payload: clients
  });

  export const getAllClientError = (error) => ({
    type: GET_ALLCLIENT_ERROR,
    payload: error
  });

  //getSocialDP

export const getSocialDp = (id) => ({
    type: GETSOCIALDP,
    payload: { id }
  });

  export const getSocialDpSuccess = (data) => ({
    type: GETSOCIALDP_SUCCESS,
    payload: data
  });

  export const getSocialDpError = (error) => ({
    type: GETSOCIALDP_ERROR,
    payload: error
  });