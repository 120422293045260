import {
  GET_ALL_CONTENT_IDEAS,
  GET_ALL_CONTENT_IDEAS_ERROR,
  GET_ALL_CONTENT_IDEAS_SUCCESS,
  RESET_MESSAGE,
} from "../actions";

const intialState = {
  loading: {
    getAllContentIdeas: false,
  },
  message: {
    getAllContentIdeas: "",
  },
  error: {
    getAllContentIdeas: "",
  },
  contentIdeas: [],
};

const ContentIdeasReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_ALL_CONTENT_IDEAS:
      return {
        ...state,
        loading: { ...state?.loading, getAllContentIdeas: true },
      };
    case GET_ALL_CONTENT_IDEAS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getAllContentIdeas: false },
        contentIdeas: action?.payload,
      };
    case GET_ALL_CONTENT_IDEAS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getAllContentIdeas: false },
        error: action?.payload,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: {
          getAllContentIdeas: "",
        },
        error: {
          getAllContentIdeas: "",
        },
      };
    default:
      return state;
  }
};
export default ContentIdeasReducer;
