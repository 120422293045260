import React from "react";
import { CenteredModal } from "../../components/Modal";

const ContentIdeaDetails = (props) => {
  const { postIdea, eventState, updateEventState } = props;
  return (
    <CenteredModal
      position="centered"
      modalState={eventState}
      setModalState={updateEventState}
    >
      <div className="upcoming-event-modal">
        <div>
          <div className="event-img-wrapper ">
            <img src={postIdea?.image} className="event-img " alt="event" />
          </div>
          <div className="text-center">
            <h1 className="event-header-title mb-0">
              {postIdea?.content_idea_name}
            </h1>
            <p className="event-description">
              {postIdea?.content_idea_description}
            </p>
            <button
              className="btn btn-primary send px-5 py-2"
              style={{ whiteSpace: "nowrap" }}
              // onClick={onClick}
            >
              Create Content
            </button>
          </div>
        </div>
      </div>
    </CenteredModal>
  );
};

export default ContentIdeaDetails;
