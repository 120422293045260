import {
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_MESSAGE,
  GET_BUSINESS_DETAILS,
  GET_BUSINESS_DETAILS_SUCCESS,
  GET_BUSINESS_DETAILS_ERROR,
  UPDATE_BUSINESS_DETAILS,
  UPDATE_BUSINESS_DETAILS_SUCCESS,
  UPDATE_BUSINESS_DETAILS_ERROR,
  CHANGE_BUSINESS_LOGO,
  CHANGE_BUSINESS_LOGO_SUCCESS,
  CHANGE_BUSINESS_LOGO_ERROR,
  CHANGE_PROFILE_IMAGE,
  CHANGE_PROFILE_IMAGE_SUCCESS,
  CHANGE_PROFILE_IMAGE_ERROR,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_ERROR,
  CONNECT_PAYMENT_METHOD_ERROR,
  CONNECT_PAYMENT_METHOD_SUCCESS,
  CONNECT_PAYMENT_METHOD,
  GET_ALL_PAYMENT_METHODS_ERROR,
  GET_ALL_PAYMENT_METHODS_SUCCESS,
  GET_ALL_PAYMENT_METHODS,
  GET_INVOICE_SETTINGS,
  GET_INVOICE_SETTINGS_SUCCESS,
  GET_INVOICE_SETTINGS_ERROR,
  UPDATE_INVOICE_SETTINGS,
  UPDATE_INVOICE_SETTINGS_SUCCESS,
  UPDATE_INVOICE_SETTINGS_ERROR,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_ERROR,
} from "../actions";

const initialState = {
  loading: {
    resetPassword: false,
    getBusiness: false,
    updateBusinessDetails: false,
    changeBusinessLogo: false,
    changeProfileImage: false,
    updateUserDetails: false,
    connectPaymentMethod: false,
    getPaymentMethod: false,
    getInvoiceSettings: false,
    updateInvoiceSettings: false,
  },
  message: {
    resetPassword: "",
    getBusiness: "",
    updateBusinessDetails: "",
    changeBusinessLogo: "",
    changeProfileImage: "",
    updateUserDetails: "",
    connectPaymentMethod: "",
    getPaymentMethod: "",
    getInvoiceSettings: "",
    updateInvoiceSettings: "",
  },
  error: {
    resetPassword: "",
    getBusiness: "",
    updateBusinessDetails: "",
    changeBusinessLogo: "",
    changeProfileImage: "",
    updateUserDetails: "",
    connectPaymentMethod: "",
    getPaymentMethod: "",
    getInvoiceSettings: "",
    updateInvoiceSettings: "",
  },
  businessDetails: {},
  invoiceSettings: {},
  paymentMethods: {},
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      return {
        ...state,
        loading: { ...state?.loading, resetPassword: true },
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, resetPassword: false },
        message: { ...state?.message, resetPassword: action.payload },
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, resetPassword: false },
        error: { ...state?.message, resetPassword: action.payload.error },
      };

    case GET_BUSINESS_DETAILS:
      return {
        ...state,
        loading: { ...state?.loading, getBusiness: true },
      };
    case GET_BUSINESS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getBusiness: false },
        businessDetails: action?.payload,
      };
    case GET_BUSINESS_DETAILS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getBusiness: false },
        error: { ...state?.error, getBusiness: action.payload },
      };
    case UPDATE_BUSINESS_DETAILS:
      return {
        ...state,
        loading: { ...state?.loading, updateBusinessDetails: true },
      };
    case UPDATE_BUSINESS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, updateBusinessDetails: false },
        message: { ...state?.message, updateBusinessDetails: action.payload },
      };
    case UPDATE_BUSINESS_DETAILS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, updateBusinessDetails: false },
        error: { ...state?.error, updateBusinessDetails: action.payload },
      };
    case CHANGE_BUSINESS_LOGO:
      return {
        ...state,
        loading: { ...state?.loading, changeBusinessLogo: true },
      };
    case CHANGE_BUSINESS_LOGO_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, changeBusinessLogo: false },
        message: { ...state?.message, changeBusinessLogo: action.payload },
      };
    case CHANGE_BUSINESS_LOGO_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, changeBusinessLogo: false },
        error: { ...state?.error, changeBusinessLogo: action.payload },
      };
    case CHANGE_PROFILE_IMAGE:
      return {
        ...state,
        loading: { ...state?.loading, changeProfileImage: true },
      };
    case CHANGE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, changeProfileImage: false },
        message: { ...state?.message, changeProfileImage: action.payload },
      };
    case CHANGE_PROFILE_IMAGE_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, changeProfileImage: false },
        error: { ...state?.error, changeProfileImage: action.payload },
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        loading: { ...state?.loading, updateUserDetails: true },
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, updateUserDetails: false },
        message: { ...state?.message, updateUserDetails: action.payload },
      };
    case UPDATE_USER_DETAILS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, updateUserDetails: false },
        error: { ...state?.error, updateUserDetails: action.payload },
      };
    case GET_INVOICE_SETTINGS:
      return {
        ...state,
        loading: { ...state?.loading, getInvoiceSettings: true },
      };
    case GET_INVOICE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getInvoiceSettings: false },
        invoiceSettings: action.payload,
      };
    case GET_INVOICE_SETTINGS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getInvoiceSettings: false },
        error: { ...state?.error, getInvoiceSettings: false },
        getInvoiceSettingError: action.payload,
      };
    case UPDATE_INVOICE_SETTINGS:
      return {
        ...state,
        loading: { ...state?.loading, updateInvoiceSettings: true },
      };
    case UPDATE_INVOICE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, updateInvoiceSettings: false },
        message: { ...state?.message, updateInvoiceSettings: action.payload },
      };
    case UPDATE_INVOICE_SETTINGS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, updateInvoiceSettings: false },
        error: { ...state?.error, updateInvoiceSettings: action.payload },
      };

    case GET_ALL_PAYMENT_METHODS:
      return {
        ...state,
        loading: { ...state?.loading, getPaymentMethod: true },
      };
    case GET_ALL_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getPaymentMethod: true },
        paymentMethods: action?.payload,
      };
    case GET_ALL_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getPaymentMethod: false },
        error: { ...state?.error, getPaymentMethod: action.payload },
      };
    case CONNECT_PAYMENT_METHOD:
      return {
        ...state,
        loading: { ...state?.loading, connectPaymentMethod: true },
      };
    case CONNECT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, connectPaymentMethod: false },
        message: {
          ...state?.message,
          connectPaymentMethod: "Payment Method Connected Successfully",
        },
        paymentMethods: [...state?.paymentMethods, action?.payload],
      };
    case CONNECT_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, connectPaymentMethod: false },
        message: {
          ...state?.message,
          connectPaymentMethod:
            action.payload || "Payment Method failed to connect",
        },
      };
    case UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        loading: { ...state?.loading, updatePaymentMethod: true },
      };
    case UPDATE_PAYMENT_METHOD_SUCCESS:
      const newPaymentMethods = [...state.paymentMethods];
      const oldMethodIndex = newPaymentMethods.findIndex(
        (meth) => meth.id === action?.payload?.id
      );
      if (oldMethodIndex !== -1) {
        newPaymentMethods.splice(oldMethodIndex, 1, action.payload);
      }

      return {
        ...state,
        loading: { ...state?.loading, updatePaymentMethod: false },
        message: {
          ...state?.message,
          updatePaymentMethod: "Payment Method Updated Successfully",
        },
        paymentMethods: newPaymentMethods,
      };

    case UPDATE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, updatePaymentMethod: false },
        message: { ...state?.message, updatePaymentMethod: action.payload },
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: {
          resetPassword: "",
          getBusiness: "",
          updateBusinessDetails: "",
          changeBusinessLogo: "",
          changeProfileImage: "",
          updateUserDetails: "",
          connectPaymentMethod: "",
          getPaymentMethod: "",
          getInvoiceSettings: "",
          updateInvoiceSettings: "",
        },
        error: {
          resetPassword: "",
          getBusiness: "",
          updateBusinessDetails: "",
          changeBusinessLogo: "",
          changeProfileImage: "",
          updateUserDetails: "",
          connectPaymentMethod: "",
          getPaymentMethod: "",
          getInvoiceSettings: "",
          updateInvoiceSettings: "",
        },
      };
    default:
      return state;
  }
};

export default settingsReducer;
