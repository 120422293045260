/*NAV*/
export const SET_CURRENT_SECTION = "SET_CURRENT_SECTION";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOG_USER_IN = "LOG_USER_IN";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const SEND_VERIFICATION_EMAIL = "SEND_VERIFICATION_EMAIL";
export const SEND_VERIFICATION_EMAIL_SUCCESS =
  "SEND_VERIFICATION_EMAIL_SUCCESS";
export const SEND_VERIFICATION_EMAIL_ERROR = "SEND_VERIFICATION_EMAIL_ERROR";

export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";

//dashboard
export const GET_DASHBOARD_METRICS = "GET_DASHBOARD_METRICS";
export const GET_DASHBOARD_METRICS_SUCCESS = "GET_DASHBOARD_METRICS_SUCCESS";
export const GET_DASHBOARD_METRICS_ERROR = "GET_DASHBOARD_METRICS_ERROR";
export const GET_DASHBOARD_REVENUE = "GET_DASHBOARD_REVENUE";
export const GET_DASHBOARD_REVENUE_SUCCESS = "GET_DASHBOARD_REVENUE_SUCCESS";
export const GET_DASHBOARD_REVENUE_ERROR = "GET_DASHBOARD_REVENUE_ERROR";
export const GET_DASHBOARD_SCHEDULED_POSTS = "GET_DASHBOARD_SCHEDULED_POSTS";
export const GET_DASHBOARD_SCHEDULED_POSTS_SUCCESS =
  "GET_DASHBOARD_SCHEDULED_POSTS_SUCCESS";
export const GET_DASHBOARD_SCHEDULED_POSTS_ERROR =
  "GET_DASHBOARD_SCHEDULED_POSTS_ERROR";
export const GET_DASHBOARD_POST_IDEAS = "GET_DASHBOARD_POST_IDEAS";
export const GET_DASHBOARD_POST_IDEAS_SUCCESS =
  "GET_DASHBOARD_POST_IDEAS_SUCCESS";
export const GET_DASHBOARD_POST_IDEAS_ERROR = "GET_DASHBOARD_POST_IDEAS_ERROR";
//Reset State
export const RESET_MESSAGE = "RESET_MESSAGE";

/*Client*/
export const CREATE_CLIENT = "CREATE_CLIENT";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_ERROR = "CREATE_CLIENT_ERROR";

export const GET_CLIENT = "GET_CLIENT";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_ERROR = "GET_CLIENT_ERROR";

export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS";
export const GET_CLIENT_DETAILS_SUCCESS = "GET_CLIENT_DETAILS_SUCCESS";
export const GET_CLIENT_DETAILS_ERROR = "GET_CLIENT_DETAILS_ERROR";

export const INVITE_CLIENT = "INVITE_CLIENT";
export const INVITE_CLIENT_SUCCESS = "INVITE_CLIENT_SUCCESS";
export const INVITE_CLIENT_ERROR = "INVITE_CLIENT_ERROR";
export const CREATE_TAG = "CREATE_TAG";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_ERROR = "CREATE_TAG_ERROR";
export const GET_TAG = "GET_TAG";
export const GET_TAG_SUCCESS = "GET_TAG_SUCCESS";
export const GET_TAG_ERROR = "GET_TAG_ERROR";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR";
export const UPDATE_CLIENT_DETAILS = "UPDATE_CLIENT_DETAILS";
export const UPDATE_CLIENT_DETAILS_SUCCESS = "UPDATE_CLIENT_DETAILS_SUCCESS";
export const UPDATE_CLIENT_DETAILS_ERROR = "UPDATE_CLIENT_DETAILS_ERROR";
export const GET_CLIENT_INVOICE = "GET_CLIENT_INVOICE";
export const GET_CLIENT_INVOICE_SUCCESS = "GET_CLIENT_INVOICE_SUCCESS";
export const GET_CLIENT_INVOICE_ERROR = "GET_CLIENT_INVOICE_ERROR";
export const GET_CLIENT_SCHEDULED_POSTS = "GET_CLIENT_SCHEDULED_POSTS";
export const GET_CLIENT_SCHEDULED_POSTS_SUCCESS =
  "GET_CLIENT_SCHEDULED_POSTS_SUCCESS";
export const GET_CLIENT_SCHEDULED_POSTS_ERROR =
  "GET_CLIENT_SCHEDULED_POSTS_ERROR";

export const GET_CLIENT_SOCIALS_CONNECTION_STATUS =
  "GET_CLIENT_SOCIALS_CONNECTION_STATUS";
export const GET_CLIENT_SOCIALS_CONNECTION_STATUS_SUCCESS =
  "GET_CLIENT_SOCIALS_CONNECTION_STATUS_SUCCESS";
export const GET_CLIENT_SOCIALS_CONNECTION_STATUS_ERROR =
  "GET_CLIENT_SOCIALS_CONNECTION_STATUS_ERROR";
//Setttings (General)
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const UPDATE_BUSINESS_DETAILS = "UPDATE_BUSINESS_DETAILS";
export const GET_BUSINESS_DETAILS = "GET_BUSINESS_DETAILS";
export const GET_BUSINESS_DETAILS_SUCCESS = "GET_BUSINESS_DETAILS_SUCCESS";
export const GET_BUSINESS_DETAILS_ERROR = "GET_BUSINESS_DETAILS_ERROR";
export const UPDATE_BUSINESS_DETAILS_SUCCESS =
  "UPDATE_BUSINESS_DETAILS_SUCCESS";
export const UPDATE_BUSINESS_DETAILS_ERROR = "UPDATE_BUSINESS_DETAILS_ERROR";
export const CHANGE_BUSINESS_LOGO = "CHANGE_BUSINESS_LOGO";
export const CHANGE_BUSINESS_LOGO_SUCCESS = "CHANGE_BUSINESS_LOGO_SUCCESS";
export const CHANGE_BUSINESS_LOGO_ERROR = "CHANGE_BUSINESS_LOGO_ERROR";
export const CHANGE_PROFILE_IMAGE = "CHANGE_PROFILE_IMAGE";
export const CHANGE_PROFILE_IMAGE_SUCCESS = "CHANGE_PROFILE_IMAGE_SUCCESS";
export const CHANGE_PROFILE_IMAGE_ERROR = "CHANGE_PROFILE_IMAGE_ERROR";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_ERROR = "UPDATE_USER_DETAILS_ERROR";
// Payment methods
export const GET_ALL_PAYMENT_METHODS = "GET_ALL_PAYMENT_METHOD";
export const GET_ALL_PAYMENT_METHODS_SUCCESS = "GET_ALL_PAYMENT_METHOD_SUCCESS";
export const GET_ALL_PAYMENT_METHODS_ERROR = "GET_ALL_PAYMENT_METHOD_ERROR";
export const CONNECT_PAYMENT_METHOD = "CONNECT_PAYMENT_METHOD";
export const CONNECT_PAYMENT_METHOD_SUCCESS = "CONNECT_PAYMENT_METHOD_SUCCESS";
export const CONNECT_PAYMENT_METHOD_ERROR = "CONNECT_PAYMENT_METHOD_ERROR";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_ERROR = "UPDATE_PAYMENT_METHOD_ERROR";

//Invoices
export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_ERROR = "GET_INVOICES_ERROR";
export const CREATE_NEW_INVOICE = "CREATE_NEW_INVOICE";
export const CREATE_NEW_INVOICE_SUCCESS = "CREATE_NEW_INVOICE_SUCCESS";
export const CREATE_NEW_INVOICE_ERROR = "CREATE_NEW_INVOICE_ERROR";
export const REQUEST_PAYOUT = "REQUEST_PAYOUT";
export const REQUEST_PAYOUT_SUCCESS = "REQUEST_PAYOUT_SUCCESS";
export const REQUEST_PAYOUT_ERROR = "REQUEST_PAYOUT_ERROR";
export const GET_INVOICES_SUMMARY = "GET_INVOICES_SUMMARY";
export const GET_INVOICES_SUMMARY_SUCCESS = "GET_INVOICES_SUMMARY_SUCCESS";
export const GET_INVOICES_SUMMARY_ERROR = "GET_INVOICES_SUMMARY_ERROR";

//One Invoice Action
export const GET_ONE_INVOICE = "GET_ONE_INVOICE";
export const GET_ONE_INVOICE_SUCCESS = "GET_ONE_INVOICE_SUCCESS";
export const GET_ONE_INVOICE_ERROR = "GET_ONE_INVOICE_ERROR";
export const GET_ONE_INVOICE_SETTINGS = "GET_ONE_INVOICE_SETTINGS";
export const GET_ONE_INVOICE_SETTINGS_SUCCESS =
  "GET_ONE_INVOICE_SETTINGS_SUCCESS";
export const GET_ONE_INVOICE_SETTINGS_ERROR = "GET_ONE_INVOICE_SETTINGS_ERROR";
export const UPDATE_ONE_INVOICE_SETTINGS = "UPDATE_ONE_INVOICE_SETTINGS";
export const UPDATE_ONE_INVOICE_SETTINGS_SUCCESS =
  "UPDATE_ONE_INVOICE_SETTINGS_SUCCESS";
export const UPDATE_ONE_INVOICE_SETTINGS_ERROR =
  "UPDATE_ONE_INVOICE_SETTINGS_ERROR";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_ERROR = "DELETE_INVOICE_ERROR";
export const RECORD_ONE_INVOICE_PAYMENT = "RECORD_ONE_INVOICE_PAYMENT";
export const RECORD_ONE_INVOICE_PAYMENT_SUCCESS =
  "RECORD_ONE_INVOICE_PAYMENT_SUCCESS";
export const RECORD_ONE_INVOICE_PAYMENT_ERROR =
  "RECORD_ONE_INVOICE_PAYMENT_ERROR";
export const DUPLICATE_ONE_INVOICE = "DUPLICATE_ONE_INVOICE";
export const DUPLICATE_ONE_INVOICE_SUCCESS = "DUPLICATE_ONE_INVOICE_SUCCESS";
export const DUPLICATE_ONE_INVOICE_ERROR = "DUPLICATE_ONE_INVOICE_ERROR";
export const GET_PAYOUT_REQUESTS = "GET_PAYOUT_REQUESTS";
export const GET_PAYOUT_REQUESTS_SUCCESS = "GET_PAYOUT_REQUESTS_SUCCESS";
export const GET_PAYOUT_REQUESTS_ERROR = "GET_PAYOUT_REQUESTS_ERROR";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_ERROR = "UPDATE_INVOICE_ERROR";
export const SEND_INVOICE = "SEND_INVOICE";
export const SEND_INVOICE_SUCCESS = "SEND_INVOICE_SUCCESS";
export const SEND_INVOICE_ERROR = "SEND_INVOICE_ERROR";
export const SEND_RECEIPT = "SEND_RECEIPT";
export const SEND_RECEIPT_SUCCESS = "SEND_RECEIPT_SUCCESS";
export const SEND_RECEIPT_ERROR = "SEND_RECEIPT_ERROR";

//Invoice payment page
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR";
export const GET_BUSINESS_PAYMENT_GATEWAYS = "GET_BUSINESS_PAYMENT_GATEWAYS";
export const GET_BUSINESS_PAYMENT_GATEWAYS_SUCCESS =
  "GET_BUSINESS_PAYMENT_GATEWAYS_SUCCESS";
export const GET_BUSINESS_PAYMENT_GATEWAYS_ERROR =
  "GET_BUSINESS_PAYMENT_GATEWAYS_ERROR";

//Schedule

export const GET_ALLCLIENT = "GET_ALLCLIENT";
export const GET_ALLCLIENT_SUCCESS = "GET_ALLCLIENT_SUCCESS";
export const GET_ALLCLIENT_ERROR = "GET_ALLCLIENT_ERROR";

export const GET_ONECLIENTPOST = "GET_ONECLIENTPOST";
export const GET_ONECLIENTPOST_SUCCESS = "GET_ONECLIENTPOST_SUCCESS";
export const GET_ONECLIENTPOST_ERROR = "GET_ONECLIENTPOST_ERROR";

export const DELETE_FACEBOOK_POST = "DELETE_FACEBOOK_POST";
export const DELETE_FACEBOOK_POST_SUCCESS = "DELETE_FACEBOOK_POST_SUCCESS";
export const DELETE_FACEBOOK_POST_ERROR = "DELETE_FACEBOOK_POST_ERROR";

export const DELETE_INSTAGRAM_POST = "DELETE_INSTAGRAM_POST";
export const DELETE_INSTAGRAM_POST_SUCCESS = "DELETE_INSTAGRAM_POST_SUCCESS";
export const DELETE_INSTAGRAM_POST_ERROR = "DELETE_INSTAGRAM_POST_ERROR";

export const DELETE_LINKEDIN_POST = "DELETE_LINKEDIN_POST";
export const DELETE_LINKEDIN_POST_SUCCESS = "DELETE_LINKEDIN_POST_SUCCESS";
export const DELETE_LINKEDIN_POST_ERROR = "DELETE_LINKEDIN_POST_ERROR";

export const DELETE_TWITTER_POST = "DELETE_TWITTER_POST";
export const DELETE_TWITTER_POST_SUCCESS = "DELETE_TWITTER_POST_SUCCESS";
export const DELETE_TWITTER_POST_ERROR = "DELETE_TWITTER_POST_ERROR";
//schedulePost

export const SCHEDULEPOST = "SCHEDULEPOST";
export const SCHEDULEPOST_SUCCESS = "SCHEDULEPOST_SUCCESS";
export const SCHEDULEPOST_ERROR = "SCHEDULEPOST_ERROR";

//SocilaDp

export const GETSOCIALDP = "GETSOCIALDP";
export const GETSOCIALDP_SUCCESS = "GETSOCIALDP_SUCCESS";
export const GETSOCIALDP_ERROR = "GETSOCIALDP_ERROR";

//Expenses
export const GET_EXPENSES = "GET_EXPENSES";
export const GET_EXPENSES_SUCCESS = "GET_EXPENSES_SUCCESS";
export const GET_EXPENSES_ERROR = "GET_EXPENSES_ERROR";
export const CREATE_NEW_EXPENSE = "CREATE_NEW_EXPENSE";
export const CREATE_NEW_EXPENSE_SUCCESS = "CREATE_NEW_EXPENSE_SUCCESS";
export const CREATE_NEW_EXPENSE_ERROR = "CREATE_NEW_EXPENSE_ERROR";
export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS";
export const DELETE_EXPENSE_ERROR = "DELETE_EXPENSE_ERROR";

//Vendors
export const GET_ALL_VENDORS = "GET_ALL_VENDORS";
export const GET_ALL_VENDORS_SUCCESS = "GET_ALL_VENDORS_SUCCESS";
export const GET_ALL_VENDORS_ERROR = "GET_ALL_VENDORS_ERROR";
export const CREATE_NEW_VENDOR = "CREATE_NEW_VENDOR";
export const CREATE_NEW_VENDOR_SUCCESS = "CREATE_NEW_VENDOR_SUCCESS";
export const CREATE_NEW_VENDOR_ERROR = "CREATE_NEW_VENDOR_ERROR";

//Subscription
//get current subcription
export const GET_CURRENT_SUBSCRIPTION = "GET_CURRENT_SUBSCRIPTION";
export const GET_CURRENT_SUBSCRIPTION_SUCCESS =
  "GET_CURRENT_SUBSCRIPTION_SUCCESS";
export const GET_CURRENT_SUBSCRIPTION_ERROR = "GET_CURRENT_SUBSCRIPTION_ERROR";

//get all subscriptions
export const GET_ALL_SUBSCRIPTIONS = "GET_ALL_SUBSCRIPTIONS";
export const GET_ALL_SUBSCRIPTIONS_SUCCESS = "GET_ALL_SUBSCRIPTIONS_SUCCESS";
export const GET_ALL_SUBSCRIPTIONS_ERROR = "GET_ALL_SUBSCRIPTIONS_ERROR";

//get all cards
export const GET_ALL_CARDS = "GET_ALL_CARDS";
export const GET_ALL_CARDS_SUCCESS = "GET_ALL_CARDS_SUCCESS";
export const GET_ALL_CARDS_ERROR = "GET_ALL_CARDS_ERROR";

//remove cards
export const REMOVE_CARD = "REMOVE_CARD";
export const REMOVE_CARD_SUCCESS = "REMOVE_CARD_SUCCESS";
export const REMOVE_CARD_ERROR = "REMOVE_CARD_ERROR";

//initiate subcription
export const INITIATE_SUBSCRIPTION = "INITIATE_SUBSCRIPTION";
export const INITIATE_SUBSCRIPTION_SUCCESS = "INITIATE_SUBSCRIPTION_SUCCESS";
export const INITIATE_SUBSCRIPTION_ERROR = "INITIATE_SUBSCRIPTION_ERROR";

//change subscription plan
export const CHANGE_SUBSCRIPTION_PLAN = "CHANGE_SUBSCRIPTION_PLAN";
export const CHANGE_SUBSCRIPTION_PLAN_SUCCESS =
  "CHANGE_SUBSCRIPTION_PLAN_SUCCESS";
export const CHANGE_SUBSCRIPTION_PLAN_ERROR = "CHANGE_SUBSCRIPTION_PLAN_ERROR";

//get subscripton details
export const GET_SUBSCRIPTION_DETAILS = "GET_SUBSCRIPTION_DETAILS";
export const GET_SUBSCRIPTION_DETAILS_SUCCESS =
  "GET_SUBSCRIPTION_DETAILS_SUCCESS";
export const GET_SUBSCRIPTION_DETAILS_ERROR = "GET_SUBSCRIPTION_DETAILS_ERROR";

//cancel subscription plan
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_ERROR = "CANCEL_SUBSCRIPTION_ERROR";

//socials
export const GET_BUSINESS_SOCIALS_CONNECTION_STATUS =
  "GET_BUSINESS_SOCIALS_CONNECTION_STATUS";
export const GET_BUSINESS_SOCIALS_CONNECTION_STATUS_SUCCESS =
  "GET_BUSINESS_SOCIALS_CONNECTION_STATUS_SUCCESS";
export const GET_BUSINESS_SOCIALS_CONNECTION_STATUS_ERROR =
  "GET_BUSINESS_SOCIALS_CONNECTION_STATUS_ERROR";
//linkedin
export const GET_LINKEDIN_CALLBACK_URL = "GET_LINKEDIN_CALLBACK_URL";
export const GET_LINKEDIN_CALLBACK_URL_SUCCESS =
  "GET_LINKEDIN_CALLBACK_URL_SUCCESS";
export const GET_LINKEDIN_CALLBACK_URL_ERROR =
  "GET_LINKEDIN_CALLBACK_URL_ERROR";
export const SEND_LINKEDIN_CODE_STATE = "SEND_LINKEDIN_CODE_STATE";
export const SEND_LINKEDIN_CODE_STATE_SUCCESS =
  "SEND_LINKEDIN_CODE_STATE_SUCCESS";
export const SEND_LINKEDIN_CODE_STATE_ERROR = "SEND_LINKEDIN_CODE_STATE_ERROR";
export const CONNECT_LINKEDIN_PAGE = "CONNECT_LINKEDIN_PAGE";
export const CONNECT_LINKEDIN_PAGE_SUCCESS = "CONNECT_LINKEDIN_PAGE_SUCCESS";
export const CONNECT_LINKEDIN_PAGE_ERROR = "CONNECT_LINKEDIN_PAGE_ERROR";

//Twitter
//V2
export const GET_TWITTER_CALLBACK_URL = "GET_TWITTER_CALLBACK_URL";
export const GET_TWITTER_CALLBACK_URL_SUCCESS =
  "GET_TWITTER_CALLBACK_URL_SUCCESS";
export const GET_TWITTER_CALLBACK_URL_ERROR = "GET_TWITTER_CALLBACK_URL_ERROR";
export const SEND_TWITTER_CODE_STATE = "SEND_TWITTER_CODE_STATE";
export const SEND_TWITTER_CODE_STATE_SUCCESS =
  "SEND_TWITTER_CODE_STATE_SUCCESS";
export const SEND_TWITTER_CODE_STATE_ERROR = "SEND_TWITTER_CODE_STATE_ERROR";
//v1
export const GET_TWITTER_V1_CALLBACK_URL = "GET_TWITTER_V1_CALLBACK_URL";
export const GET_TWITTER_V1_CALLBACK_URL_SUCCESS =
  "GET_TWITTER_V1_CALLBACK_URL_SUCCESS";
export const GET_TWITTER_V1_CALLBACK_URL_ERROR =
  "GET_TWITTER_V1_CALLBACK_URL_ERROR";
export const SEND_TWITTER_V1_CODE_STATE = "SEND_TWITTER_V1_CODE_STATE";
export const SEND_TWITTER_V1_CODE_STATE_SUCCESS =
  "SEND_TWITTER_V1_CODE_STATE_SUCCESS";
export const SEND_TWITTER_V1_CODE_STATE_ERROR =
  "SEND_TWITTER_V1_CODE_STATE_ERROR";

//Facebook
export const GET_FACEBOOK_CALLBACK_URL = "GET_FACEBOOK_CALLBACK_URL";
export const GET_FACEBOOK_CALLBACK_URL_SUCCESS =
  "GET_FACEBOOK_CALLBACK_URL_SUCCESS";
export const GET_FACEBOOK_CALLBACK_URL_ERROR =
  "GET_FACEBOOK_CALLBACK_URL_ERROR";
export const SEND_FACEBOOK_CODE_STATE = "SEND_FACEBOOK_CODE_STATE";
export const SEND_FACEBOOK_CODE_STATE_SUCCESS =
  "SEND_FACEBOOK_CODE_STATE_SUCCESS";
export const SEND_FACEBOOK_CODE_STATE_ERROR = "SEND_FACEBOOK_CODE_STATE_ERROR";
export const CONNECT_SELECTED_FACEBOOK_PAGE = "CONNECT_SELECTED_FACEBOOK_PAGE";
export const CONNECT_SELECTED_FACEBOOK_PAGE_SUCCESS =
  "CONNECT_SELECTED_FACEBOOK_PAGE_SUCCESS";
export const CONNECT_SELECTED_FACEBOOK_PAGE_ERROR =
  "CONNECT_SELECTED_FACEBOOK_PAGE_ERROR";

export const GET_CONNECTED_INSTAGRAM_ACCOUNT =
  "GET_CONNECTED_INSTAGRAM_ACCOUNT";
export const GET_CONNECTED_INSTAGRAM_ACCOUNT_SUCCESS =
  "GET_CONNECTED_INSTAGRAM_ACCOUNT_SUCCESS";
export const GET_CONNECTED_INSTAGRAM_ACCOUNT_ERROR =
  "GET_CONNECTED_INSTAGRAM_ACCOUNT_ERROR";

export const CONNECT_INSTAGRAM_ACCOUNT = "CONNECT_INSTAGRAM_ACCOUNT";
export const CONNECT_INSTAGRAM_ACCOUNT_SUCCESS =
  "CONNECT_INSTAGRAM_ACCOUNT_SUCCESS";
export const CONNECT_INSTAGRAM_ACCOUNT_ERROR =
  "CONNECT_INSTAGRAM_ACCOUNT_ERROR";

export const ADD_NEW_NOTIFICATION = "ADD_NEW_NOTIFICATION";
export const ADD_NEW_NOTIFICATION_SUCCESS = "ADD_NEW_NOTIFICATION_SUCCESS";
export const ADD_NEW_NOTIFICATION_ERROR = "ADD_NEW_NOTIFICATION_ERROR";

export const GET_ALL_TEAM_MEMBERS = "GET_ALL_TEAM_MEMBERS";
export const GET_ALL_TEAM_MEMBERS_SUCCESS = "GET_ALL_TEAM_MEMBERS_SUCCESS";
export const GET_ALL_TEAM_MEMBERS_ERROR = "GET_ALL_TEAM_MEMBERS_ERROR";
export const ADD_NEW_TEAM_MEMBER = "ADD_NEW_TEAM_MEMBER";
export const ADD_NEW_TEAM_MEMBER_SUCCESS = "ADD_NEW_TEAM_MEMBER_SUCCESS";
export const ADD_NEW_TEAM_MEMBER_ERROR = "ADD_NEW_TEAM_MEMBER_ERROR";
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER";
export const DELETE_TEAM_MEMBER_SUCCESS = "DELETE_TEAM_MEMBER_SUCCESS";
export const DELETE_TEAM_MEMBER_ERROR = "DELETE_TEAM_MEMBER_ERROR";

//invoice settings
export const GET_INVOICE_SETTINGS = "GET_INVOICE_SETTINGS";
export const GET_INVOICE_SETTINGS_SUCCESS = "GET_INVOICE_SETTINGS_SUCCESS";
export const GET_INVOICE_SETTINGS_ERROR = "GET_INVOICE_SETTINGS_ERROR";
export const UPDATE_INVOICE_SETTINGS = "UPDATE_INVOICE_SETTINGS";
export const UPDATE_INVOICE_SETTINGS_SUCCESS =
  "UPDATE_INVOICE_SETTINGS_SUCCESS";
export const UPDATE_INVOICE_SETTINGS_ERROR = "UPDATE_INVOICE_SETTINGS_ERROR";

//content ideas
export const GET_ALL_CONTENT_IDEAS = "GET_ALL_CONTENT_IDEAS";
export const GET_ALL_CONTENT_IDEAS_SUCCESS = "GET_ALL_CONTENT_IDEAS_SUCCESS";
export const GET_ALL_CONTENT_IDEAS_ERROR = "GET_ALL_CONTENT_IDEAS_ERROR";

export * from "./Auth/action";
export * from "./Nav/action";
export * from "./Client/action";
export * from "./Settings/action";
export * from "./General/action";
export * from "./Invoices/action";
export * from "./Expenses/action";
export * from "./Invoices/OneInvoice/action";
export * from "./Expenses/Vendor/action";
export * from "./Schedule/action";
export * from "./Schedule/oneClientPost/action";
export * from "./Schedule/schedulePost/action";
export * from "./Subscription/action";
export * from "./Socials/action";
export * from "./Teams/action";
export * from "./Dashboard/action";
export * from "./ContentIdeas/action";
