import React,{useState} from 'react'
// import "react-datepicker/dist/react-datepicker.css";
import Button from '../../../../components/Button';
import PostHeader from '../PostHeader';
import 'react-clock/dist/Clock.css';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';


const PostDetails = ({next}) => {
  const [value, onChange] = useState();

  return (
    <div className='postDetails'>
      <PostHeader title="Post Details"/>
          <DateTimePicker dayPlaceholder='dd'  monthPlaceholder='mm' yearPlaceholder='yy' hourPlaceholder='hh' minutePlaceholder='mm' onChange={onChange} value={value} clearIcon={null} calendarIcon={null} disableClock={true} minDate={new Date()} className='scheduleDateInput'/><br/><br/>
          <Button text="Next" onButtonClick={()=>next(value)} disabled={!value}/>
    </div>
  )
}

export default PostDetails