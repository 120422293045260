import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import Axios from "../../utils/Axios.js";

import {
  CREATE_CLIENT,
  INVITE_CLIENT,
  DELETE_CLIENT,
  CREATE_TAG,
  GET_TAG,
  GET_CLIENT,
  GET_CLIENT_DETAILS,
  GET_CLIENT_INVOICE,
  UPDATE_CLIENT_DETAILS,
  GET_CLIENT_SCHEDULED_POSTS,
  GET_CLIENT_SOCIALS_CONNECTION_STATUS,
  createTagSuccess,
  inviteClientSuccess,
  inviteClientError,
  deleteClientSuccess,
  deleteClientError,
  createTagError,
  getTagSuccess,
  getTagError,
  getClientSuccess,
  getClientError,
  createClientSuccess,
  createClientError,
  getClientDetailsSuccess,
  getClientDetailsError,
  getClientInvoicesSuccess,
  getClientInvoicesError,
  updateClientDetailsSuccess,
  updateClientDetailsError,
  getClientScheduledPostsSuccess,
  getClientScheduledPostsError,
  getClientSocialConnectionStatusSuccess,
  getClientSocialConnectionStatusError,
} from "../actions";

function* getClient() {

  try {
    const response = yield Axios.get(`/business/api/v1/manage-client/`);
    if (response?.status === 200) {
      yield put(getClientSuccess(response?.data));
      // window.location.reload();
    } else {
      yield put(getClientError(response.data.message));
    }
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data?.detail;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }

    yield put(getTagError(message));
  }
}

function* getClientDetails() {
  try {
    const response = yield Axios.get(`/business/api/v1/manage-client/`);
    yield put(getClientDetailsSuccess(response?.data));
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data?.detail;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getClientDetailsError(message));
  }
}

function* createClient({ payload }) {
  const { clientDetails } = payload;
  try {
    const response = yield Axios.post(
      `/business/api/v1/client/no-invite/`,
      clientDetails
    );
    if (response?.status === 201) {
      yield put(createClientSuccess("Client Manually Added Successfully"));
      //
      yield call(getClient);
    } else {
      yield put(createClientError(response?.data?.message));
    }
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data?.detail
        ? error.response.data.detail
        : error.response.data?.fullname
        ? error.response.data?.fullname[0]
        : error.response.data?.country
        ? error.response.data?.country[0]
        : error.response.data?.password
        ? error.response.data?.password[0]
        : error.response.data?.email
        ? error.response.data?.email[0]
        : error.response.data;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(createClientError(message));
  }
}

function* inviteClient({ payload }) {
  const { clientDetails } = payload;
  try {
    const response = yield Axios.post(
      `/business/api/v1/invite/`,
      clientDetails
    );

    if (response?.status === 201) {
      yield put(inviteClientSuccess(response?.data?.msg));
      yield call(getClient);
    } else {
      yield put(inviteClientError(response.data.message));
    }
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data?.detail
        ? error.response.data.detail
        : error.response.data?.fullname
        ? error.response.data?.fullname[0]
        : error.response.data?.country
        ? error.response.data?.country[0]
        : error.response.data?.password
        ? error.response.data?.password[0]
        : error.response.data?.email
        ? error.response.data?.email[0]
        : error.response.data;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(inviteClientError(message));
  }
}

function* getTag() {
  try {
    const response = yield Axios.get(`/client/tag/user`, {
      //   auth: {
      //     Username: 'damitee868@gmail.com',
      //     Password: 'H1de&seek'
      //     // 'authorization': `Basic ${token}`
      //   }
    });
    if (response.status === 200) {
      yield put(getTagSuccess(response.data));
      // window.location.reload();
    } else {
      yield put(getTagError(response.data.message));
    }
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data?.detail;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getTagError(message));
  }
}

function* createTag({ payload }) {
  const { tag } = payload;

  try {
    const response = yield Axios.post(`/client/tags/`, tag);
    if (response.status === 201) {
      yield put(createTagSuccess("Tag Created"));
      yield call(getTag);
      // window.relocation.reload();
    } else {
      yield put(createTagError(response.data.message));
    }
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data?.detail;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(createTagError(message));
  }
}

function* deleteClient({ payload }) {
  try {
    const response = yield Axios.delete(`/business/api/v1/manage-client/`, {
      data: { id: payload },
    });
    if (response?.status === 204) {
      yield put(deleteClientSuccess());
      yield call(getClient);
    } else {
      yield put(deleteClientError(response?.data?.message));
    }
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(deleteClientError(message));
  }
}
function* GetClientInvoices({ payload }) {
  try {
    const response = yield Axios.get(
      `/invoice/api/v1/invoice/client/${payload}/`
    );
    if (response?.status === 200) {
      yield put(getClientInvoicesSuccess(response?.data));
      // window.location.reload();
    } else {
      yield put(getClientInvoicesError(response.data.message));
    }
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data?.detail;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }

    yield put(getClientInvoicesError(message));
  }
}
function* UpdateClientDetails({ payload }) {
  try {
    const response = yield Axios.patch(
      `/business/api/v1/manage-client/`,
      payload
    );
    if (response?.status === 200) {
      yield put(updateClientDetailsSuccess(response?.data?.msg));
      yield call(getClient);
    } else {
      yield put(updateClientDetailsError(response.data.message));
    }
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data?.detail;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(updateClientDetailsError(message));
  }
}
function* GetClientScheduledPosts({ payload }) {
  try {
    const response = yield Axios.get(
      `/dashboard/api/v1/${payload?.client_id}/posts/${payload?.month}/${payload?.day}/`
    );
    if (response?.status === 200) {
      yield put(getClientScheduledPostsSuccess(response?.data));
    } else {
      yield put(getClientScheduledPostsError(response.data.message));
    }
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data?.detail;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }

    yield put(getClientScheduledPostsError(message));
  }
}
function* GetClientSocialConnectionStatus({ payload }) {
  try {
    const response = yield Axios.get(
      `/client/api/v1/user/${payload}/social/accounts/`
    );
    if (response?.status === 200) {
      yield put(getClientSocialConnectionStatusSuccess(response?.data));
    } else {
      yield put(getClientSocialConnectionStatusError(response?.data?.message));
    }
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response.data?.detail;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Unauthorized";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }

    yield put(getClientSocialConnectionStatusError(message));
  }
}
export function* watchCreateClient() {
  yield takeEvery(CREATE_CLIENT, createClient);
}
export function* watchInviteClient() {
  yield takeEvery(INVITE_CLIENT, inviteClient);
}
export function* watchDeleteClient() {
  yield takeEvery(DELETE_CLIENT, deleteClient);
}
export function* watchGetTag() {
  yield takeEvery(GET_TAG, getTag);
}
export function* watchCreateTag() {
  yield takeEvery(CREATE_TAG, createTag);
}
export function* watchGetClient() {
  yield takeEvery(GET_CLIENT, getClient);
}
export function* watchGetClientDetails() {
  yield takeEvery(GET_CLIENT_DETAILS, getClientDetails);
}
export function* watchGetClientInvoices() {
  yield takeEvery(GET_CLIENT_INVOICE, GetClientInvoices);
}
export function* watchUpdateClientDetails() {
  yield takeEvery(UPDATE_CLIENT_DETAILS, UpdateClientDetails);
}
export function* watchGetClientScheduledPosts() {
  yield takeEvery(GET_CLIENT_SCHEDULED_POSTS, GetClientScheduledPosts);
}
export function* watchGetClientSocialConnectionStatus() {
  yield takeEvery(
    GET_CLIENT_SOCIALS_CONNECTION_STATUS,
    GetClientSocialConnectionStatus
  );
}
export default function* rootSaga() {
  yield all([
    fork(watchCreateClient),
    fork(watchDeleteClient),
    fork(watchGetTag),
    fork(watchCreateTag),
    fork(watchInviteClient),
    fork(watchGetClient),
    fork(watchGetClientDetails),
    fork(watchGetClientInvoices),
    fork(watchUpdateClientDetails),
    fork(watchGetClientScheduledPosts),
    fork(watchGetClientSocialConnectionStatus),
  ]);
}
