import React, { useEffect, useState } from "react";
import Table from "../../../components/Table";
import { Col, Row } from "reactstrap";
import { GoDotFill } from "react-icons/go";
// import { Link } from "react-router-dom";
import CancelPlanModal from "./components/CancelPlanModal";
import { connect } from "react-redux";
import { getAllSubscriptions } from "../../../redux/actions";
import EmptyTableData from "../../../components/Table/EmptyTableData";
import {
  capitalizeFirstLetter,
  formatAmount,
  formatInvoiceIssueDate,
} from "../../../utils/helper";
// import CancelPlanModal from "../Subscriptions/components/CancelPlanModal";

const SubscriptionHistory = ({
  getAllSubscriptions,
  subscriptions,
  getSubscriptionsLoading,
}) => {
  const [ModalOpen, setModalOpen] = useState(false);

  const getStatusClassName = (status) => {
    if (status?.toLowerCase() === "trial") {
      return "subscription-status-trial";
    } else if (status?.toLowerCase() === "paid") {
      return "subscription-status-paid";
    } else if (status?.toLowerCase() === "incomplete") {
      return "subscription-status-failed";
    } else if (status?.toLowerCase() === "cancelled") {
      return "subscription-status-cancelled";
    }
  };
  const cols = React.useMemo(
    () => [
      {
        Header: "Your Plan",
        accessor: "plan",
        cellClass: "subscription-data-item pt-3",
        Cell: (props) => {
          return <p>{capitalizeFirstLetter(props?.value)}</p>;
        },
      },
      {
        Header: "Date",
        accessor: "start_date",
        cellClass: "pt-3 subscription-data-item",
        Cell: (props) => <p>{formatInvoiceIssueDate(props.value)}</p>,
      },

      {
        Header: "Amount",
        accessor: "price",
        cellClass: "pt-3 subscription-data-item-bold ",
        Cell: (props) => <p>{formatAmount(props.value)}</p>,
      },
      {
        Header: "Status",
        accessor: "status",
        cellClass: "pt-2  subscription-data-item text-center d-flex",
        Cell: (props) => (
          <p className={`${getStatusClassName(props?.value)}`}>
            <GoDotFill color="" className="mb ml-2" />
            {props.value}
          </p>
        ),
      },
    ],
    []
  );

  const toggleCancelSubscriptionModal = () => {
    setModalOpen(!ModalOpen);
  };

  useEffect(() => {
    getAllSubscriptions();
  }, [getAllSubscriptions]);
  return (
    <div className="subscription-section">
      <Row>
        {/* <Col sm="12" xl="5" className="mb-2">
          <div className="left-section mt-4">
            <h1 className="mb-4 left-section-title">Subscription</h1>
            <Card className="subscription-details-card">
              <h1 className="text-center subscription-details-title">
                Current Plan
              </h1>
              <div className="subscription-price-wrapper d-flex flex-column align-items-center">
                <div className="d-flex justify-content-center">
                  <h1 className="mt-3 p-0">$</h1>
                  <h1 className="subscription-price p-0">40</h1>
                </div>
                <h2 className="subcription-plan-name">Starter</h2>
              </div>
              <Link to="/Subscriptions" className="w-50 delete-btn">
                Change Plan
              </Link>
              <button
                className="w-50 delete-btn"
                onClick={toggleCancelSubscriptionModal}
              >
                Cancel Plan
              </button>
            </Card>
          </div>
        </Col> */}
        <Col sm="12" xl="12" lg="12" className="right-section-container">
          <div className="right-section mt-5">
            <h1 className="right-section-title">Subscription History</h1>
            {subscriptions?.length ? (
              <div className="mb-0 mt-2 overflow-auto">
                <Table
                  columns={cols}
                  data={subscriptions}
                  dividedRow
                  loading={getSubscriptionsLoading}
                  defaultPageSize={3}
                  pagePosition="center"
                />
              </div>
            ) : (
              <EmptyTableData subHeaderText="No data" />
            )}
          </div>
        </Col>
      </Row>
      <CancelPlanModal
        open={ModalOpen}
        setModalState={setModalOpen}
        toggleCancelSubscriptionModal={toggleCancelSubscriptionModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptions: state?.subscription?.subscriptions,
    getSubscriptionsLoading: state?.subscription?.getAllSubscriptions,
  };
};
export default connect(mapStateToProps, { getAllSubscriptions })(
  SubscriptionHistory
);
