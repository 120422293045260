import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { ReactComponent as CloseIcon } from "../../assets/close-circle.svg";
import styles from "./ConfigureModal.module.css";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import {
  connectPaymentMethod,
  updatePaymentMethod,
} from "../../../../../redux/Settings/action";

const ConfigureModal = ({
  isOpen,
  title,
  onToggle,
  onClose,
  onConnectMethod,
  connectPaymentMethod,
  updatePaymentMethod,
  connectError,
  connectLoading,
  connectMessage,
  paymentMethods,
}) => {
  const existingMethod = paymentMethods.find((meth) => meth.name === title);

  const { handleSubmit, register } = useForm({
    defaultValues: {
      public_key: existingMethod?.public_key,
      secret_key: existingMethod?.secret_key,
    },
  });

  const handleConfigureSubmit = async (values) => {
    onConnectMethod(title, values);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle} modalClassName={styles.container}>
      <ModalHeader
        close={
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        }
        onToggle={onToggle}
      >
        <span className={styles.title}>{title}</span>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(handleConfigureSubmit)}>
          <p
            className={styles.subtitle}
          >{`To connect your ${title} account, please navigate to the Developers section of your ${title} dashboard and locate your API keys. Once you have retrieved your keys, please enter them into the appropriate fields.`}</p>
          <div className={styles.inputs}>
            <div className={styles.inputBox}>
              <p className={styles.inputTitle}>Publishable Key</p>
              <input
                {...register("public_key", {
                  required: true,
                })}
              />
            </div>
            <div className={styles.inputBox}>
              <p className={styles.inputTitle}>Secret Key</p>
              <input
                {...register("secret_key", {
                  required: true,
                })}
              />
            </div>
          </div>
          <div className={styles.submit}>
            <button type="submit" disabled={connectLoading}>
              {connectLoading ? (
                <div className="text-center w-100 align-items-center">
                  <ThreeDots
                    color="white"
                    height={"12px"}
                    wrapperStyle={{ display: "block" }}
                  />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({ settings }) => {
  const { message, loading, error, paymentMethods } = settings;
  return {
    connectLoading: loading?.connectPaymentMethod,
    connectMessage: message?.connectPaymentMethod,
    connectError: error?.connectPaymentMethod,
    paymentMethods,
  };
};
export default connect(mapStateToProps, {
  connectPaymentMethod,
  updatePaymentMethod,
})(ConfigureModal);
