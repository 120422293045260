import {
  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_ERROR,
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_ERROR,
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_ERROR,
  INVITE_CLIENT,
  INVITE_CLIENT_SUCCESS,
  INVITE_CLIENT_ERROR,
  GET_TAG,
  GET_TAG_SUCCESS,
  GET_TAG_ERROR,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_ERROR,
  DELETE_CLIENT,
  DELETE_CLIENT_ERROR,
  DELETE_CLIENT_SUCCESS,
  UPDATE_CLIENT_DETAILS,
  UPDATE_CLIENT_DETAILS_SUCCESS,
  UPDATE_CLIENT_DETAILS_ERROR,
  GET_CLIENT_INVOICE,
  GET_CLIENT_INVOICE_SUCCESS,
  GET_CLIENT_INVOICE_ERROR,
  GET_CLIENT_SCHEDULED_POSTS,
  GET_CLIENT_SCHEDULED_POSTS_SUCCESS,
  GET_CLIENT_SCHEDULED_POSTS_ERROR,
  GET_CLIENT_SOCIALS_CONNECTION_STATUS,
  GET_CLIENT_SOCIALS_CONNECTION_STATUS_SUCCESS,
  GET_CLIENT_SOCIALS_CONNECTION_STATUS_ERROR,
} from "../actions";

export const createClient = (clientDetails) => ({
  type: CREATE_CLIENT,
  payload: { clientDetails },
});
export const createClientSuccess = (message) => ({
  type: CREATE_CLIENT_SUCCESS,
  payload: { message },
});
export const createClientError = (error) => ({
  type: CREATE_CLIENT_ERROR,
  payload: { error },
});

export const getClient = () => ({
  type: GET_CLIENT,
});
export const getClientSuccess = (data) => ({
  type: GET_CLIENT_SUCCESS,
  payload: { data },
});
export const getClientError = (error) => ({
  type: GET_CLIENT_ERROR,
  payload: { error },
});
export const getClientDetails = (id) => ({
  type: GET_CLIENT_DETAILS,
  payload: { id },
});
export const getClientDetailsSuccess = (data) => ({
  type: GET_CLIENT_DETAILS_SUCCESS,
  payload: { data },
});
export const getClientDetailsError = (error) => ({
  type: GET_CLIENT_DETAILS_ERROR,
  payload: { error },
});

export const inviteClient = (clientDetails) => ({
  type: INVITE_CLIENT,
  payload: { clientDetails },
});
export const inviteClientSuccess = (message) => ({
  type: INVITE_CLIENT_SUCCESS,
  payload: { message },
});
export const inviteClientError = (error) => ({
  type: INVITE_CLIENT_ERROR,
  payload: { error },
});

export const deleteClient = (id) => ({
  type: DELETE_CLIENT,
  payload: id,
});
export const deleteClientSuccess = () => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: "Client Record Deleted Successfully",
});
export const deleteClientError = (error) => ({
  type: DELETE_CLIENT_ERROR,
  payload: error,
});

export const createTag = (tag) => ({
  type: CREATE_TAG,
  payload: { tag },
});
export const createTagSuccess = (message) => ({
  type: CREATE_TAG_SUCCESS,
  payload: { message },
});
export const createTagError = (error) => ({
  type: CREATE_TAG_ERROR,
  payload: { error },
});

export const getTag = () => ({
  type: GET_TAG,
});
export const getTagSuccess = (tags) => ({
  type: GET_TAG_SUCCESS,
  payload: { tags },
});
export const getTagError = (error) => ({
  type: GET_TAG_ERROR,
  payload: { error },
});

export const updateClientDetails = (credentials) => ({
  type: UPDATE_CLIENT_DETAILS,
  payload: credentials,
});

export const updateClientDetailsSuccess = () => ({
  type: UPDATE_CLIENT_DETAILS_SUCCESS,
  payload: "Client Details Updated Successfully",
});

export const updateClientDetailsError = (error) => ({
  type: UPDATE_CLIENT_DETAILS_ERROR,
  payload: error,
});

export const getClientInvoices = (id) => ({
  type: GET_CLIENT_INVOICE,
  payload: id,
});

export const getClientInvoicesSuccess = (data) => ({
  type: GET_CLIENT_INVOICE_SUCCESS,
  payload: { data, message: "Client invoces fetched successfully" },
});

export const getClientInvoicesError = (error) => ({
  type: GET_CLIENT_INVOICE_ERROR,
  message: error,
});

export const getClientScheduledPosts = (id) => ({
  type: GET_CLIENT_SCHEDULED_POSTS,
  payload: id,
});

export const getClientScheduledPostsSuccess = (message) => ({
  type: GET_CLIENT_SCHEDULED_POSTS_SUCCESS,
  payload: message,
});
export const getClientScheduledPostsError = (error) => ({
  type: GET_CLIENT_SCHEDULED_POSTS_ERROR,
  payload: error,
});

export const getClientSocialConnectionStatus = (id) => ({
  type: GET_CLIENT_SOCIALS_CONNECTION_STATUS,
  payload: id,
});
export const getClientSocialConnectionStatusSuccess = (status) => ({
  type: GET_CLIENT_SOCIALS_CONNECTION_STATUS_SUCCESS,
  payload: status,
});
export const getClientSocialConnectionStatusError = (error) => ({
  type: GET_CLIENT_SOCIALS_CONNECTION_STATUS_ERROR,
  payload: error,
});
