import React from "react";
import { HiPlus } from "react-icons/hi";

import postEmptyIcon from "../../../assets/img/postEmptyIcon.png";

const EmptyPosts = ({ handleClose, schedulePostAction }) => {
  const handleScheduleNow = () => {
    handleClose();
    schedulePostAction();
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column h-100">
      <div className="mt-5">
        <img src={postEmptyIcon} alt="icon" />
      </div>
      <div className=" text-center mt-3 mb-4">
        <h5
          style={{
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: " 28px",
            letterSpacing: "0.35px",
            color: "#111317",
          }}
        >
          No scheduled posts for this date
        </h5>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
      </div>

      <div
        className=" text-white  create-button align-items-center mb-3"
        role="button"
        onClick={handleScheduleNow}
        style={{
          width: "225px",
          borderRadius: "10px",
          fontSize: "14px",
          lineHeight: "21px",
          letterSpacing: "1.25px",
          color: " #F7F7FC",
        }}
      >
        <HiPlus color="#2465ec" className="create-icon" />
        Schedule now
      </div>
    </div>
  );
};

export default EmptyPosts;
