import "react-calendar/dist/Calendar.css";
import { Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import SocialChannels from "./tabs";
import NoPostSchedule from "./NoPostSchedule";

//redux

import { connect, useDispatch, useSelector } from "react-redux";
import {
  getAllClient,
  getOneClientPost,
  getClientSocialConnectionStatus,
} from "../../redux/actions";
import { useEffect } from "react";
import { useState } from "react";
import ListAllClient from "./ListAllClient";
import ListAllClientDrawer from "./ListAllClientDrawer";
import BarLoader from "../../components/Loaders/BarLoader";
import Button from "../../components/Button";

const Schedule = ({
  getAllClient,
  getAllClientDetails,
  getClientPost,
  getAllClientLoading,
  getOneClientPostLoading,
}) => {
  const businessOwner = useSelector((state) => state?.auth?.currentUser);

  const [activeId, setActiveId] = useState(0);
  const [showClient, setShowClient] = useState(false);
  const [allClient, setAllClient] = useState(getAllClientDetails);
  const [id, setId] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [userType, setUserType] = useState(null);

  const onChangeSearchValue = (e) => {
    let value = e.target.value;
    setSearchValue(value);
  };

  let navigate = useNavigate();

  const newPost = () => {
    navigate(`/schedule/newpost`, { state: { id, userType } });
  };

  const dispatch = useDispatch();

  function onChangeClientPost(activeIndex, id, type) {
    setActiveId(activeIndex);
    const clientId = id;
    setId(clientId);
    setUserType(type);
    if (type === "client") {
      dispatch(getClientSocialConnectionStatus(clientId));
    }
    dispatch(getOneClientPost(clientId));
    if (showClient === true) {
      setShowClient(false);
    }
  }

  useEffect(() => {
    getAllClient();
  }, [getAllClient]);

  useEffect(() => {
    if (searchValue.length <= 0) {
      setAllClient(getAllClientDetails);
    } else if (searchValue.length >= 4) {
      const results = getAllClientDetails.filter((o) =>
        o?.fullname?.toLowerCase().includes(searchValue?.toLowerCase())
      );
      if (results.length > 0) {
        setAllClient(results);
      } else {
        setAllClient(getAllClientDetails);
      }
    }
  }, [searchValue, getAllClientDetails, setAllClient]);
  useEffect(() => {
    if (businessOwner?.unique_code) {
      let defaultId = businessOwner?.unique_code;
      setId(defaultId);
      dispatch(getOneClientPost(defaultId));
    }
  }, [dispatch, businessOwner]);

  return (
    <div className="dashboard dashboard-wrapper schedule-post-wrapper">
      {showClient && (
        <ListAllClientDrawer
          setShowClient={setShowClient}
          searchValue={searchValue}
          onChangeSearchValue={onChangeSearchValue}
          getAllClientDetails={allClient}
          activeId={activeId}
          onChangeClientPost={onChangeClientPost}
        />
      )}
      <Row gap={2}>
        <Col xs="12" md="3" sm="12" className="fee">
          {getAllClientLoading ? (
            <BarLoader height="50" width="50" />
          ) : (
            <ListAllClient
              onChangeClientPost={onChangeClientPost}
              getAllClientDetails={allClient}
              activeId={activeId}
              setAllClient={setAllClient}
              searchValue={searchValue}
              onChangeSearchValue={onChangeSearchValue}
            />
          )}
        </Col>

        {!showClient && (
          <Col className="bg-light" xs="12" md="9" sm="12">
            <div className="showclient-wrapper">
              <h5 className="showclient" onClick={() => setShowClient(true)}>
                showclient
              </h5>
            </div>
            <div className="postWrapper">
              <div className="topnav">
                <h6 className="">Posts</h6>
                <div>
                  <Button
                    text={"Create Post"}
                    className={"Button_Wrap"}
                    btnstyle={{
                      background: "#2062F4",
                      fontSize: "14px",
                      width: "180px",
                      height: "45px",
                    }}
                    onButtonClick={newPost}
                  />
                </div>
              </div>
              <div className="socialChannelWrapper">
                {getOneClientPostLoading ? (
                  <BarLoader />
                ) : (
                  <>
                    {getClientPost?.user_instagram_post?.length <= 0 &&
                    getClientPost?.user_facebook_post?.length <= 0 &&
                    getClientPost?.user_linkedin_post?.length <= 0 ? (
                      <NoPostSchedule
                        text={"Get started by scheduling a post"}
                        button
                        buttonProps={
                          <>
                            <Button
                              text={"Create Post"}
                              className={"Button_Wrap"}
                              btnstyle={{
                                background: "#2062F4",
                                fontSize: "14px",
                                width: "180px",
                                height: "45px",
                              }}
                              onButtonClick={newPost}
                            />
                          </>
                        }
                      />
                    ) : (
                      <SocialChannels id={id} />
                    )}
                  </>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { schedule, postSchedule } = state;
  return {
    getAllClientDetails: schedule?.getAllClientDetails,
    getAllClientLoading: schedule?.getAllClientLoading,
    getClientPost: postSchedule?.getOneClientPost,
    getOneClientPostLoading: postSchedule?.getOneClientPostLoading,
  };
};

export default connect(mapStateToProps, {
  getAllClient,
})(Schedule);
