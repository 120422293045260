import { useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import "react-calendar/dist/Calendar.css";
import greenMark from "../../assets/img/green-icon.svg";
import greyMark from "../../assets/img/gray-icon-mark.svg";
import SubscriptionModal from "./SubscriptionModal";
// import successConfeti from "../../assets/img/success-confeti.svg";

//utils
import { plans } from "../../utils/Plans";
// import PaymentScreen from "./PaymentScreen";

//react-redux
import { connect } from "react-redux";
import { getAllSubscriptionDetails } from "../../redux/actions";

const Subscription = ({ getAllSubscriptionDetails, subscriptionDetails }) => {
  const [planState, updatePlanState] = useState("");
  const [planDetails, setPlanDetails] = useState({});
  const [durationState, updateDurationState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  // const [view, setView] = useState("plans");

  // eslint-disable-next-line

  const togglePlanState = (newPlan) => {
    updatePlanState(newPlan);
  };

  const toggleDurationState = () => {
    updateDurationState(!durationState);
  };

  const handleSelectPlan = (e) => {
    e.stopPropagation();
    // setView("payment");
    setModalOpen(true);
    const activePlanDetails = plans.find((plan) => {
      let planObj = plan.name === planState;
      return planObj;
    });
    setPlanDetails(activePlanDetails);
  };

  const filteredPlans = useMemo(() => {
    return plans?.filter(
      (plan) => plan.name.toLowerCase() !== subscriptionDetails?.plan
    );
  }, [subscriptionDetails]);

  useEffect(() => {
    getAllSubscriptionDetails();
  }, [getAllSubscriptionDetails]);
  return (
    <div className="dashboard dashboard-wrapper">
      <Row>
        <Col md="12" sm="12" lg="12" xxl="12" className="mb-3">
          <div>
            <Row>
              <Col md="12">
                <div className="dashboard-analytics">
                  <Card className="analytics-card pt-3 pb-5">
                    {/* {view === "plans" ? ( */}
                      <div>
                        <div className="d-flex flex-column align-items-center my-4 subscription__header__text">
                          <h1>Affordable plans that grow with your business</h1>
                          <p>Get started with a 14-day free trial</p>
                          <div className="d-flex justify-content-center align-items-center my-4">
                            <label class="switch">
                              <input
                                type="checkbox"
                                onChange={toggleDurationState}
                                value={durationState}
                              />
                              <span class="slider round"></span>
                            </label>
                            <span
                              style={{
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#032041",
                                paddingLeft: "1rem",
                              }}
                            >
                              <strong>Annual</strong> (Get 2 months Free)
                            </span>
                          </div>
                        </div>
                        <Row className="subscription__plans-container justify-content-center align-items-center">
                          {filteredPlans?.map((plan) => {
                            return (
                              <Col
                                onClick={() => {
                                  togglePlanState(plan.name);
                                }}
                                md="4"
                                sm="6"
                                lg="4"
                                xl="4"
                                className="sub"
                                key={plan.id}
                              >
                                <div className="subscription__card__border">
                                  <div
                                    className={`subscription__card__content px-4 py-4 ${
                                      planState === plan.name
                                        ? "subscription__card__content__active"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      className="d-flex justify-content-between align-items-center"
                                      style={{ maxHeight: "47px" }}
                                    >
                                      <div>
                                        <h4 className="subscription__card__content__plan-type">
                                          {plan.name}
                                        </h4>
                                      </div>

                                      {planState === plan.name ? (
                                        <img
                                          src={greenMark}
                                          style={{ height: "60px" }}
                                          className="my-auto"
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src={greyMark}
                                          style={{ height: "*60px" }}
                                          className="my-auto"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <p className="subscription__card__content__price-text">
                                      $
                                      {durationState
                                        ? plan.price * 10
                                        : plan.price}
                                      <span
                                        className={`subscription__card__content__price-text__duration ${
                                          planState === plan.name
                                            ? "subscription__card__content__price-text__duration-active"
                                            : ""
                                        }`}
                                      >
                                        / {durationState ? "Year" : "Month"}
                                      </span>
                                    </p>
                                    <p className="subscription__card__content__description">
                                      {plan.description}
                                    </p>
                                    <div className="mt-2">
                                      {plan.features?.map((feature) => {
                                        return (
                                          <h6
                                            className="subscription__card__content__regular-text mt-1"
                                            key={feature}
                                          >
                                            {feature}
                                          </h6>
                                        );
                                      })}
                                    </div>
                                    <div className="mt-5 mb-4 d-flex justify-content-center">
                                      <Button
                                        className={`subscription__card__content__btn ${
                                          planState === plan.name
                                            ? "subscription__card__content__btn__active"
                                            : ""
                                        }`}
                                        onClick={handleSelectPlan}
                                        disabled={planState !== plan.name}
                                      >
                                        Select Plan
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    {/* ) : (
                      <PaymentScreen
                        planDetails={planDetails}
                        planDuration={durationState}
                      />
                    )} */}
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <SubscriptionModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        subscriptionDetails={subscriptionDetails}
        planDetails={planDetails}
        planDuration={durationState}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionDetails: state?.subscription?.subscriptionDetails,
  };
};
export default connect(mapStateToProps, { getAllSubscriptionDetails })(
  Subscription
);
