import React from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import classnames from "classnames";
import { Bars } from "react-loader-spinner";
import DatatablePagination from "../Pagination";

function Table({
  columns,
  data,
  divided,
  dividedRow,
  shadow,
  defaultPageSize,
  pagePosition,
  rowOnClick,
  loading,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    useSortBy,
    usePagination
  );

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <table
        {...getTableProps()}
        className={`r-table table  ${classnames({
          "table-divided": divided,
          "table-divided-row": dividedRow,
        })}`}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sorted-desc"
                        : "sorted-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                  <span />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "6rem" }}
            >
              <Bars height="100" width="100" color="#2062F4" />
            </div>
          ) : (
            page.map((row) => {
              prepareRow(row);
              return (
                // <div className='table-row'>
                <tr
                  {...row.getRowProps()}
                  onClick={
                    rowOnClick
                      ? (e) => {
                          rowOnClick(row?.original);
                        }
                      : () => console.log()
                  }
                  className={`table-row ${shadow ? "table-row-shadow" : ""}`}
                  style={{ cursor: rowOnClick ? "pointer" : "" }}
                >
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      key={`td_${cellIndex}`}
                      {...cell.getCellProps({
                        className: cell.column.cellClass,
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
                // </div>
              );
            })
          )}
        </tbody>
      </table>

      {data?.length > 0 && (
        <DatatablePagination
          position={pagePosition}
          page={pageIndex}
          pages={pageCount}
          canPrevious={canPreviousPage}
          canNext={canNextPage}
          pageSizeOptions={[4, 10, 20, 30, 40, 50]}
          showPageSizeOptions={false}
          showPageJump={false}
          defaultPageSize={pageSize}
          onPageChange={(p) => gotoPage(p)}
          onPageSizeChange={(s) => setPageSize(s)}
          paginationMaxSize={pageCount}
        />
      )}
    </div>
  );
}

export default Table;
