import React, { useState } from "react";
import StepNavigation from "./StepNavigation";
import CreatePost from "./schedulePostStep/CreatePost";
import SelectSocialChannels from "./schedulePostStep/SelectSocialChannels";
import FineTuneChannel from "./schedulePostStep/FineTuneChannel";
import GenericChannel from "./schedulePostStep/GenericChannel";
import PostDetails from "./schedulePostStep/PostDetails";
import { Col, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getBusinessSocialsConnectionStatus,
  getSocialDp,
  scheduledPost,
  getClientSocialConnectionStatus,
} from "../../../redux/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";

const ScheduleSteps = (props) => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const id = state?.id;
  if (!id) {
    navigate("/Schedule");
  }

  const [currentStep, UpdateCurrentStep] = useState(1);
  const [socialMedia, setSocialMedia] = useState([]);
  const [postDate, setPostDate] = useState();

  const [captions, setCaptions] = useState();

  const businessOwner = useSelector((state) => state?.auth?.currentUser);

  const { scheduledPost, loading, postScheduleMessage, postScheduleError } =
    props;

  const [igDate, setIgDate] = useState();
  const [igcaptions, setIgCaptions] = useState();
  const [fbDate, setfbDate] = useState();
  const [fbcaptions, setfbCaptions] = useState();
  const [twitterDate, setTwitterDate] = useState();
  const [twittercaptions, setTwitterCaptions] = useState();
  const [linkedinDate, setLinkedinDate] = useState();
  const [linkedincaptions, setLinkedinCaptions] = useState();
  const [url, setUrl] = useState();
  const [images, setImages] = useState();

  const dispatch = useDispatch();
  const postToSocial = socialMedia.length
    ? {
        facebook: socialMedia.includes("facebook") ? true : false,
        instagram: socialMedia.includes("instagram") ? true : false,
        linkedin: socialMedia.includes("linkedin") ? true : false,
        twitter: socialMedia.includes("twitter") ? true : false,
      }
    : null;

  const socials = useSelector(
    (state) => state?.socials?.businessSocialsConnectionStatus
  );

  const clientSocials = useSelector(
    (state) => state?.client?.socialConnectionStatus
  );

  const selectedAccountSocials =
    state?.userType === "client" ? clientSocials : socials;
  const checkResult = Object.keys(selectedAccountSocials).some(
    (key) => socials[key]?.connection_status === true
  );

  const onHandleSubmit = (type) => {
    const payload = {
      user: id,
      linkedin_caption:
        linkedincaptions === undefined
          ? null
          : JSON.stringify(linkedincaptions)
              .replace(/\\n/g, "<br>")
              .replace(/"/g, "")
              .replaceAll("<br>", "\n"),
      linkedin_date_time:
        linkedinDate === undefined
          ? null
          : moment(linkedinDate).format("YYYY-MM-DD HH:mm:ss"),
      facebook_caption:
        fbcaptions === undefined
          ? null
          : JSON.stringify(fbcaptions)
              .replace(/\\n/g, "<br>")
              .replace(/"/g, "")
              .replaceAll("<br>", "\n"),
      facebook_date_time:
        fbDate === undefined
          ? null
          : moment(fbDate).format("YYYY-MM-DD HH:mm:ss"),
      instagram_caption:
        igcaptions === undefined
          ? null
          : JSON.stringify(igcaptions)
              .replace(/\\n/g, "<br>")
              .replace(/"/g, "")
              .replaceAll("<br>", "\n"),
      instagram_date_time:
        igDate === undefined
          ? null
          : moment(igDate).format("YYYY-MM-DD HH:mm:ss"),
      tweet_caption:
        twittercaptions === undefined
          ? null
          : JSON.stringify(twittercaptions)
              .replace(/\\n/g, "<br>")
              .replace(/"/g, "")
              .replaceAll("<br>", "\n"),
      tweet_date_time:
        twitterDate === undefined
          ? null
          : moment(twitterDate).format("YYYY-MM-DD HH:mm:ss"),
      media: url,
      post_status: type,
      post_to_facebook: postToSocial?.facebook,
      post_to_twitter: postToSocial?.twitter,
      post_to_linkedin: postToSocial?.linkedin,
      post_to_instagram: postToSocial?.instagram,
      is_carousel: url?.length > 1 ? true : false,
    };
    scheduledPost(payload);
  };

  useEffect(() => {
    const isPostScheduleError = postScheduleError?.length > 0;
    const isPostScheduleMessage = postScheduleMessage?.length > 0;
    const isLoading = loading?.draft || loading?.schedule || loading?.pending;

    if (!isLoading) {
      if (isPostScheduleError) {
        toast.error(postScheduleError);
        setTimeout(() => window.location.reload(), 1000);
      } else if (isPostScheduleMessage) {
        toast.success(postScheduleMessage);
        setTimeout(() => navigate("/schedule"), 1000);
      }
    }
  }, [loading, postScheduleError, postScheduleMessage, navigate]);

  useEffect(() => {
    dispatch(getSocialDp(businessOwner?.unique_code));
  }, [id, businessOwner?.unique_code, dispatch]);

  useEffect(() => {
    if (state?.userType === "client") {
      dispatch(getClientSocialConnectionStatus(id));
    } else {
      dispatch(getBusinessSocialsConnectionStatus(businessOwner?.unique_code));
    }
  }, [dispatch, businessOwner?.unique_code, state?.userType, id]);

  function prev() {
    UpdateCurrentStep(currentStep - 1);
  }

  function nextSocial(social) {
    setSocialMedia(social);
    UpdateCurrentStep(currentStep + 1);
  }

  function nextPostDetails(date) {
    if (!!checkResult) {
      setPostDate(date);
      UpdateCurrentStep(currentStep + 1);
    } else {
      toast.error("Please connect a social account");
    }
  }

  function nextGenericDetails(caption, url, images) {
    setCaptions(caption);
    setUrl(url);
    setImages(images);
    UpdateCurrentStep(currentStep + 1);
  }

  function nextGotItDetails(
    igText,
    igDate,
    fbText,
    fbDate,
    lnText,
    lnDate,
    twitterText,
    twitterDate
  ) {
    setIgCaptions(igText);
    setIgDate(igDate);
    setfbCaptions(fbText);
    setfbDate(fbDate);
    setLinkedinCaptions(lnText);
    setLinkedinDate(lnDate);
    setTwitterCaptions(twitterText);
    setTwitterDate(twitterDate);
    UpdateCurrentStep(currentStep + 1);
  }
  const labelArray = [
    {
      name: "Set Post Timing",
      content: <PostDetails next={nextPostDetails} />,
    },
    {
      name: "Select Social Channels",
      content: <SelectSocialChannels next={nextSocial} prev={prev} />,
    },
    {
      name: "Define Generic Content",
      content: <GenericChannel next={nextGenericDetails} prev={prev} />,
    },
    {
      name: "Fine-tune Each Channel",
      content: (
        <FineTuneChannel
          postDate={postDate}
          baseCaption={captions}
          socialMedia={socialMedia}
          next={nextGotItDetails}
          prev={prev}
          images={images}
        />
      ),
    },
    {
      name: "Create Post",
      content: (
        <CreatePost onSubmit={onHandleSubmit} loading={loading} prev={prev} />
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col xs="12" md="5" lg={3} sm="12" className="p-2">
          <div className="stepHolder">
            <StepNavigation labelArray={labelArray} currentStep={currentStep} />
          </div>
        </Col>

        <Col xs="12" md="7" lg={9} sm="12" className="p-2">
          <div className="postStepContent">
            {labelArray[currentStep - 1].content}
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  const { postSchedule } = state;
  const { loading, postScheduleData, postScheduleError, postScheduleMessage } =
    postSchedule;
  return { loading, postScheduleData, postScheduleError, postScheduleMessage };
};

export default connect(mapStateToProps, { scheduledPost })(ScheduleSteps);
