import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  //  Button
} from "reactstrap";
import facebook from "../../assets/img/facebook.png";
import twitter from "../../assets/img/twitter.png";
import instagram from "../../assets/img/instagram.png";
import linkedin from "../../assets/img/linkedin.svg";
import { FaPlusCircle } from "react-icons/fa";
// import greenMark from "../../assets/img/green-icon.svg";
import userAvatar from "../../assets/img/avatar.png";

//redux
import { connect } from "react-redux";
import {
  getBusinessSocialsConnectionStatus,
  getClientSocialConnectionStatus,
  addNewNotification,
  getLinkedInCallbackURL,
  getFacebookCallbackURL,
  getTwitterCallbackURL,
} from "../../redux/actions";
import InstagramModal from "./InstagramModal";

const SocialConnectionSection = (props) => {
  const {
    getBusinessSocialsConnectionStatus,
    getClientSocialConnectionStatus,
    addNewNotification,
    getLinkedInCallbackURL,
    getFacebookCallbackURL,
    getTwitterCallbackURL,
    businessSocialsConnectionStatus,
    socialConnectionStatus,
    callbackURL,
    socialsMessage,
    loading,
    id,
    isClient,
    // clientId,
    businessUniquecode,
    clientUniqueCode,
    // facebookData,
  } = props;

  const [openModal, setOpenModal] = useState(false);

  const handleOpenInstagramModal = () => {
    localStorage.setItem(
      "id",
      isClient ? clientUniqueCode : businessUniquecode
    );
    window.open(
      "https://business.ontriv.com/socials/connection/instagram/",
      "_blank"
    );
  };

  const socialsConnectionStatus = useMemo(() => {
    if (isClient) {
      return socialConnectionStatus;
    } else {
      return businessSocialsConnectionStatus;
    }
  }, [isClient, businessSocialsConnectionStatus, socialConnectionStatus]);
  useEffect(() => {
    // if (isClient) {
    //   addNewNotification(clientId);
    // } else {
    addNewNotification(id);
    // }
  }, [addNewNotification, id]);

  useEffect(() => {
    if (isClient) {
      getClientSocialConnectionStatus(clientUniqueCode);
    } else {
      getBusinessSocialsConnectionStatus(businessUniquecode);
    }
  }, [
    getBusinessSocialsConnectionStatus,
    getClientSocialConnectionStatus,
    businessUniquecode,
    isClient,
    clientUniqueCode,
  ]);
  useEffect(() => {
    if (
      !loading?.getLinkedInCallbackURL &&
      socialsMessage?.getLinkedInCallbackURL?.length > 0
    ) {
      localStorage.setItem(
        "id",
        isClient ? clientUniqueCode : businessUniquecode
      );
      window.open(callbackURL?.linkedIn, "_blank");
    } else if (
      !loading?.getFacebookCallbackURL &&
      socialsMessage?.getFacebookCallbackURL?.length > 0
    ) {
      localStorage.setItem(
        "id",
        isClient ? clientUniqueCode : businessUniquecode
      );
      window.open(callbackURL?.facebook, "_blank");
    } else if (
      !loading?.getTwitterCallbackURL &&
      socialsMessage?.getTwitterCallbackURL?.length > 0
    ) {
      localStorage.setItem(
        "id",
        isClient ? clientUniqueCode : businessUniquecode
      );
      window.open(callbackURL?.twitter, "_blank");
    }
  }, [
    callbackURL?.linkedIn,
    callbackURL?.facebook,
    callbackURL?.twitter,
    loading?.getTwitterCallbackURL,
    loading?.getFacebookCallbackURL,
    loading?.getLinkedInCallbackURL,
    socialsMessage?.getLinkedInCallbackURL,
    socialsMessage?.getFacebookCallbackURL,
    socialsMessage?.getTwitterCallbackURL,
    isClient,
    clientUniqueCode,
    businessUniquecode,
  ]);

  return (
    <>
      <Row>
        <Col md="6" sm="6" lg="6">
          <div
            className="business__left-section__social-container d-flex justify-content-between align-items-center p-2 mt-4"
            onClick={getFacebookCallbackURL}
          >
            <div className="d-flex align-items-center">
              <div className="position-relative">
                <img
                  src={
                    socialsConnectionStatus?.facebook?.connection_status
                      ? socialsConnectionStatus?.facebook?.profile_image ||
                        userAvatar
                      : facebook
                  }
                  alt="Facebook icon"
                  style={{
                    width: isClient ? "22px" : "40px",
                    borderRadius: socialsConnectionStatus?.facebook
                      ?.connection_status
                      ? "50%"
                      : "",
                  }}
                />
                {socialsConnectionStatus?.facebook?.connection_status ? (
                  <div
                    style={{ position: "absolute", bottom: "6%", right: "5%" }}
                  >
                    <div
                      style={{
                        background: "#ffffff",
                        borderRadius: "50%",
                        height: isClient ? "11px" : "14px",
                        width: isClient ? "12px" : "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={facebook}
                        alt="instagram icon"
                        width={8}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <h6
                className="business__left-section__social-container__text"
                style={{
                  fontSize: isClient ? "12px" : "14px",
                  padding: "0.5rem 0",
                }}
              >
                {socialsConnectionStatus?.facebook?.connection_status
                  ? socialsConnectionStatus?.facebook?.username
                  : "Facebook"}
              </h6>
            </div>
            {socialsConnectionStatus?.facebook?.connection_status ? null : (
              <FaPlusCircle color="#9DA8B6" size="14px" />
            )}
          </div>
        </Col>
        <Col md="6" sm="6" lg="6">
          <div
            className="business__left-section__social-container d-flex justify-content-between align-items-center p-2 mt-4"
            onClick={handleOpenInstagramModal}
          >
            <div className="d-flex align-items-center">
              <div className="position-relative">
                <img
                  src={
                    socialsConnectionStatus?.instagram?.connection_status
                      ? socialsConnectionStatus?.instagram?.profile_image ||
                        userAvatar
                      : instagram
                  }
                  alt="Instagram icon"
                  style={{
                    width: isClient ? "22px" : "40px",
                    borderRadius: socialsConnectionStatus?.instagram
                      ?.connection_status
                      ? "50%"
                      : "",
                  }}
                />
                {socialsConnectionStatus?.instagram?.connection_status ? (
                  <div
                    style={{ position: "absolute", bottom: "6%", right: "5%" }}
                  >
                    <div
                      style={{
                        background: "#ffffff",
                        borderRadius: "50%",
                        height: isClient ? "11px" : "14px",
                        width: isClient ? "12px" : "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={instagram}
                        alt="instagram icon"
                        width={8}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              <h6
                className="business__left-section__social-container__text"
                style={{
                  fontSize: isClient ? "12px" : "14px",
                  padding: "0.5rem 0",
                }}
              >
                {socialsConnectionStatus?.instagram?.connection_status
                  ? socialsConnectionStatus?.instagram?.username
                  : "Instagram"}
              </h6>
            </div>
            {socialsConnectionStatus?.instagram?.connection_status ? null : (
              <FaPlusCircle color="#9DA8B6" size="14px" />
            )}
          </div>
        </Col>

        <Col d="6" sm="6" lg="6">
          <div
            className="business__left-section__social-container d-flex justify-content-between align-items-center p-2 mt-4"
            onClick={getTwitterCallbackURL}
          >
            <div className="d-flex align-items-center">
              <div className="position-relative">
                <img
                  src={
                    socialsConnectionStatus?.twitter_v1?.connection_status &&
                    socialsConnectionStatus?.twitter_v2?.connection_status
                      ? socialsConnectionStatus?.twitter_v1?.profile_image ||
                        userAvatar
                      : twitter
                  }
                  alt="Twitter icon"
                  style={{
                    width: isClient ? "18px" : "40px",
                    height: isClient ? "18px" : "40px",
                    borderRadius:
                      socialsConnectionStatus?.twitter_v1?.connection_status &&
                      socialsConnectionStatus?.twitter_v2?.connection_status
                        ? "50%"
                        : "",
                  }}
                />
                {socialsConnectionStatus?.twitter_v1?.connection_status &&
                socialsConnectionStatus?.twitter_v2?.connection_status ? (
                  <div
                    style={{ position: "absolute", bottom: "6%", right: "5%" }}
                  >
                    <div
                      style={{
                        background: "#ffffff",
                        borderRadius: "50%",
                        height: isClient ? "11px" : "14px",
                        width: isClient ? "12px" : "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={twitter}
                        alt="instagram icon"
                        width={8}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <h6
                className="business__left-section__social-container__text"
                style={{
                  fontSize: isClient ? "12px" : "14px",
                  padding: "0.5rem 0",
                }}
              >
                {socialsConnectionStatus?.twitter_v1?.connection_status &&
                socialsConnectionStatus?.twitter_v2?.connection_status
                  ? socialsConnectionStatus?.twitter_v1?.username ||
                    "X (FKA Twitter)"
                  : "X X (FKA Twitter)"}
              </h6>
            </div>
            {socialsConnectionStatus?.twitter_v1?.connection_status &&
            socialsConnectionStatus?.twitter_v2?.connection_status ? null : (
              <FaPlusCircle color="#9DA8B6" size="14px" />
            )}
          </div>
        </Col>
        <Col md="6" sm="6" lg="6">
          <div
            className="business__left-section__social-container d-flex justify-content-between align-items-center p-2 mt-4"
            onClick={getLinkedInCallbackURL}
            // style={{ cursor: "pointer" }}
          >
            <div className="d-flex align-items-center">
              <div className="position-relative">
                <img
                  src={
                    socialsConnectionStatus?.linkedin?.connection_status
                      ? socialsConnectionStatus?.linkedin?.company_logo ||
                        userAvatar
                      : linkedin
                  }
                  alt="LinkedIn icon"
                  style={{
                    width: isClient ? "22px" : "40px",
                    borderRadius: socialsConnectionStatus?.linkedin
                      ?.connection_status
                      ? "50%"
                      : "",
                  }}
                />
                {socialsConnectionStatus?.linkedin?.connection_status ? (
                  <div
                    style={{ position: "absolute", bottom: "6%", right: "5%" }}
                  >
                    <div
                      style={{
                        background: "#ffffff",
                        borderRadius: "50%",
                        height: isClient ? "11px" : "14px",
                        width: isClient ? "12px" : "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={linkedin}
                        alt="linkedin icon"
                        width={8}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <h6
                className="business__left-section__social-container__text"
                style={{
                  fontSize: isClient ? "12px" : "14px",
                  padding: "0.5rem 0",
                }}
              >
                {socialsConnectionStatus?.linkedin?.connection_status
                  ? socialsConnectionStatus?.linkedin?.company_name ||
                    "LinkedIn"
                  : "LinkedIn"}
              </h6>
            </div>
            {socialsConnectionStatus?.linkedin?.connection_status ? null : (
              <FaPlusCircle color="#9DA8B6" size="14px" />
            )}
          </div>
        </Col>
      </Row>
      <InstagramModal
        modalState={openModal}
        setModalState={setOpenModal}
        id={isClient ? clientUniqueCode : businessUniquecode}
      />
    </>
  );
};

const mapStateToProps = ({ auth, socials, client }) => {
  const {
    businessSocialsConnectionStatus,
    callbackURL,
    loading,
    facebookData,
  } = socials;
  const { socialConnectionStatus } = client;
  return {
    id: auth?.currentUser?.id,
    businessUniquecode: auth?.currentUser?.unique_code,
    businessSocialsConnectionStatus,
    callbackURL,
    loading,
    socialsMessage: socials?.message,
    socialConnectionStatus,
    facebookData: facebookData,
  };
};
export default connect(mapStateToProps, {
  getBusinessSocialsConnectionStatus,
  getClientSocialConnectionStatus,
  addNewNotification,
  getLinkedInCallbackURL,
  getTwitterCallbackURL,
  getFacebookCallbackURL,
})(SocialConnectionSection);
