import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  getAllContentIdeasSuccess,
  getAllContentIdeasError,
  clearMessages,
  GET_ALL_CONTENT_IDEAS,
} from "../actions";
import Axios from "../../utils/Axios";

export function* GetAllContentIdeas({ payload }) {
  console.log(payload);
  try {
    const response = yield Axios.get(`/client/api/v1/content/ideas/filter/`, {
      params: { month: payload?.month, year: payload?.year },
    });
    if (response?.status === 200) {
      yield put(getAllContentIdeasSuccess(response?.data));
    } else {
      yield put(getAllContentIdeasError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getAllContentIdeasError(message));
    yield put(clearMessages());
  }
}

export function* watchGetAllContentIdeas() {
  yield takeEvery(GET_ALL_CONTENT_IDEAS, GetAllContentIdeas);
}
export default function* rootSaga() {
  yield all([fork(watchGetAllContentIdeas)]);
}
