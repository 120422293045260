import React, { useState } from "react";
import PostHeader from "../PostHeader";
import facebook from "../../../../assets/img/facebook.png";
import instagram from "../../../../assets/img/instagram.png";
import linkedin from "../../../../assets/img/linkedin.png";
import twitter from "../../../../assets/img/twitter.png";
import userAvatar from "../../../../assets/img/avatar.png";
import { Col, Row } from "reactstrap";
import Button from "../../../../components/Button";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

const SelectSocialChannels = ({ next, prev }) => {
  const [checkedValue, setCheckedValue] = useState([]);
  const { state } = useLocation();

  const { userType } = state;
  const socials = useSelector(
    (state) => state?.socials?.businessSocialsConnectionStatus
  );
  const clientSocials = useSelector(
    (state) => state?.client?.socialConnectionStatus
  );
  const selectedAccountSocials =
    userType === "client" ? clientSocials : socials;
  const socialNetworks = Object.keys(selectedAccountSocials).map((key) => {
    if (
      (key === "twitter_v1" || key === "twitter_v2") &&
      (socials["twitter_v1"]?.connection_status === true ||
        socials["twitter_v2"]?.connection_status === true)
    ) {
      return "twitter";
    } else if (socials[key]?.connection_status === true) {
      return key;
    } else {
      return null;
    }
  });

  let uniqueChars = [...new Set(socialNetworks)];
  const onCheckItem = (e) => {
    if (e.target.checked && !checkedValue.includes(e.target.value)) {
      let values = e.currentTarget.value;
      let items = [...checkedValue, values];
      let uniqueItems = [...new Set(items)];
      setCheckedValue(uniqueItems);
    } else if (!e.target.checked) {
      let targetItem = checkedValue.indexOf(e.target.value);
      checkedValue.splice(targetItem, 1);
    }
  };
  return (
    <>
      <div className="socialChannels">
        <div className="backBtn" onClick={prev}>
          <BiArrowBack size="24px" />
        </div>
        <div className="content">
          <PostHeader title="Social channels" />
          <Row>
            {uniqueChars.map((social, index) =>
              social === null ? null : (
                <Col xs="6" md="6" sm="6" key={index}>
                  <div
                    className={`${
                      social === "instagram"
                        ? "instagramCheckbox"
                        : social === "facebook"
                        ? "facebookCheckbox"
                        : social === "linkedin"
                        ? "linkedinCheckbox"
                        : social === "twitter"
                        ? "linkedinCheckbox"
                        : null
                    } mb-2`}
                  >
                    <div className="position-relative">
                      <img
                        src={
                          selectedAccountSocials?.[
                            social === "twitter" ? "twitter_v1" : social
                          ]?.connection_status
                            ? selectedAccountSocials?.[
                                social === "twitter" ? "twitter_v1" : social
                              ]?.profile_image ||
                              selectedAccountSocials?.[
                                social === "twitter" ? "twitter_v1" : social
                              ]?.company_logo ||
                              userAvatar
                            : social === "instagram"
                            ? instagram
                            : social === "facebook"
                            ? facebook
                            : social === "linkedin"
                            ? linkedin
                            : twitter
                        }
                        alt="Facebook icon"
                        style={{
                          width: "35px",
                          borderRadius: selectedAccountSocials?.[
                            social === "twitter" ? "twitter_v1" : social
                          ]?.connection_status
                            ? "50%"
                            : "",
                        }}
                      />
                      {selectedAccountSocials?.[
                        social === "twitter" ? "twitter_v1" : social
                      ]?.connection_status ? (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "6%",
                            right: "5%",
                          }}
                        >
                          <div
                            style={{
                              background: "#ffffff",
                              borderRadius: "50%",
                              height: "14px",
                              width: "15px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                social === "instagram"
                                  ? instagram
                                  : social === "facebook"
                                  ? facebook
                                  : social === "linkedin"
                                  ? linkedin
                                  : social === "twitter"
                                  ? twitter
                                  : null
                              }
                              alt="instagram icon"
                              width={8}
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {/* <img
                    src={
                      social === "instagram"
                        ? instagram
                        : social === "facebook"
                        ? facebook
                        : social === "linkedin"
                        ? linkedin
                        : social === "twitter"
                        ? twitter
                        : null
                    }
                    alt={social}
                  /> */}
                    <input
                      type="checkbox"
                      id={social}
                      className="checkbox"
                      value={social}
                      onChange={onCheckItem}
                      disabled={
                        !selectedAccountSocials?.[
                          social === "twitter" ? "twitter_v1" : social
                        ]?.connection_status
                      }
                    />
                    <label htmlFor={social}>{social}</label>
                  </div>
                </Col>
              )
            )}
          </Row>
          <Button
            text="Next"
            onButtonClick={() => next(checkedValue)}
            btnstyle={{ width: "90%", margin: "auto", marginTop: "20px" }}
            disabled={!(checkedValue && checkedValue.length > 0)}
          />
        </div>
      </div>
    </>
  );
};

export default SelectSocialChannels;
