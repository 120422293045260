import React from "react";

const ContentItem = (props) => {
  const { icon, title, day, onClick } = props;
  return (
    <div className="available-day" onClick={onClick}>
      <div className="day-and-icon">
        <img src={icon} alt="icons" />
        <h4>{title}</h4>
      </div>
      <h4>{day}</h4>
    </div>
  );
};

export default ContentItem;
