import {
  GET_ALL_TEAM_MEMBERS,
  GET_ALL_TEAM_MEMBERS_SUCCESS,
  GET_ALL_TEAM_MEMBERS_ERROR,
  ADD_NEW_TEAM_MEMBER,
  ADD_NEW_TEAM_MEMBER_SUCCESS,
  ADD_NEW_TEAM_MEMBER_ERROR,
  DELETE_TEAM_MEMBER,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_ERROR,
} from "../actions";

export const getAllTeamMembers = () => ({
  type: GET_ALL_TEAM_MEMBERS,
});

export const getAllTeamMembersSuccess = (teamMembers) => ({
  type: GET_ALL_TEAM_MEMBERS_SUCCESS,
  payload: teamMembers,
});
export const getAllTeamMembersError = (error) => ({
  type: GET_ALL_TEAM_MEMBERS_ERROR,
  payload: error,
});

export const addNewTeamMember = (credentials) => ({
  type: ADD_NEW_TEAM_MEMBER,
  payload: credentials,
});

export const addNewTeamMemberSuccess = (message) => ({
  type: ADD_NEW_TEAM_MEMBER_SUCCESS,
  payload: message,
});

export const addNewTeamMemberError = (error) => ({
  type: ADD_NEW_TEAM_MEMBER_ERROR,
  payload: error,
});

export const deleteTeamMember = (id) => ({
  type: DELETE_TEAM_MEMBER,
  payload: id,
});

export const deleteTeamMemberSuccess = (message) => ({
  type: DELETE_TEAM_MEMBER_SUCCESS,
  payload: message,
});

export const deleteTeamMemberError = (error) => ({
  type: DELETE_TEAM_MEMBER_ERROR,
  payload: error,
});
