import React, { useState, useEffect } from "react";
import { CenteredModal } from "../../components/Modal";
import { usePaystackPayment } from "react-paystack";
import { PayButton, Header } from "./style";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import paystack from "../../assets/img/paystack.png";
import flutterwave from "../../assets/img/flutterwave.png";
import stripe from "../../assets/img/stripe.png";

//redux
import { connect } from "react-redux";

const PaymentGatewayModal = (props) => {
  const { openModal, setOpenModal, amount, currency, client, paymentGateways } =
    props;

  const [keysObject, setKeysObject] = useState({});

  console.log(keysObject);
  //functions
  const onCloseModal = () => {
    setOpenModal(false);
  };
  const flutterWaveConfig = {
    public_key: keysObject?.flutterwave?.public_key,
    tx_ref: Date.now(),
    amount: +amount,
    currency,
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: client?.client?.client_email,
      phone_number: client?.client?.client_phone_number,
      name: client?.client?.client_business_name,
    },
  };

  const handleFlutterPayment = useFlutterwave(flutterWaveConfig);

  // you can call this function anything
  const onSuccess = (reference) => {
    console.log(reference);
    // TODO: store refrence
    // updateInvoiceStatToPaid();
    window.alert("Paid");
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log('closed')
    setOpenModal(false);
  };

  const paystackConfig = {
    reference: new Date().getTime().toString(),
    email: client?.client?.client_email,
    amount: +amount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: keysObject?.paystack?.public_key,
  };
  const initializePaystackPayment = usePaystackPayment(paystackConfig);

  useEffect(() => {
    const keyObj = {};
    if (paymentGateways) {
      paymentGateways.forEach((key) => {
        keyObj[key.name] = key;
      });
      setKeysObject(keyObj);
    }
  }, [paymentGateways]);
  return (
    <CenteredModal
      modalState={openModal}
      setModalState={setOpenModal}
      size="sm"
    >
      <Header>Select Payment Provider</Header>

      {/* {keys?.length === 0 ? <EmptyKey>Payment not configured</EmptyKey> : null} */}

      {keysObject?.flutterwave ? (
        <PayButton
          type="button"
          key="flutterwave"
          onClick={() => {
            handleFlutterPayment({
              callback: (response) => {
                if (response.status === "successful") {
                  onSuccess(response.flw_ref);
                }
                closePaymentModal(); // this will close the modal programmatically
                onCloseModal();
              },
              onClose: () => {
                onCloseModal();
              },
            });
          }}
        >
          <img src={flutterwave} style={{ height: 50 }} alt="flutterwave" />
        </PayButton>
      ) : null}
      {keysObject?.paystack ? (
        <PayButton
          type="button"
          key="paystack"
          onClick={() => {
            // @ts-ignore
            initializePaystackPayment(onSuccess, onClose);
          }}
        >
          <img src={paystack} style={{ height: 30 }} alt="paystack" />
        </PayButton>
      ) : null}
      {keysObject?.stripe ? (
        <PayButton
          type="button"
          key="stripe"
          onClick={() => {
            console.log(stripe);
            // onOpenStripeModal();
          }}
        >
          <img src={stripe} style={{ height: 50 }} alt="stripe" />
        </PayButton>
      ) : null}
    </CenteredModal>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentGateways: state?.invoice?.paymentGateways,
  };
};
export default connect(mapStateToProps, {})(PaymentGatewayModal);
