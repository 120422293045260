import React from "react";

//components
import { Select } from "antd";

const SelectInput = (props) => {
  const {
    onChange,
    onSearch,
    options,
    placeholder,
    className,
    value,
    showSearch,
    size = "large",
    dropdownStyle = {},
  } = props;
  return (
    <Select
      showSearch={showSearch}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      size={size}
      dropdownStyle={dropdownStyle}
      value={value}
      className={className}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={options}
    />
  );
};

export default SelectInput;
