export const LoginValidate = (values) => {
  const errors = {};
  if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Email is invalid";
    }
  } else {
    errors.email = "Enter a valid Email";
  }
  if (!values.password) {
    errors.password = "Enter your password";
  }

  return errors;
};
