import React, { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Input } from "reactstrap";
import { CenteredModal as Modal } from "../../../components/Modal";
import { useFormik } from "formik";

//redux
import { connect } from "react-redux";
import { sendReceipt } from "../../../redux/actions";
import { toast } from "react-toastify";

const SendReceiptModal = ({
  showSendReceiptModal,
  setshowSendReceiptModal,
  invoiceId,
  sendReceipt,
  sendReceiptLoading,
  sendReceiptMessage,
  sendReceiptError,
  type,
}) => {
  const formValidate = (values) => {
    const errors = {};
    if (values.email) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Email is invalid";
      }
    } else {
      errors.email = "Enter a valid Email";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: formValidate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      sendReceipt({ ...values, invoice_id: invoiceId });
    },
  });

  useEffect(() => {
    if (!sendReceiptLoading && sendReceiptMessage?.length) {
      toast.success(sendReceiptMessage);
      setshowSendReceiptModal(false);
    } else if (!sendReceiptLoading && sendReceiptError?.length) {
      toast.error(sendReceiptError);
    }
  }, [
    sendReceiptLoading,
    sendReceiptMessage,
    sendReceiptError,
    setshowSendReceiptModal,
  ]);

  return (
    <Modal
      modalState={showSendReceiptModal}
      setModalState={setshowSendReceiptModal}
    >
      <div className="add-client-wrapper text-center ">
        <div className="add-client-text text-center">
          <h3 className="text-capitalize">{`Send ${type}`}</h3>
        </div>
        <form className="business-form mt-4" onSubmit={formik.handleSubmit}>
          <div className="w-100">
            <label className="text-left w-100 py-2 px-3">Email Address</label>
            <Input
              type="email"
              name="email"
              className={`w-100 ${formik?.errors?.email ? "red-border" : ""}`}
              onChange={formik.handleChange}
              value={formik?.values?.email}
              placeholder="email address"
            />
          </div>
          {formik?.errors?.email && formik?.touched?.email ? (
            <span className="text-danger text-left field-error py-0">
              {formik?.errors?.email}
            </span>
          ) : null}
          <div className="pt-2 pb-3">
            <button type="submit" className="px-5">
              {sendReceiptLoading ? (
                <div className="text-center w-100 align-items-center">
                  <ThreeDots
                    color="white"
                    height={"12px"}
                    wrapperStyle={{ display: "block" }}
                  />
                </div>
              ) : (
                "Send"
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export const mapStateToProps = (state) => {
  return {
    sendReceiptLoading: state?.oneInvoice?.sendReceiptLoading,
    sendReceiptMessage: state?.oneInvoice?.message?.sendReceipt,
    sendReceiptError: state?.oneInvoice?.sendReceiptError,
  };
};

export default connect(mapStateToProps, { sendReceipt })(SendReceiptModal);
