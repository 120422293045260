import { all, fork, put, takeEvery } from "redux-saga/effects";
// import { setCurrentUser } from '../../utils/helper';
import {
  Axios,
  setAuthToken,
  setCurrentUser,
  setUserType,
} from "../../utils/helper";

import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  SEND_VERIFICATION_EMAIL,
  VERIFY_EMAIL,
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  changePasswordSuccess,
  changePasswordError,
  resetMessage,
  sendVerificationEmailSuccess,
  sendVerificationEmailError,
  verifyEmailSuccess,
  verifyEmailError,
} from "../actions";
import { toast } from "react-toastify";

function* register({ payload }) {
  const { data } = payload;

  try {
    const response = yield Axios.post(`/register/api/v1/registration/`, data);
    if (response?.status === 201) {
      yield put(registerUserSuccess("Registration Successful"));
      setTimeout(() => (window.location.href = `/auth/login`), 4000);
    } else {
      yield put(registerUserError(response?.data?.message));
    }
    yield put(resetMessage());
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data?.country
        ? error?.response?.data?.country?.[0]
        : error?.response?.data?.email
        ? error?.response?.data?.email?.[0]
        : error?.response?.data?.password
        ? error?.response?.data?.password?.[0]
        : error?.response?.data?.fullname
        ? error?.response?.data?.fullname?.[0]
        : error?.response?.data?.buz
        ? error?.response?.data?.buz?.[0]?.website?.[0]
        : error?.response?.data?.buz?.[0]?.business_name?.[0];

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error?.message) {
      message = error?.message;
    }
    yield put(registerUserError(message));
    yield put(resetMessage());
  }
}
function* login({ payload }) {
  try {
    const response = yield Axios.post(
      "/accounts/api/v1/login/",
      payload.userDetails
    );
    if (response?.status === 200) {
      setAuthToken(response?.data?.access_token);
      setCurrentUser(response?.data);
      const user_data = {
        ...response?.data?.user,
        accessToken: response?.data?.access_token,
      };
      setUserType(response?.data?.role);
      yield put(
        loginUserSuccess({
          userInfo: Object.assign(user_data, response?.data?.profile),
        })
      );
      yield put(resetMessage());
      if (response?.data?.user?.first_login) {
        setTimeout(() => {
          window.location.href = "/setup-business";
        }, 300);
      } else {
        // console.log(response)
        setTimeout(() => {
          window.location.href = "/overview";
        }, 300);
      }
    } else {
      yield put(loginUserError("Login Failed, please try again later"));
    }
  } catch (error) {
    let message;
    if (error.response) {
      let errorMessage =
        error?.response?.data?.[0] || error?.response?.data?.error?.[0];
      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = error?.response?.data?.message;
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message =
            errorMessage === "User verification does not exist" ||
            errorMessage === "User email is not verified"
              ? "Kindly verify your email"
              : errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    }
    yield put(loginUserError(message));
    yield put(resetMessage());
    if (message === "Kindly verify your email") {
      setTimeout(() => {
        resetMessage();
        window.location.href = "/auth/email-verification";
      }, 3000);
    }
  }
}

function* forgotPassword({ payload }) {
  const { email } = payload.data;

  try {
    const response = yield Axios.post(`/accounts/api/v1/password/reset/`, {
      email,
    });
    if (response?.status === 200) {
      yield put(forgotPasswordSuccess(response?.data?.message));
      resetMessage();

      setTimeout(() => {
        window.location.href = "/auth/forgot-password/success";
      }, [1000]);
    }
  } catch (error) {
    let message;
    if (error.response) {
      let errorMessage =
        error?.response?.data?.[0] || error?.response?.data?.email?.[0];
      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = error?.response?.data?.message;
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    }
    yield put(forgotPasswordError(message));
    resetMessage();
  }
}

function* changePassword({ payload }) {
  try {
    const response = yield Axios.put(
      `/accounts/api/v1/password/confirm/`,
      payload
    );
    if (response?.status === 200) {
      yield put(changePasswordSuccess(response?.data?.msg));
      setTimeout(() => {
        window.location.href = "/auth/login";
      }, [1000]);
    }
    // createNotification('success', response?.data?.detail)
    // window.location.href = "/auth/forgot-password/success";
  } catch (error) {
    let message;
    let errorMessage = "";
    if (error.response) {
      Object.keys(error?.response?.data)?.forEach(
        (item) => (errorMessage = `${item} is required`)
      );
      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = error?.response?.data?.message;
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(changePasswordError(message));
    yield put(resetMessage());
  }
}
function* sendVerificationEmail({ payload }) {
  try {
    const response = yield Axios.post(`/accounts/api/v1/activate/`, payload);
    if (response?.status === 200) {
      yield put(sendVerificationEmailSuccess(response?.data?.detail));
    }
    yield put(resetMessage());

    // createNotification('success', response?.data?.detail)
    // window.location.href = "/auth/forgot-password/success";
  } catch (error) {
    let message;
    if (error.response) {
      let errorMessage =
        error?.response?.data?.[0] || error?.response?.data?.error?.[0];
      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = error?.response?.data?.message;
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error?.message) {
      message = error?.message;
    }
    yield put(sendVerificationEmailError(message));
    yield put(resetMessage());
  }
}
function* verifyEmail({ payload }) {
  try {
    const response = yield Axios.put(`/accounts/api/v1/verify/`, payload);
    if (response?.status === 200) {
      yield put(verifyEmailSuccess(response?.data?.msg));
    } else {
      yield put(verifyEmailError(response?.data?.msg));
    }
    // createNotification('success', response?.data?.detail)
    // window.location.href = "/auth/forgot-password/success";
  } catch (error) {
    let message;
    if (error.response) {
      message = error.response.data.msg;
    } else if (error.message) {
      message = error.message;
    }
    toast.error(message);
    yield put(verifyEmailError(message));
    setTimeout(() => {
      window.location.href = "/auth/email-verification";
    }, 300);
  }
}
function* logout({ state }) {
  yield setCurrentUser();
  localStorage.clear();
  window.location.href = "/auth/login";
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register);
}
export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}
export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}
export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}
export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

export function* watchSendVerificationEmail() {
  yield takeEvery(SEND_VERIFICATION_EMAIL, sendVerificationEmail);
}

export function* watchVerifyEmail() {
  yield takeEvery(VERIFY_EMAIL, verifyEmail);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchChangePassword),
    fork(watchSendVerificationEmail),
    fork(watchVerifyEmail),
  ]);
}
