import React from "react";
import { ShowIconComment } from "./FinetuneIconFb";

const FinetuneIconLn = () => {
  return (
    <div className="finetuneWrapper">
      <div className="lnComment">
        <ShowIconComment
          Icons={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="none"
              height="14px"
              width="14px"
            >
              <path
                d="M3.667 15.377H8a5.51 5.51 0 0 0 2.525-.551c.853-.367 1.822-.783 4.808-.783a.667.667 0 0 0 .667-.666v-6a.667.667 0 0 0-.667-.667h-2A4.625 4.625 0 0 1 9 2.043 1.445 1.445 0 0 0 7.503.623a1.733 1.733 0 0 0-1.29.59 2.394 2.394 0 0 0-.546 1.643c.052 1.006.198 2.005.438 2.984a.166.166 0 0 1-.163.2H1.667a1.667 1.667 0 0 0-.892 3.075 1.667 1.667 0 0 0 .667 2 1.667 1.667 0 0 0 .667 2 1.667 1.667 0 0 0 1.558 2.262Zm11-2.822a.166.166 0 0 1-.162.166A10.533 10.533 0 0 0 10 13.6c-.62.31-1.307.461-2 .443H3.667a.333.333 0 1 1 0-.667.667.667 0 1 0 0-1.333H3a.333.333 0 1 1 0-.667.667.667 0 1 0 0-1.333h-.667a.333.333 0 1 1 0-.667.667.667 0 0 0 0-1.333h-.666a.333.333 0 0 1 0-.667H7a.667.667 0 0 0 .645-.837A20.567 20.567 0 0 1 7 2.855c-.02-.27.054-.538.21-.758.187-.21.457-.148.457-.054a5.991 5.991 0 0 0 5.666 6H14.5a.167.167 0 0 1 .167.166v4.346Z"
                fill="#344563"
              ></path>
            </svg>
          }
          type="svg"
          text={"Like"}
          gap="0.6rem"
          color="#616770"
        />
        <ShowIconComment
          Icons={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              width="14px"
              height="14px"
            >
              <path
                d="M10.55 1.167h-5.1a5.45 5.45 0 1 0 0 10.898h.217v2.102a.667.667 0 0 0 1.15.458l2.425-2.558h1.309a5.45 5.45 0 1 0 0-10.899v-.001Zm0 9.565H8.956a.668.668 0 0 0-.484.208L7 12.493V11.4a.667.667 0 0 0-.667-.667H5.45a4.116 4.116 0 1 1 0-8.232h5.102a4.116 4.116 0 0 1 0 8.232Z"
                fill="#344563"
              ></path>
              <path stroke="#344563" d="M5 5.5h6M5 8.5h4"></path>
            </svg>
          }
          text={"Comment"}
          type="svg"
          gap="0.6rem"
          color="#616770"
        />
        <ShowIconComment
          Icons={
            <svg viewBox="0 0 24 24" width="14px" height="14px">
              <path d="M13.96 5H6c-.55 0-1 .45-1 1v10H3V6c0-1.66 1.34-3 3-3h7.96L12 0h2.37L17 4l-2.63 4H12l1.96-3Zm5.54 3H19v10c0 .55-.45 1-1 1h-7.96L12 16H9.63L7 20l2.63 4H12l-1.96-3H18c1.66 0 3-1.34 3-3V8h-1.5Z"></path>
            </svg>
          }
          type="svg"
          text={"Share"}
          gap="0.6rem"
          color="#616770"
        />
        <ShowIconComment
          Icons={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="14px"
              height="14px"
            >
              <path d="M23.854.2A.5.5 0 0 0 23.3.091L.3 9.99a.5.5 0 0 0-.036.9l6.67 3.531a.249.249 0 0 0 .276-.021L17.5 6.1a.25.25 0 0 1 .352.352l-8.3 10.29a.249.249 0 0 0-.026.274l3.531 6.67a.5.5 0 0 0 .442.266h.019a.5.5 0 0 0 .439-.3l10-22.9A.5.5 0 0 0 23.854.2Z"></path>
            </svg>
          }
          type="svg"
          text="Send"
          gap="0.6rem"
          color="#616770"
        />
      </div>
    </div>
  );
};

export default FinetuneIconLn;
