import { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  //  Button
} from "reactstrap";
import "react-calendar/dist/Calendar.css";
import businessIcon from "../../assets/img/bussiness-icon.svg";
import blankImage from "../../assets/img/blank-img.png";
import bluePlus from "../../assets/img/blue-plus-bus-icon.svg";
// import blueArrow from "../../assets/img/forward-blue-arrow.svg";
// import axios from "../../utils/Axios";
// import notification from "../../utils/Notification";
import { ThreeDots } from "react-loader-spinner";
import fireBaseStorage from "../../lib/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
//redux
import { connect } from "react-redux";
import {
  changeBusinessLogo,
  getBusinessDetails,
  updateBusinessDetails,
} from "../../redux/actions";
import SocialConnectionSection from "../../components/Socials/SocialConnectionSection";
import { toast } from "react-toastify";

const SetUpBussiness = (props) => {
  const [address, setAddress] = useState(null);
  const [addressInputError, setAddressInputError] = useState("");
  const [img, setImg] = useState(null);
  // const [percent, setPercent] = useState(0);

  const {
    businessDetails,
    getBusinessDetails,
    onCompleteAction,
    updateBusinessDetails,
    message,
    changeBusinessLogo,
    loading,
    error,
  } = props;

  //functions
  const uploadImage = useCallback(
    (file) => {
      const storageRef = ref(fireBaseStorage, `/business-logo/${file?.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const percent = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // ); // update progress
          // setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            changeBusinessLogo({ logo: url });
          });
        }
      );
    },
    [changeBusinessLogo]
  );
  //Handle ChangeLogo
  const handlePictureUpload = (e) => {
    const imageName = e.target.files[0];
    setImg(imageName);
  };

  const UpdateBusinessAddress = () => {
    if (address) {
      updateBusinessDetails({ address });
    } else {
      setAddressInputError("Business address is required");
    }
  };

  useEffect(() => {
    //Get Business Details
    getBusinessDetails();
  }, [getBusinessDetails]);

  useEffect(() => {
    if (img) {
      uploadImage(img);
    }
  }, [img, uploadImage]);

  useEffect(() => {
    if (!loading?.getBusiness && error?.getBusiness) {
      toast.error(error?.getBusiness);
    }
    if (!loading?.updateBusinessDetails && error?.updateBusinessDetails) {
      toast.error(error?.updateBusinessDetails);
    }
    if (!loading?.updateBusinessDetails && message?.updateBusinessDetails) {
      toast.success(message?.updateBusinessDetails);
    }
    if (!loading?.changeBusinessLogo && error?.changeBusinessLogo) {
      toast.error(error?.changeBusinessLogo);
    }
    if (!loading?.changeBusinessLogo && message?.changeBusinessLogo) {
      setImg(null);
      toast.success(message?.changeBusinessLogo);
    }
  }, [
    loading?.getBusiness,
    error?.getBusiness,
    loading?.updateBusinessDetails,
    error?.updateBusinessDetails,
    message?.updateBusinessDetails,
    loading?.changeBusinessLogo,
    message?.changeBusinessLogo,
    error?.changeBusinessLogo,
  ]);

  useEffect(() => {
    if (businessDetails?.address) {
      setAddress(businessDetails?.address);
    }
  }, [businessDetails]);

  return (
    <div className="dashboard dashboard-wrapper">
      <Row>
        <Col md="16" sm="16" lg="16" xxl="16" className="mb-3">
          <div>
            <Row>
              <Col md="16">
                <div className="dashboard-analytics">
                  <Card className="analytics-card px-3 py-0">
                    <div className="">
                      <Row>
                        <Col
                          md="12"
                          lg="12"
                          xl="6"
                          className="business__right-section px-5"
                        >
                          <Row className="mb-5 pr-5">
                            <Col md="3">
                              <img src={businessIcon} alt="" />
                            </Col>
                            <Col md="9">
                              <h6 className="subscription__card__content__modal__title text-left">
                                Set up business Information
                              </h6>
                              {/* <h6 className='subscription__card__content__modal__subtext text-left'>Set up business
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                  </h6> */}
                            </Col>
                          </Row>
                          <Row className="mb-5">
                            <label
                              className="business__right-section__image-upload-placeholder px-3 py-3"
                              for="logo"
                            >
                              <Row>
                                <Col md="3" className="">
                                  {loading?.changeBusinessLogo ? (
                                    <ThreeDots
                                      color="white"
                                      height={"12px"}
                                      wrapperStyle={{ display: "block" }}
                                    />
                                  ) : img ? (
                                    <img
                                      src={URL.createObjectURL(img)}
                                      className="img-container"
                                      style={{
                                        objectFit: "cover",
                                        width: "111px",
                                        height: "100%",
                                      }}
                                      alt="business logo"
                                    />
                                  ) : businessDetails?.logo ? (
                                    <img
                                      src={businessDetails?.logo}
                                      className="img-container"
                                      style={{
                                        objectFit: "contain",
                                        width: "111px",
                                        height: "100%",
                                      }}
                                      alt="business_logo"
                                    />
                                  ) : (
                                    <img src={blankImage} alt="" />
                                  )}
                                </Col>
                                <Col md="9" lg={9} sm={9}>
                                  <h6 className="business__right-section__image-upload-placeholder__title text-left">
                                    Upload Logo Here
                                  </h6>
                                  <h6 className="subscription__card__content__modal__subtext text-left ">
                                    Image format with max size of 3mb
                                  </h6>
                                  <div className="d-flex flex-row align-items-center">
                                    <div
                                      className="d-flex align-items-center"
                                      role="button"
                                    >
                                      <img src={bluePlus} alt="" />
                                      <label
                                        for="logo"
                                        className="pt-2 pb-2 ms-2 business__right-section__image-upload-placeholder__image-upload-blue text-nowrap"
                                      >
                                        Select a photo
                                      </label>
                                      <input
                                        type="file"
                                        className="d-none"
                                        id="logo"
                                        htmlFor="logo"
                                        onChange={(e) => {
                                          handlePictureUpload(e);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </label>
                          </Row>
                        </Col>
                        <Col md="12" lg="12" xl="6" className="px-2 pt-2 pb-5">
                          <div className="px-lg-5">
                            <h6 className="mt-5 business__address-title">
                              Enter Business address
                            </h6>
                            <input
                              className="business__left-section__input w-100 py-3 mt-4 px-3"
                              placeholder="Address"
                              type="text"
                              value={address}
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}
                              onBlur={UpdateBusinessAddress}
                            />
                            {addressInputError?.length ? (
                              <span className="text-danger text-left field-error">
                                {addressInputError}
                              </span>
                            ) : null}
                            <h6 className="mt-5 business__address-title">
                              Add social media accounts
                            </h6>
                            <SocialConnectionSection isClient={false} />
                            <Row className="px-4">
                              <button
                                // disabled={loading}
                                // onClick={handleBusinessSetup}
                                // onClick={() => togglePostState()}
                                className="mt-5 mb-4 py-3 business__left-section__done-btn"
                                style={{}}
                                onClick={onCompleteAction}
                              >
                                {/* {loading ? ( */}
                                {/* <div className="text-center w-100 align-items-center">
                                    <ThreeDots
                                      color="white"
                                      height={"12px"}
                                      wrapperStyle={{ display: "block" }}
                                    />
                                  </div> */}
                                {/* ) : ( */}
                                <span className="mx-auto w-100 text-center">
                                  Done
                                </span>
                                {/* )} */}
                              </button>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  const { businessDetails, message, loading, error } = settings;
  return {
    businessDetails,
    message,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  changeBusinessLogo,
  getBusinessDetails,
  updateBusinessDetails,
})(SetUpBussiness);
