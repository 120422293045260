import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./utils/ProtectedRoute";

import ClientProtectedRoutes from "./utils/ClientProtectedRoute";
import Signup from "./views/Auth/Register";
import Signin from "./views/Auth/Login";
import ForgotPassword from "./views/Auth/ForgotPassword";
// import Dashboard from "./views/Dashboard";
// import ClientManagement from "./views/ClientManagement";
// import ClientDetails from "./views/ClientManagement/ClientDetails";
// import Report from "./views/Report";
// import ReportDetails from "./views/Report/ReportDetails";
// import Settings from "./views/Settings";
import ClientDashboard from "./views/ClientPortal/Dashboard";
import ClientPost from "./views/ClientPortal/Posts";

// import Subscription from "./views/Subscription";
// import SetUpBussiness from "./views/Bussiness";
// import Financials from "./views/Financials";
// import Messages from "./views/Messages";
// import InvoiceDetailsPage from "./views/Financials/InvoiceDetailsPage";
// import ExpenseDetailsPage from "./views/Financials/ExpenseDetailsPage";
// import Schedule from "./views/Schedule";
// import NewPost from "./views/Schedule/NewPost";
// import Test from "./components/Inputs/Test";
import InvoicePage from "./views/InvoicePage";
// import Payouts from "./views/Financials/Payouts";
import ChangePassword from "./views/Auth/ChangePassword";
import ForgotPasswordSuccess from "./views/Auth/ForgotPasswordSuccess";
import ConnectSocials from "./views/Auth/ConnectSocials";
import AvailabeRoutes from "./utils/AvailableRoutes";
import ErrorPage from "./views/Error/ErrorPage";
import { getUserType } from "./utils/helper";
import VerifyEmail from "./views/Auth/VerifyEmail";
import VerifyEmailSuccess from "./views/Auth/VerifyEmailSuccess";

const RouterConfig = () => {
  const userType = getUserType();

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<ProtectedRoutes />}>
          {AvailabeRoutes(userType)}
        </Route>

        <Route exact path="/client" element={<ClientProtectedRoutes />}>
          <Route exact path="/client/dashboard" element={<ClientDashboard />} />
          <Route exact path="/client/posts" element={<ClientPost />} />
        </Route>
        {/* <Route exact path="/test" element={<Test />} /> */}
        <Route exact path="/auth/register" element={<Signup />} />
        <Route exact path="/auth/login" element={<Signin />} />
        <Route
          exact
          path="/auth/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          exact
          path="/auth/forgot-password/success"
          element={<ForgotPasswordSuccess />}
        />
        <Route
          exact
          path="/auth/verify-email/:id"
          element={<VerifyEmailSuccess />}
        />
        <Route
          exact
          path="/auth/email-verification"
          element={<VerifyEmail />}
        />
        <Route
          exact
          path="/auth/change-password/:id"
          element={<ChangePassword />}
        />
        <Route
          exact
          path="/socials/connection/:token"
          element={<ConnectSocials />}
        />
        <Route exact path="/invoice/:id" element={<InvoicePage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};
export default RouterConfig;
