import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { Row, Col } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
// import ColorPickerInput from "../../../components/Inputs/ColorPickerInput";

//validation
import { useFormik } from "formik";
import { clientPortalValidate } from "./validation";

//redux
import { connect } from "react-redux";
import { updateBusinessDetails } from "../../../redux/actions";
import { copierHelper } from "../../../utils/helper";

const ClientPortal = ({
  updateBusinessDetails,
  businessDetails,
  loading,
  unique_code,
  message,
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const formik = useFormik({
    initialValues: {
      // welcome_message: businessDetails?.welcome_message || "",
      // extra_information: businessDetails?.extra_information || "",
      brand_color: businessDetails?.brand_color || "#2062F4",
    },
    enableReinitialize: true,
    validate: clientPortalValidate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      updateBusinessDetails(values);
    },
  });

  return (
    <div className="invoice-form">
      <h1 className="my-5">Client Portal</h1>
      <form onSubmit={formik.handleSubmit}>
        <Row className="mb-4">
          {/* {formik?.errors?.brand_color && formik?.touched?.brand_color ? (
            <span className="text-danger text-left field-error">
              {formik?.errors?.brand_color}
            </span>
          ) : null} */}
          <Col sm="12" md="8" lg="8">
            <div className="d-flex justify-space-between align-items-center w-100 flex-1">
              <div
                className="d-flex align-items-center"
                style={{ width: "35%" }}
              >
                <label>Client Portal link</label>
              </div>
              <div
                style={{
                  background: "#E0E0E050",
                  padding: "0.6rem 1rem",
                  borderRadius: "10px",
                }}
                onClick={() =>
                  copierHelper(
                    `client.ontriv.com/?code=${unique_code}`,
                    "Client portal link"
                  )
                }
              >
                {`client.ontriv.com/?code=${unique_code}`}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          {formik?.errors?.brand_color && formik?.touched?.brand_color ? (
            <span className="text-danger text-left field-error">
              {formik?.errors?.brand_color}
            </span>
          ) : null}
          <Col sm="12" md="8" lg="8">
            <div className="d-flex justify-space-between align-items-center w-100 flex-1">
              <div
                className="d-flex align-items-center"
                style={{ width: "35%" }}
              >
                <label>Theme Color</label>
              </div>
              <div
                style={{
                  background: "#E0E0E050",
                  padding: "0.6rem 1rem",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <div
                    style={{
                      padding: "5px",
                      borderRadius: "1px",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                    onClick={handleClick}
                  >
                    <div
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        background: `${formik?.values?.brand_color}`,
                      }}
                    />
                  </div>
                  {displayColorPicker ? (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "2",
                      }}
                    >
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={handleClose}
                      />
                      <SketchPicker
                        color={formik?.values?.brand_color}
                        onChange={(color) => {
                          formik.setFieldValue("brand_color", color.hex);
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                {formik?.values?.brand_color}
              </div>
            </div>
          </Col>
        </Row>

        {/* <ColorPickerInput /> */}
        <button className="delete-btn" type="submit">
          {loading?.updateBusinessDetails ? (
            <div className="text-center w-100 align-items-center">
              <ThreeDots
                color="white"
                height={"12px"}
                wrapperStyle={{ display: "block" }}
              />
            </div>
          ) : (
            "Update"
          )}
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { businessDetails, loading, message } = state?.settings;
  const { userDetails } = state?.general;
  return {
    businessDetails,
    loading,
    message,
    unique_code: userDetails?.unique_code,
  };
};

export default connect(mapStateToProps, { updateBusinessDetails })(
  ClientPortal
);
