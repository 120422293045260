import {
  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_ERROR,
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_ERROR,
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_ERROR,
  INVITE_CLIENT,
  INVITE_CLIENT_SUCCESS,
  INVITE_CLIENT_ERROR,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_ERROR,
  RESET_MESSAGE,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_ERROR,
  GET_TAG,
  GET_TAG_SUCCESS,
  GET_TAG_ERROR,
  UPDATE_CLIENT_DETAILS,
  UPDATE_CLIENT_DETAILS_SUCCESS,
  UPDATE_CLIENT_DETAILS_ERROR,
  GET_CLIENT_INVOICE,
  GET_CLIENT_INVOICE_SUCCESS,
  GET_CLIENT_INVOICE_ERROR,
  GET_CLIENT_SCHEDULED_POSTS,
  GET_CLIENT_SCHEDULED_POSTS_SUCCESS,
  GET_CLIENT_SCHEDULED_POSTS_ERROR,
  GET_CLIENT_SOCIALS_CONNECTION_STATUS,
  GET_CLIENT_SOCIALS_CONNECTION_STATUS_SUCCESS,
  GET_CLIENT_SOCIALS_CONNECTION_STATUS_ERROR,
} from "../actions";

const INIT_STATE = {
  loading: {
    createClient: false,
    inviteClient: false,
    deleteClient: false,
    createTag: false,
    getTag: false,
    getClient: false,
    getClientDetails: false,
    updateClientDetails: false,
    getClientInvoice: false,
    getClientScheduledPost: false,
    getSocialsStatus: false,
  },
  message: {
    createClient: "",
    inviteClient: "",
    deleteClient: "",
    createTag: "",
    getTag: "",
    getClient: "",
    getClientDetails: "",
    updateClientDetails: "",
    getClientInvoice: "",
    getClientScheduledPost: "",
    getSocialsStatus: "",
  },
  error: {
    createClient: "",
    inviteClient: "",
    deleteClient: "",
    createTag: "",
    getTag: "",
    getClient: "",
    getClientDetails: "",
    updateClientDetails: "",
    getClientInvoice: "",
    getClientScheduledPost: "",
    getSocialsStatus: "",
  },
  tags: [],
  clients: [],
  clientDetails: null,
  clientInvoices: [],
  clientScheduledPosts: {},
  socialConnectionStatus: {},
};

const ClientReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_CLIENT:
      return {
        ...state,
        loading: { ...state?.loading, createClient: true },
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, createClient: false },
        message: { ...state?.message, createClient: action?.payload?.message },
      };
    case CREATE_CLIENT_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, createClient: false },
        error: { ...state?.error, createClient: action?.payload?.error },
      };

    case INVITE_CLIENT:
      return {
        ...state,
        loading: { ...state?.loading, inviteClient: true },
      };
    case INVITE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, inviteClient: false },
        message: { ...state?.message, inviteClient: action?.payload?.message },
      };
    case INVITE_CLIENT_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, inviteClient: false },
        error: { ...state?.error, inviteClient: action?.payload?.message },
      };
    case CREATE_TAG:
      return {
        ...state,
        loading: { ...state?.loading, createTag: true },
      };
    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, createTag: false },
        message: { ...state?.message, createTag: action?.payload?.message },
      };
    case CREATE_TAG_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, createTag: false },
        error: { ...state?.error, createTag: action?.payload?.error },
      };
    case DELETE_CLIENT:
      return {
        ...state,
        loading: { ...state?.loading, deleteClient: true },
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, deleteClient: false },
        message: { ...state?.message, deleteClient: action?.payload },
      };
    case DELETE_CLIENT_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, deleteClient: false },
        error: { ...state?.error, deleteClient: action?.payload },
      };
    case GET_TAG:
      return {
        ...state,
        loading: { ...state?.loading, getTag: true },
      };
    case GET_TAG_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getTag: false },
        tags: action.payload?.tags,
      };
    case GET_TAG_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getTag: false },
        error: { ...state?.error, getTag: action.payload.error },
      };
    case GET_CLIENT:
      return {
        ...state,
        loading: { ...state?.loading, getClient: true },
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getClient: false },
        clients: action.payload?.data,
      };
    case GET_CLIENT_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getClient: true },
        error: { ...state?.error, getClient: action.payload.error },
      };
    case GET_CLIENT_DETAILS:
      return {
        ...state,
        loading: { ...state?.loading, getClientDetails: true },
      };
    case GET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getClientDetails: false },
        clientDetails: action.payload?.data,
      };
    case GET_CLIENT_DETAILS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getClientDetails: false },
        error: { ...state?.error, getClientDetails: action.payload.error },
      };
    case UPDATE_CLIENT_DETAILS:
      return {
        ...state,
        loading: { ...state?.loading, updateClientDetails: true },
      };
    case UPDATE_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, updateClientDetails: false },
        message: { ...state?.message, updateClientDetails: action.payload },
      };
    case UPDATE_CLIENT_DETAILS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, updateClientDetails: false },
        error: { ...state?.loading, updateClientDetails: action.payload },
      };
    case GET_CLIENT_INVOICE:
      return {
        ...state,
        loading: { ...state?.loading, getClientInvoice: true },
      };
    case GET_CLIENT_INVOICE_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getClientInvoice: false },
        message: {
          ...state?.message,
          getClientInvoice: action.payload.message,
        },
        clientInvoices: action.payload.data,
      };
    case GET_CLIENT_INVOICE_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getClientInvoice: false },
        error: { ...state?.error, getClientInvoice: action.payload },
      };
    case GET_CLIENT_SCHEDULED_POSTS:
      return {
        ...state,
        loading: { ...state?.loading, getClientScheduledPost: true },
      };
    case GET_CLIENT_SCHEDULED_POSTS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getClientScheduledPost: false },
        clientScheduledPosts: action.payload,
      };
    case GET_CLIENT_SCHEDULED_POSTS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getClientScheduledPost: false },
        error: { ...state?.error, getClientScheduledPost: action.payload },
      };
    case GET_CLIENT_SOCIALS_CONNECTION_STATUS:
      return {
        ...state,
        loading: { ...state?.loading, getSocialsStatus: true },
      };
    case GET_CLIENT_SOCIALS_CONNECTION_STATUS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getSocialsStatus: false },
        socialConnectionStatus: action.payload,
      };
    case GET_CLIENT_SOCIALS_CONNECTION_STATUS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getSocialsStatus: false },
        error: { ...state?.error, getSocialsStatus: action.payload },
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: {
          createClient: "",
          inviteClient: "",
          deleteClient: "",
          createTag: "",
          getTag: "",
          getClient: "",
          getClientDetails: "",
          updateClientDetails: "",
          getClientInvoice: "",
          getClientScheduledPost: "",
          getSocialsStatus: "",
        },
        error: {
          createClient: "",
          inviteClient: "",
          deleteClient: "",
          createTag: "",
          getTag: "",
          getClient: "",
          getClientDetails: "",
          updateClientDetails: "",
          getClientInvoice: "",
          getClientScheduledPost: "",
          getSocialsStatus: "",
        },
      };
    default:
      return { ...state };
  }
};
export default ClientReducer;
