import React, { useEffect, useMemo } from "react";
import { Col, Card } from "reactstrap";
import { HiPlus, HiChevronRight } from "react-icons/hi";
import GreenCircle from "../../../assets/img/finanace-green-circle.svg";
import InvoiceIcon1 from "../../../assets/img/InvoiceIcon1.svg";
import InvoiceIcon2 from "../../../assets/img/invoiceIcon2.svg";
// import YellowCircle from "../../../assets/img/finanace-yellow-circle.svg";
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useNavigate } from "react-router-dom";
import { formatAmount, formatInvoiceIssueDate } from "../../../utils/helper";
import { GoDotFill } from "react-icons/go";
//react-redux
import { connect } from "react-redux";
import { getDashboardRevenue, getAllInvoices } from "../../../redux/actions";

const FinancialCard = (props) => {
  const currentYear = new Date().getFullYear();
  const {
    handleAddInvoice,
    getDashboardRevenue,
    dashboardRevenue,
    getAllInvoices,
    invoices,
    currencySymbol,
    currency,
  } = props;
  const navigate = useNavigate();

  //Get current year and add 50 and return an array
  // const range = (start, stop, step) =>
  //   Array.from(
  //     { length: (stop - start) / step + 1 },
  //     (_, i) => start + i * step
  //   );
  // const years = useMemo(
  //   () => range(currentYear, currentYear + 50, 1),
  //   [currentYear]
  // );

  // const handleSelectYear = (e) => {
  //   const { value } = e.target;
  //   setActiveYear(value);
  // };

  echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);
  useEffect(() => {
    var chartDom = document.getElementById("chart-container");
    var myChart = echarts.init(chartDom, null, {
      renderer: "canvas",
      useDirtyRect: false,
      useCoarsePointer: true,
    });
    var option;

    option = {
      xAxis: {
        type: "category",
        data: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: dashboardRevenue?.[currency],
          type: "line",
          showSymbol: false,
          smooth: true,
          color: "#2062F4",
        },
      ],
    };

    option && myChart.setOption(option);
  }, [currentYear, dashboardRevenue, currency]);

  useEffect(() => {
    if (currentYear) {
      getDashboardRevenue(currentYear);
    }
  }, [currentYear, getDashboardRevenue]);

  //get pending Invoices
  const overdueInvoices = useMemo(() => {
    return (
      invoices?.length > 0 &&
      invoices?.filter(
        (invoice) => invoice?.status?.toLowerCase() === "overdue"
      )
    );
  }, [invoices]);

  //get paidInvoices
  const paidInvoices = useMemo(() => {
    return (
      invoices?.length > 0 &&
      invoices?.filter((invoice) => invoice?.status?.toLowerCase() === "paid")
    );
  }, [invoices]);

  useEffect(() => {
    getAllInvoices();
  }, [getAllInvoices]);

  return (
    <Col lg="12" md="7" xl="7" className="mb-3  nn">
      <Card className="overview-card">
        <div className="finacial-overview">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="pl-2 financial-overview-header mb-0">
              Financial Overview
            </h4>
            {/* <select
              className="px-5 py-2"
              style={{ borderRadius: "12px" }}
              onChange={handleSelectYear}
              value={activeYear}
            >
              {years?.map((year, idx) => {
                return (
                  <option value={year} key={idx}>
                    {year}
                  </option>
                );
              })}
            </select> */}
          </div>
          <div className="financial-overview-chart">
            <div className="chart-container" id="chart-container">
              {/* <img src={chart} alt="chart" /> */}
            </div>
          </div>
        </div>

        <div className="invoice-overview">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="pl-2 financial-overview-header mb-0">
              Invoice Overview
            </h4>
            <p
              className="mb-0"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/invoices-&-financials")}
            >
              See more{" "}
              <span>
                <HiChevronRight size="24px" color="#49A8F8" />
              </span>
            </p>
          </div>
        </div>

        <div
          className={`d-flex flex-row ${
            invoices?.length
              ? "justify-content-between"
              : "justify-content-center"
          } w-100`}
        >
          {invoices?.length ? (
            invoices?.slice(0, 2)?.map((invoice, index) => (
              <div
                className={`dashboard-invoice-card ${
                  index === 0 ? "card1-bg" : "card2-bg"
                }`}
                key={invoice?.id}
              >
                <p className="text-center font-weight-600 font-16px">
                  <img
                    src={index === 0 ? InvoiceIcon1 : InvoiceIcon2}
                    className="me-2"
                    alt="invoice icon"
                  />
                  INV #{invoice?.invoice_number}
                </p>
                <div className="card-content">
                  <p className="m-0 mb-1 amount font-weight-600">
                    {currencySymbol} {formatAmount(invoice?.total)}
                  </p>
                  <p className="m-0 mb-1">
                    Date: {formatInvoiceIssueDate(invoice?.issued_on)}
                  </p>
                  <p className="m-0">
                    To:{" "}
                    {invoice?.client?.client_business_name ||
                      invoice?.client?.fullname}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div
              className="d-flex flex-column h-100 align-items-center justify-content-center"
              style={{ margin: "1.5rem 0" }}
            >
              <div
                className="text-center pt-3"
                style={{ whiteSpace: "nowrap" }}
              >
                <p>Get started by creating your first invoice</p>
              </div>
              <button
                className="btn btn-primary send px-5 py-2"
                style={{ whiteSpace: "nowrap" }}
                onClick={handleAddInvoice}
              >
                <HiPlus color="#2465ec" className="create-icon" /> Create
                Invoice
              </button>
            </div>
          )}

          {invoices?.length > 0 && (
            <div className="d-flex flex-column my-2 font-12px px-2 pt-2">
              <div className="mb-3">
                <img src={GreenCircle} className="me-2" alt="" />
                <span className="my-auto mp-2">Paid</span>
                <h6 className="ms-3">{paidInvoices?.length}</h6>
              </div>
              <div className="d-flex flex-row align-items-start ms-n3">
                <GoDotFill
                  color="red"
                  size="21px"
                  style={{ marginTop: "-0.13rem", marginLeft: "-0.4rem" }}
                />
                <div>
                  <span className="my-auto mp-2 pt-2">Overdue</span>
                  <h6 style={{}}>{overdueInvoices?.length}</h6>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardRevenue: state?.dashboard?.dashboardRevenue,
    invoices: state?.invoice?.invoices,
  };
};
export default connect(mapStateToProps, {
  getDashboardRevenue,
  getAllInvoices,
})(FinancialCard);
