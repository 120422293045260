import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Row, Col, Card } from "reactstrap";
import bluePlus from "../../../assets/img/blue-plus-bus-icon.svg";
import { ThreeDots } from "react-loader-spinner";
import { useFormik } from "formik";

//Notification
//React-hook-form
//Select Component
// import Select from "react-select";
import SelectInput from "../../../components/Select/SelectInput";
import countryList from "react-select-country-list";

import { connect } from "react-redux";
import {
  getBusinessDetails,
  updateBusinessDetails,
  changeBusinessLogo,
} from "../../../redux/actions";
import SocialConnectionSection from "../../../components/Socials/SocialConnectionSection";
//firebase
import fireBaseStorage from "../../../lib/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { timezones } from "../../../utils/timezones";
import { toast } from "react-toastify";

const BusinessDetails = ({
  businessDetails,
  getBusinessDetails,
  updateBusinessDetails,
  message,
  changeBusinessLogo,
  error,
  loading,
}) => {
  const [data, setdata] = useState({});
  const [img, setImg] = useState(null);
  // const [percent, setPercent] = useState(0);

  //fileInput Ref
  const inputRef = useRef(null);

  const options = useMemo(() => countryList().getData(), []);
  const timezoneOptions = useMemo(
    () =>
      timezones.map((timezone) => {
        return { value: timezone, label: timezone };
      }),
    []
  );

  //form Validation
  const validate = (values) => {
    const errors = {};
    // if (!values.footer_note) {
    //   errors.footer_note = "Footer note cannot be empty";
    // }
    if (!values.business_country) {
      errors.business_country = "Choose a country";
    }
    if (!values.business_name) {
      errors.business_name = "Business name is required";
    }
    if (!values.website) {
      errors.website = "Website is required";
    }
    if (!values.user_timezone) {
      errors.user_timezone = "Choose a timezone";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      business_country: businessDetails?.business_country,

      business_name: businessDetails?.business_name || "",
      website: businessDetails?.website || "",
      user_timezone: businessDetails?.user_timezone || "",
      // address: businessDetails?.address || "",
    },
    validate,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateBusinessDetails({ ...values, country: values?.country?.value });
    },
  });

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };
  const uploadImage = useCallback(
    (file) => {
      const storageRef = ref(fireBaseStorage, `/business-logo/${file?.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const percent = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // ); // update progress
          // setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            changeBusinessLogo({ logo: url });
          });
        }
      );
    },
    [changeBusinessLogo]
  );
  //Handle ChangeLogo
  const changeLogo = (e) => {
    const imageName = e.target.files[0];
    setImg(imageName);
  };

  const GetBusinessDetails = useCallback(() => {
    getBusinessDetails();
  }, [getBusinessDetails]);

  useEffect(() => {
    //Get Business Details
    GetBusinessDetails();
  }, [GetBusinessDetails]);

  useEffect(() => {
    if (businessDetails && Object.entries(businessDetails)?.length > 0) {
      const countryObject = options.filter(
        (item) => item.value === businessDetails?.business_country
      );
      setdata({ ...businessDetails, country: countryObject || null });
    }
  }, [businessDetails, options]);

  useEffect(() => {
    // console.log(loginError, message, loading);
    if (!loading?.updateBusinessDetails && error?.updateBusinessDetails) {
      toast.error(error?.updateBusinessDetails);
    }
    if (!loading?.updateBusinessDetails && message?.updateBusinessDetails) {
      toast.success(message?.updateBusinessDetails);
    }
    if (!loading?.changeBusinessLogo && error?.changeBusinessLogo) {
      toast.error(error?.changeBusinessLogo);
    }
    if (!loading?.changeBusinessLogo && message?.changeBusinessLogo) {
      setImg(null);
      toast.success(message?.changeBusinessLogo);
    }
  }, [
    loading?.updateBusinessDetails,
    error?.updateBusinessDetails,
    message?.updateBusinessDetails,
    message,
    loading?.changeBusinessLogo,
    message?.changeBusinessLogo,
    error?.changeBusinessLogo,
  ]);

  useEffect(() => {
    if (img) {
      uploadImage(img);
    }
  }, [img, uploadImage]);
  return (
    <div className="business-details mt-4">
      <Row>
        <Col xl="6" className="mb-3">
          <div>
            <Card className="business-details-form-card">
              <h1 className="mb-5">Business Details</h1>
              <form onSubmit={formik.handleSubmit}>
                <input
                  type="text"
                  placeholder="Business name"
                  className="w-100"
                  name="business_name"
                  onChange={formik.handleChange}
                  value={formik?.values?.business_name}
                />
                {formik?.errors?.business_name &&
                formik?.touched?.business_name ? (
                  <span className="text-danger text-left field-error">
                    {formik?.errors?.business_name}
                  </span>
                ) : null}
                <input
                  type="text"
                  placeholder="Website"
                  className="w-100"
                  name="website"
                  value={formik?.values?.website}
                  onChange={formik.handleChange}
                />
                {formik?.errors?.website && formik?.touched?.website ? (
                  <span className="text-danger text-left field-error">
                    {formik?.errors?.website}
                  </span>
                ) : null}
                {/* <input
                  type="text"
                  placeholder="Office address"
                  className="w-100"
                  // name="address"
                  defaultValue={data?.address}
                  {...register("address")}
                /> */}
                <SelectInput
                  showSearch={true}
                  options={options}
                  placeholder="Select Country"
                  name="business_country"
                  value={formik?.values?.business_country}
                  onChange={(value) =>
                    formik.setFieldValue("business_country", value)
                  }
                  className={`w-100 ${
                    formik?.errors?.business_country &&
                    formik?.touched?.business_country
                      ? "mb-3"
                      : "mb-3"
                  }  ${
                    formik?.errors.business_country ? "border-danger" : ""
                  } `}
                />
                {formik?.errors?.business_country &&
                formik?.touched?.business_country ? (
                  <span className="text-danger text-left field-error d-block">
                    {formik?.errors?.business_country}
                  </span>
                ) : null}
                <SelectInput
                  options={timezoneOptions}
                  placeholder="Select your Timezone"
                  name="user_timezone"
                  value={formik?.values?.user_timezone}
                  onChange={(value) =>
                    formik.setFieldValue("user_timezone", value)
                  }
                  className={`w-100 ${
                    formik?.errors?.user_timezone &&
                    formik?.touched?.user_timezone
                      ? "mb-3"
                      : "mb-3"
                  }  ${formik?.errors.user_timezone ? "border-danger" : ""} `}
                />
                {formik?.errors?.user_timezone &&
                formik?.touched?.user_timezone ? (
                  <span className="text-danger text-left field-error d-block">
                    {formik?.errors?.user_timezone}
                  </span>
                ) : null}
                <button
                  className="delete-btn w-50"
                  type="submit"
                  disabled={loading?.updateBusinessDetails}
                >
                  {loading?.updateBusinessDetails ? (
                    <ThreeDots
                      color="white"
                      height={"12px"}
                      wrapperStyle={{ display: "block" }}
                    />
                  ) : (
                    "Update"
                  )}
                </button>
              </form>
            </Card>
          </div>
        </Col>
        <Col xl="6" className="bg-white">
          <div>
            <Card className="upload-picture-container mb-4 w-100">
              <h1>Business Logo</h1>
              <div className="d-flex ">
                {loading?.changeBusinessLogo ? (
                  <div>loading!!!!</div>
                ) : img ? (
                  <img
                    src={URL.createObjectURL(img)}
                    className="img-container"
                    style={{ objectFit: "cover" }}
                    alt="business logo"
                  />
                ) : data?.logo ? (
                  <img
                    src={data?.logo}
                    className="img-container"
                    style={{ objectFit: "cover" }}
                    alt="business logo"
                  />
                ) : (
                  <div className="img-container" alt="company logo"></div>
                )}

                <div className="upload-text-wrapper">
                  <h1>Upload Logo</h1>
                  <p className="mb-0">Image format with max size of 3mb</p>
                  <div className="d-flex flex-row">
                    <button className="d-flex align-items-center bg-transparent border-0">
                      <img src={bluePlus} alt="plus icon" />
                      <h6
                        className="pt-2 ms-2 business__right-section__image-upload-placeholder__image-upload-blue text-nowrap"
                        onClick={handleClick}
                      >
                        Upload new Logo
                      </h6>
                    </button>
                  </div>
                  <input
                    type="file"
                    className="w-100 file-upload-button"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={changeLogo}
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className="px-3 py-2">
            <h1 className="my-4 business__accounts-title ">
              Your Connected Accounts
            </h1>
            <SocialConnectionSection isClient={false} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  const {
    businessDetails,
    updateBusinessDetailsError,
    updatingStateLoading,
    message,
    changeLogoloading,
    changeLogoError,
    error,
    loading,
  } = settings;
  return {
    businessDetails,
    updateBusinessDetailsError,
    updatingStateLoading,
    message,
    changeLogoloading,
    changeLogoError,
    error,
    loading,
  };
};
export default connect(mapStateToProps, {
  getBusinessDetails,
  updateBusinessDetails,
  changeBusinessLogo,
})(BusinessDetails);
