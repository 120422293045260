export const addVendorValidate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Name is required";
  }
  if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Email is invalid";
    }
  } else {
    errors.email = "Email is required";
  }
  return errors;
};
