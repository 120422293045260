/* eslint-disable no-self-compare */
import React from "react";
import { Modal } from "antd";
import { capitalizeWord } from "../../utils/func";
const PreviewPost = (props) => {
  const { open, handleClose, record, channel } = props;
  return (
    <Modal
      centered
      open={open}
      onCancel={handleClose}
      width={780}
      footer={null}
    >
      <div className="preview-modal">
        <div>
          {record?.post_status === "pending" ? (
            <div className="pending">
              <div></div>
              <p className="m-0">{capitalizeWord(record?.post_status)}</p>
            </div>
          ) : record?.post_status === "scheduled" ? (
            <div className="scheduled">
              <div></div>
              <p className="m-0">{capitalizeWord(record?.post_status)}</p>
            </div>
          ) : record?.post_status === "published" ? (
            <div className="published">
              <div></div>
              <p className="m-0">{capitalizeWord(record?.post_status)}</p>
            </div>
          ) : record?.post_status === "draft" ? (
            <div className="draft">
              <div></div>
              <p className="m-0">{capitalizeWord(record?.post_status)}</p>
            </div>
          ) : null}
        </div>
        <div className="preview-post">
          <div style={{ width: "60%" }}>
            {record?.media_type?.toLowerCase() === "image" ? (
              <img
                src={
                  channel === "twitter"
                    ? record?.post_details?.media
                    : record?.media
                }
                alt="post media"
              />
            ) : (
              <video
                width="100%"
                src={
                  channel === "twitter"
                    ? record?.post_details?.media
                    : record?.media
                }
                controls
              />
            )}
          </div>

          <div className="post-caption">
            <h4>Caption</h4>
            <div style={{ height: "417px", overflowY: "auto" }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(record?.post_details?.caption)
                    ?.replace(/\\n/g, "<br>")
                    ?.replaceAll('"', ""),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewPost;
