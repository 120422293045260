import { put, takeEvery, fork, all, call } from "redux-saga/effects";
import Axios from "../../utils/Axios";
import {
  RESET_PASSWORD,
  GET_BUSINESS_DETAILS,
  UPDATE_BUSINESS_DETAILS,
  CHANGE_BUSINESS_LOGO,
  CHANGE_PROFILE_IMAGE,
  UPDATE_USER_DETAILS,
  CONNECT_PAYMENT_METHOD,
  GET_ALL_PAYMENT_METHODS,
  GET_INVOICE_SETTINGS,
  UPDATE_INVOICE_SETTINGS,
  resetPasswordSuccess,
  resetPasswordError,
  clearMessages,
  getBusinessDetailsSuccess,
  getBusinessDetailsError,
  updateBusinessDetailsSuccess,
  updateBusinessDetailsError,
  changeBusinessLogoSuccess,
  changeBusinessLogoError,
  changeProfileImageSuccess,
  changeProfileImageError,
  updateUserDetailsSuccess,
  updateUserDetailsError,
  connectPaymentMethodSuccess,
  connectPaymentMethodError,
  getAllPaymentMethodsError,
  getAllPaymentMethodsSuccess,
  updateInvoiceSettingSuccess,
  updateInvoiceSettingError,
  getInvoiceSettingsSuccess,
  getInvoiceSettingsError,
  getInvoiceSettings,
  updatePaymentMethodSuccess,
  updatePaymentMethodError,
  UPDATE_PAYMENT_METHOD,
} from "../actions";
import { GetUserDetails } from "../General/saga";

function* resetUserPassword({ payload }) {
  const { credentials } = payload;

  try {
    const response = yield Axios.post(
      `/accounts/api/v1/password/change/`,
      credentials
    );
    if (response?.status === 200) {
      yield put(resetPasswordSuccess());
    } else {
      yield put(resetPasswordError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.new_password1
        ? error?.response?.data?.new_password[0]
        : error?.response?.data?.new_password2[0];

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(resetPasswordError(message));
    yield put(clearMessages());
  }
}

function* getBusinessDetails() {
  try {
    const response = yield Axios.get(`/business/api/v1/manage-business/`);
    if (response?.status === 200) {
      yield put(getBusinessDetailsSuccess(response?.data));
    } else {
      yield put(getBusinessDetailsError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error?.response) {
      const errorMessage = error?.response?.data?.new_password1
        ? error?.response?.data.new_password?.[0]
        : error?.response?.data?.new_password2?.[0];

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getBusinessDetailsError(message));
    yield put(clearMessages());
  }
}

function* UpdateBusinessDetails({ payload }) {
  const { credentials } = payload;
  try {
    const response = yield Axios.patch(
      `/business/api/v1/manage-business/`,
      credentials
    );
    if (response?.status === 200) {
      yield put(updateBusinessDetailsSuccess());
      yield call(getBusinessDetails);
    } else {
      yield put(updateBusinessDetailsError(response.data.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data.website
        ? error?.response?.data?.website?.[0]
        : error?.response?.data?.business_name
        ? error?.response?.data.business_name?.[0]
        : error?.response?.data?.address
        ? error?.response.data?.address?.[0]
        : error?.response?.data?.country;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(updateBusinessDetailsError(message));
    yield put(clearMessages());
  }
}
function* ChangeBusinessLogo({ payload }) {
  const { credentials } = payload;
  try {
    const response = yield Axios.patch(
      `/business/api/v1/manage-business/`,
      credentials
    );
    if (response?.status === 200) {
      yield put(changeBusinessLogoSuccess());
      yield call(getBusinessDetails);
    } else {
      yield put(updateBusinessDetailsError(response.data.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.website
        ? error?.response?.data?.website?.[0]
        : error?.response?.data.business_name
        ? error?.response?.data.business_name?.[0]
        : error?.response?.data?.address
        ? error?.response.data?.address?.[0]
        : error?.response?.data?.country;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(changeBusinessLogoError(message));
    yield put(clearMessages());
  }
}
function* ChangeProfileImage({ payload }) {
  const { credentials } = payload;
  try {
    const response = yield Axios.patch(
      `/accounts/api/v1/user/`,
      credentials,
      {}
    );
    if (response?.status === 200) {
      yield put(changeProfileImageSuccess());
      yield call(GetUserDetails);
    } else {
      yield put(changeProfileImageError(response.data.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data.website
        ? error?.response?.data?.website?.[0]
        : error?.response?.data?.business_name
        ? error?.response?.data?.business_name?.[0]
        : error?.response?.data?.address
        ? error?.response.data?.address?.[0]
        : error?.response?.data?.country;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(changeProfileImageError(message));
    yield put(clearMessages());
  }
}
function* UpdateUserDetails({ payload }) {
  const { credentials } = payload;
  try {
    const response = yield Axios.patch(`/accounts/api/v1/user/`, credentials);
    if (response?.status === 200) {
      yield put(updateUserDetailsSuccess());
      yield call(GetUserDetails);
    } else {
      yield put(updateUserDetailsError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.phone
        ? error?.response?.data?.phone?.[0]
        : error?.response?.data?.fullname
        ? error?.response?.data?.fullname?.[0]
        : null;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(updateUserDetailsError(message));
    yield put(clearMessages());
  }
}

function* getAllPaymentMethods() {
  try {
    const response = yield Axios.get(`/payment/api/v1/payment-gateway/`);
    if (response?.status === 200) {
      yield put(getAllPaymentMethodsSuccess(response?.data));
    } else {
      yield put(getAllPaymentMethodsError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getAllPaymentMethodsError(message));
    yield put(clearMessages());
  }
}

function* ConnectPaymentMethod({ payload }) {
  const { data } = payload;
  try {
    const response = yield Axios.post(`/payment/api/v1/payment-gateway/`, data);
    if (response?.status === 200) {
      yield put(connectPaymentMethodSuccess(response?.data));
    } else {
      // console.log('response?.data')
      // console.log(response?.data)
      // yield put(connectPaymentMethodError(response?.data?.name));
      // yield put(connectPaymentMethodError());
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.name[0];

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(connectPaymentMethodError(message));
    yield put(clearMessages());
  }
}

function* UpdatePaymentMethod({ payload }) {
  const { data } = payload;
  try {
    const response = yield Axios.patch(
      `/payment/api/v1/payment-gateway/${data.id}/`,
      data
    );
    if (response?.status === 200) {
      yield put(updatePaymentMethodSuccess(response?.data));
    } else {
      yield put(updatePaymentMethodError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error?.response?.data?.phone
        ? error?.response?.data?.phone?.[0]
        : error?.response?.data?.fullname
        ? error.response?.data?.fullname?.[0]
        : null;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(updateUserDetailsError(message));
    yield put(clearMessages());
  }
}

function* GetInvoiceSettings() {
  try {
    const response = yield Axios.get(
      `/invoice/api/v1/invoice/settings/default/`
    );
    if (response?.status === 200) {
      yield put(getInvoiceSettingsSuccess(response?.data));
    } else {
      yield put(getInvoiceSettingsError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.message;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getInvoiceSettingsError(message));
    yield put(clearMessages());
  }
}

function* UpdateInvoiceSettings({ payload }) {
  try {
    const response = yield Axios.patch(
      `/invoice/api/v1/invoice/settings/default/`,
      payload
    );
    if (response?.status === 200) {
      yield put(updateInvoiceSettingSuccess());
      yield call(getInvoiceSettings);
    } else {
      yield put(updateInvoiceSettingError(response.data.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    let errorMessage = "";
    if (error.response) {
      Object.keys(error?.response?.data)?.forEach(
        (item) => (errorMessage = `${item} is required`)
      );

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(updateInvoiceSettingError(message));
    yield put(clearMessages());
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetUserPassword);
}

export function* watchUpdateBusinessDetails() {
  yield takeEvery(UPDATE_BUSINESS_DETAILS, UpdateBusinessDetails);
}

export function* watchGetBusinessDetails() {
  yield takeEvery(GET_BUSINESS_DETAILS, getBusinessDetails);
}
export function* watchChangeBusinessLogo() {
  yield takeEvery(CHANGE_BUSINESS_LOGO, ChangeBusinessLogo);
}
export function* watchChangeProfileImage() {
  yield takeEvery(CHANGE_PROFILE_IMAGE, ChangeProfileImage);
}
export function* watchUpdateUserDetails() {
  yield takeEvery(UPDATE_USER_DETAILS, UpdateUserDetails);
}

export function* watchGetAllPaymentMethods() {
  yield takeEvery(GET_ALL_PAYMENT_METHODS, getAllPaymentMethods);
}
export function* watchConnectPaymentMethod() {
  yield takeEvery(CONNECT_PAYMENT_METHOD, ConnectPaymentMethod);
}
export function* watchUpdatePaymentMethod() {
  yield takeEvery(UPDATE_PAYMENT_METHOD, UpdatePaymentMethod);
}

export function* watchGetInvoiceSettings() {
  yield takeEvery(GET_INVOICE_SETTINGS, GetInvoiceSettings);
}

export function* watchUpdateInvoiceSettings() {
  yield takeEvery(UPDATE_INVOICE_SETTINGS, UpdateInvoiceSettings);
}
export default function* rootSaga() {
  yield all([
    fork(watchResetPassword),
    fork(watchUpdateBusinessDetails),
    fork(watchGetBusinessDetails),
    fork(watchChangeBusinessLogo),
    fork(watchChangeProfileImage),
    fork(watchUpdateUserDetails),
    fork(watchGetAllPaymentMethods),
    fork(watchConnectPaymentMethod),
    fork(watchUpdatePaymentMethod),
    fork(watchGetInvoiceSettings),
    fork(watchUpdateInvoiceSettings),
  ]);
}
