import {
  GET_DASHBOARD_METRICS,
  GET_DASHBOARD_METRICS_SUCCESS,
  GET_DASHBOARD_METRICS_ERROR,
  GET_DASHBOARD_REVENUE,
  GET_DASHBOARD_REVENUE_SUCCESS,
  GET_DASHBOARD_REVENUE_ERROR,
  GET_DASHBOARD_SCHEDULED_POSTS,
  GET_DASHBOARD_SCHEDULED_POSTS_SUCCESS,
  GET_DASHBOARD_SCHEDULED_POSTS_ERROR,
  GET_DASHBOARD_POST_IDEAS,
  GET_DASHBOARD_POST_IDEAS_SUCCESS,
  GET_DASHBOARD_POST_IDEAS_ERROR,
} from "../actions";

export const getAllDashboardMetrics = () => ({
  type: GET_DASHBOARD_METRICS,
});

export const getAllDashboardMetricsSuccess = (data) => ({
  type: GET_DASHBOARD_METRICS_SUCCESS,
  payload: data,
});

export const getAllDashboardMetricsError = (error) => ({
  type: GET_DASHBOARD_METRICS_ERROR,
  payload: error,
});
export const getDashboardRevenue = (year) => ({
  type: GET_DASHBOARD_REVENUE,
  payload: year,
});
export const getDashboardRevenueSuccess = (data) => ({
  type: GET_DASHBOARD_REVENUE_SUCCESS,
  payload: data,
});
export const getDashboardRevenueError = (error) => ({
  type: GET_DASHBOARD_REVENUE_ERROR,
  payload: error,
});
export const getDashboardScheduledPosts = (params) => ({
  type: GET_DASHBOARD_SCHEDULED_POSTS,
  payload: params,
});
export const getDashboardScheduledPostsSuccess = (posts) => ({
  type: GET_DASHBOARD_SCHEDULED_POSTS_SUCCESS,
  payload: posts,
});
export const getDashboardScheduledPostsError = (error) => ({
  type: GET_DASHBOARD_SCHEDULED_POSTS_ERROR,
  payload: error,
});
export const getDashboardPostIdea = () => ({
  type: GET_DASHBOARD_POST_IDEAS,
});
export const getDashboardPostIdeaSuccess = (posts) => ({
  type: GET_DASHBOARD_POST_IDEAS_SUCCESS,
  payload: posts,
});
export const getDashboardPostIdeaError = (error) => ({
  type: GET_DASHBOARD_POST_IDEAS_ERROR,
  payload: error,
});
