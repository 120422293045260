import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  sendLinkedInCodeAndState,
  sendFacebookCodeAndState,
  sendTwitterCodeAndState,
  getTwitterV1CallbackURL,
  sendTwitterV1CodeAndState,
  connectSelectedFacebookPage,
  getConnectedInstagramAccount,
  connectInstagramAccount,
  connectLinkedInPage,
} from "../../redux/actions";
import { connect } from "react-redux";
import logo from "../../assets/img/logo.png";
import SocialsConnectImage from "../../assets/img/socials_page_image.svg";
import instagram from "../../assets/img/instagram.png";
import facebook from "../../assets/img/facebook.png";
import Linkedin from "../../assets/img/linkedin.svg";
// import userAvatar from "../../assets/img/avatar.png";
const ConnectSocials = ({
  sendLinkedInCodeAndState,
  sendFacebookCodeAndState,
  sendTwitterCodeAndState,
  getTwitterV1CallbackURL,
  twitterV1CallbackURl,
  sendTwitterV1CodeAndState,
  connectSelectedFacebookPage,
  connectLinkedInPage,
  getConnectedInstagramAccount,
  instagramData,
  connectInstagramAccount,
  message,
  loading,
  error,
  facebookData,
  linkedInPages,
}) => {
  const [activeSocial, setActiveSocial] = useState("");
  const [availableAccounts, setAvailableAccounts] = useState([]);

  //functions
  const handleSuccess = (message, callback) => {
    if (message?.length) {
      toast.success(message);
      if (callback) {
        callback();
      }
      setTimeout(() => window.close(), 700);
    }
  };

  const handleError = (error, message) => {
    if (error?.length) {
      toast.error(message);
    }
  };

  const handleConnectSelectedFacebookPage = (selectedAccount) => {
    let currentURl = window.location.href;
    const id = localStorage.getItem("id");
    const currentURLArray = currentURl?.split("/");
    const getParamsFromArray = currentURLArray[currentURLArray?.length - 1];
    const paramsArray = getParamsFromArray?.split("&");
    let code = paramsArray[0]?.slice(6);
    let state = paramsArray[1]?.slice(6);
    let payload = {
      id,
      credentials: {
        state,
        code,
      },
      data: {
        ...selectedAccount,
        user_access_token: facebookData?.user_access_token,
      },
    };
    connectSelectedFacebookPage(payload);
  };
  const handleConnectSelectedLinkedInPage = (selectedAccount) => {
    let currentURl = window.location.href;
    const id = localStorage.getItem("id");
    const currentURLArray = currentURl?.split("/");
    const getParamsFromArray = currentURLArray[currentURLArray?.length - 1];
    const paramsArray = getParamsFromArray?.split("&");
    let code = paramsArray[0]?.slice(6);
    let state = paramsArray[1]?.slice(6);
    let payload = {
      id,
      credentials: {
        state,
        code,
      },
      data: selectedAccount,
    };
    connectLinkedInPage(payload);
  };

  const handleConnectSelectedInstgramPage = (selectedAccount) => {
    const id = localStorage.getItem("id");

    const payload = {
      ...selectedAccount,
      id,
    };
    connectInstagramAccount(payload);
  };
  useEffect(() => {
    let currentURl = window.location.href;
    const id = localStorage.getItem("id");
    const currentURLArray = currentURl?.split("/");
    setActiveSocial(currentURLArray[5]);
    const getParamsFromArray = currentURLArray[currentURLArray?.length - 1];
    const paramsArray = getParamsFromArray?.split("&");
    let code =
      currentURLArray[5] === "twitter"
        ? paramsArray[0]?.slice(7)
        : paramsArray[0]?.slice(6);
    let state =
      currentURLArray[5] === "twitter"
        ? paramsArray[1]?.slice(5)
        : paramsArray[1]?.slice(6);
    if (currentURLArray[5]?.toLowerCase() === "linkedin") {
      sendLinkedInCodeAndState({
        payload: { code, state: state?.replace("%", "$") },
        id,
      });
    } else if (currentURLArray[5]?.toLowerCase() === "facebook") {
      sendFacebookCodeAndState({
        credentials: { code, state: state?.replace("%", "$") },
        id,
      });
    } else if (currentURLArray[5]?.toLowerCase() === "twitter") {
      //twitter needed a tweak because the code and state were reversed
      sendTwitterCodeAndState({
        credentials: { code: state, state: code },
        id,
      });
    } else if (currentURLArray[5]?.toLowerCase() === "instagram") {
      getConnectedInstagramAccount(id);
    } else {
      let oauth_token = paramsArray?.[0]?.slice(13);
      let oauth_verifier = paramsArray?.[1]?.slice(15);
      sendTwitterV1CodeAndState({
        credentials: { oauth_token, oauth_verifier },
        id,
      });
    }
  }, [
    sendLinkedInCodeAndState,
    sendFacebookCodeAndState,
    sendTwitterCodeAndState,
    sendTwitterV1CodeAndState,
    getConnectedInstagramAccount,
  ]);

  useEffect(() => {
    handleSuccess(message?.sendLinkedInCodeState);
    handleError(error?.sendLinkedInCodeState, error?.sendLinkedInCodeState);

    handleSuccess(message?.sendFacebookCodeState, () =>
      setTimeout(() => window.close(), 700)
    );
    handleError(error?.sendFacebookCodeState, error?.sendFacebookCodeState);

    handleSuccess(message?.sendTwitterCodeState, getTwitterV1CallbackURL);
    handleError(error?.sendTwitterCodeState, error?.sendTwitterCodeState);

    handleSuccess(message?.sendTwitterV1CodeState);
    handleError(error?.sendTwitterV1CodeState, error?.sendTwitterV1CodeState);

    handleSuccess(message?.connectSelectedFacebookPage, () => {
      toast.success("Facebook Page Connected successfully");
      setTimeout(() => window.close(), 700);
    });
    handleSuccess(message?.connectLinkedInPage, () => {
      toast.success("LinkedIn Page Connected successfully");
      setTimeout(() => window.close(), 700);
    });
    handleError(
      error?.connectSelectedFacebookPage,
      "An error occurred while connecting the Facebook account"
    );
    handleSuccess(message?.connectInstagramAccount, () => {
      toast.success("Instagram Account Connected successfully");
      setTimeout(() => window.close(), 700);
    });
    handleError(
      error?.connectInstagramAccount,
      "An error occurred while connecting the instagram account"
    );
    handleError(
      error?.connectLinkedInPage,
      "An error occurred while connecting the LinkedIn page"
    );
  }, [
    message?.sendLinkedInCodeState,
    message?.sendFacebookCodeState,
    message?.sendTwitterCodeState,
    message?.sendTwitterV1CodeState,
    message?.connectSelectedFacebookPage,
    message?.connectLinkedInPage,
    error?.connectLinkedInPage,
    error?.sendLinkedInCodeState,
    error?.sendFacebookCodeState,
    error?.sendTwitterCodeState,
    error?.sendTwitterV1CodeState,
    error?.connectSelectedFacebookPage,
    message?.connectInstagramAccount,
    error?.connectInstagramAccount,
    getTwitterV1CallbackURL,
  ]);

  useEffect(() => {
    if (activeSocial === "facebook") {
      setAvailableAccounts(facebookData?.page);
    } else if (activeSocial === "instagram") {
      setAvailableAccounts([instagramData]);
    } else {
      setAvailableAccounts(linkedInPages);
    }
  }, [activeSocial, instagramData, facebookData, linkedInPages]);

  useEffect(() => {
    if (twitterV1CallbackURl?.length && activeSocial === "twitter") {
      window.location.assign(twitterV1CallbackURl);
      // window.location.href = twitterV1CallbackURl;
    }
  }, [twitterV1CallbackURl, activeSocial]);

  useEffect(() => {
    getTwitterV1CallbackURL();
  }, [getTwitterV1CallbackURL]);

  console.log("instagramData", {
    instagramData: [instagramData],
    availableAccounts,
    linkedInPages,
  });
  return (
    <>
      {activeSocial === "facebook" || activeSocial === "instagram" ? (
        <>
          <div className="logo-container">
            <img
              src={logo}
              width="153px"
              className="pt-4 pl-5"
              alt="Ontriv Logo"
            />
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center m-auto"
            style={{ height: "85vh" }}
          >
            <h3
              style={{
                fontSize: "27px",
                fontWeight: 600,
                letterSpacing: "0.374px",
                color: "#111317",
                marginBottom: "1.5rem",
              }}
            >
              Please select the{" "}
              {activeSocial === "facebook"
                ? "Facebook Page"
                : "Instagram Business profile"}
            </h3>
            {availableAccounts?.length
              ? availableAccounts?.map((page, index) => {
                  return (
                    <button
                      className="d-flex flex-row align-items-center social-account-card mb-3"
                      key={index}
                      onClick={
                        activeSocial === "facebook"
                          ? () => handleConnectSelectedFacebookPage(page)
                          : () => handleConnectSelectedInstgramPage(page)
                      }
                    >
                      <div className="position-relative">
                        <img
                          src={page?.profile_image}
                          alt="profile pic"
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "50%",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            bottom: "10%",
                            background: "#ffffff",
                            borderRadius: "50%",
                            padding: "0 5px",
                            right: "5%",
                            margin: "0 auto",
                          }}
                        >
                          <img
                            src={
                              activeSocial === "facebook" ? facebook : instagram
                            }
                            alt="instagram icon"
                            width={11}
                          />
                        </div>
                      </div>
                      <div>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#111317",
                            marginBottom: 0,
                          }}
                        >
                          {page?.facebook_page_name || page?.username}
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            marginBottom: 0,
                          }}
                        >
                          @{page?.facebook_page_name || page?.username}
                        </p>
                      </div>
                    </button>
                  );
                })
              : "No accounts available"}
          </div>
        </>
      ) : activeSocial === "linkedin" ? (
        <>
          <div className="logo-container">
            <img
              src={logo}
              width="153px"
              className="pt-4 pl-5"
              alt="Ontriv Logo"
            />
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center m-auto"
            style={{ height: "85vh" }}
          >
            <h3
              style={{
                fontSize: "27px",
                fontWeight: 600,
                letterSpacing: "0.374px",
                color: "#111317",
                marginBottom: "1.5rem",
              }}
            >
              Please select the linkedIn page
            </h3>
            {availableAccounts?.length
              ? availableAccounts?.map((page, index) => {
                  return (
                    <button
                      className="d-flex flex-row align-items-center social-account-card mb-3"
                      key={index}
                      onClick={() => handleConnectSelectedLinkedInPage(page)}
                    >
                      <div className="position-relative">
                        <img
                          src={page?.company_logo}
                          alt="profile pic"
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "50%",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            bottom: "10%",
                            background: "#ffffff",
                            borderRadius: "50%",
                            padding: "0 5px",
                            right: "5%",
                            margin: "0 auto",
                          }}
                        >
                          <img src={Linkedin} alt="linkedin icon" width={11} />
                        </div>
                      </div>
                      <div>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#111317",
                            marginBottom: 0,
                          }}
                        >
                          {page?.company_name}
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            marginBottom: 0,
                          }}
                        >
                          @{page?.company_name}
                        </p>
                      </div>
                    </button>
                  );
                })
              : "No accounts available"}
          </div>
        </>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center vh-100 m-auto">
          <div className="d-flex flex-column align-items-center socials-connection-content">
            <div className="logo-container">
              <img src={logo} width="100px" alt="Ontriv Logo" />
            </div>
            <div className="d-flex flex-column align-items-center p-4">
              <img
                src={SocialsConnectImage}
                width="240px"
                alt="Connecting socials"
              />
              <p className="mt-4">
                Please wait a moment while we link your {activeSocial} account.
                You can check the application in the previous tab to see if the
                connection was successful.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    loading: state?.socials?.loading,
    message: state?.socials?.message,
    twitterV1CallbackURl: state?.socials?.callbackURL?.twitterV1,
    error: state?.socials?.error,
    facebookData: state?.socials?.facebookData,
    instagramData: state?.socials?.connectedInstagramAccount,
    linkedInPages: state?.socials?.linkedInPages,
  };
};
export default connect(mapStateToProps, {
  sendLinkedInCodeAndState,
  sendFacebookCodeAndState,
  sendTwitterCodeAndState,
  getTwitterV1CallbackURL,
  sendTwitterV1CodeAndState,
  connectSelectedFacebookPage,
  getConnectedInstagramAccount,
  connectInstagramAccount,
  connectLinkedInPage,
})(ConnectSocials);
