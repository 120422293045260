import {
  GET_DASHBOARD_METRICS,
  GET_DASHBOARD_METRICS_SUCCESS,
  GET_DASHBOARD_METRICS_ERROR,
  GET_DASHBOARD_REVENUE,
  GET_DASHBOARD_REVENUE_SUCCESS,
  GET_DASHBOARD_REVENUE_ERROR,
  GET_DASHBOARD_SCHEDULED_POSTS,
  GET_DASHBOARD_SCHEDULED_POSTS_SUCCESS,
  GET_DASHBOARD_SCHEDULED_POSTS_ERROR,
  GET_DASHBOARD_POST_IDEAS,
  GET_DASHBOARD_POST_IDEAS_SUCCESS,
  GET_DASHBOARD_POST_IDEAS_ERROR,
  RESET_MESSAGE,
} from "../actions";

const intialState = {
  loading: {
    getMetrics: false,
    getRevenue: false,
    getScheduledPosts: false,
    getPostIdeas: false,
  },
  message: {
    getMetrics: "",
    getRevenue: "",
    getScheduledPosts: "",
    getPostIdeas: "",
  },
  error: {
    getMetrics: "",
    getRevenue: "",
    getScheduledPosts: "",
    getPostIdeas: "",
  },
  dashboardMetrics: {},
  dashboardRevenue: [],
  schedulePosts: [],
  postIdeas: [],
};

const dashboardReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_METRICS:
      return {
        ...state,
        loading: { ...state?.loading, getMetrics: true },
      };
    case GET_DASHBOARD_METRICS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getMetrics: false },
        dashboardMetrics: action?.payload,
      };
    case GET_DASHBOARD_METRICS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getMetrics: false },
        error: { ...state?.error, getMetrics: action.payload.error },
      };
    case GET_DASHBOARD_REVENUE:
      return {
        ...state,
        loading: { ...state?.loading, getRevenue: true },
      };
    case GET_DASHBOARD_REVENUE_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getRevenue: false },
        dashboardRevenue: action.payload,
      };
    case GET_DASHBOARD_REVENUE_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getRevenue: false },
        error: { ...state?.error, getRevenue: action.payload },
      };
    case GET_DASHBOARD_SCHEDULED_POSTS:
      return {
        ...state,
        loading: { ...state?.loading, getScheduledPosts: true },
      };
    case GET_DASHBOARD_SCHEDULED_POSTS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getScheduledPosts: false },
        schedulePosts: action.payload,
      };
    case GET_DASHBOARD_SCHEDULED_POSTS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getScheduledPosts: false },
        error: { ...state?.error, getScheduledPosts: action.payload },
      };
    case GET_DASHBOARD_POST_IDEAS:
      return {
        ...state,
        loading: { ...state?.loading, getPostIdeas: true },
      };
    case GET_DASHBOARD_POST_IDEAS_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, getPostIdeas: false },
        postIdeas: action.payload,
      };
    case GET_DASHBOARD_POST_IDEAS_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, getPostIdeas: false },
        error: { ...state?.error, getPostIdeas: action.payload },
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: {
          getMetrics: "",
          getRevenue: "",
          getScheduledPosts: "",
          getPostIdeas: "",
        },
        error: {
          getMetrics: "",
          getRevenue: "",
          getScheduledPosts: "",
          getPostIdeas: "",
        },
      };
    default:
      return state;
  }
};

export default dashboardReducer;
