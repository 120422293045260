import styled from "styled-components";
export const PayButton = styled.button`
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  //background: #E0E0E0;
  background-color: rgba(224, 224, 224, 0.2);
  //opacity: 0.2;
  border: 1px solid #c0ccda;
  border-radius: 20px;
`;

export const Header = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.374px;
  color: #111317;
  margin-bottom: 30px !important;
`;
