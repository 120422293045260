import styles from "./Method.module.css";
import { useState } from "react";
import ConfigureModal from "../ConfigureModal";
import greenMark from "../../../../../assets/img/green-icon.svg";

const Method = ({ logo, title, isConnected, onDisconnectMethod, onConnectMethod }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onDisconnect = () => {
    onDisconnectMethod(title)
  };

  const onConnect = () => {
    setIsOpen(true);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.container}>
      {isConnected && (
        <div className={styles.tick}>

          <img
            src={greenMark}
            style={{ height: "60px" }}
            // className="my-auto"
            alt=""
          />
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={logo} alt={title} />
        </div>
        <div className={styles.nameButton}>
          <p className={styles.name}>{title}</p>
          {isConnected ? (
            <button className={styles.button} onClick={onDisconnect}>Disconnect</button>
          ) : (
            <button className={styles.button} onClick={onConnect}>
              Connect
            </button>
          )}
        </div>
      </div>
      {
        isOpen && <ConfigureModal
        isOpen={isOpen}
        title={title}
        onToggle={onToggle}
        onClose={onClose}
        onConnectMethod={onConnectMethod}
      />
      }
    </div>
  );
};

export default Method;
