import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_DASHBOARD_METRICS,
  GET_DASHBOARD_REVENUE,
  GET_DASHBOARD_SCHEDULED_POSTS,
  GET_DASHBOARD_POST_IDEAS,
  getAllDashboardMetricsSuccess,
  getAllDashboardMetricsError,
  getDashboardRevenueSuccess,
  getDashboardRevenueError,
  getDashboardScheduledPostsSuccess,
  getDashboardScheduledPostsError,
  getDashboardPostIdeaSuccess,
  getDashboardPostIdeaError,
  clearMessages,
} from "../actions";
import Axios from "../../utils/Axios";

export function* GetAllDashboardMetrics() {
  try {
    const response = yield Axios.get(`/dashboard/api/v1/metrics/`);
    if (response?.status === 200) {
      yield put(getAllDashboardMetricsSuccess(response?.data));
    } else {
      yield put(getAllDashboardMetricsError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getAllDashboardMetricsError(message));
    yield put(clearMessages());
  }
}
export function* GetAllDashboardRevenue({ payload }) {
  try {
    const response = yield Axios.get(
      `invoice/api/v1/dashboard/revenue/${payload}/`
    );
    if (response?.status === 200) {
      yield put(getDashboardRevenueSuccess(response?.data));
    } else {
      yield put(getDashboardRevenueError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getDashboardRevenueError(message));
    yield put(clearMessages());
  }
}
export function* GetDashboardSchedulePosts({ payload }) {
  try {
    const response = yield Axios.get(
      `/dashboard/api/v1/${payload?.id}/posts/${payload?.month}/${payload?.day}/`
    );
    if (response?.status === 200) {
      yield put(getDashboardScheduledPostsSuccess(response?.data));
    } else {
      yield put(getDashboardScheduledPostsError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getDashboardScheduledPostsError(message));
    yield put(clearMessages());
  }
}
export function* GetDashboardPostIdeas() {
  try {
    const response = yield Axios.get(`/dashboard/api/v1/content/ideas/`);
    if (response?.status === 200) {
      yield put(getDashboardPostIdeaSuccess(response?.data));
    } else {
      yield put(getDashboardPostIdeaError(response?.data?.message));
    }
    yield put(clearMessages());
  } catch (error) {
    let message;
    if (error.response) {
      const errorMessage = error.response.data.detail;

      switch (error?.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Not found";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 400:
          message = errorMessage;
          break;
        default:
          message = error.response.statusText;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getDashboardPostIdeaError(message));
    yield put(clearMessages());
  }
}
export function* watchGetDashboardMetrics() {
  yield takeEvery(GET_DASHBOARD_METRICS, GetAllDashboardMetrics);
}
export function* watchGetDashboardRevenue() {
  yield takeEvery(GET_DASHBOARD_REVENUE, GetAllDashboardRevenue);
}
export function* watchGetDashboardSchedulePosts() {
  yield takeEvery(GET_DASHBOARD_SCHEDULED_POSTS, GetDashboardSchedulePosts);
}
export function* watchGetDashboardPostIdeas() {
  yield takeEvery(GET_DASHBOARD_POST_IDEAS, GetDashboardPostIdeas);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetDashboardMetrics),
    fork(watchGetDashboardRevenue),
    fork(watchGetDashboardSchedulePosts),
    fork(watchGetDashboardPostIdeas),
  ]);
}
