import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BarLoader from "../../../../components/Loaders/BarLoader";
import Table from "../../../../components/Table";
import { capitalizeWord, sortItem } from "../../../../utils/func";
import NoPostSchedule from "../../NoPostSchedule";
import SocialChannelTopNav from "../SocialChannelTopNav";
import { addDays } from "date-fns";
import format from "date-fns/format";
import dummylogo from "../../../../assets/img/dummylogo.png";
import moment from "moment";
import TableDropdown from "../../../../components/Dropdown/TableDropdown";
import DeleteModal from "../../../../components/Modal/DeleteModal";
//redux
import { connect } from "react-redux";
import { deleteFacebookPost } from "../../../../redux/actions";
import { toast } from "react-toastify";
import { truncateText } from "../../../../utils/helper";
import PreviewPost from "../../PreviewPost";
const FacebookPost = (props) => {
  const {
    id,
    deleteFacebookPost,
    deleteFacebookError,
    deleteFacebookMessage,
    deleteFacebookloading,
  } = props;
  const { getOneClientPostLoading, getOneClientPost } = useSelector(
    (state) => state?.postSchedule
  );

  let sortedFbData = sortItem(getOneClientPost?.user_facebook_post);

  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("all");
  const [facebookData, setFacebookData] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletePayload, setDeletePayload] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewInfo, setPreviewInfo] = useState(null);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const onChangeSearchValue = (e) => {
    let value = e.target.value;
    setSearchValue(value);
  };

  const onSelectItem = (e) => {
    setSelectedValue(e.target.value);
  };

  const openDeleteModal = (data) => {
    console.log({ data, id });
    setShowDeleteModal(true);
    setDeletePayload(data?.id);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeletePayload(null);
  };
  const openPreviewModal = (record) => {
    setShowPreviewModal(true);
    setPreviewInfo(record);
  };
  const closePreviewModal = () => {
    setShowPreviewModal(false);
    setPreviewInfo(null);
  };
  const handleDelete = () => {
    deleteFacebookPost({ userID: id, postId: deletePayload });
  };

  useEffect(() => {
    if (sortedFbData?.length) {
      let fbData = sortedFbData;
      setFacebookData(fbData);
    }
  }, [sortedFbData]);

  useEffect(() => {
    if (sortedFbData) {
      const handleFilter = sortedFbData.filter(
        (user) =>
          format(new Date(user.created_at), "dd/MM/yyyy") >=
            format(range[0].startDate, "dd/MM/yyyy") &&
          format(new Date(user.created_at), "dd/MM/yyyy") <=
            format(range[0].endDate, "dd/MM/yyyy")
      );

      if (handleFilter.length <= 0) {
        setFacebookData(sortedFbData);
      } else {
        setFacebookData(handleFilter);
      }
    }
  }, [sortedFbData, range]);

  useEffect(() => {
    switch (selectedValue) {
      case "scheduled": {
        const scheduledPost = sortedFbData.filter(
          (o) => o?.post_status?.toLowerCase() === "scheduled"
        );
        if (scheduledPost.length) {
          setFacebookData(scheduledPost);
        } else {
          setFacebookData(sortedFbData);
        }
        break;
      }
      case "published": {
        const publishedPost = sortedFbData.filter(
          (o) => o?.post_status?.toLowerCase() === "published"
        );
        if (publishedPost.length) {
          setFacebookData(publishedPost);
        } else {
          setFacebookData(sortedFbData);
        }
        break;
      }
      case "pending": {
        const pendingPost = sortedFbData.filter(
          (o) => o?.post_status?.toLowerCase() === "pending"
        );
        if (pendingPost.length) {
          setFacebookData(pendingPost);
        } else {
          setFacebookData(sortedFbData);
        }
        break;
      }
      case "draft": {
        const draftPost = sortedFbData.filter(
          (o) => o?.post_status?.toLowerCase() === "draft"
        );
        if (draftPost.length) {
          setFacebookData(draftPost);
        } else {
          setFacebookData(sortedFbData);
        }
        break;
      }
      default: {
        setFacebookData(sortedFbData);
        break;
      }
    }
  }, [sortedFbData, selectedValue]);

  useEffect(() => {
    if (searchValue.length <= 0) {
      setFacebookData(sortedFbData);
    } else if (searchValue.length >= 4) {
      const results = sortedFbData.filter((o) =>
        o?.post_details?.caption
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase())
      );
      if (results.length > 0) {
        setFacebookData(results);
      } else {
        setFacebookData(sortedFbData);
      }
    }
  }, [searchValue, sortedFbData]);

  useEffect(() => {
    if (!deleteFacebookloading && deleteFacebookMessage?.length > 0) {
      closeDeleteModal();
      toast.success(deleteFacebookMessage);
    } else if (!deleteFacebookloading && deleteFacebookError?.length > 0) {
      toast.error(deleteFacebookError);
    }
  }, [deleteFacebookMessage, deleteFacebookError, deleteFacebookloading]);
  const cols = React.useMemo(
    () => [
      {
        Header: "Post",
        accessor: "post_details",
        Cell: (props) => {
          const media_type = props?.row?.original?.media_type;
          return (
            <div className="row-post">
              <div className="post-image">
                {media_type?.toLowerCase() === "image" ? (
                  <img src={props?.value?.media || dummylogo} alt="" />
                ) : (
                  <video src={props?.value?.media} />
                )}
              </div>
              <div className="post-title-name">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(
                      truncateText(props?.value?.caption, "120")
                    )
                      ?.replace(/\\n/g, "<br>")
                      ?.replaceAll('"', ""),
                  }}
                />

                {/* <div className='post-user'>
                        <div className="post-user-img"> <img src={props?.value?.media} alt="" /></div>
                        <h2>{props?.value?.user}</h2>
                    </div> */}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Post Type",
        accessor: "media_type",
        Cell: (props) => <h6 className="post-postType">{props.value}</h6>,
      },
      {
        Header: "Date",
        accessor: "due_date",
        Cell: (props) => (
          <div className="post-date-time">
            <p>{moment(props?.value).format("DD/MM/YYYY")}</p>
            <p>{moment(props?.value).format("h:mm a")}</p>
          </div>
        ),
      },
      {
        Header: "Post Status",
        accessor: "post_status",
        Cell: (props) => (
          <div>
            {props.value?.toLowerCase() === "pending" ? (
              <div className="post-status pending">
                <div></div>
                <p>{capitalizeWord(props.value)}</p>
              </div>
            ) : props.value?.toLowerCase() === "scheduled" ? (
              <div className="post-status scheduled">
                <div></div>
                <p>{capitalizeWord(props.value)}</p>
              </div>
            ) : props.value?.toLowerCase() === "published" ? (
              <div className="post-status published">
                <div></div>
                <p>{capitalizeWord(props.value)}</p>
              </div>
            ) : props.value?.toLowerCase() === "draft" ? (
              <div className="post-status draft">
                <div></div>
                <p>{capitalizeWord(props.value)}</p>
              </div>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "img",
        cellClass: "",
        Cell: (props) => {
          let record = props?.row?.original;
          return (
            <TableDropdown
              actions={[
                {
                  name: "Preview",
                  action: () => openPreviewModal(record),
                },
                {
                  name: "Delete",
                  action: () => openDeleteModal(record),
                },
              ]}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div className="overflow-x-auto">
        {getOneClientPostLoading ? (
          <BarLoader />
        ) : (
          <>
            <SocialChannelTopNav
              searchValue={searchValue}
              onChange={onChangeSearchValue}
              selectedValue={selectedValue}
              OnSelectItem={onSelectItem}
              range={range}
              setRange={setRange}
            />
            <br />
            <div style={{ height: "60vh", overflowY: "auto" }}>
              {facebookData?.length ? (
                <Table
                  columns={cols}
                  data={facebookData}
                  divided
                  defaultPageSize={4}
                  pagePosition="left"
                  rowOnClick={(record) => openPreviewModal(record)}
                />
              ) : (
                <NoPostSchedule
                  text={"There are no scheduled Instagram posts "}
                />
              )}
            </div>
          </>
        )}
      </div>
      <PreviewPost
        open={showPreviewModal}
        handleClose={closePreviewModal}
        record={previewInfo}
      />
      <DeleteModal
        openModal={showDeleteModal}
        setOpenModal={setShowDeleteModal}
        deleteAction={handleDelete}
        deleteloading={deleteFacebookloading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deleteFacebookloading: state?.postSchedule?.loading?.deleteFacebookPost,
    deleteFacebookError: state?.postSchedule?.error?.deleteFacebookPost,
    deleteFacebookMessage: state?.postSchedule?.message?.deleteFacebookPost,
  };
};
export default connect(mapStateToProps, { deleteFacebookPost })(FacebookPost);
