import React from "react";
import { useState } from "react";
// import "react-datepicker/dist/react-datepicker.css";
import { Col, Row } from "reactstrap";
import Button from "../../../../../components/Button";
import TextArea from "../../../../../components/textArea/TextArea";
import { Carousel } from "react-responsive-carousel";
import DateTimePicker from "react-datetime-picker";
import dummyimg from "../../../../../assets/img/dummyimg.png";

const FineTuneTwitter = ({
  socialchannel,
  setOpenModal,
  activeTab,
  setActiveTab,
  images,
  caption,
  postDate,
  twitterData,
  selectedAccountSocials,
}) => {
  const [startDate, setStartDate] = useState(postDate);
  const [twitterCaption, setTwitterCaption] = useState(caption);
  const [count, setCount] = useState(2200);

  const onChangeTextArea = (e) => {
    const value = e.target.value;
    const valueLength = e.target.value.length;
    setTwitterCaption(value);
    setCount(2200 - valueLength);
  };

  const nextButton = (tab) => {
    if (socialchannel.length === activeTab) {
      twitterData(twitterCaption, startDate);
      setOpenModal(true);
    } else {
      if (activeTab !== tab) {
        twitterData(twitterCaption, startDate);
        setActiveTab(tab);
      }
    }
  };

  return (
    <div className="finetuneTabContent">
      <Row>
        <Col xs="12" md="7" sm="12">
          <div className="previewWrapper">
            <h2> Preview</h2>
            <div className="preview-iG-wrapper">
              <div className="preview-dp-wrapper">
                <div className="dp">
                  <img
                    src={
                      selectedAccountSocials?.twitter_v1?.profile_image ||
                      dummyimg
                    }
                    alt="dp"
                  />
                </div>
                <div>
                  {selectedAccountSocials?.twitter_v1?.username ? (
                    <span>{selectedAccountSocials?.twitter_v1?.username}</span>
                  ) : null}
                </div>
              </div>
              <div className="mt-3">
                <p
                  className="previewText"
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(twitterCaption)
                      .replace(/\\n/g, "<br>")
                      ?.replaceAll('"', ""),
                  }}
                />
              </div>
              <div>
                <Carousel showThumbs={false} infiniteLoop={true} autoPlay>
                  {images?.map((image, index) => {
                    return (
                      <div className="img-holder" key={index}>
                        {image?.type.slice(0, 5) === "video" ? (
                          <video width="100%" height="100%" controls autoplay>
                            <source
                              src={URL.createObjectURL(image)}
                              type={`video/${image?.name.slice(-3)}`}
                            />
                          </video>
                        ) : (
                          <img src={URL.createObjectURL(image)} alt="" />
                        )}
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </Col>
        <Col xs="12" md="5" sm="12">
          <div>
            <DateTimePicker
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yy"
              hourPlaceholder="hh"
              minutePlaceholder="mm"
              onChange={(date) => setStartDate(date)}
              value={startDate}
              clearIcon={null}
              calendarIcon={null}
              disableClock={true}
              minDate={new Date()}
              className="scheduleDateInput"
            />
            <br />
            <br />
            <TextArea
              maxLength="2200"
              placeholder="Caption"
              value={twitterCaption}
              onChangeTextArea={onChangeTextArea}
              count={count}
            />
            <Button
              text="Next"
              onButtonClick={() => nextButton(activeTab + 1)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FineTuneTwitter;
