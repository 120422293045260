import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = "https://ontriv.spinxe.com";
const token = localStorage.getItem("ontrivUserToken")
  ? localStorage.getItem("ontrivUserToken")
  : "";
const Axios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});
Axios.interceptors.response.use(undefined, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // if (
  //   error.response.status === 401 ||
  //   error.response.status === 403 ||
  //   error.response.data.message === "401 Unauthorized"
  // ) {
  //   toast.info(
  //     "Your current session has ended and you will logged out shortly"
  //   );
  //   setTimeout(() => {
  //     localStorage.removeItem("ontrivUserToken");
  //     localStorage.removeItem("ontrivCurrentUser");
  //     localStorage.clear();
  //     window.location.href = "/auth/login";
  //   }, [3000]);
  // } 
  // else 
  if (
    !window.navigator.onLine &&
    !error.response &&
    error.code === "ERR_NETWORK"
  ) {
    toast.info("No Internet Connection");
  }
  return Promise.reject(error);
});

// axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
// Axios.defaults.headers.common = { Authorization: `Token ${token}` }
// Axios.defaults.headers.common = { Authorization: `bearer ${token}` }

export default Axios;
