import {
  GET_ALL_CONTENT_IDEAS,
  GET_ALL_CONTENT_IDEAS_SUCCESS,
  GET_ALL_CONTENT_IDEAS_ERROR,
} from "../actions";

export const getAllContentIdeas = (filters) => ({
  type: GET_ALL_CONTENT_IDEAS,
  payload: filters,
});

export const getAllContentIdeasSuccess = (data) => ({
  type: GET_ALL_CONTENT_IDEAS_SUCCESS,
  payload: data,
});
export const getAllContentIdeasError = (error) => ({
  type: GET_ALL_CONTENT_IDEAS_ERROR,
  payload: error,
});
