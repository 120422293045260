import {
  GET_BUSINESS_SOCIALS_CONNECTION_STATUS,
  GET_BUSINESS_SOCIALS_CONNECTION_STATUS_SUCCESS,
  GET_BUSINESS_SOCIALS_CONNECTION_STATUS_ERROR,
  GET_LINKEDIN_CALLBACK_URL,
  GET_LINKEDIN_CALLBACK_URL_SUCCESS,
  GET_LINKEDIN_CALLBACK_URL_ERROR,
  GET_FACEBOOK_CALLBACK_URL,
  GET_FACEBOOK_CALLBACK_URL_SUCCESS,
  GET_FACEBOOK_CALLBACK_URL_ERROR,
  ADD_NEW_NOTIFICATION,
  ADD_NEW_NOTIFICATION_SUCCESS,
  ADD_NEW_NOTIFICATION_ERROR,
  SEND_LINKEDIN_CODE_STATE,
  SEND_LINKEDIN_CODE_STATE_SUCCESS,
  SEND_LINKEDIN_CODE_STATE_ERROR,
  CONNECT_LINKEDIN_PAGE,
  CONNECT_LINKEDIN_PAGE_SUCCESS,
  CONNECT_LINKEDIN_PAGE_ERROR,
  SEND_FACEBOOK_CODE_STATE,
  SEND_FACEBOOK_CODE_STATE_SUCCESS,
  SEND_FACEBOOK_CODE_STATE_ERROR,
  CONNECT_SELECTED_FACEBOOK_PAGE,
  CONNECT_SELECTED_FACEBOOK_PAGE_SUCCESS,
  CONNECT_SELECTED_FACEBOOK_PAGE_ERROR,
  GET_CONNECTED_INSTAGRAM_ACCOUNT,
  GET_CONNECTED_INSTAGRAM_ACCOUNT_SUCCESS,
  GET_CONNECTED_INSTAGRAM_ACCOUNT_ERROR,
  CONNECT_INSTAGRAM_ACCOUNT,
  CONNECT_INSTAGRAM_ACCOUNT_SUCCESS,
  CONNECT_INSTAGRAM_ACCOUNT_ERROR,
  GET_TWITTER_CALLBACK_URL,
  GET_TWITTER_CALLBACK_URL_SUCCESS,
  GET_TWITTER_CALLBACK_URL_ERROR,
  SEND_TWITTER_CODE_STATE,
  SEND_TWITTER_CODE_STATE_SUCCESS,
  SEND_TWITTER_CODE_STATE_ERROR,
  GET_TWITTER_V1_CALLBACK_URL,
  GET_TWITTER_V1_CALLBACK_URL_SUCCESS,
  GET_TWITTER_V1_CALLBACK_URL_ERROR,
  SEND_TWITTER_V1_CODE_STATE,
  SEND_TWITTER_V1_CODE_STATE_SUCCESS,
  SEND_TWITTER_V1_CODE_STATE_ERROR,
  RESET_MESSAGE,
} from "../actions";

export const getBusinessSocialsConnectionStatus = (id) => ({
  type: GET_BUSINESS_SOCIALS_CONNECTION_STATUS,
  payload: id,
});

export const getBusinessSocialsConnectionStatusSuccess = (response) => ({
  type: GET_BUSINESS_SOCIALS_CONNECTION_STATUS_SUCCESS,
  payload: { response, message: "Success" },
});

export const getBusinessSocialsConnectionStatusError = (error) => ({
  type: GET_BUSINESS_SOCIALS_CONNECTION_STATUS_ERROR,
  payload: error,
});

export const getLinkedInCallbackURL = () => ({
  type: GET_LINKEDIN_CALLBACK_URL,
});

export const getLinkedInCallbackURLSuccess = (response) => ({
  type: GET_LINKEDIN_CALLBACK_URL_SUCCESS,
  payload: { response, message: "Please grant access in the newly opened tab" },
});

export const getLinkedInCallbackURLError = (error) => ({
  type: GET_LINKEDIN_CALLBACK_URL_ERROR,
  payload: error,
});
export const getFacebookCallbackURL = () => ({
  type: GET_FACEBOOK_CALLBACK_URL,
});
export const getFacebookCallbackURLSuccess = (response) => ({
  type: GET_FACEBOOK_CALLBACK_URL_SUCCESS,
  payload: { response, message: "Please grant access in the newly opened tab" },
});
export const getFacebookCallbackURLError = (error) => ({
  type: GET_FACEBOOK_CALLBACK_URL_ERROR,
  payload: error,
});
export const addNewNotification = (id) => ({
  type: ADD_NEW_NOTIFICATION,
  payload: id,
});

export const addNewNotificationSuccess = (response) => ({
  type: ADD_NEW_NOTIFICATION_SUCCESS,
  payload: response,
});

export const addNewNotificationError = (error) => ({
  type: ADD_NEW_NOTIFICATION_ERROR,
  payload: error,
});

export const sendLinkedInCodeAndState = (credentials) => ({
  type: SEND_LINKEDIN_CODE_STATE,
  payload: credentials,
});
export const sendLinkedInCodeAndStateSuccess = ({ message, data }) => ({
  type: SEND_LINKEDIN_CODE_STATE_SUCCESS,
  payload: { message, data },
});
export const sendLinkedInCodeAndStateError = (error) => ({
  type: SEND_LINKEDIN_CODE_STATE_ERROR,
  payload: error,
});
export const connectLinkedInPage = (credentials) => ({
  type: CONNECT_LINKEDIN_PAGE,
  payload: credentials,
});
export const connectLinkedInPageSuccess = (message) => ({
  type: CONNECT_LINKEDIN_PAGE_SUCCESS,
  payload: message,
});
export const connectLinkedInPageError = (error) => ({
  type: CONNECT_LINKEDIN_PAGE_ERROR,
  payload: error,
});
export const sendFacebookCodeAndState = (credentials) => ({
  type: SEND_FACEBOOK_CODE_STATE,
  payload: credentials,
});
export const sendFacebookCodeAndStateSuccess = ({ message, data }) => ({
  type: SEND_FACEBOOK_CODE_STATE_SUCCESS,
  payload: { message, data },
});
export const sendFacebookCodeAndStateError = (error) => ({
  type: SEND_FACEBOOK_CODE_STATE_ERROR,
  payload: error,
});
export const connectSelectedFacebookPage = (credentials) => ({
  type: CONNECT_SELECTED_FACEBOOK_PAGE,
  payload: credentials,
});
export const connectSelectedFacebookPageSuccess = (message) => ({
  type: CONNECT_SELECTED_FACEBOOK_PAGE_SUCCESS,
  payload: message,
});
export const connectSelectedFacebookPageError = (error) => ({
  type: CONNECT_SELECTED_FACEBOOK_PAGE_ERROR,
  payload: error,
});
export const getConnectedInstagramAccount = (id) => ({
  type: GET_CONNECTED_INSTAGRAM_ACCOUNT,
  payload: id,
});
export const getConnectedInstagramAccountSuccess = (response) => ({
  type: GET_CONNECTED_INSTAGRAM_ACCOUNT_SUCCESS,
  payload: response,
});
export const getConnectedInstagramAccountError = (error) => ({
  type: GET_CONNECTED_INSTAGRAM_ACCOUNT_ERROR,
  payload: error,
});

export const connectInstagramAccount = (credentials) => ({
  type: CONNECT_INSTAGRAM_ACCOUNT,
  payload: credentials,
});

export const connectInstagramAccountSuccess = (message) => ({
  type: CONNECT_INSTAGRAM_ACCOUNT_SUCCESS,
  payload: message,
});
export const connectInstagramAccountError = (error) => ({
  type: CONNECT_INSTAGRAM_ACCOUNT_ERROR,
  payload: error,
});

export const getTwitterCallbackURL = () => ({
  type: GET_TWITTER_CALLBACK_URL,
});

export const getTwitterCallbackURLSuccess = (response) => ({
  type: GET_TWITTER_CALLBACK_URL_SUCCESS,
  payload: { response, message: "Please grant access in the newly opened tab" },
});

export const getTwitterCallbackURLError = (error) => ({
  type: GET_TWITTER_CALLBACK_URL_ERROR,
  payload: error,
});

export const sendTwitterCodeAndState = (credentials) => ({
  type: SEND_TWITTER_CODE_STATE,
  payload: credentials,
});
export const sendTwitterCodeAndStateSuccess = (message) => ({
  type: SEND_TWITTER_CODE_STATE_SUCCESS,
  payload: message,
});
export const sendTwitterCodeAndStateError = (error) => ({
  type: SEND_TWITTER_CODE_STATE_ERROR,
  payload: error,
});

//V1
export const getTwitterV1CallbackURL = () => ({
  type: GET_TWITTER_V1_CALLBACK_URL,
});

export const getTwitterV1CallbackURLSuccess = (response) => ({
  type: GET_TWITTER_V1_CALLBACK_URL_SUCCESS,
  payload: { response, message: "Please grant access in the newly opened tab" },
});

export const getTwitterV1CallbackURLError = (error) => ({
  type: GET_TWITTER_V1_CALLBACK_URL_ERROR,
  payload: error,
});

export const sendTwitterV1CodeAndState = (credentials) => ({
  type: SEND_TWITTER_V1_CODE_STATE,
  payload: credentials,
});
export const sendTwitterV1CodeAndStateSuccess = (message) => ({
  type: SEND_TWITTER_V1_CODE_STATE_SUCCESS,
  payload: message,
});
export const sendTwitterV1CodeAndStateError = (error) => ({
  type: SEND_TWITTER_V1_CODE_STATE_ERROR,
  payload: error,
});
export const clearMessages = () => ({
  type: RESET_MESSAGE,
});
