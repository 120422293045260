export const plans = [
  {
    id: 1,
    name: "Starter",
    description: "Perfect for solo founders",
    price: 39,
    features: [
      "Up to 3 clients",
      "270 Scheduled posts",
      "Unlimeted Invoices",
      "Expense tracking",
      "Client portals",
    ],
  },
  {
    id: 2,
    name: "Small Team",
    key: "basic",
    description: "Powerful tools for small teams",
    price: 79,
    features: [
      "Up to 10 clients",
      "Unlimited  Scheduled posts",
      "Unlimited Invoices",
      "Expense tracking",
      "White-labeled Client portals",
      "3 team members included",
    ],
  },
  {
    id: 3,
    name: "Agency",
    description: "Scale with ease",
    price: 129,
    features: [
      "Up to 20 clients",
      "Unlimited Invoices",
      "Expense tracking",
      "Unlimited Scheduled posts",
      "White-labeled Client Portals",
      "% team members included",
    ],
  },
];
