import {
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  GET_BUSINESS_DETAILS,
  GET_BUSINESS_DETAILS_SUCCESS,
  GET_BUSINESS_DETAILS_ERROR,
  UPDATE_BUSINESS_DETAILS,
  UPDATE_BUSINESS_DETAILS_ERROR,
  UPDATE_BUSINESS_DETAILS_SUCCESS,
  CHANGE_BUSINESS_LOGO,
  CHANGE_BUSINESS_LOGO_SUCCESS,
  CHANGE_BUSINESS_LOGO_ERROR,
  CHANGE_PROFILE_IMAGE,
  CHANGE_PROFILE_IMAGE_SUCCESS,
  CHANGE_PROFILE_IMAGE_ERROR,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_ERROR,
  CONNECT_PAYMENT_METHOD_ERROR,
  CONNECT_PAYMENT_METHOD_SUCCESS,
  CONNECT_PAYMENT_METHOD,
  GET_ALL_PAYMENT_METHODS,
  GET_ALL_PAYMENT_METHODS_SUCCESS,
  GET_ALL_PAYMENT_METHODS_ERROR,
  GET_INVOICE_SETTINGS,
  GET_INVOICE_SETTINGS_SUCCESS,
  GET_INVOICE_SETTINGS_ERROR,
  UPDATE_INVOICE_SETTINGS,
  UPDATE_INVOICE_SETTINGS_SUCCESS,
  UPDATE_INVOICE_SETTINGS_ERROR,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_ERROR
} from "../actions";

export const resetPassword = (credentials) => ({
  type: RESET_PASSWORD,
  payload: { credentials },
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: "Password Updated Successfully",
});
export const resetPasswordError = (error) => ({
  type: RESET_PASSWORD_ERROR,
  payload: error ,
});
export const getBusinessDetails = () => ({
  type: GET_BUSINESS_DETAILS,
});
export const getBusinessDetailsSuccess = (businessInfo) => ({
  type: GET_BUSINESS_DETAILS_SUCCESS,
  payload: businessInfo,
});
export const getBusinessDetailsError = (error) => ({
  type: GET_BUSINESS_DETAILS_ERROR,
  payload: error,
});
export const updateBusinessDetails = (credentials) => ({
  type: UPDATE_BUSINESS_DETAILS,
  payload: { credentials },
});
export const updateBusinessDetailsSuccess = () => ({
  type: UPDATE_BUSINESS_DETAILS_SUCCESS,
  payload: "Business Details Updated Successfully",
});

export const updateBusinessDetailsError = (error) => ({
  type: UPDATE_BUSINESS_DETAILS_ERROR,
  payload: error,
});

export const changeBusinessLogo = (credentials) => ({
  type: CHANGE_BUSINESS_LOGO,
  payload: { credentials },
});

export const changeBusinessLogoSuccess = () => ({
  type: CHANGE_BUSINESS_LOGO_SUCCESS,
  payload: "Business Logo Updated Successfully",
});

export const changeBusinessLogoError = (error) => ({
  type: CHANGE_BUSINESS_LOGO_ERROR,
  payload: error,
});
export const changeProfileImage = (credentials) => ({
  type: CHANGE_PROFILE_IMAGE,
  payload: { credentials },
});

export const changeProfileImageSuccess = () => ({
  type: CHANGE_PROFILE_IMAGE_SUCCESS,
  payload: "Profile Image Updated Successfully",
});

export const changeProfileImageError = (error) => ({
  type: CHANGE_PROFILE_IMAGE_ERROR,
  payload: error,
});

export const updateUserDetails = (credentials) => ({
  type: UPDATE_USER_DETAILS,
  payload: { credentials },
});

export const updateUserDetailsSuccess = () => ({
  type: UPDATE_USER_DETAILS_SUCCESS,
  payload: "Profile Details Updated Successfully",
});

export const updateUserDetailsError = (error) => ({
  type: UPDATE_USER_DETAILS_ERROR,
  payload: error,
});

export const getAllPaymentMethods = () => ({
  type: GET_ALL_PAYMENT_METHODS,
});

export const getAllPaymentMethodsSuccess = (methods) => ({
  type: GET_ALL_PAYMENT_METHODS_SUCCESS,
  payload: methods,
});
export const getAllPaymentMethodsError = (error) => ({
  type: GET_ALL_PAYMENT_METHODS_ERROR,
  payload: { error },
});

export const connectPaymentMethod = (data) => ({
  type: CONNECT_PAYMENT_METHOD,
  payload: { data },
});

export const connectPaymentMethodSuccess = (resData) => ({
  type: CONNECT_PAYMENT_METHOD_SUCCESS,
  payload: resData,
});

export const connectPaymentMethodError = (error) => ({
  type: CONNECT_PAYMENT_METHOD_ERROR,
  payload: error,
});

export const updatePaymentMethod = (data) => ({
  type: UPDATE_PAYMENT_METHOD,
  payload: { data },
});

export const updatePaymentMethodSuccess = (resData) => ({
  type: UPDATE_PAYMENT_METHOD_SUCCESS,
  payload: resData,
});

export const updatePaymentMethodError = (error) => ({
  type: UPDATE_PAYMENT_METHOD_ERROR,
  payload: error,
});

export const getInvoiceSettings = () => ({
  type: GET_INVOICE_SETTINGS,
});

export const getInvoiceSettingsSuccess = (invoiceSettings) => ({
  type: GET_INVOICE_SETTINGS_SUCCESS,
  payload: invoiceSettings,
});

export const getInvoiceSettingsError = (error) => ({
  type: GET_INVOICE_SETTINGS_ERROR,
  payload: error,
});

export const updateInvoiceSetting = (credentials) => ({
  type: UPDATE_INVOICE_SETTINGS,
  payload: credentials,
});

export const updateInvoiceSettingSuccess = () => ({
  type: UPDATE_INVOICE_SETTINGS_SUCCESS,
  payload: "Invoice Settings Updated Successfully",
});

export const updateInvoiceSettingError = (error) => ({
  type: UPDATE_INVOICE_SETTINGS_ERROR,
  payload: error,
});
