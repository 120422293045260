import {
  GET_ALL_TEAM_MEMBERS,
  GET_ALL_TEAM_MEMBERS_SUCCESS,
  GET_ALL_TEAM_MEMBERS_ERROR,
  ADD_NEW_TEAM_MEMBER,
  ADD_NEW_TEAM_MEMBER_SUCCESS,
  ADD_NEW_TEAM_MEMBER_ERROR,
  DELETE_TEAM_MEMBER,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_ERROR,
  RESET_MESSAGE,
} from "../actions";

const initialState = {
  loading: {
    getAllTeamMembers: false,
    addNewTeamMember: false,
    deleteTeamMember: false,
  },
  error: {
    getAllTeamMembers: "",
    addNewTeamMember: "",
    deleteTeamMember: "",
  },
  message: {
    getAllTeamMembers: "",
    addNewTeamMember: "",
    deleteTeamMember: "",
  },
  teamMembers: [],
};

const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TEAM_MEMBERS:
      return {
        ...state,
        loading: { ...state.loading, getAllTeamMembers: true },
      };
    case GET_ALL_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllTeamMembers: false },
        teamMembers: action.payload,
      };
    case GET_ALL_TEAM_MEMBERS_ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllTeamMembers: false },
        error: { ...state.error, getAllTeamMembers: action.payload },
      };
    case ADD_NEW_TEAM_MEMBER:
      return {
        ...state,
        loading: { ...state.loading, addNewTeamMember: true },
      };
    case ADD_NEW_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, addNewTeamMember: false },
        message: { ...state?.message, addNewTeamMember: action.payload },
      };
    case ADD_NEW_TEAM_MEMBER_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, addNewTeamMember: false },
        error: { ...state?.error, addNewTeamMember: action.payload },
      };
    case DELETE_TEAM_MEMBER:
      return {
        ...state,
        loading: { ...state?.loading, deleteTeamMember: true },
      };
    case DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, deleteTeamMember: false },
        message: { ...state?.message, deleteTeamMember: action.payload },
      };
    case DELETE_TEAM_MEMBER_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, deleteTeamMember: false },
        error: { ...state?.error, deleteTeamMember: action.payload },
      };
    case RESET_MESSAGE:
      return {
        ...state,
        error: {
          getAllTeamMembers: "",
          addNewTeamMember: "",
          deleteTeamMember: "",
        },
        message: {
          getAllTeamMembers: "",
          addNewTeamMember: "",
          deleteTeamMember: "",
        },
      };
    default:
      return state;
  }
};

export default teamsReducer;
