import React from "react";
import { Card, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { formatAmount } from "../../../utils/helper";

const OverviewCards = ({ dashboardResults, currencySymbol }) => {
  return (
    <Row>
      <Col md="12">
        <div className="dashboard-analytics">
          <Card className="analytics-card">
            <Row className="g-3">
              <Col sm="12" md="4">
                <Card className="card-sm revenue h-100">
                  <h4 className="text-white">Revenue</h4>
                  <h5 className="text-white">
                    {currencySymbol}{" "}
                    <span>
                      {" "}
                      {formatAmount(dashboardResults?.["revenue"]) || 0.0}
                    </span>
                  </h5>
                </Card>
              </Col>
              <Col sm="12" md="4">
                <Card className="card-sm posts h-100">
                  <h4 className="">No scheduled posts</h4>
                  <h5 className="">{dashboardResults?.["scheduled posts"]}</h5>
                </Card>
              </Col>
              <Col sm="12" md="4">
                <Card className="card-sm clients h-100">
                  <h4 className="">Number of clients</h4>
                  <h5 className="">
                    {dashboardResults?.["number of clients"]}
                  </h5>
                </Card>
              </Col>
            </Row>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardResults: state?.dashboard?.dashboardMetrics,
  };
};
export default connect(mapStateToProps, {})(OverviewCards);
