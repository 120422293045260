import { useState } from "react";
import { Card, TabContent, TabPane } from "reactstrap";
import Business from "./BusinessDetails";
import Billing from "./Billing";
import Personal from "./PersonalDetails";
// import Team from "./Team";
// import Subscription from "./Subscriptions";
import Integrations from "./Integrations";

//Redux
import { connect } from "react-redux";
import Invoice from "./Invoice";
import ClientPortal from "./ClientPortal";
import { getUserType, role } from "../../utils/helper";
import Construction from "../Construction";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("personal details");

  const settingsTabs = [
    {
      id: 1,
      name: "Personal details",
      isAvailable: ["business", "admin", "client-manager", "content-manager"],
    },
    {
      id: 2,
      name: "Business Details",
      isAvailable: ["business", "admin"],
    },
    // {
    //   id: 3,
    //   name: "Billing",
    //   isAvailable: ["business", "admin"],
    // },
    {
      id: 4,
      name: "Team",
      isAvailable: ["business", "admin"],
    },
    {
      id: 5,
      name: "Client Portal",
      isAvailable: ["business", "admin"],
    },
    {
      id: 6,
      name: "Finance",
      isAvailable: ["business", "admin"],
    },
    {
      id: 6,
      name: "Integrations",
      isAvailable: ["business", "admin"],
    },
  ];
  const userType = getUserType();
  return (
    <div className="settings">
      <Card className="settings-card">
        <div className="border-bottom tab-container">
          {settingsTabs.map((tab, index) => {
            return (
              tab.isAvailable.includes(role(userType)) && (
                <div
                  key={index}
                  className={`tab ${
                    tab.name.toLowerCase() === activeTab ? "active-tab" : ""
                  }`}
                  onClick={() => setActiveTab(tab.name.toLowerCase())}
                >
                  {tab.name}
                </div>
              )
            );
          })}
        </div>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="personal details">
            <Personal />
          </TabPane>
          <TabPane tabId="business details">
            <Business />
          </TabPane>
          <TabPane tabId="billing">
            <Billing />
          </TabPane>
          <TabPane tabId="team">
            <Construction />
          </TabPane>
          <TabPane tabId="client portal">
            <ClientPortal />
          </TabPane>
          <TabPane tabId="integrations">
            <Integrations />
          </TabPane>
          <TabPane tabId="finance">
            <Invoice />
          </TabPane>
        </TabContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(Settings);
