import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  RESET_MESSAGE,
  SEND_VERIFICATION_EMAIL,
  SEND_VERIFICATION_EMAIL_SUCCESS,
  SEND_VERIFICATION_EMAIL_ERROR,
} from "../actions";

const INIT_STATE = {
  accessToken: localStorage.getItem("ontrivUserToken"),
  currentUser: "",
  loading: {
    login: false,
    register: false,
    changePassword: false,
    forgotPassword: false,
    verifyEmail: false,
    sendVerificationEmail: false,
  },
  error: {
    login: "",
    register: "",
    changePassword: "",
    forgotPassword: "",
    verifyEmail: "",
    sendVerificationEmail: "",
  },
  message: {
    login: "",
    register: "",
    changePassword: "",
    forgotPassword: "",
    verifyEmail: "",
    sendVerificationEmail: "",
  },
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: { ...state?.loading, login: true },
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, login: false },
        currentUser: action.payload.userInfo,
        message: { ...state?.message, login: action.payload?.message },
        accessToken: action.payload.userInfo.accessToken,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, login: false },
        currentUser: null,
        error: {
          ...state?.error,
          login: action.payload.error,
        },
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: { ...state?.loading, forgotPassword: true } };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, forgotPassword: false },
        message: { ...state?.message, forgotPassword: action.payload },
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, forgotPassword: false },
        error: { ...state?.error, forgotPassword: action.payload.message },
      };

    case REGISTER_USER:
      return {
        ...state,
        loading: { ...state?.loading, register: true },
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, register: false },
        message: { ...state?.message, register: action.payload },
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, register: false },
        error: { ...state?.error, register: action.payload },
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: { ...state?.loading, changePassword: true },
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, changePassword: false },
        message: { ...state?.message, changePassword: action.payload },
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, changePassword: false },
        error: { ...state?.error, changePassword: action.payload },
      };
    case SEND_VERIFICATION_EMAIL:
      return {
        ...state,
        loading: { ...state?.loading, sendVerificationEmail: true },
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, sendVerificationEmail: false },
        message: { ...state?.loading, sendVerificationEmail: action.payload },
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        loading: { ...state?.error, sendVerificationEmail: false },
        error: { ...state?.error, sendVerificationEmail: action.payload },
      };
    case VERIFY_EMAIL:
      return {
        ...state,
        loading: { ...state?.loading, verifyEmail: true },
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: { ...state?.loading, verifyEmail: false },
        message: { ...state?.message, verifyEmail: action.payload },
      };
    case VERIFY_EMAIL_ERROR:
      return {
        ...state,
        loading: { ...state?.loading, verifyEmail: false },
        error: { ...state?.error, verifyEmail: action.payload },
      };
    case LOGOUT_USER:
      return {
        ...state,
        currentUser: null,
        error: "",
        message: null,
        loading: { login: false },
      };
    case RESET_MESSAGE:
      return {
        ...state,
        loading: {
          login: false,
          register: false,
          changePassword: false,
          forgotPassword: false,
          verifyEmail: false,
          sendVerificationEmail: false,
        },
        error: {
          login: "",
          register: "",
          changePassword: "",
          forgotPassword: "",
          verifyEmail: "",
          sendVerificationEmail: "",
        },
        message: {
          login: "",
          register: "",
          changePassword: "",
          forgotPassword: "",
          verifyEmail: "",
          sendVerificationEmail: "",
        },
      };
    default:
      return state;
  }
};

export default authReducer;
