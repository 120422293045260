import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "reactstrap";
import Table from "../../../../components/Table";
import {
  paymentStatus,
  formatInvoiceIssueDate,
  formatAmount,
} from "../../../../utils/helper";
import { HiPlusCircle } from "react-icons/hi";

const InvoicesCard = ({ clients, loading }) => {
  const navigate = useNavigate();
  const cols = React.useMemo(
    () => [
      {
        Header: "Invoice Name",
        accessor: "description",
        cellClass: "pt-4 list-client-item-finance ",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "issued_on",
        cellClass: "pt-4 list-client-item-finance  ",
        Cell: (props) => <>{formatInvoiceIssueDate(props.value)}</>,
      },
      {
        Header: "Inv No",
        accessor: "id",
        cellClass: "pt-4 list-client-item-finance  ",
        Cell: (props) => <>#{props.value}</>,
      },
      {
        Header: "Amount",
        accessor: "total",
        cellClass: "pt-4 list-client-item-finance ",
        Cell: (props) => (
          <>
            <span className="pt-2 mb-0 text-underline">
              {formatAmount(props.value)}
            </span>
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        cellClass: "pt-3  list-client-item-finance ",
        Cell: (props) => <>{paymentStatus(props.value)}</>,
      },
    ],
    []
  );
  return (
    <Card className="client-invoices">
      <h2 className="mb-0">Invoices</h2>
      {clients?.length ? (
        <div className="mb-0 mt-2 overflow-x-scroll">
          <Table
            columns={cols}
            data={clients}
            defaultPageSize={4}
            pagePosition="left"
            loading={loading}
          />
        </div>
      ) : (
        <div className="client-details-empty-invoice-state py-5">
          <div className="mb-2">
            <p className="mb-0 text-center">
              This client does not have any invoices
            </p>
            <p className="mb-0 text-center">created by you</p>
          </div>
          <div className="pt-2 pb-2 mb-1 text-center ">
            <button
              className=" btn-primary btn py-2"
              style={{
                borderRadius: "8px",
                border: "none",
                width: "30%",
                color: "#fff",
                background:
                  "linear-gradient(93.88deg, #49A8F8 6.88%, #0053F4 74.45%) !important",
              }}
              onClick={() => {
                navigate("/invoices-&-financials");
              }}
            >
              <HiPlusCircle /> Create New Invoice
            </button>
          </div>
        </div>
      )}
    </Card>
  );
};

export default InvoicesCard;
